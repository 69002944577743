import { gatewayApi } from "@/ReduxToolkit";
import { packPropertyBag } from "@/ReduxToolkit/MediaProperties";
import { selectMedia } from "../selectors";
import { selectPhotoTypeId } from "../selectors/mediaTypes";
import { getFormData } from "./utils";

export const updateMediaEndpoint = {
	queryFn: async (
		{ file, id, assetId, dataURL },
		{ getState },
		_extra,
		baseQuery
	) => {
		const media = selectMedia(id)(getState());
		const photoType = selectPhotoTypeId(getState());
		const propertyBag = packPropertyBag(media);

		const data = {
			createdBy: "055b1771-f2e1-4b05-9418-c5dc2d594c1a",
			id,
			file,
			MediaTypeId: photoType,
			propertyBag: JSON.stringify(propertyBag),
		};

		const response = await baseQuery({
			url: "api/Media",
			method: "put",
			headers: {
				"content-type": undefined,
			},
			body: getFormData(data),
		});

		if (response.error) return { error: response.error };

		return response;
	},
	onQueryStarted: async (
		{ id, dataURL },
		{ dispatch, getState, queryFulfilled }
	) => {
		const patchResult = dispatch(
			gatewayApi.util.updateQueryData("getMedia", id, (draft) => {
				Object.assign(draft, { path: dataURL });
			})
		);

		try {
			await queryFulfilled;
		} catch {
			patchResult.undo();
		}
	},
	invalidatesTags: (_result, _error, { id, assetId }) => [
		{ type: "Media", id },
	],
};
