import { Input, InputIcon } from "@salesforce/design-system-react";
import classes from "./StaticInput.module.scss";
import { HTMLInputTypeAttribute } from "react";

interface StaticInputProps {
	label?: string;
	name?: string;
	fixedTextLeft?: string;
	fixedTextRight?: string;
	onClickEdit: () => void;
	readOnly?: boolean;
	type?: HTMLInputTypeAttribute;
	value?: any;
	span?: number;
}

export const StaticInput = ({
	onClickEdit = () => {},
	readOnly,
	fixedTextRight = "",
	fixedTextLeft = "",
	type,
	value,
	span,
	...props
}: StaticInputProps) => {
	const isBool = typeof value === "boolean";
	const boolValue = value ? "Yes" : "No";
	const formValue = isBool ? boolValue : value;
	const displayValue = `${fixedTextLeft}${formValue ?? ""}${fixedTextRight}`;
	return (
		<div
			className={classes.StaticInput}
			onClick={() => {
				if (!readOnly) {
					onClickEdit();
				}
			}}
			style={{ gridColumn: `span ${span ?? 2}` }}
		>
			<Input
				readOnly
				disabled={readOnly}
				iconRight={
					!readOnly && (
						<InputIcon
							title={`Edit ${props.label}`}
							name="edit"
							category="utility"
							onClick={() => onClickEdit()}
						/>
					)
				}
				{...props}
				value={displayValue}
			/>
		</div>
	);
};
