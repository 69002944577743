import { useIsOnline } from "@/features/offline-use";
import { useGetOfflineSyncItemsQuery } from "@/features/offline-use/api/endpoints/syncAssetDetails";
import {
	selectIsSyncing,
	selectSyncItemCount,
} from "@/ReduxToolkit/offlineAssetSlice";
import { ImSpinner3 } from "react-icons/im";
import { useSelector } from "react-redux";
import classes from "./HomebaseStatus.module.scss";

export const HomebaseStatus = () => {
	const isOnline = useIsOnline();
	const isSyncing = useSelector(selectIsSyncing);
	const syncItemCount = useSelector(selectSyncItemCount);
	const { data: syncItemsData } = useGetOfflineSyncItemsQuery();
	const syncItems = syncItemsData?.total ?? 0;

	if (!isOnline) {
		return (
			<div className={classes.offline}>
				<span>Offline</span>
			</div>
		);
	}
	if (isSyncing) {
		return (
			<div className={classes.syncing}>
				<span>
					<ImSpinner3 className={classes.spinner} />
					Syncing: ( {syncItemCount - syncItems} / {syncItemCount} items )
				</span>
			</div>
		);
	}
	return <></>;
};
