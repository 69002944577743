import { getDispositionOfTitle, getLoanOrigination } from "@/Utilities/enums";
import dayjs from "dayjs";

export const headerDetails = (account) => {
	// Age calculation = Todays Date - Assigned Date
	const now = dayjs();
	const age = now.diff(account?.assignedDate, "days");
	// Format the assigned Date appropriately
	const assigned = dayjs(account?.assignedDate).format("MM/DD/YYYY");
	return [
		{ label: "Account Balance", content: account?.balance },
		{ label: "Assigned", content: assigned },
		{ label: "Age", content: age + " Days" },
		{ label: "Last Name", content: account?.customerName },
		{ label: "Responsible AM", content: account?.assetManager },
		{ label: "VMF Account Status", content: account?.accountStatus },
		{
			label: "Disposition of Title",
			content: getDispositionOfTitle(account?.dispositionOfTitle),
		},
		{
			label: "Loan Origination",
			content: getLoanOrigination(account?.loanOrigination),
		},
		{ label: "Master Dealer", content: account?.masterDealer },
		{ label: "Lot", content: account?.lotNumber },
	];
};
