import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import dayjs from "dayjs";
import { useInspectionUpdateModal } from "./InspectionUpdate";

export const useInspectionUpdate = (id) => {
	const { data: asset } = useGetAssetQuery(id, {
		selectFromResult: ({ data }) => ({
			data: {
				lastInspection: data?.lastInspection,
				firstInspection: data?.salesInfo?.firstInspection,
			},
		}),
	});
	const inspectionModal = useInspectionUpdateModal();

	const openInspectionModal = (max) => {
		inspectionModal.show({
			newDate: dayjs(max).toISOString(),
			assetId: id,
		});
	};

	const shouldAskForUpdate = (max) => {
		const newDate = dayjs(max).utc(false);
		const today = dayjs();
		const lastInspection = asset?.lastInspection;
		const firstInspection = asset?.firstInspection;
		const ignoredDates = JSON.parse(
			localStorage.getItem("ignoreInspectionDate")
		);
		const ignoreDate = ignoredDates?.[id] ?? false;
		// Has the user asked that we ignore this pop up for the day?
		const shouldIgnore =
			ignoreDate && today.diff(dayjs(ignoreDate), "day") === 0;
		// if the difference is greater than 0, and the user hasn't stated they'd like to ignore for the day on this asset, pop open inspection modal
		return (
			(!firstInspection | !lastInspection ||
				newDate.diff(dayjs(lastInspection).utc(false), "day") > 0) &&
			!shouldIgnore
		);
	};
	return {
		openInspectionModal,
		shouldAskForUpdate,
	};
};
