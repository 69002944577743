import { StaticInput } from "@/Components/Form/StaticInput";
import { SLDSInput } from "@salesforce/design-system-react";
import { useController } from "react-hook-form";
import { useFormSection } from "@/Components/Forms";
import { useIsEditable } from "@/Components/Forms";
import { ChangeEvent, HTMLInputTypeAttribute } from "react";

export type InputProps = {
	name: string;
	section: string;
	readOnly: boolean;
	label?: string;
	span?: number;
	type: HTMLInputTypeAttribute;
	labels?: {
		label: string;
	};
	history?: boolean;
};

export const Input = ({ readOnly, type, ...props }: InputProps) => {
	const {
		field: { ref, value, onChange, ...rest },
		fieldState: { error },
	} = useController({ name: props.name });

	const { isStatic, editField: onClickEdit } = useFormSection(props.section);

	const isEditable = useIsEditable();

	return (
		<span style={{ gridColumn: `span ${props.span ?? 2}` }}>
			{(isStatic || !isEditable) && error === undefined ? (
				<StaticInput
					readOnly={readOnly || !isEditable}
					key={`field-${props.name}`}
					label={props.label || props.labels?.label}
					value={value ?? ""}
					onClickEdit={() => onClickEdit(props.name)}
					{...props}
				/>
			) : (
				<SLDSInput
					inputRef={ref}
					errorText={error?.message}
					value={value?.toString() ?? ""}
					type={type}
					{...props}
					{...rest}
					onChange={(_e: ChangeEvent, { value }: { value: string }) =>
						onChange(Number(value))
					}
					{...(type === "number" && {
						onChange: (_e: ChangeEvent, { value }: { value: string }) => {
							onChange(value ? Number(value) : 0);
						},
					})}
					disabled={!isEditable}
				/>
			)}
		</span>
	);
};
