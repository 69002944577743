import { concatErrorCache } from "@/ReduxToolkit/GatewayApi";
import { Asset } from "../types";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";

export const getAssetEndpoint = (
	builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
	builder.query<Asset, string>({
		query: (id) => `api/HomeDetails/${id}`,
		providesTags: (_result, error, id) => {
			if (error) return concatErrorCache([{ type: "Asset", id }], error);
			return [{ type: "Asset", id }];
		},
		transformResponse: (response: Asset) => {
			return response;
		},
	});
