import { addOfflineAssetEndpoint } from "./addOfflineAsset";
import {
	removeOfflineAssetEndpoint,
	removeAllOfflineAssetsEndpoint,
} from "./removeOfflineAsset";
import {
	getOfflineAssetsEndpoint,
	offlineAssetAdapter,
} from "./getOfflineAssets";
import { gatewayApi } from "@/ReduxToolkit";
import { offlineAssetDB } from "@/storage";
import { listenerMiddleware } from "@/ReduxToolkit/listenerMiddleware";
import {
	getAssetMedia,
	getMedia,
} from "@/features/media-manager/api/endpoints";
import { getConditionReport } from "@/features/condition-report/api/endpoints";
import { cacheOfflineAssets } from "@/ReduxToolkit/offlineAssetSlice";
import {
	getPendingAssetDetailsEndpoint,
	getPendingConditionReportEndpoint,
	getPendingDocumentationEndpoint,
	getPendingMediaUploadsEndpoint,
} from "./getPendingSyncItems";
import { getAllAssets } from "@/features/asset-list/api/endpoints";

gatewayApi.enhanceEndpoints({
	addTagTypes: [
		"OfflineAsset",
		"PendingAssetDetails",
		"PendingMediaUploads",
		"PendingConditionReport",
		"PendingDocumentation",
	],
});

gatewayApi.injectEndpoints({
	endpoints: (builder) => ({
		getOfflineAssets: builder.query(getOfflineAssetsEndpoint),
		addOfflineAsset: builder.mutation(addOfflineAssetEndpoint),
		removeOfflineAsset: builder.mutation(removeOfflineAssetEndpoint),
		removeAllOfflineAssets: builder.mutation(removeAllOfflineAssetsEndpoint),
		getOfflineAsset: builder.query({
			queryFn: async (assetId) => {
				const asset = await offlineAssetDB.getItem(assetId);

				return { data: asset ? true : false };
			},
			providesTags: (id) => [{ type: "OfflineAsset", id }],
		}),
		getPendingAssetDetails: builder.query(getPendingAssetDetailsEndpoint),
		getPendingMediaUploads: builder.query(getPendingMediaUploadsEndpoint),
		getPendingConditionReport: builder.query(getPendingConditionReportEndpoint),
		getPendingDocumentation: builder.query(getPendingDocumentationEndpoint),
	}),
	overrideExisting: true,
});

export const {
	useGetOfflineAssetsQuery,
	useLazyGetOfflineAssetsQuery,
	useLazyCacheOfflineAssetsQuery,
	useAddOfflineAssetMutation,
	useRemoveOfflineAssetMutation,
	useRemoveAllOfflineAssetsMutation,
	useCacheOfflineAssetsQuery,
	useGetOfflineAssetQuery,
	useGetPendingAssetDetailsQuery,
	useGetPendingMediaUploadsQuery,
	useGetPendingConditionReportQuery,
	useGetPendingDocumentationQuery,
	endpoints,
} = gatewayApi;

export { offlineAssetAdapter };

export const { getOfflineAssets, addOfflineAsset } = endpoints;

listenerMiddleware.startListening({
	actionCreator: cacheOfflineAssets,
	effect: async (action, api) => {
		api.cancelActiveListeners();

		const offlineAssetKeys = await offlineAssetDB.keys();

		const offlineAssets = await Promise.all(
			offlineAssetKeys.map(async (key) => await offlineAssetDB.getItem(key))
		);

		const outdatedAssetDetails = offlineAssets
			.filter((asset) => Date.now() - asset?.timestamp > 10 * 60 * 1000)
			.map((asset) => asset.id);

		const outdatedAssetMedia = offlineAssets.filter(
			({ assetMedia }) => Date.now() - assetMedia?.timestamp > 10 * 60 * 1000
		);

		const outdatedMedia = outdatedAssetMedia.flatMap((asset) => asset.media);

		const outdatedConditionReports = offlineAssets
			.filter(
				({ conditionReports }) =>
					Date.now() - conditionReports?.timestamp > 10 * 60 * 1000
			)
			.map(({ id }) => id);

		const assets = outdatedAssetDetails.map((id) =>
			api.dispatch(
				gatewayApi.endpoints.getAsset.initiate(id, { forceRefetch: true })
			)
		);

		const assetListProps = {
			name: "offlineAssets",
			paginationCriteria: {
				itemsPerPage: 20,
				pageNumber: 0,
			},
			filteringCriteria: [
				{
					propertyPath: "id",
					operation: "==",
					value: offlineAssetKeys,
				},
			],
			sortCriteria: { direction: "desc", propertyPath: "year" },
		};

		const assetList = api.dispatch(
			getAllAssets.initiate(assetListProps, {
				forceRefetch: true,
			})
		);

		const assetMedia = outdatedAssetMedia.map(({ id }) =>
			api.dispatch(getAssetMedia.initiate(id, { forceRefetch: true }))
		);

		const media = outdatedMedia.map((media) =>
			api.dispatch(getMedia.initiate(media.id, { forceRefetch: true }))
		);

		const conditionReports = outdatedConditionReports.map((id) =>
			api.dispatch(getConditionReport.initiate(id, { forceRefetch: true }))
		);

		await assetList.unwrap();
		await Promise.allSettled(assets.map(async (asset) => await asset.unwrap()));
		await Promise.allSettled(
			assetMedia.map(async (assetMedia) => await assetMedia.unwrap())
		);

		await Promise.allSettled(media.map(async (media) => await media.unwrap()));

		await Promise.allSettled(
			outdatedMedia.map(
				async (media) =>
					await fetch(`${media.path}&offline&w=1000&h=1000`, {
						mode: "cors",
					})
			)
		);

		await Promise.allSettled(
			conditionReports.map(async (report) => await report.unwrap())
		);

		assetList.unsubscribe();
		assets.forEach((asset) => asset.unsubscribe());
		assetMedia.forEach((assetMedia) => assetMedia.unsubscribe());
		media.forEach((media) => media.unsubscribe());
		conditionReports.forEach((report) => report.unsubscribe());

		const recache = api.fork(async (forkApi) => {
			await forkApi.delay(5 * 60 * 1000);
			return cacheOfflineAssets();
		});

		const recacheResult = await recache.result;

		if (recacheResult.status === "ok") {
			api.dispatch(recacheResult.value);
		}
	},
});
