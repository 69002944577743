import { Icon, Input, InputIcon } from "@salesforce/design-system-react";
import { useEffect, useRef } from "react";
import classes from "../PhotoToolbar/PhotoToolbar.module.scss";
import { useTitle } from "../../hooks/useTitle";

export const MediaPreviewTitle = ({ image }) => {
	const { title, isEditingTitle, setIsEditingTitle, updateTitle } = useTitle(
		image
	);

	const titleRef = useRef();

	useEffect(() => {
		if (isEditingTitle) {
			titleRef.current.focus();
		}
	}, [isEditingTitle, titleRef]);

	const handleTitleInputKeyDown = (e) => {
		if (e.code === "Escape") {
			e.stopPropagation();
		}
	};

	const handleTitleInputKeyUp = (e) => {
		switch (e.code) {
			case "Enter":
				return updateTitle(e.target.value);
			case "Escape":
				return setIsEditingTitle(false);
			default:
				return;
		}
	};

	return (
		<div className={classes.title}>
			{isEditingTitle ? (
				<Input
					type="text"
					defaultValue={title}
					className={classes.titleInput}
					inputRef={(ref) => {
						titleRef.current = ref;
					}}
					onKeyDown={handleTitleInputKeyDown}
					onKeyUp={handleTitleInputKeyUp}
					iconRight={
						<>
							<InputIcon
								inverse
								category="utility"
								name="check"
								style={{ marginRight: "1rem" }}
								onClick={(e) => updateTitle(e.target.previousSibling.value)}
							/>
							<InputIcon
								inverse
								category="utility"
								name="close"
								style={{ marginLeft: ".5rem" }}
								onClick={() => setIsEditingTitle(false)}
							/>
						</>
					}
				/>
			) : (
				<>
					<h3
						className={classes.title}
						onClick={() => setIsEditingTitle(!isEditingTitle)}
					>
						<Icon
							category="utility"
							name="edit"
							size="xx-small"
							variant="neutral"
							inverse
							style={{ marginRight: ".5rem", verticalAlign: "middle" }}
						/>
						{title || "Untitled"}
					</h3>
				</>
			)}
		</div>
	);
};
