import { useSelector } from "react-redux";
import { useTagsMany } from "../../hooks/useTags";
import { useAddtoWebReady } from "./useAddToWebReady";
import { useDeleteMedia } from "./useDeleteMedia";
import { useMoveWebReady } from "./useMoveWebReady";
import { useRemoveFromWebReady } from "./useRemoveFromWebReady";
import { useUploadMedia } from "./useUploadMedia";
import { selectPresignedUploads } from "../selectors";

export const useMediaLoadingState = (assetId) => {
	const { isLoading: isLoadingAddtoWebReady } = useAddtoWebReady();

	const { isLoading: isLoadingRemoveFromWebReady } = useRemoveFromWebReady();

	const { isLoading: isLoadingMoveWebReady } = useMoveWebReady();

	const { isLoading: isLoadingUploadMedia } = useUploadMedia();

	const { isLoading: isLoadingTags } = useTagsMany(assetId);

	const { isLoading: isLoadingDelete } = useDeleteMedia(assetId);

	const uploadingPresignedLength = useSelector(
		selectPresignedUploads()
	)?.length;

	const loadingMessage = [
		isLoadingAddtoWebReady && "adding to Web Ready",
		isLoadingMoveWebReady && "moving image",
		isLoadingRemoveFromWebReady && "removing from Web Ready",
		isLoadingUploadMedia && "uploading media",
		isLoadingTags && "updating tags",
		isLoadingDelete && "deleting media",
		uploadingPresignedLength > 0 &&
			`uploading ${uploadingPresignedLength} media`,
	]
		.filter((message) => message)
		.join(", ");

	const commonLoadingStates =
		isLoadingAddtoWebReady ||
		isLoadingRemoveFromWebReady ||
		isLoadingTags ||
		isLoadingDelete;

	return {
		isLoadingWebReady: commonLoadingStates || isLoadingMoveWebReady,
		isLoadingAllMedia:
			commonLoadingStates ||
			isLoadingUploadMedia ||
			uploadingPresignedLength > 0,
		loadingMessage,
	};
};
