import LogRocket from "logrocket";
import { useGetCurrentUserQuery } from "../api/endpoints";

export const useUser = () => {
	const { data, isSuccess, isLoading, isFetching } = useGetCurrentUserQuery();

	if (isSuccess) {
		LogRocket.identify(data.email);
	}

	return {
		data,
		isSuccess,
		isLoading,
		isFetching,
	};
};
