export const Marker = ({ index, item, onClick }) => {
	return (
		<svg
			style={{ cursor: "pointer" }}
			width="30"
			title={`Account #${item.accountNumber}`}
			height="30"
			xmlns="http://www.w3.org/2000/svg"
			onClick={() => onClick(item)}
		>
			<circle id="svgCircle" stroke="#FFF" fill="green" cx="8" cy="8" r="8" />
			<text
				id="svgText"
				name="stopNumber"
				x="8"
				y="10"
				fontSize="6pt"
				fontFamily="arial"
				fontWeight="bold"
				textAnchor="middle"
				fill="#FFF"
			>
				{index + 1}
			</text>
		</svg>
	);
};
