import { getConditionReport } from "@/features/condition-report/api/endpoints";
import {
	getAssetMedia,
	getMedia,
} from "@/features/media-manager/api/endpoints";
import { gatewayApi } from "@/ReduxToolkit";
import { offlineAssetDB } from "@/storage";
import { offlineAssetAdapter } from "./getOfflineAssets";
import { getAllAssets } from "@/features/asset-list/api/endpoints";

export const addOfflineAssetEndpoint = {
	queryFn: async (assetId, api, extraOptions, baseQuery) => {
		await api.dispatch(
			gatewayApi.util.updateQueryData(
				"getOfflineAssets",
				undefined,
				(offlineAssets) =>
					offlineAssetAdapter.addOne(offlineAssets, { id: assetId })
			)
		);

		await offlineAssetDB.setItem(assetId, {
			id: assetId,
			media: [],
			timestamp: Date.now(),
		});

		const offlineAssetKeys = await offlineAssetDB.keys();

		const assetListProps = {
			name: "offlineAssets",
			paginationCriteria: {
				itemsPerPage: 20,
				pageNumber: 0,
			},
			filteringCriteria: [
				{
					propertyPath: "id",
					operation: "==",
					value: offlineAssetKeys,
				},
			],
			sortCriteria: { direction: "desc", propertyPath: "year" },
		};

		const assetList = api.dispatch(
			getAllAssets.initiate(assetListProps, {
				forceRefetch: true,
			})
		);

		const asset = api.dispatch(
			gatewayApi.endpoints.getAsset.initiate(assetId, { forceRefetch: true })
		);

		try {
			await assetList.unwrap();
			await asset.unwrap();
		} catch (e) {
			await offlineAssetDB.removeItem(assetId);
			return { error: e };
		}

		const assetMedia = api.dispatch(
			getAssetMedia.initiate(assetId, { forceRefetch: true })
		);

		let media;

		try {
			const data = await assetMedia.unwrap();

			const imageIds = data.ids?.filter((id) => {
				const media = data.entities[id];

				return (
					media.tags.includes("Field Manager") ||
					media.tags.includes("Coordinates") ||
					media.isPrimary
				);
			});

			media = imageIds.map((id) =>
				api.dispatch(getMedia.initiate(id, { forceRefetch: true }))
			);

			const mediaResult = await Promise.all(
				media.map(async (media) => await media.unwrap())
			);

			const offlineAsset = await offlineAssetDB.getItem(assetId);
			await offlineAssetDB.setItem(assetId, {
				...offlineAsset,
				media: mediaResult.map(({ id, path }) => ({ id, path })),
			});

			await Promise.all(
				mediaResult.map(
					async (media) =>
						await fetch(`${media.path}&offline&w=1000&h=1000`, {
							mode: "cors",
						})
				)
			);
		} catch (e) {
			const offlineAsset = await offlineAssetDB.getItem(assetId);
			await offlineAssetDB.setItem(assetId, {
				...offlineAsset,
				assetMedia: { ...offlineAsset.assetMedia, isError: true },
			});
		}

		const conditionReport = api.dispatch(
			getConditionReport.initiate(assetId, { forceRefetch: true })
		);

		try {
			await conditionReport.unwrap();
		} catch (e) {
			const offlineAsset = await offlineAssetDB.getItem(assetId);
			await offlineAssetDB.setItem(assetId, {
				...offlineAsset,
				conditionReports: { ...offlineAsset.conditionReport, isError: true },
			});
		}

		asset.unsubscribe();
		assetMedia.unsubscribe();
		media?.forEach((media) => {
			media.unsubscribe();
		});
		conditionReport.unsubscribe();

		return { data: null };
	},
	invalidatesTags: () => {
		return [{ type: "AssetList" }, "OfflineAsset"];
	},
};
