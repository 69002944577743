import { gatewayApi } from "@/ReduxToolkit";
import { serializeUser } from "./userData";

export const updateUserEndpoint = {
	queryFn: async (changes, api, _extraOptions, baseQuery) => {
		// in the future the args should be {id, ...changes} and the selector below should select by id
		// current state should include the patched value from onQueryStarted
		const { data: currentUserData } = await api.dispatch(
			gatewayApi.endpoints.getCurrentUser.initiate()
		);
		const patch = { ...currentUserData, ...changes };

		const patchUser = api.dispatch(
			gatewayApi.util.updateQueryData("getCurrentUser", undefined, (user) =>
				Object.assign(user, patch)
			)
		);

		const result = await baseQuery({
			url: `/api/UserInfo`,
			method: "PUT",
			body: serializeUser(patch),
		});

		if (result.error) {
			patchUser.undo();
			return { error: result.error };
		}

		return result;
	},
	invalidatesTags: (res, _, args) => {
		const returnValue = ["User"];

		return returnValue;
	},
};
