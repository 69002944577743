import { createSelector } from "@reduxjs/toolkit";

import {
	CARPET_PER_YARD,
	PAINT_EXTERIOR_MODIFIER,
	PAINT_HOME_MODIFIER,
	PRESSURE_WASH_BASE_PRICE,
	PRESSURE_WASH_MODIFIER,
	ROOF_SHINGLE_MODIFIER,
	SALES_CLEAN_BASE_PRICE,
	SALES_CLEAN_MODIFIER,
	SKIRTING_MODIFIER,
	LAMINATE_YARDS,
	VINYL_YARDS,
} from "../constants/constants";
import { min, max } from "lodash";
import { gatewayApi } from "@/ReduxToolkit";
import { round } from "lodash";
import { getACPricing } from "../pricing/airConditioners";
import { getHeatPumpPricing } from "../pricing/heatPumps";
const selectConditionReport = (state) => state.conditionReport;
const selectAssetResult = (id) => gatewayApi.endpoints.getAsset.select(id);

export const selectAsset = (id) =>
	createSelector(selectAssetResult(id), (result) => {
		return result?.data;
	});

export const selectPricing = () =>
	createSelector(selectConditionReport, (result) => {
		return result?.pricing ?? {};
	});

export const selectPricingByName = (name) =>
	createSelector(selectConditionReport, (result) => {
		return result?.pricing[name] ?? undefined;
	});

export const selectCarpet = (id) =>
	createSelector(selectConditionReport, selectAsset(id), (result, asset) => {
		const width = asset?.width;
		const length = asset?.length;
		const carpet = result?.pricing[CARPET_PER_YARD];
		return ((width * length) / 9) * 0.75 * carpet;
	});

export const selectLinoluemVinyl = (id) =>
	createSelector(selectConditionReport, selectAsset(id), (result, asset) => {
		const width = asset?.width;
		const length = asset?.length;
		const vinyl = result?.pricing[VINYL_YARDS];
		return ((width * length) / 9) * vinyl * 0.3;
	});
export const selectLVP = (id) =>
	createSelector(selectConditionReport, selectAsset(id), (result, asset) => {
		const width = asset?.width;
		const length = asset?.length;
		const laminate = result?.pricing[LAMINATE_YARDS];
		return ((width * length) / 9) * laminate * 0.3;
	});

export const selectPaintEntireHome = (id) =>
	createSelector(selectConditionReport, selectAsset(id), (result, asset) => {
		const width = asset?.width;
		const length = asset?.length;
		const paintModifier = result?.pricing[PAINT_HOME_MODIFIER];
		return round(width * length * paintModifier, 2);
	});

export const selectAirConditioner = (id) =>
	createSelector(selectAsset(id), (asset) => {
		const width = asset?.width;
		const length = asset?.length;
		const airConditioners = getACPricing();
		const selection = Math.floor((width * length) / 250);
		const index =
			selection > airConditioners.length
				? airConditioners.length - 1
				: selection;
		return 1000 + airConditioners[index];
	});

export const selectHeatPump = (id) =>
	createSelector(selectAsset(id), (asset) => {
		const width = asset?.width;
		const length = asset?.length;
		const heatPumps = getHeatPumpPricing();
		const selection = Math.floor((width * length) / 250);
		const index =
			selection > heatPumps.length ? heatPumps.length - 1 : selection;
		return 1000 + heatPumps[index];
	});

export const selectPressureWash = (id) =>
	createSelector(selectConditionReport, selectAsset(id), (result, asset) => {
		const width = asset?.width;
		const pressureWashModifier = result?.pricing[PRESSURE_WASH_MODIFIER];
		const pressureWashBasePrice = result?.pricing[PRESSURE_WASH_BASE_PRICE];
		return (
			pressureWashBasePrice +
			min([max([width - 17, 0]), 1]) * pressureWashModifier
		);
	});

export const selectSalesCleanEntireHome = (id) =>
	createSelector(selectConditionReport, selectAsset(id), (result, asset) => {
		const width = asset?.width;
		const salesCleanModifier = result?.pricing[SALES_CLEAN_MODIFIER];
		const salesCleanBasePrice = result?.pricing[SALES_CLEAN_BASE_PRICE];
		return (
			salesCleanBasePrice + min([max([width - 18, 0]), 1]) * salesCleanModifier
		);
	});

export const selectPaintExterior = (id) =>
	createSelector(selectConditionReport, selectAsset(id), (result, asset) => {
		const width = asset?.width;
		const length = asset?.length;
		const paintModifier = result?.pricing[PAINT_EXTERIOR_MODIFIER];
		return Math.ceil(width * length * paintModifier);
	});

export const selectRoofShingle = (id) =>
	createSelector(selectConditionReport, selectAsset(id), (result, asset) => {
		const width = asset?.width;
		const length = asset?.length;
		const roofShingleModifier = result?.pricing[ROOF_SHINGLE_MODIFIER];
		return Math.ceil((width * length * 1.11 * 1.1) / 100) * roofShingleModifier;
	});

export const selectSkirting = (id) =>
	createSelector(selectConditionReport, selectAsset(id), (result, asset) => {
		const width = asset?.width;
		const length = asset?.length;
		const skirtingModifier = result?.pricing[SKIRTING_MODIFIER];
		return (width + width + length + length) * skirtingModifier;
	});

export const selectFHAReady = (id) =>
	createSelector(selectConditionReport, selectAsset(id), (result, asset) => {
		const width = asset?.width;
		return width > 18 ? 3000 : 2000;
	});

export const selectSpecialPricing = (id) =>
	createSelector(
		selectRoofShingle(id),
		selectSkirting(id),
		selectPaintExterior(id),
		selectSalesCleanEntireHome(id),
		selectPressureWash(id),
		selectPaintEntireHome(id),
		selectCarpet(id),
		selectAirConditioner(id),
		selectHeatPump(id),
		selectLinoluemVinyl(id),
		selectLVP(id),
		selectFHAReady(id),
		(a, b, c, d, e, f, g, h, i, j, k, l) => {
			return {
				roofShingle: a,
				skirting: b,
				paintExterior: c,
				salesClean: d,
				pressureWash: e,
				paintEntireHome: f,
				carpetEntireHome: g,
				airConditioner: h,
				heatPump: i,
				linoleumVinyl: j,
				lvpEntireHome: k,
				fhaReady: l,
			};
		}
	);

export const selectAllPricing = (id) =>
	createSelector(
		selectConditionReport,
		selectSpecialPricing(id),
		(result, pricing) => {
			return {
				...(result?.pricing ?? {}),
				...pricing,
			};
		}
	);
