import React from "react";
import { useController } from "react-hook-form";
import { VisualPicker, Radio } from "@salesforce/design-system-react";
import classes from "./CommunityInputConcurrency.module.scss";
import { CommunityDataCard } from "./CommunityDataCard";
import { useCommunity } from "../../api/useCommunities";
export const CommunityInputConcurrency = ({ name, options, ...props }) => {
	const {
		field: { ref, ...rest },
	} = useController({
		name: name,
	});
	return (
		<div
			style={{
				padding: "0rem 0rem",
			}}
		>
			<h1>{props.label}</h1>
			<div className={classes.VisualPickerWrapper}>
				<VisualPicker
					label="Select a Community"
					id={`${name}-visual-picker`}
					className={classes.VisualPicker}
				>
					<Radio
						{...rest}
						value={rest?.value?.toString()}
						onChange={() => rest.onChange(options[0])}
						className={classes.Radio}
						id={`${name}-option-1`}
						onRenderVisualPicker={() => <RadioCard value={options[0]} />}
					/>
					<Radio
						{...rest}
						value={rest?.value?.toString()}
						id={`${name}-option-2`}
						className={classes.Radio}
						onChange={() => rest.onChange(options[1])}
						onRenderVisualPicker={() => <RadioCard value={options[1]} />}
					/>
				</VisualPicker>
			</div>
		</div>
	);
};

const RadioCard = ({ value }) => {
	const { data, isSuccess } = useCommunity(value);
	return (
		<>
			{isSuccess ? (
				<CommunityDataCard community={data} />
			) : (
				<div
					style={{
						minHeight: "3rem",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					None
				</div>
			)}
		</>
	);
};
