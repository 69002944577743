import {
	getCurrentUser,
	useUpdateUserMutation,
} from "@/features/user/api/endpoints";
import { TaskFilterForm } from "../components/Filters/TasksFilters";

interface NewList extends TaskFilterForm {
	searchName: string;
}

export const useUpdateTaskLists = () => {
	const [getUser] = getCurrentUser.useLazyQuery();
	const [updateUser] = useUpdateUserMutation();

	const saveNewList = async (filters: NewList) => {
		const currentUserLists = await getUser();

		if ("error" in currentUserLists || !("data" in currentUserLists)) {
			return {
				error: true,
				message: "Something went wrong fetching users task lists",
			};
		}

		const currentLists = currentUserLists.data?.savedTaskViews || {};
		const newLists = {
			...currentLists,
			[filters.searchName]: filters.filters,
		};

		const result = await updateUser({
			savedTaskViews: newLists,
		});
		if ("error" in result) {
			return {
				error: true,
				message: "something went wrong updating users task lists",
			};
		}
		return {
			error: false,
			message: "Task list saved successfully",
		};
	};

	const saveDefaultList = async (filter: string) => {
		const currentUser = await getUser();

		if ("error" in currentUser || !("data" in currentUser)) {
			return {
				error: true,
				message: "Something went wrong fetching users task lists",
			};
		}
		const result = await updateUser({
			defaultTaskView: filter,
		});
		if ("error" in result) {
			return {
				error: true,
				message: "something went wrong updating default task list",
			};
		}
		return {
			error: false,
			message: "Default task list saved successfully",
		};
	};

	const deleteSavedList = async (filterName: string) => {
		const currentUser = await getUser();
		if ("error" in currentUser || !("data" in currentUser)) {
			return {
				error: true,
				message: "Something went wrong fetching users task lists",
			};
		}
		let currentLists = { ...currentUser.data?.savedTaskViews };

		delete currentLists[filterName];

		const result = await updateUser({
			savedTaskViews: currentLists,
		});
		if ("error" in result) {
			return {
				error: true,
				message: "something went wrong deleting users task list",
			};
		}
		return {
			error: false,
			message: "Task list deleted successfully",
		};
	};

	return { saveNewList, saveDefaultList, deleteSavedList };
};
