import {
	parsePropertyBag,
	unpackPropertyBag,
} from "@/ReduxToolkit/MediaProperties";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const getFormData = (object) =>
	Object.keys(object).reduce((formData, key) => {
		formData.append(key, object[key]);
		return formData;
	}, new FormData());

export const shapeMediaObject = (media) => {
	const properties = unpackPropertyBag(media);

	return {
		...media,
		dateUploaded: dayjs(media.dateUploaded).utc(true).toISOString(),
		dateModified:
			media.dateModified === null
				? null
				: dayjs(media.dateModified).utc(true).toISOString(),
		dateFileCreated: dayjs(media.dateFileCreated).utc(true).toISOString(),
		path: `${media.path}?v=${dayjs(
			media.dateModified ?? media.dateFileCreated
		).valueOf()}`,
		...properties,
	};
};
export const shapeData = (mediaFiles) => {
	const mediaArray = [];
	for (let media in mediaFiles) {
		const flattenedAssetList = shapeMediaObject(
			parsePropertyBag(mediaFiles[media])
		);
		mediaArray.push(flattenedAssetList);
	}
	return mediaArray;
};
/**
 *
 * @param {String} path Base path of image
 * @param {Array<Object>} queryParams Optional Query Params array
 * @returns {String} Resized Image url
 */
export const resizedImage = (path, queryParams) => {
	const url = new URL(path);
	queryParams.map((item) => url.searchParams.append(item.name, item.value));
	return url;
};
