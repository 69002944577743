import { createSlice } from "@reduxjs/toolkit";

export const insuranceQueueSlice = createSlice({
	name: "insuranceQueue",
	initialState: {
		filters: {},
	},
	reducers: {
		setFilterValues: (state, action) => {
			const { attribute, value } = action.payload;
			state.filters = { ...state.filters, [attribute]: value };
			return state;
		},
	},
});

export const { setFilterValues } = insuranceQueueSlice.actions;

export const selectInsuranceQueueFilters = (state) =>
	state.insuranceQueue?.filters;
