// import { GiCancel } from "react-icons/gi";
// import { GiPlainCircle } from "react-icons/gi";
// import { BiRefresh } from "react-icons/bi";
import classes from "./Badges.module.scss";
// import { RiUploadCloudFill } from "react-icons/ri";
// import { ImCheckmark } from "react-icons/im";
import { useGetAssetDetailsConcurrencyErrorsQuery } from "@/features/offline-use/api/endpoints/syncAssetDetails";

// const Upload = () => <RiUploadCloudFill />;
// const Offline = () => <GiCancel />;
// const Refreshing = () => <BiRefresh />;
// const Online = () => <ImCheckmark />;
// const Alert = ({ number }) => (
// 	<div
// 		style={{
// 			width: "12px",
// 			height: "12px",
// 			padding: "1rem",
// 			backgroundColor: "red",
// 			color: "white",
// 			textAlign: "center",
// 			font: "6px",
// 		}}
// 	>
// 		{number}
// 	</div>
// );

// // const badges = {
// // 	upload: { Component: Upload, style: "upload" },
// // 	offline: { Component: Offline, style: "offline" },
// // 	refresh: { Component: Refreshing, style: "refresh" },
// // 	online: { Component: Online, style: "online" },
// // 	alert: { Component: Alert, style: "alert" },
// // };

export const Badge = () => {
	const { data: concurrencyErrors, isSuccess } =
		useGetAssetDetailsConcurrencyErrorsQuery();
	if (isSuccess && Object.keys(concurrencyErrors).length > 0) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
				className={classes.alert}
			>
				{Object.keys(concurrencyErrors).length}
			</div>
		);
	}
	return <></>;
};
