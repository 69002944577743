import { useIsMobile } from "@/Responsive";
import {
	ACCOUNT_NUMBER,
	ADDRESS,
	ASSET_MANAGER_ID,
	ASSIGNED_DATE,
	BALANCE,
	CITY,
	CUSTOMER_NAME,
	LENGTH,
	PRICING_WORKSHEET_APPROVAL_STATUS,
	REMARKETING_STATUS_TYPE_ID,
	STATE,
	STOCK_NUMBER,
	WIDTH,
	YEAR,
	ZIP,
} from "@/features/asset/constants";
import { useFeature } from "@/providers/feature-flags/FeatureProvider";
import { PRICING_WORKSHEET_FEATURE_FLAG } from "@/providers/feature-flags/constants";

const desktopOnlyColumns = [
	STOCK_NUMBER.path,
	YEAR.path,
	WIDTH.path,
	LENGTH.path,
	BALANCE.path,
];

const assetPaths = [
	ACCOUNT_NUMBER.path,
	PRICING_WORKSHEET_APPROVAL_STATUS.path,
	CUSTOMER_NAME.path,
	REMARKETING_STATUS_TYPE_ID.path,
	BALANCE.path,
	ASSIGNED_DATE.path,
	ADDRESS.path,
	CITY.path,
	STATE.path,
	ZIP.path,
	STOCK_NUMBER.path,
	YEAR.path,
	LENGTH.path,
	WIDTH.path,
	ASSET_MANAGER_ID.path,
];

const tripPaths = [
	ACCOUNT_NUMBER.path,
	ADDRESS.path,
	CITY.path,
	STATE.path,
	ZIP.path,
	ASSIGNED_DATE.path,
];

export const useGetColumns = () => {
	const isMobile = useIsMobile();
	const isEnabledPricingWorksheet = useFeature(PRICING_WORKSHEET_FEATURE_FLAG);

	const config = {
		tripPlanning: tripPaths,
		assetList: assetPaths,
		mobileAssetList: assetPaths.filter(
			(item) => !desktopOnlyColumns.includes(item)
		),
	};

	const pricingWorksheetFilter = (path) =>
		!(
			path === PRICING_WORKSHEET_APPROVAL_STATUS.path &&
			!isEnabledPricingWorksheet
		);
	const getColumns = () => {
		const assetListCols = isMobile
			? config.mobileAssetList
			: config.assetList.filter(pricingWorksheetFilter);

		return window.location.pathname === "/trip-planning"
			? config.tripPlanning
			: assetListCols;
	};
	return getColumns;
};
