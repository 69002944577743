import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { SLDSInput } from "@salesforce/design-system-react";
import { useWatch } from "react-hook-form";

export const DateFilter = ({ value, name, onChange, ...props }) => {
	const isISODate = dayjs(value).isValid() && !/^\d{4}-\d\d-\d\d$/.test(value);

	const op = useWatch({ name: props.operation });
	const convertValue = (val) =>
		isISODate ? dayjs(val).utc(false).format("YYYY-MM-DD") : val ?? "";

	const [inputValue, setInputValue] = useState(convertValue(value));

	const onChangeHandler = (e, { value }) => {
		setInputValue(value);
		if (dayjs(value).isValid()) {
			if (op === "<=") {
				onChange(dayjs(value).add(1, "second").utc(true).toISOString());
			} else {
				onChange(dayjs(value).utc(true).toISOString());
			}
		} else {
			onChange(null);
		}
	};

	useEffect(() => {
		if (op === "dateRange" && !Array.isArray(value)) {
			onChange([convertValue(value), convertValue(value)]);
		} else if (op !== "dateRange" && Array.isArray(value)) {
			onChange(convertValue(value[0]));
		}
		//eslint-disable-next-line
	}, [op]);

	if (op === "dateRange") {
		const [start, end] = value;
		return (
			<>
				<SLDSInput
					value={dayjs(start).utc(false).format("YYYY-MM-DD")}
					name={`${name}-0`}
					type="date"
					onChange={(e, { value }) => {
						if (dayjs(value).isValid()) {
							onChange([dayjs(value).utc(true).toISOString(), end]);
						}
					}}
					maxValue="2100-12-31"
					minValue="1980-01-01"
				/>
				<SLDSInput
					value={dayjs(end).utc(false).format("YYYY-MM-DD")}
					name={`${name}-1`}
					type="date"
					onChange={(e, { value }) => {
						if (dayjs(value).isValid()) {
							onChange([start, dayjs(value).utc(true).toISOString()]);
						}
					}}
					maxValue="2100-12-31"
					minValue="1980-01-01"
				/>
			</>
		);
	}
	return (
		<SLDSInput
			value={inputValue}
			name={name}
			type="date"
			onChange={onChangeHandler}
			maxValue="2100-12-31"
			minValue="1980-01-01"
		/>
	);
};
