import classes from "@/Components/ControlledForm.module.scss";
import {
	ControlledInput as Input,
	ControlledCheckbox as Checkbox,
	ControlledTextarea as Textarea,
} from "@/Components/Form";
import { ControlledAddress as Address } from "./ControlledAddress";
import { Coordinates } from "./Coordinates";
import {
	AssetLocationsSelect,
	useSelectAssetLocations,
} from "@/features/lookups";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
	ContactPersonTypeSelect,
	StateSelect,
} from "@/features/enums/EnumSelect";
import React, { useEffect } from "react";

import textAreaClasses from "@/Components/Form/ControlledTextarea/ControlledTextArea.module.scss";
import { nanoid } from "@reduxjs/toolkit";
import {
	ACREAGE,
	ADDRESS,
	ADDRESS_2,
	ASSET_LOCATION_TYPE_ID,
	CITY,
	COMMUNITY_NUMBER,
	CONTACT_NAME,
	CONTACT_PERSON_TYPE,
	CONTACT_PHONE,
	COUNTY,
	DEALER_NUMBER,
	LAND_OWNER_NAME,
	LAND_OWNER_PHONE_NUMBER,
	LAND_RENT_AMOUNT,
	PARK_RENT_AMOUNT,
	SALES_CENTER,
	STATE,
	HOME_STAYS_ON_LAND,
	ZIP,
} from "@/features/asset/constants";

import { LOCATION_SECTION_TITLE, SPECIAL_INSTRUCTIONS } from "./constants";

import {
	FormSection,
	useFormSection,
} from "@/Components/Form/ControlledForm/FormSection";
import { CommunityInput } from "@/features/communities/components/community-input/CommunityInput";
import { useIsOnline } from "@/features/offline-use";
import { useIsMobile } from "@/Responsive";

export const LocationInfoForm = ({ assetId, ...props }) => {
	return (
		<FormSection title={LOCATION_SECTION_TITLE} {...props}>
			<LocationInfoFields />
		</FormSection>
	);
};

function useLookupIdFromDescription(lookup) {
	const assetLocations = useSelectAssetLocations();

	return (descriptionMatcher) =>
		Object.entries(assetLocations).find(
			([_id, description]) => description === descriptionMatcher
		)?.[0];
}

function useIsAssetLocation(description) {
	const { watch } = useFormContext();
	const findId = useLookupIdFromDescription();
	const locationIdField = watch(ASSET_LOCATION_TYPE_ID.path);
	return locationIdField === findId(description);
}

function CommunitiesParksInput({ name, ...props }) {
	const { setValue } = useFormContext();
	const isCommunitiesParks = useIsAssetLocation("Communities/Parks");

	useEffect(() => {
		if (!isCommunitiesParks) {
			setValue(name, null, { shouldTouch: false, shouldDirty: false });
		}
		// eslint-disable-next-line no-sparse-arrays
	}, [isCommunitiesParks, name, setValue]);

	return <>{isCommunitiesParks && <Input name={name} {...props} />}</>;
}

function PrivatePropertyInput({ name, ...props }) {
	const { setValue } = useFormContext();
	const isPrivateProperty = useIsAssetLocation("Private Property");

	useEffect(() => {
		if (!isPrivateProperty) {
			setValue(name, null, { shouldTouch: false, shouldDirty: false });
		}
	}, [isPrivateProperty, name, setValue]);

	return <>{isPrivateProperty && <Input name={name} {...props} />}</>;
}

function SalesCenterInput({ name, ...props }) {
	const { setValue } = useFormContext();

	const isClaytonSalesCenter = useIsAssetLocation("Clayton Sales Center");

	const isIndependentSalesCenter = useIsAssetLocation(
		"Independent Sales Center"
	);

	const isSalesCenter = isClaytonSalesCenter || isIndependentSalesCenter;

	useEffect(() => {
		if (!isSalesCenter) {
			setValue(name, null, { shouldTouch: false, shouldDirty: false });
		}
	}, [isSalesCenter, name, setValue]);

	return <>{isSalesCenter && <Input name={name} {...props} />}</>;
}

const AddressSearch = ({ name, ...props }) => {
	const online = useIsOnline();
	if (online) {
		return <Address name={name} {...props} />;
	}
	return <Input name={name} {...props} />;
};

function LocationInfoFields() {
	const { isLoaded, control, watch, isConcurrencyError } = useFormContext();
	const { isStatic } = useFormSection();
	const isMobile = useIsMobile();

	const { fields } = useFieldArray({
		name: SPECIAL_INSTRUCTIONS,
		control,
	});

	const watchFieldArray = watch(SPECIAL_INSTRUCTIONS);

	const controlledFields = fields.map((field, index) => {
		return {
			...field,
			...watchFieldArray[index],
		};
	});

	const fullWidthTextArea =
		!isConcurrencyError && !isStatic
			? {
					display: "grid",
					gridTemplateColumns: "1fr 2rem",
			  }
			: {};

	return (
		isLoaded && (
			<>
				<div className={classes.formGrid}>
					<AddressSearch
						name={ADDRESS.path}
						label={ADDRESS.label}
						span={isMobile ? 4 : 2}
					/>
					<Input name={ADDRESS_2.path} label={ADDRESS_2.label} />
					<Input name={CITY.path} label={CITY.label} />
					<StateSelect name={STATE.path} labels={{ label: STATE.label }} />
					<Input name={ZIP.path} label={ZIP.label} />
					<Input name={COUNTY.path} label={COUNTY.label} readOnly />
					<Coordinates />
					<ContactPersonTypeSelect
						name={CONTACT_PERSON_TYPE.path}
						labels={{ label: CONTACT_PERSON_TYPE.label }}
					/>
					<Input name={CONTACT_NAME.path} label={CONTACT_NAME.label} />
					<Input
						name={CONTACT_PHONE.path}
						label={CONTACT_PHONE.label}
						type="tel"
					/>

					<Input name={ACREAGE.path} label={ACREAGE.label} type="number" />
					<Input
						name={DEALER_NUMBER.path}
						label={DEALER_NUMBER.label}
						type="number"
					/>
					<Checkbox
						name={HOME_STAYS_ON_LAND.path}
						label={HOME_STAYS_ON_LAND.label}
					/>
					<AssetLocationsSelect
						name={ASSET_LOCATION_TYPE_ID.path}
						labels={{ label: ASSET_LOCATION_TYPE_ID.label }}
					/>
					<SalesCenterInput
						name={SALES_CENTER.path}
						label={SALES_CENTER.label}
					/>
					<PrivatePropertyInput
						name={LAND_OWNER_NAME.path}
						label={LAND_OWNER_NAME.label}
					/>
					<PrivatePropertyInput
						name={LAND_OWNER_PHONE_NUMBER.path}
						label={LAND_OWNER_PHONE_NUMBER.label}
					/>
					<PrivatePropertyInput
						name={LAND_RENT_AMOUNT.path}
						label={LAND_RENT_AMOUNT.label}
						type="number"
						fixedTextLeft="$"
					/>
					<CommunitiesParksInput
						name={PARK_RENT_AMOUNT.path}
						label={PARK_RENT_AMOUNT.label}
						type="number"
						fixedTextLeft="$"
					/>
					<CommunityInput name={COMMUNITY_NUMBER.path} />

					{controlledFields.map((field, index) => {
						return (
							<div
								key={`${SPECIAL_INSTRUCTIONS}-${index}-location`}
								className={textAreaClasses.gridItem}
								style={fullWidthTextArea}
							>
								<Textarea
									key={field.id || nanoid(5)}
									className={isStatic ? undefined : ""}
									name={`${SPECIAL_INSTRUCTIONS}[${index}].note`}
									label={`Location Note`}
								/>
							</div>
						);
					})}
				</div>
			</>
		)
	);
}
