import { useDispatch, useSelector } from "react-redux";
import {
	removeProgress,
	selectImagingDownloadProgress,
	setProgress
} from "./ImagingSlice";
import { openToast } from "@/ReduxToolkit/ToastsSlice";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { getAPIURL, getToken } from "@/ReduxToolkit/GatewayApi";
import { useCallback, useState } from "react";

export const useDownloadDocument = (documentId, accountNumber) => {
	const [isLoading, setIsLoading] = useState(false);
	const progress = useSelector(
		selectImagingDownloadProgress(accountNumber, documentId)
	);
	const { assetId } = useAssetId();
	const dispatch = useDispatch();

	const fetchDoc = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await fetch(
				`${getAPIURL()}api/Imaging/documents/${documentId}`,
				{
					method: "GET",
					headers: {
						Authorization: "Bearer " + (await getToken()),
						Accept: "application/octet-stream"
					}
				}
			);
			if (!response.ok) {
				throw response;
			}
			const reader = response.body.getReader();
			let receivedLength = 0; // received that many bytes at the moment
			let chunks = []; // array of received binary chunks (comprises the body)
			while (true) {
				const { done, value } = await reader.read();
				if (done) {
					break;
				}
				chunks.push(value);
				receivedLength += value.length;
				dispatch(
					setProgress({ documentId, accountNumber, progress: receivedLength })
				);
			}

			let chunksAll = new Uint8Array(receivedLength); // (4.1)
			let position = 0;
			for (let chunk of chunks) {
				chunksAll.set(chunk, position); // (4.2)
				position += chunk.length;
			}
			const newBlob = new Blob([chunksAll], { type: "application/pdf" });

			const doc = window.URL.createObjectURL(newBlob);
			setIsLoading(false);
			dispatch(removeProgress({ documentId, accountNumber }));
			return { data: doc };
		} catch {
			setIsLoading(false);
			dispatch(
				openToast({
					id: assetId,
					labels: {
						heading: "Error",
						details: "Something went wrong downloading the document"
					},
					timeout: 3000,
					variant: "error"
				})
			);
			dispatch(removeProgress({ documentId, accountNumber }));
			return { error: true };
		}
		//eslint-disable-next-line
	}, [documentId]);

	return { fetchDoc, progress, isLoading };
};
