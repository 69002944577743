import { create, group, only } from "vest";
import { bookingValidation } from "./components/booking/bookingValidation";
import { BOOKING_SECTION_TITLE } from "./components/booking/constants";
import { HOME_INFO_SECTION_TITLE } from "./components/home/constants";
import { homeValidation } from "./components/home/homeValidation";
import { LISTING_SECTION_TITLE } from "./components/listing/constants";
import { listingValidation } from "./components/listing/listingValidation";
import { LOCATION_SECTION_TITLE } from "./components/location/constants";
import { locationValidation } from "./components/location/locationValidation";
import { LOCATION_MODAL_TITLE } from "../trip-planning/components/TripPlanning/LocationModal";
import { locationModalValidation } from "../trip-planning/components/TripPlanning/locationModalValidation";

export const assetDetailValidation = create(
	(data, activeSection, concurrencyDiff, isConcurrencyError) => {
		if (isConcurrencyError) {
			only(Object.keys(concurrencyDiff));
			bookingValidation(data);
			homeValidation(data);
			locationValidation(data);
			listingValidation(data);
			locationModalValidation(data);
		} else {
			only.group(activeSection);
			group(BOOKING_SECTION_TITLE, () => {
				bookingValidation(data);
			});
			group(HOME_INFO_SECTION_TITLE, () => {
				homeValidation(data);
			});
			group(LOCATION_SECTION_TITLE, () => {
				locationValidation(data);
			});
			group(LISTING_SECTION_TITLE, () => {
				listingValidation(data);
			});
			group(LOCATION_MODAL_TITLE, () => {
				locationModalValidation(data);
			});
		}
	}
);
