import { vestResolver } from "@hookform/resolvers/vest";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateSaleMutation } from "../api/endpoints";
import { createSaleValidation } from "./createSaleValidation";
import { Date, Input } from "./SourceOfFunds";
import { Button } from "@salesforce/design-system-react";
import classes from "./SalesInformation.module.scss";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { openToast } from "@/ReduxToolkit/ToastsSlice";
import { Sale } from "../types";
import { bookingEndpoints } from "../api/endpoints";

export const SalesInformation = ({
	sale,
	assetId,
}: {
	assetId: string;
	sale: Sale;
}) => {
	const { reset, ...methods } = useForm({
		resolver: vestResolver(createSaleValidation),
		defaultValues: {
			...sale,
			saleDate: dayjs(sale.saleDate).utc(true).toISOString(),
		},

		mode: "onChange",
	});

	useEffect(() => {
		if (sale && sale.id && methods.control._formValues) {
			reset(sale);
		}
		//eslint-disable-next-line
	}, [sale]);
	const [updateSale] = useUpdateSaleMutation();
	const dispatch = useDispatch();

	const onSubmit = async (data: Sale) => {
		const optimistic = dispatch(
			bookingEndpoints.util.updateQueryData("getSaleById", sale.id, () => {
				return data;
			})
		);
		const updatedSale = await updateSale(data);
		if ("error" in updatedSale) {
			// show toast if error
			dispatch(
				openToast({
					id: assetId,
					labels: {
						heading: "Error",
						details: "Something went wrong updating sales info",
					},
					timeout: 3000,
					variant: "error",
				})
			);
			//@ts-ignore
			optimistic.undo();
		}
	};

	return (
		<div>
			<FormProvider {...methods} reset={reset}>
				<form name="Source of Funds" onSubmit={methods.handleSubmit(onSubmit)}>
					<div className={classes.container}>
						<Input
							name="totalAmount"
							type="number"
							fixedTextLeft="$"
							step={0.01}
							label="Total Sale Amount"
						/>
						<Date name="saleDate" label="Date of Sale" />
					</div>

					{methods.formState.isDirty && (
						<div className={classes.submitButtons}>
							<Button
								disabled={!methods.formState.isDirty}
								onClick={() => reset()}
							>
								Reset
							</Button>
							<Button
								disabled={!methods.formState.isDirty}
								variant="brand"
								type="submit"
							>
								Save
							</Button>
						</div>
					)}
				</form>
			</FormProvider>
		</div>
	);
};
