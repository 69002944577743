import {
	getDispositionOfTitle,
	getLoanOrigination,
	getWindZone,
} from "@/Utilities/enums";
import dayjs from "dayjs";
import { Asset } from "../../types";

const formatDate = (value: string) => {
	return value ? dayjs(value).format("MM/DD/YYYY") : "";
};

export const headerDetails = (
	asset: Asset,
	statuses: Record<string, string>
) => {
	const now = dayjs();
	const age = now.diff(asset.account.assignedDate ?? "", "days");

	return [
		{ label: "Display Number", content: asset.displayAccount },
		{ label: "Account Balance", content: "$" + asset.account.balance },
		{ label: "City", content: asset.physicalLocation.city },
		{ label: "State", content: asset.physicalLocation.state },
		{ label: "Zip", content: asset.physicalLocation.zip },
		{
			label: "Assigned",
			content: dayjs(asset.account.assignedDate).format("MM/DD/YYYY"),
		},
		{ label: "Age", content: age + " Days" },
		{ label: "Last Name", content: asset.account.customerName },
		{ label: "Responsible AM", content: asset.account.assetManager },
		{ label: "VMF Account Status", content: asset.account.accountStatus },
		{
			label: "Remarketing Asset Status",
			content: statuses[asset.remarketingStatusTypeId ?? ""],
		},
		{
			label: "Personal Items Letter Expiration Date",
			content: formatDate(asset.salesInfo.personalItemsLetterExpiration ?? ""),
		},
		{
			label: "Notice of Sale Letter Expiration Date",
			content: formatDate(asset.noticeOfSaleExpires ?? ""),
		},
		{
			label: "Projected Recovery Percentage",
			content: asset.salesInfo.projectedRecoveryPercent ?? 0 + "%",
		},
		{
			label: "Market Ready Date",
			content: formatDate(asset.marketingInfo.marketReadyDate ?? ""),
		},
		{ label: "Wind Zone", content: getWindZone(asset.windZone) },
		{
			label: "Land Involvement",
			content: getDispositionOfTitle(asset.account.dispositionOfTitle),
		},
		{
			label: "Loan Origination",
			content: getLoanOrigination(asset.account.loanOrigination),
		},
		{ label: "Master Dealer", content: asset.account.masterDealer },
		{ label: "Lot", content: asset.account.lotNumber },
		{ label: "Vantage Status", content: asset.vantageStatus },
	];
};
