import { Button, ButtonGroup, Dropdown } from "@salesforce/design-system-react";
import React from "react";
import { useSelectionDropdownActions } from "@/features/media-manager/hooks/useSelectionDropdownActions";
import { useSelectedMedia } from "@/features/media-manager/api/hooks/useSelectedMedia";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";

export function DesktopSelectionGroup({ bucket, selectionActions }) {
	const { assetId } = useAssetId();

	const { selectAll, selectNone } = useSelectedMedia({
		bucket,
		assetId,
	});

	const actions = useSelectionDropdownActions({
		bucket,
		assetId,
		selectionActions,
	});

	const handleSelectAction = (e) => e.value();

	return (
		<>
			<ButtonGroup variant="list">
				<Button
					label="Select All"
					size="small"
					variant="neutral"
					onClick={selectAll}
				/>
				<Button label="Select None" variant="neutral" onClick={selectNone} />
				<Dropdown
					label="Actions"
					iconName="down"
					iconCategory="utility"
					iconPosition="right"
					align="right"
					options={actions}
					onSelect={handleSelectAction}
				/>
			</ButtonGroup>
		</>
	);
}
