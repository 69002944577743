import { Button } from "@salesforce/design-system-react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const SubmitButtons = ({ form }) => {
	const {
		reset,
		formState: { isSubmitSuccessful, dirtyFields },
		isConcurrencyError,
		concurrencyReset,
		isStatic,
	} = useFormContext();

	const handleReset = (e) => {
		if (isConcurrencyError) concurrencyReset();
		reset();
	};

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset();
		}
	});

	return (
		!isStatic && (
			<div
				style={{
					display: "grid",
					backgroundColor: "white",
					marginTop: ".5rem",
					placeContent: "center",
					width: "100%",
					height: "4rem",
					position: "sticky",
					bottom: "0px",
					background: "rgba(236, 235, 234, 0.9)",
					zIndex: "50",
				}}
			>
				<div>
					<Button onClick={handleReset}>Cancel</Button>
					<Button
						variant="brand"
						type="submit"
						form={form}
						disabled={!Object.keys(dirtyFields).length && !isConcurrencyError}
					>
						Save
					</Button>
				</div>
			</div>
		)
	);
};
