import React, { useState } from "react";
import { Button } from "@salesforce/design-system-react";
import classes from "@/features/media-manager/components/media-container/MediaContainer.module.scss";
import { Filter } from "./Filter";
import { useDispatch, useSelector } from "react-redux";
import { Desktop } from "@/Responsive";
import {
	addOrUpdateFilter,
	removeAllFilters,
	removeFilter,
} from "@/ReduxToolkit/MediaSlice";
import { selectAllMediaFilters } from "../../api/selectors";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";

export const FilterPanel = ({
	title,
	label,
	closeFilterPanel,
	container = null,
	...props
}) => {
	const { assetId } = useAssetId();
	const [isAddingNewFilter, setIsAddingNewFilter] = useState(false);
	const filters = useSelector(selectAllMediaFilters(assetId));
	const dispatch = useDispatch();

	const handleAddOrUpdateFilter = (filter) => {
		dispatch(addOrUpdateFilter({ assetId, filter }));
		setIsAddingNewFilter(false);
	};

	const handleRemoveFilter = (id) => {
		dispatch(removeFilter({ assetId, filterId: id }));
	};

	const topFix = {
		page: classes.filterPageTopFix,
	};

	const footer = (
		<div className="slds-filters__footer slds-grid slds-shrink-none">
			<button
				onClick={() => {
					setIsAddingNewFilter(true);
				}}
				className="slds-button_reset slds-text-link"
				type="button"
			>
				Add Filter
			</button>
			<button
				className="slds-button_reset slds-text-link slds-col_bump-left"
				type="button"
				onClick={() => {
					dispatch(removeAllFilters({ assetId }));
				}}
			>
				Remove All
			</button>
		</div>
	);

	const header = (
		<div className="slds-filters__header slds-grid slds-has-divider_bottom-space">
			<h2 className="slds-align-middle slds-text-heading_small">{label}</h2>
			<Desktop>
				<Button
					className="slds-button slds-col_bump-left"
					variant="icon"
					iconCategory="utility"
					iconName="close"
					onClick={() => {
						closeFilterPanel();
					}}
				/>
			</Desktop>
		</div>
	);

	return (
		<div
			className={`${classes.FilterPanel} ${
				container ? topFix[container] : ""
			} ${filters?.length > 0 ? classes.hasFilters : ""}`}
		>
			{header}
			{filters?.map((filter, index) => (
				<Filter
					key={filter.id}
					id={filter.id}
					isEditing={false}
					property={filter.property}
					comparison={filter.comparison}
					predicate={filter.predicate}
					onAddOrUpdateFilter={handleAddOrUpdateFilter}
					onRemoveFilter={handleRemoveFilter}
				/>
			))}
			{isAddingNewFilter && (
				<Filter
					isEditing={true}
					onAddOrUpdateFilter={handleAddOrUpdateFilter}
				/>
			)}
			{footer}
		</div>
	);
};
