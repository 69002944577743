import React from "react";
import {
	DataTable,
	DataTableColumn,
	DataTableCell,
} from "@salesforce/design-system-react";
import {
	AccountDataTableCell,
	DateCell,
	InsuranceDecision,
	InsuranceStatusCell,
} from "./TableValues";
import { useSort } from "@/features/asset-list/hooks/useSort";
import { useInsuranceQueue } from "../hooks/useInsuranceQueue";
const config = {
	id: {
		component: AccountDataTableCell,
		width: "60px",
		label: "ID",
		title: "ID",
	},
	repossessionDate: {
		component: DateCell,
		label: "Cancellation Date",
		title: "CancellationDate",
	},
	loanNumber: {
		label: "Loan Number",
		title: "LoanNumber",
	},
	xferNumber: {
		label: "Xfer Number",
		title: "XferNumber",
	},
	customerName: {
		label: "Last Name",
		title: "LastName",
	},
	policyType: {
		label: "Policy Type",
		title: "PolicyType",
	},
	addedDate: {
		component: DateCell,
		label: "Date Added",
		title: "DateAdded",
	},
	reviewStatus: {
		component: InsuranceStatusCell,
		label: "Review Status",
		title: "ReviewStatus",
	},
	recipient: {
		width: "110px",
		component: InsuranceDecision,
		label: "Insurance Decision",
		title: "InsuranceDecision",
		truncate: false,
	},
};
export const InsuranceCancellationTable = (props) => {
	const { sort, state, dispatch } = useSort({
		column: "addedDate",
		direction: "asc",
	});
	const configCols = Object.keys(config);
	const { data: queueItems } = useInsuranceQueue();
	const data = sort(queueItems);

	const handleSort = (sortColumn) => {
		const { property: sortProperty, sortDirection } = sortColumn;
		dispatch({ column: sortProperty, direction: sortDirection });
	};
	return (
		<>
			<div style={{ overflow: "auto", height: "100vh", maxHeight: "60vh" }}>
				<DataTable
					assistiveText={{
						columnSort: "sort this column",
						columnSortedAscending: "asc",
						columnSortedDescending: "desc",
						selectAllRows: "Select all rows",
						selectRow: "Select this row",
					}}
					style={{ fontSize: ".8em", minWidth: "800px" }}
					items={data}
					id="InsuranceCancellationTable"
					onSort={handleSort}
					fixedHeader
					fixedLayout
					columnBordered
				>
					{configCols.map((column) => {
						return (
							<DataTableColumn
								truncate={config[column]?.truncate === false ? false : true}
								key={column}
								width={config[column]?.width ? config[column].width : "50px"}
								label={config[column]?.label ? config[column].label : column}
								property={column}
								sortable
								isSorted={state.column === column}
								sortDirection={state.column === column ? state.direction : null}
							>
								{config[column]?.component ? (
									React.createElement(config[column].component, {
										...{ anchor: column.id },
										...config[column].props,
										title: config[column].title,
									})
								) : (
									<DataTableCell title={config[column].title} />
								)}
							</DataTableColumn>
						);
					})}
				</DataTable>
			</div>
		</>
	);
};
