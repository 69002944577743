import { useFieldArray, useFormContext } from "react-hook-form";
import { useGetCommentTypesQuery } from "../api/endpoints";
import { useGetCurrentUserQuery } from "@/features/user/api/endpoints";
import classes from "./TaskComments.module.scss";
import { Button, ExpandableSection } from "@salesforce/design-system-react";
import { User } from "./TaskCard";
import { useState } from "react";
import { TaskFormType } from "./TaskForm";
import { getFormattedISODatetime } from "@/Utilities/utils";

const DEFAULT_COMMENT = {
	title: "",
	content: "",
};

export const TaskComments = () => {
	const { control } = useFormContext<TaskFormType>();

	const [newComment, setNewComment] = useState<{
		title: string;
		content: string;
	}>(DEFAULT_COMMENT);

	const { append, fields } = useFieldArray<TaskFormType, "comments">({
		control,
		name: "comments",
	});

	const { data: user } = useGetCurrentUserQuery();
	const { data: comments, isLoading, isError } = useGetCommentTypesQuery();

	const [isOpen, setIsOpen] = useState<boolean>(false);
	if (isLoading || isError) {
		return <></>;
	}

	return (
		<ExpandableSection
			title="Comments"
			isOpen={isOpen}
			onToggleOpen={() => setIsOpen(!isOpen)}
		>
			<div className={classes.comments} id="comments">
				{fields.map((item, index) => (
					<div key={`comment-${index}`} className={classes.comment}>
						{item.title && <h4>{item.title}</h4>}
						{item.createdBy && (
							<div className={classes.commentUser}>
								<User id={item.createdBy} /> on{" "}
								{getFormattedISODatetime(item.createdDate)}
							</div>
						)}
						{item.content && <p>{item.content}</p>}
					</div>
				))}
			</div>
			<div className={classes.commentForm}>
				<input
					className="slds-input"
					placeholder="Optional Title"
					value={newComment.title}
					onChange={(e) =>
						setNewComment({ ...newComment, title: e.target.value })
					}
				/>
				<textarea
					onChange={(e) =>
						setNewComment({ ...newComment, content: e.target.value })
					}
					value={newComment.content}
					placeholder="Add a comment"
					className={classes.commentInput}
				/>
				<Button
					onClick={() => {
						append({
							createdBy: user?.id ?? "unknown",
							content: newComment.content,
							title: newComment.title,
							commentTypeId: comments?.[0]?.id ?? "",
							createdDate: new Date().toISOString(),
						});
						setNewComment(DEFAULT_COMMENT);
					}}
				>
					Add Comment
				</Button>
			</div>
		</ExpandableSection>
	);
};
