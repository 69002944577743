import { useSearchParams } from "react-router-dom";

export const useAssetListSplitView = () => {
	const [params, setParams] = useSearchParams();

	const setSplitView = (splitView) => {
		const { split, tab, assetId, ...rest } = Object.fromEntries(
			params.entries()
		);

		setParams(
			splitView ? { ...rest, split: splitView, tab: "details" } : { ...rest },
			{ replace: true }
		);
	};

	return {
		isSplitView: params.has("split") && params.get("split") === "true",
		setSplitView,
	};
};
