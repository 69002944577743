import { words } from "lodash";
import { kebabCase } from "lodash";
import { camelCase } from "lodash";
import { useSearchParams } from "react-router-dom";
import * as home from "@/features/asset/constants";
import { getQueryName } from "./useLazyAssetList";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getAllAssets } from "./endpoints";
import { useUser } from "@/features/user/hooks/useUser";

export const defaultQuery = {
	view: "allAssets",
	sort: home.ASSIGNED_DATE.key,
	direction: "desc",
};

export const useAssetListParams = () => {
	const [params, setParams] = useSearchParams();
	const { data: userData, isSuccess, isLoading, isFetching } = useUser();

	const userDefaultView =
		isSuccess && userData?.defaultAssetListView
			? userData?.defaultAssetListView
			: defaultQuery.view;

	const view = params.has("view")
		? camelCase(params.get("view"))
		: userDefaultView;

	const sortKey = params.has("sort") && camelCase(params.get("sort"));
	const direction =
		params.has("direction") && camelCase(params.get("direction"));
	const hasQ = params.has("q") && params.get("q").length > 0;
	const {
		q: _q,
		filtered: _filtered,
		...currentParams
	} = useMemo(() => Object.fromEntries(params.entries()), [params]);

	const { status } = useSelector(
		getAllAssets.select(
			getQueryName({
				...(defaultQuery && {
					name: defaultQuery.view,
					property: defaultQuery.sort,
					direction: defaultQuery.direction,
				}),
				...(view && {
					name: view,
				}),
				...(sortKey && {
					property: sortKey,
				}),
				...(direction && {
					direction,
				}),
				...(hasQ && {
					q: "search", //search.replace(" ", "+"),
				}),
				filtered: true,
			})
		)
	);
	const { status: searchStatus } = useSelector(
		getAllAssets.select(
			getQueryName({
				...(defaultQuery && {
					name: defaultQuery.view,
					property: defaultQuery.sort,
					direction: defaultQuery.direction,
				}),
				...(view && {
					name: view,
				}),
				...(sortKey && {
					property: sortKey,
				}),
				...(direction && {
					direction,
				}),
				...(hasQ && {
					q: "search", //search.replace(" ", "+"),
				}),
			})
		)
	);

	const filtered = params.has("filtered") && status !== "uninitialized";
	const search =
		params.has("q") &&
		searchStatus !== "uninitialized" &&
		words(params.get("q")).join(" ");

	const paramEntries = useMemo(
		() => ({
			...defaultQuery,
			...currentParams,
			...(view && {
				view,
			}),
			...(sortKey && {
				sort: sortKey,
			}),
			...(direction && {
				direction,
			}),
			...(search &&
				search.length > 0 && {
					q: search, //search.replace(" ", "+"),
				}),
			...(filtered && {
				filtered: true,
			}),
		}),
		[currentParams, direction, filtered, search, sortKey, view]
	);

	const removeParam = (key) => {
		const { [key]: value, ...rest } = Object.fromEntries(params.entries());
		setParams(rest);
	};

	const setFiltered = () => {
		const { filtered, ...rest } = Object.fromEntries(params.entries());

		setParams({
			...rest,
			filtered: true,
		});
	};

	const setSearch = (search) => {
		const { q, filtered, ...rest } = Object.fromEntries(params.entries());

		const formatted = search.replace(" ", "+");

		setParams({
			...rest,
			...(search &&
				search.length > 0 && {
					q: formatted,
				}),
		});
	};

	const setView = (view) => {
		setParams({
			...(view !== userDefaultView && {
				view: kebabCase(view),
			}),
		});
	};

	const removeSearchAndFilter = () => {
		const { q, filtered, ...rest } = Object.fromEntries(params.entries());

		setParams(rest);
	};

	const setSorting = ({ direction, property }) => {
		const {
			sort: _sort,
			direction: _dir,
			...rest
		} = Object.fromEntries(params.entries());

		setParams({
			...rest,
			...(direction !== defaultQuery.direction && {
				direction: kebabCase(direction),
			}),
			...(property !== defaultQuery.sort && {
				sort: kebabCase(property),
			}),
		});
	};

	return [
		{
			...paramEntries,
			isSuccess,
			isLoading,
			isFetching,
		},
		{
			removeParam,
			setSorting,
			setFiltered,
			setView,
			setParams,
			setSearch,
			removeSearchAndFilter,
		},
	];
};

export const useSetAssetListParams = () => {
	const {
		setView,
		setSorting,
		setFiltered,
		setSearch,
		removeParam,
		removeSearchAndFilter,
		setParams,
	} = useAssetListParams()?.[1];

	return {
		setView,
		setSorting,
		setFiltered,
		setSearch,
		setParams,
		removeParam,
		removeSearchAndFilter,
	};
};
