import {
	BrowserAuthError,
	InteractionRequiredAuthError,
	PublicClientApplication,
	AuthenticationResult,
} from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";

const clientId = process.env.REACT_APP_CLIENT_ID!;
const tenantId = process.env.REACT_APP_TENANT_ID!;
const authority = `https://login.microsoftonline.com/${tenantId}`;

const instance = new PublicClientApplication({
	auth: {
		clientId,
		authority,
		redirectUri: window.location.origin,
		postLogoutRedirectUri: window.location.origin,
	},
	cache: {
		cacheLocation: "localStorage",
		secureCookies: true,
	},
});

// Track initialization state
let isInitialized = false;
const initializationPromise = instance.initialize().then(() => {
	isInitialized = true;
	const accounts = instance.getAllAccounts();
	if (accounts.length > 0) {
		instance.setActiveAccount(accounts[0]);
	}
});

export const useIsAuth = useIsAuthenticated;

/**
 * Acquires an access token silently or redirects for login if needed.
 */
export const acquireAccessToken = async (): Promise<string> => {
	// Wait for MSAL to initialize before proceeding
	if (!isInitialized) {
		await initializationPromise;
	}

	const activeAccount = instance.getActiveAccount();
	if (!activeAccount) {
		return "";
	}

	const request = {
		scopes: [
			"openid",
			"profile",
			"offline_access",
			`${clientId}/HomebaseGatewayAPI`,
		],
		account: activeAccount,
	};

	try {
		const authResult: AuthenticationResult = await instance.acquireTokenSilent(
			request
		);
		return authResult.accessToken;
	} catch (e) {
		if (e instanceof InteractionRequiredAuthError) {
			console.warn("Interaction required for authentication. Redirecting...");
			await instance.acquireTokenRedirect(request);
		} else if (
			e instanceof BrowserAuthError &&
			e.message.includes("no_network_connectivity")
		) {
			console.warn("No network connectivity detected.");
			return "";
		} else {
			console.error("Unexpected error during token acquisition:", e);
		}
	}

	return "";
};

export { instance as pca };
