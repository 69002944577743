import {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useContext,
	useState,
} from "react";
import { useFormReset } from "../hooks/useFormReset";
import { useFormFocus } from "../hooks/useFormFocus";
import { FormsContext } from "../components/Forms";

interface FormSectionContextType {
	sections: {
		[key: string]: boolean;
	};
	setSections: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
	activateSection: (section: string) => void;
	deactivateSection: (section: string) => void;
	isEditable: boolean;
	isFetching: boolean;
	formId: string;
}

export interface ActivateSectionCallbackArgs {
	sections?: { [key: string]: boolean };
	section: string;
	setSections: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
}

export const FormSectionContext = createContext({} as FormSectionContextType);

interface FormSectionProviderProps {
	children: ReactNode;
	isEditable: boolean;
	isFetching: boolean;
	onActivateSection?: (arg: ActivateSectionCallbackArgs) => void;
	formId: string;
}

export const FormSectionProvider = ({
	children,
	onActivateSection,
	isEditable,
	isFetching,
	formId,
}: FormSectionProviderProps) => {
	const [sections, setSections] = useState({});
	const { setFocusField } = useFormFocus();

	const activateSection = (section: string) => {
		if (onActivateSection) {
			onActivateSection({ sections, section, setSections });
		} else {
			setSections((sections) => ({
				...Object.fromEntries(Object.keys(sections).map((key) => [key, false])),
				[section]: true,
			}));
		}
	};

	const deactivateSection = (section: string) => {
		setSections((sections) => ({ ...sections, [section]: false }));
	};

	const formsContext = useContext(FormsContext);
	const deactivateAllSections = () => {
		setSections((sections) =>
			Object.fromEntries(Object.keys(sections).map((key) => [key, false]))
		);
		if (formsContext) {
			formsContext?.setOpenForm(null);
		}
	};

	const reset = () => {
		deactivateAllSections();
		setFocusField(null);
	};

	useFormReset(reset);

	return (
		<FormSectionContext.Provider
			value={{
				sections,
				setSections,
				activateSection,
				deactivateSection,
				isEditable,
				isFetching,
				formId,
			}}
		>
			{children}
		</FormSectionContext.Provider>
	);
};
