import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { useDispatch } from "react-redux";
import { setTripDetails } from "../store/tripPlanningSlice";

export const useTripDetails = () => {
	const dispatch = useDispatch();

	const getTripDetails = (route) => {
		if (route.length > 1) {
			TrimbleMaps.Routing.postDerivedRouteReports({
				routePings: route.map((val) => {
					return new TrimbleMaps.LngLat(val.longitude, val.latitude);
				}),
				reportType: TrimbleMaps.Common.ReportType.MILEAGE,

				routingOptions: {
					vehicleType: TrimbleMaps.Common.VehicleType.AUTOMOBILE,
					routeOptimization:
						TrimbleMaps.Common.RouteOptimization.OPTIMIZE_INTERMEDIATE_STOPS,
				},
				callback: (error, value) => {
					if (error === null) {
						const ReportLines = JSON.parse(value.data).Reports[0].ReportLines;
						const { THours: totalHours, TMiles: totalMiles } =
							ReportLines[ReportLines.length - 1];
						dispatch(setTripDetails({ totalHours, totalMiles }));
					} else {
						console.log("failure", error);
					}
				},
			});
		}
	};

	return { getTripDetails };
};
