import { Icon, PageHeader } from "@salesforce/design-system-react";
import styles from "./TaskLoadingSkeleton.module.scss";

export const TaskLoadingSkeleton = () => {
	return (
		<div className={styles.skeletonContainer}>
			<PageHeaderSkeleton />
			<div className={styles.skeletonBody}>
				{Array.from({ length: 16 }).map((_, index) => (
					<div key={index} className={styles.skeletonLine} />
				))}
			</div>
		</div>
	);
};

const PageHeaderSkeleton = () => {
	return (
		<PageHeader
			icon={
				<Icon
					assistiveText={{
						label: "Tasks",
					}}
					category="standard"
					name="task"
					style={{
						backgroundColor: "#009688",
						fill: "#ffffff",
					}}
					title="Tasks"
				/>
			}
			label="Tasks"
			info={
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "0.5rem",
					}}
				>
					<p>Loading latest information</p>
					<Icon
						className={styles.spinner}
						assistiveText={{ label: "loading" }}
						category="utility"
						name="spinner"
						size="x-small"
					/>
				</div>
			}
			title={"Loading..."}
			truncate
			variant="object-home"
		/>
	);
};
