import { useInsuranceQueue } from "./useInsuranceQueue";
import { useRequestInsuranceCancellationMutation } from "@/ReduxToolkit/GatewayApi";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@/features/user/api/selectors";

export const useAutoRefunds = () => {
	const { data } = useInsuranceQueue();
	const [updateInsuranceCancellation] =
		useRequestInsuranceCancellationMutation();
	const { email } = useSelector(selectCurrentUser);
	const approveAllAutoRefunds = () => {
		for (const i in data) {
			const submission = {
				cancellationQueueId: Number(data[i].id),
				recipient: "",
				updatingUser: email,
			};
			updateInsuranceCancellation(submission);
		}
	};
	return { approveAllAutoRefunds: () => approveAllAutoRefunds };
};
