import { useSelector } from "react-redux";
import { useGetImagingByAccountNumberQuery } from "./endpoints";
import { selectFilteredImagingDocuments } from "./ImagingSlice";

export const useImaging = (accountNumber) => {
	const { length, isSuccess } = useGetImagingByAccountNumberQuery(
		accountNumber,
		{
			selectFromResult: ({ data, isSuccess }) => {
				return {
					length: data?.length,
					isSuccess
				};
			}
		}
	);

	const data = useSelector(selectFilteredImagingDocuments(accountNumber));

	return {
		data: data ?? [],
		total: length,
		isSuccess
	};
};
