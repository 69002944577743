import { useSearchParams } from "react-router-dom";

export function usePricingWorksheetHistory() {
	const [params, setParams] = useSearchParams();

	const isOpenHistory = !!params.get("history");

	function openHistory() {
		const newParams = {
			tab: "marketingPlan",
			...(!isOpenHistory && {
				history: "true",
			}),
		};
		setParams(newParams);
	}

	return [isOpenHistory, openHistory];
}
