import React from "react";
import classes from "../RolesAndFlags.module.scss";
import { Flag } from "./Flag";
import { FEATURE_FLAGS } from "@/providers/feature-flags/constants";
// If looking to add feature flag, go to Flag.js to pull component

export const FeatureFlags = () => {
	return (
		<div className={classes.container}>
			<h1 className={classes.subtitle}>Feature Flags</h1>
			<div className={classes.flagsGrid}>
				{FEATURE_FLAGS.map((feature) => (
					<Flag
						key={`toggle-${feature.value}`}
						title={feature.label}
						value={feature}
					/>
				))}
			</div>
		</div>
	);
};
