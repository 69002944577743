import { ControlledDateInput, ControlledInput } from "@/Components/Form";

import {
	BROKERAGE_NAME,
	LISTING_AGENT_NAME,
	LISTING_AGENT_EMAIL,
	LISTING_EXPIRATION,
	MLS_NUMBER,
	LISTING_AGENT_PHONE,
} from "@/features/asset/constants";

export const ListingConfig = [
	{
		component: ControlledInput,
		props: {
			name: BROKERAGE_NAME.path,
			label: BROKERAGE_NAME.label,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: LISTING_AGENT_NAME.path,
			label: LISTING_AGENT_NAME.label,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: LISTING_AGENT_EMAIL.path,
			label: LISTING_AGENT_EMAIL.label,
			type: "email",
		},
	},
	{
		component: ControlledInput,
		props: {
			name: LISTING_AGENT_PHONE.path,
			label: LISTING_AGENT_PHONE.label,
			type: "tel",
		},
	},
	{
		component: ControlledDateInput,
		props: {
			name: LISTING_EXPIRATION.path,
			label: LISTING_EXPIRATION.label,
			type: "date",
		},
	},
	{
		component: ControlledInput,
		props: {
			name: MLS_NUMBER.path,
			label: MLS_NUMBER.label,
		},
	},
];
