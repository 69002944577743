import "vest/dist/cjs/enforce/compounds.production";
import "vest/dist/cjs/enforce/schema.production";
import isEmail from "validator/lib/isEmail";
import isISODate from "validator/lib/isISO8601";
import isUUID from "validator/lib/isUUID";
import isPostalCode from "validator/lib/isPostalCode";
import isAlphanumeric from "validator/lib/isAlphanumeric";
import { enforce } from "vest";

enforce.extend({
	isEmail,
	isISODate,
	isUUID,
	isZipCode: (arg) => isPostalCode(arg?.toString(), "US") && arg.length === 5,
	isAlphanumeric,
});

export { isISODate };
