import { Button, Combobox, InputIcon } from "@salesforce/design-system-react";
import { selectPricingByName } from "../../api/selectors";
import { useSelector } from "react-redux";
import { useController, useWatch } from "react-hook-form";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { round } from "lodash";
import { removeSpecialCharacters } from "@/Utilities/utils";
import { SLDSInput, Checkbox, Textarea } from "@salesforce/design-system-react";
import { useRef, useEffect } from "react";
import classes from "./ControlledPrice.module.scss";
import { OVERVIEW, SKIP_CONDITION_REPORT } from "../../constants/constants";
import { lockTypes, skipReasonType } from "@/Utilities/enums";
import { usePricingModal } from "../PricingModal/PricingModal";

export const ControlledPrice = ({
	component: Component,
	selector,
	control,
	multiplier = 1,
	...props
}) => {
	const { assetId } = useAssetId();
	const unitAmount = useWatch({ name: props.name });
	const selectValue = selector
		? selector(assetId)
		: selectPricingByName(props.costName);
	const price = useSelector(selectValue);
	const pricingModal = usePricingModal();
	const {
		fieldState: { error },
	} = useController({ name: props.name });
	const buttonStyle = error?.message ? "center" : "flex-end";
	const totalPrice = round(unitAmount * price * multiplier || 0, 2);
	if (props.skipPrice) return <Component {...props} />;
	return (
		<div className={classes.controlledPriceContainer}>
			<Component {...props} />
			<div style={{ alignItems: buttonStyle }} className={classes.price}>
				<Button
					style={{ border: "none", maxHeight: "32px" }}
					id={props.name + "-button"}
					onClick={pricingModal.show}
				>
					${totalPrice}
				</Button>
			</div>
		</div>
	);
};

export const ControlledPriceInput = ({ component, ...props }) => {
	const defaultValue = props.defaultValue ? props.defaultValue : 0;
	return (
		<ControlledPrice
			component={ControlledInput}
			{...props}
			defaultValue={defaultValue}
		/>
	);
};

export const ControlledPriceCheckbox = ({ component, ...props }) => {
	const defaultValue = props.defaultValue ? props.defaultValue : false;
	return (
		<ControlledPrice
			component={ControlledCheckbox}
			{...props}
			defaultValue={defaultValue}
		/>
	);
};

export const ControlledCheckboxDisable = ({
	component,
	watchField,
	...props
}) => {
	const shouldDisable = useWatch({ name: watchField });
	const defaultValue = props.defaultValue ? props.defaultValue : false;
	return (
		<>
			{!shouldDisable && (
				<ControlledPrice
					component={ControlledCheckbox}
					{...props}
					defaultValue={defaultValue}
				/>
			)}
		</>
	);
};

export const ControlledInputDisable = ({ component, watchField, ...props }) => {
	const shouldDisable = useWatch({ name: watchField });
	const defaultValue = props.defaultValue ? props.defaultValue : 0;
	return (
		<>
			{!shouldDisable && (
				<ControlledPrice
					component={ControlledInput}
					{...props}
					defaultValue={defaultValue}
				/>
			)}
		</>
	);
};

export const ControlledCheckbox = ({ defaultValue, ...props }) => {
	const {
		field: { ref, value, ...rest },
		fieldState: { error },
	} = useController({ name: props.name, defaultValue: defaultValue });
	const focusRef = useRef(null);
	useEffect(() => {
		if (error) {
			focusRef.current?.input?.focus();
		}
	}, [error]);

	return (
		<div className="slds-form-element">
			<label
				htmlFor={`checkbox-${props.name}`}
				className="slds-form-element__label"
			>
				{props.label}
			</label>
			<Checkbox
				id={`checkbox-${props.name}`}
				errorText={error?.message}
				ref={(inputRef) => {
					ref(inputRef);
					focusRef.current = inputRef;
				}}
				checked={rest.value}
				{...rest}
			/>
		</div>
	);
};

export const SkipConditionReport = ({ ...props }) => {
	return <ControlledCheckbox {...props} />;
};

export const SkipReason = ({ ...props }) => {
	const SkipConditionReport = useWatch({
		name: `${OVERVIEW}.${SKIP_CONDITION_REPORT}`,
	});

	const isEnabled =
		SkipConditionReport !== undefined && SkipConditionReport !== false;
	return isEnabled ? <SkipReasonSelect {...props} /> : <></>;
};

export const ControlledInput = ({ defaultValue, ...props }) => {
	const {
		field: { ref, value, ...rest },
		fieldState: { error },
	} = useController({ name: props.name, defaultValue: defaultValue });
	return (
		<SLDSInput
			inputRef={ref}
			errorText={error?.message}
			{...props}
			{...rest}
			value={value.toString()}
			onChange={(e, { value }) =>
				rest.onChange(value ? removeSpecialCharacters(value) : "")
			}
			{...(props.type === "number" && {
				onChange: (e, { value }) => {
					rest.onChange(value ? Number(value) : 0);
				},
			})}
		/>
	);
};

export const ControlledTextArea = ({ defaultValue, ...props }) => {
	const {
		field: { ref, value, ...rest },
		fieldState: { error },
	} = useController({ name: props.name, defaultValue: defaultValue });
	return (
		<div className={props.className ?? classes.gridItem}>
			<label
				htmlFor={`textbox-${props.name}`}
				className="slds-form-element__label"
			>
				{props.label}
			</label>
			<Textarea
				id={`textbox-${props.name}`}
				disabled={props.readOnly}
				name={props.name}
				errorText={error?.message}
				{...rest}
				value={value}
				onChange={(e) => {
					rest.onChange(removeSpecialCharacters(e.target.value));
				}}
			/>
		</div>
	);
};

export const ControlledSelect = ({
	defaultValue,
	options: optionsProp,
	...props
}) => {
	const {
		field: { ref, value, ...rest },
		fieldState: { error },
	} = useController({ name: props.name, defaultValue: defaultValue });
	const focusRef = useRef(null);

	const options = optionsProp.map((option, index) => {
		return {
			id: `${props.formName}-option-${props.name}-${option.value}`,
			value: option.value,
			label: option.label,
		};
	});
	const selection = options.filter((option) => {
		return option?.value?.toString() === value?.toString();
	});
	const comboboxValue = value?.toString();
	return (
		<span ref={(ref) => (focusRef.current = ref?.querySelector("input"))}>
			<Combobox
				name={props.name}
				variant="readonly"
				options={options}
				selection={selection}
				events={{
					onSelect: (e, { selection: [selection] }) => {
						const isNumeric = Number(selection?.value).toString() !== "NaN";
						rest.onChange(
							isNumeric ? Number(selection?.value) : selection?.value || null
						);
					},
				}}
				inputRef={ref}
				errorText={error?.message}
				iconLeft={<InputIcon name="error" category="utility" />}
				{...props}
				labels={{ label: props.label || props.labels.label }}
				{...rest}
				value={comboboxValue}
			/>
		</span>
	);
};

const createConditionReportSelect = (lookup) => (props) => {
	const options = Object.entries(lookup).map(([id, description]) => ({
		label: description,
		value: id,
	}));

	return <ControlledSelect options={options} {...props} />;
};

export const LockTypeSelect = createConditionReportSelect(lockTypes);
const SkipReasonSelect = createConditionReportSelect(skipReasonType);
