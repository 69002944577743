import {
	PageHeader,
	Icon,
	PageHeaderControl,
	Button,
	ButtonGroup,
	Dropdown,
} from "@salesforce/design-system-react";

import { useGetCurrentUserQuery } from "@/features/user/api/endpoints";
import { useSaveTripMutation } from "../../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import {
	selectSelectedLatLong,
	setLocations,
} from "../../store/tripPlanningSlice";
import { GPXFile } from "../GPXFile";
import { PrintDirectionsButton } from "./PrintDirections";
import {
	useDefaultViews,
	useLazyAssetListView,
} from "@/features/asset-list/api/useLazyAssetList";
import { words } from "lodash";
import { upperFirst } from "lodash";
import { camelCase } from "lodash";
import { SearchForm } from "@/features/asset-list/components/Search/SearchForm";
import { usePageAssetList } from "@/features/asset-list/api/usePageAssetList";

export const Header = () => {
	const {
		data: { email },
		isSuccess,
	} = useGetCurrentUserQuery(undefined, {
		selectFromResult: ({ data, ...rest }) => {
			return { data: data ?? [], ...rest };
		},
	});

	const { view, trigger: triggerView } = useLazyAssetListView();

	const { pagination } = usePageAssetList();
	const totalAssets = pagination?.totalItems;

	const locations = useSelector(selectSelectedLatLong);

	const [save] = useSaveTripMutation();

	const handleSaveTrip = () => {
		save({ driverEmail: email, stops: locations });
	};

	const Actions = () => (
		<PageHeaderControl>
			<ButtonGroup variant="list" id="button-group-page-header-actions">
				<PrintDirectionsButton />
				<Button
					assistiveText={{ icon: "Save Asset" }}
					iconCategory="utility"
					iconName="save"
					iconVariant="border-filled"
					variant="icon"
					onClick={handleSaveTrip}
					disabled={!isSuccess || locations?.length < 1}
				/>
				<GPXFile />
			</ButtonGroup>
		</PageHeaderControl>
	);
	const filterNames = useDefaultViews();
	const optionsArray = filterNames.map((key) => {
		const value = key;
		const label = words(upperFirst(key)).join(" ");
		return {
			label,
			value,
		};
	});
	const dispatch = useDispatch();
	const switchTitle = (e) => {
		dispatch(setLocations([]));
		triggerView(e.value);
	};

	const title = view
		? words(upperFirst(camelCase(view))).join(" ")
		: "My Assets";
	return (
		<PageHeader
			icon={
				<Icon
					assistiveText={{
						label: "car",
					}}
					category="custom"
					name="custom31"
					style={{
						backgroundColor: "#352269",
						fill: "#ffffff",
					}}
					title="Home"
				/>
			}
			info={`${totalAssets ?? 0} items`}
			label="Trip Planning"
			truncate
			title={title}
			onRenderActions={Actions}
			nameSwitcherDropdown={
				<Dropdown
					width="x-small"
					align="right"
					assistiveText={{ icon: "Name Switcher" }}
					buttonClassName="slds-button_icon-small"
					buttonVariant="icon"
					iconCategory="utility"
					iconName="down"
					id="page-header-name-switcher-dropdown"
					checkmark
					onSelect={switchTitle}
					options={optionsArray}
					value={view}
				/>
			}
			onRenderControls={() => (
				<PageHeaderControl>
					<SearchForm />
				</PageHeaderControl>
			)}
			variant="object-home"
		/>
	);
};
