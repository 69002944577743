export const AIR_CONDITIONER = {
	path: "airConditioner",
	key: "airConditioner",
	label: "Air Conditioner",
};
export const ACREAGE = { path: "acreage", key: "acreage", label: "Acreage" };
export const BEDROOMS = {
	path: "bedrooms",
	key: "bedrooms",
	label: "Beds",
};
export const BATHROOMS = {
	path: "bathrooms",
	key: "bathrooms",
	label: "Baths",
};
export const SERIAL_NUMBER = {
	path: "serialNumber",
	key: "serialNumber",
	label: "Serial Number",
};
export const LENGTH = { path: "length", key: "length", label: "Length" };
export const WIDTH = { path: "width", key: "width", label: "Width" };
export const MODEL_NAME = {
	path: "modelName",
	key: "modelName",
	label: "Model",
};
export const MANUFACTURER = {
	path: "manufacturer",
	key: "manufacturer",
	label: "Make",
};
export const SECTIONS = {
	path: "sections",
	key: "sections",
	label: "Sections",
};
export const STOCK_NUMBER = {
	path: "stockNumber",
	key: "stockNumber",
	label: "Stock Number",
};
export const YEAR = { path: "year", key: "year", label: "Year" };
export const SQUARE_FEET = {
	path: "squareFeet",
	key: "squareFeet",
	label: "Square Feet",
};
export const PARK = { path: "park", key: "park", label: "Park" };
export const THERMAL_ZONE = {
	path: "thermalZone",
	key: "thermalZone",
	label: "Thermal Zone",
};
export const UTILITY_SYSTEM = {
	path: "utilitySystem",
	key: "utilitySystem",
	label: "Utility System",
};
export const WASTE_SYSTEM = {
	path: "wasteSystem",
	key: "wasteSystem",
	label: "Waste System",
};
export const WATER_SYSTEM = {
	path: "waterSystem",
	key: "waterSystem",
	label: "Water System",
};
export const WIND_ZONE = {
	path: "windZone",
	key: "windZone",
	label: "Wind Zone",
};
export const ROOF_LOAD = {
	path: "roofLoad",
	key: "roofLoad",
	label: "Roof Load",
};
export const B_SEAL_TYPE_ID = {
	path: "bSealTypeId",
	key: "bSealTypeId",
	label: "B Seal",
};
export const FOUNDATION_TYPE_ID = {
	path: "foundationTypeId",
	key: "foundationTypeId",
	label: "Foundation",
};
export const SIDING_TYPE_ID = {
	path: "sidingTypeId",
	key: "sidingTypeId",
	label: "Siding Type",
};
export const PARK_CODE_TYPE_ID = {
	path: "parkCodeTypeId",
	key: "parkCodeTypeId",
	label: "Park Code",
};
export const ROOF_TYPE_ID = {
	path: "roofTypeId",
	key: "roofTypeId",
	label: "Roof Type",
};
export const ACCOUNT_ID = {
	path: "accountId",
	key: "accountId",
	label: "Account Id",
};
export const DISPLAY_ACCOUNT = {
	path: "displayAccount",
	key: "displayAccount",
	label: "Display Account",
};
export const LAST_INSPECTION = {
	path: "lastInspection",
	key: "lastInspection",
	label: "Last Inspection",
};
export const NOTICE_OF_SALE_REQUIRED = {
	path: "noticeOfSaleRequired",
	key: "noticeOfSaleRequired",
	label: "NOS Required",
};
export const NOTICE_OF_SALE_SENT = {
	path: "noticeOfSaleSent",
	key: "noticeOfSaleSent",
	label: "NOS Sent Date",
};
export const NOTICE_OF_SALE_EXPIRES = {
	path: "noticeOfSaleExpires",
	key: "noticeOfSaleExpires",
	label: "NOS Expiration Date",
};
export const REMARKETING_STATUS_TYPE_ID = {
	path: "remarketingStatusTypeId",
	key: "remarketingStatusTypeId",
	label: "Remarketing Status",
};
export const HUD_NUMBER_1 = {
	path: "hudNumber1",
	key: "hudNumber1",
	label: "HUD Number 1",
};
export const HUD_NUMBER_2 = {
	path: "hudNumber2",
	key: "hudNumber2",
	label: "HUD Number 2",
};
export const HVAC_TYPE = {
	path: "hvacType",
	key: "hvacType",
	label: "HVAC Type",
};
export const HVAC_STATUS = {
	path: "hvacStatus",
	key: "hvacStatus",
	label: "HVAC Status",
};
export const ACCOUNT_NUMBER = {
	path: "account.accountNumber",
	key: "accountNumber",
	label: "Account Number",
};
export const BALANCE = {
	path: "account.balance",
	key: "balance",
	label: "Balance",
};
export const CUSTOMER_NAME = {
	path: "account.customerName",
	key: "customerName",
	label: "Customer Name",
};
export const LOAN_TYPE_ID = {
	path: "account.loanTypeId",
	key: "loanTypeId",
	label: "Loan Type",
};
export const ASSIGNED_DATE = {
	path: "account.assignedDate",
	key: "assignedDate",
	label: "Assigned Date",
};
export const BACKOUT_DATE = {
	path: "account.backoutDate",
	key: "backoutDate",
	label: "Backout Date",
};
export const VSI_CLAIM_DATE = {
	path: "account.vsiClaimDate",
	key: "vsiClaimDate",
	label: "VSI Claim Date",
};
export const VSI_REFUND_AMOUNT = {
	path: "account.vsiRefundAmount",
	key: "vsiRefundAmount",
	label: "VSI Claim Amount",
};
export const MASTER_DEALER = {
	path: "account.masterDealer",
	key: "masterDealer",
	label: "Master Dealer",
};
export const LOT_NUMBER = {
	path: "account.lotNumber",
	key: "lotNumber",
	label: "Lot Number",
};
export const INVENTORY_AGE = {
	path: "account.inventoryAge",
	key: "inventoryAge",
	label: "Inventory Age",
};
export const LOAN_ORIGINATION = {
	path: "account.loanOrigination",
	key: "loanOrigination",
	label: "Loan Origination",
};
export const ASSET_MANAGER = {
	path: "account.assetManager",
	key: "assetManager",
	label: "Asset Manager",
};
export const ASSET_MANAGER_ID = {
	path: "account.assetManagerId",
	key: "assetManagerId",
	label: "Asset Manager Id",
};
export const DISPOSITION_OF_TITLE = {
	path: "account.dispositionOfTitle",
	key: "dispositionOfTitle",
	label: "Land Involvement",
};
export const ACCOUNT_STATUS = {
	path: "account.accountStatus",
	key: "accountStatus",
	label: "Account Status",
};
export const FOLLOW_UP_DATE = {
	path: "account.followUpDate",
	key: "followUpDate",
	label: "Follow Up Date",
};
export const HOME_ID = {
	path: "notes[0].homeId",
	key: "homeId",
	label: "Home Id",
};
export const FHA_TITLE_II_FINANCING = {
	path: "salesInfo.fhaTitleIIFinancing",
	key: "fhaTitleIiFinancing",
	label: "FHA Title II Financing",
};
export const FIRST_INSPECTION = {
	path: "salesInfo.firstInspection",
	key: "firstInspection",
	label: "First Inspection",
};
export const PERSONAL_ITEMS_LETTER_EXPIRATION = {
	path: "salesInfo.personalItemsLetterExpiration",
	key: "personalItemsLetterExpiration",
	label: "Personal Items Letter Expiration",
};
export const PROJECTED_SALE_MONTH = {
	path: "salesInfo.projectedSaleMonth",
	key: "projectedSaleMonth",
	label: "Projected Sale Month",
};
export const REPOSSESSION_DATE = {
	path: "salesInfo.repossessionDate",
	key: "repossessionDate",
	label: "Repossession Date",
};
export const WINTERIZED_DATE = {
	path: "salesInfo.winterizedDate",
	key: "winterizedDate",
	label: "Winterized",
};
export const PROJECTED_RECOVERY_PERCENT = {
	path: "salesInfo.projectedRecoveryPercent",
	key: "projectedRecoveryPercent",
	label: "Projected Recovery Percent",
};
export const VMF_FACILITATION = {
	path: "salesInfo.vmfFacilitation",
	key: "vmfFacilitation",
	label: "Vmf Facilitation",
};
export const TAX_PAID_AMOUNT = {
	path: "salesInfo.taxPaidAmount",
	key: "taxPaidAmount",
	label: "Tax Paid Amount",
};
export const TAX_PAID = {
	path: "salesInfo.taxPaid",
	key: "taxPaid",
	label: "Tax Paid",
};
export const TAX_PAID_THROUGH = {
	path: "salesInfo.taxPaidThrough",
	key: "taxPaidThrough",
	label: "TX Paid Through Date",
};
export const TAX_RESEARCH_DATE = {
	path: "salesInfo.taxResearchDate",
	key: "taxResearchDate",
	label: "Tax Research Date",
};
export const TAX_RESEARCH_AMOUNT = {
	path: "salesInfo.taxResearchAmount",
	key: "taxResearchAmount",
	label: "Tax Research Amount",
};
export const ACCEPTING_OFFERS = {
	path: "marketingInfo.acceptingOffers",
	key: "acceptingOffers",
	label: "Accepting Offers",
};
export const BROKERAGE_NAME = {
	path: "marketingInfo.brokerageName",
	key: "brokerageName",
	label: "Brokerage",
};
export const CONTACT_NAME = {
	path: "marketingInfo.contactName",
	key: "contactName",
	label: "Contact Name",
};
export const CONTACT_PHONE = {
	path: "marketingInfo.contactPhone",
	key: "contactPhone",
	label: "Contact Phone",
};
export const LISTING_ID = {
	path: "marketingInfo.listingId",
	key: "listingId",
	label: "Listing Id",
};
export const DESCRIPTION = {
	path: "marketingInfo.description",
	key: "description",
	label: "Description",
};
export const LAND = { path: "marketingInfo.land", key: "land", label: "Land" };
export const HAS_PICTURES = {
	path: "marketingInfo.hasPictures",
	key: "hasPictures",
	label: "Has Pictures",
};
export const SHOW_ADDRESS = {
	path: "marketingInfo.showAddress",
	key: "showAddress",
	label: "Show Address",
};
export const SHOW_MAP = {
	path: "marketingInfo.showMap",
	key: "showMap",
	label: "Show Map",
};
export const MANAGER = {
	path: "marketingInfo.manager",
	key: "manager",
	label: "Manager",
};
export const SALESPERSON = {
	path: "marketingInfo.salesperson",
	key: "salesperson",
	label: "Salesperson",
};
export const PURCHASE_OPTION = {
	path: "marketingInfo.purchaseOption",
	key: "purchaseOption",
	label: "Purchase Option",
};
export const LIST_TYPE = {
	path: "marketingInfo.listType",
	key: "listType",
	label: "List Type",
};
export const SAFE_TO_SHOW = {
	path: "marketingInfo.safeToShow",
	key: "safeToShow",
	label: "Home Meets Standard Requirements to Show",
};
export const INSPECTION_ORDERED = {
	path: "marketingInfo.inspectionOrdered",
	key: "inspectionOrdered",
	label: "Inspection Ordered",
};
export const MARKET_READY_DATE = {
	path: "marketingInfo.marketReadyDate",
	key: "marketReadyDate",
	label: "Market Ready Date",
};
export const ASSET_TYPE = {
	path: "marketingInfo.assetType",
	key: "assetType",
	label: "Asset Type",
};
export const NOTE = { path: "notes[0].note", key: "note", label: "Note" };
export const CREATED = {
	path: "notes[0].created",
	key: "created",
	label: "Created",
};
export const MARKETING_INFO_ID = {
	path: "marketingInfo.mlsDetails.marketingInfoId",
	key: "marketingInfoId",
	label: "Marketing Info Id",
};
export const DEALER_NUMBER = {
	path: "marketingInfo.dealerNumber",
	key: "dealerNumber",
	label: "Dealer Number",
};
export const SALES_CENTER = {
	path: "marketingInfo.salesCenter",
	key: "salesCenter",
	label: "Sales Center",
};
export const CONTACT_PERSON_TYPE = {
	path: "marketingInfo.contactPersonType",
	key: "contactPersonType",
	label: "Contact Person Type",
};
export const HOME_STAYS_ON_LAND = {
	path: "marketingInfo.homeStaysOnLand",
	key: "homeStaysOnLand",
	label: "Can stay at present location?",
};
export const LAND_OWNER_NAME = {
	path: "marketingInfo.landOwnerName",
	key: "landOwnerName",
	label: "Land Owner Name",
};
export const LAND_OWNER_PHONE_NUMBER = {
	path: "marketingInfo.landOwnerPhoneNumber",
	key: "landOwnerPhoneNumber",
	label: "Land Owner Phone Number",
};
export const LAND_RENT_AMOUNT = {
	path: "marketingInfo.landRentAmount",
	key: "landRentAmount",
	label: "Land Rent Amount",
};
export const PARK_RENT_AMOUNT = {
	path: "marketingInfo.parkRentAmount",
	key: "parkRentAmount",
	label: "Park Rent Amount",
};
export const BASE_PRICE = {
	path: "marketingInfo.basePrice",
	key: "basePrice",
	label: "Base Price",
};
export const RETAIL_ASKING_PRICE = {
	path: "marketingInfo.retailAskingPrice",
	key: "retailAskingPrice",
	label: "Retail Price",
};
export const WHOLESALE_PRICE = {
	path: "marketingInfo.wholesalePrice",
	key: "wholesalePrice",
	label: "Wholesale Price",
};
export const LISTING_AGENT_NAME = {
	path: "marketingInfo.mlsDetails.listingAgentName",
	key: "listingAgentName",
	label: "Listing Agent Name",
};
export const LISTING_AGENT_EMAIL = {
	path: "marketingInfo.mlsDetails.listingAgentEmail",
	key: "listingAgentEmail",
	label: "Listing Agent Email",
};
export const LISTING_AGENT_PHONE = {
	path: "marketingInfo.mlsDetails.listingAgentPhone",
	key: "listingAgentPhone",
	label: "Listing Agent Phone",
};
export const LISTING_EXPIRATION = {
	path: "marketingInfo.mlsDetails.listingExpiration",
	key: "listingExpiration",
	label: "Listing Expiration",
};
export const MLS_NUMBER = {
	path: "marketingInfo.mlsDetails.mlsNumber",
	key: "mlsNumber",
	label: "MLS ID",
};
export const FHA_APPRAISAL_REQUEST = {
	path: "titling.fhaAppraisalRequest",
	key: "fhaAppraisalRequest",
	label: "FHA Appraisal Request Date",
};
export const FHA_APPRAISAL_RECEIVED = {
	path: "titling.fhaAppraisalReceived",
	key: "fhaAppraisalReceived",
	label: "FHA Appraisal Receive Date",
};
export const APPRAISED_VALUED = {
	path: "titling.appraisedValued",
	key: "appraisedValued",
	label: "HUD Price",
};
export const HUD_DUE = {
	path: "titling.hudDue",
	key: "hudDue",
	label: "Hud Due",
};
export const TITLE_CODE_ID = {
	path: "titling.titleCodeId",
	key: "titleCodeId",
	label: "Title Code Id",
};
export const TITLE_REQUEST = {
	path: "titling.titleRequest",
	key: "titleRequest",
	label: "Title Request",
};
export const REPO_TITLE_ORDERED = {
	path: "titling.repoTitleOrdered",
	key: "repoTitleOrdered",
	label: "Repo Title Ordered",
};
export const DUPLICATE_TITLE_REQUEST = {
	path: "titling.duplicateTitleRequest",
	key: "duplicateTitleRequest",
	label: "Duplicate Title Request",
};
export const TITLE_FEE_AMOUNT = {
	path: "titling.titleFeeAmount",
	key: "titleFeeAmount",
	label: "Title Fee Amount",
};
export const TITLE_REQUEST_RECEIVED = {
	path: "titling.titleRequestReceived",
	key: "titleRequestReceived",
	label: "Title Request Received",
};
export const TITLE_REJECTION_RECEIVED = {
	path: "titling.titleRejectionReceived",
	key: "titleRejectionReceived",
	label: "Title Rejection Received",
};
export const DUPLICATE_TITLE_RECEIVED = {
	path: "titling.duplicateTitleReceived",
	key: "duplicateTitleReceived",
	label: "Duplicate Title Received",
};
export const TITLE_OUT = {
	path: "titling.titleOut",
	key: "titleOut",
	label: "Title Out",
};
export const ADDRESS = {
	path: "physicalLocation.address",
	key: "address",
	label: "Address",
};
export const CITY = {
	path: "physicalLocation.city",
	key: "city",
	label: "City",
};
export const COUNTY = {
	path: "physicalLocation.county",
	key: "county",
	label: "County",
};
export const STATE = {
	path: "physicalLocation.state",
	key: "state",
	label: "State",
};
export const ZIP = { path: "physicalLocation.zip", key: "zip", label: "Zip" };
export const LATITUDE = {
	path: "physicalLocation.latitude",
	key: "latitude",
	label: "Latitude",
};
export const LONGITUDE = {
	path: "physicalLocation.longitude",
	key: "longitude",
	label: "Longitude",
};
export const LOCATION_TYPE = {
	path: "physicalLocation.locationType",
	key: "locationType",
	label: "Location Type",
};
export const LOCATION_ID = {
	path: "physicalLocation.notes[0].locationId",
	key: "locationId",
	label: "Location Id",
};
export const ADDRESS_2 = {
	path: "physicalLocation.address2",
	key: "address2",
	label: "Address 2",
};
export const ASSET_LOCATION_TYPE_ID = {
	path: "physicalLocation.assetLocationTypeId",
	key: "assetLocationTypeId",
	label: "Asset Location",
};
export const COMMUNITY_NUMBER = {
	path: "physicalLocation.communityNumber",
	key: "communityNumber",
	label: "Community Number",
};
export const VANTAGE_STATUS = {
	path: "vantageStatus",
	key: "vantageStatus",
	label: "Vantage Status",
};
export const PRICING_WORKSHEET_APPROVAL_STATUS = {
	path: "activePricingWorksheetApprovalStatus",
	key: "activePricingWorksheetApprovalStatus",
	label: "Pricing Worksheet Status",
};
