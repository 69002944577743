import {
	FormProvider,
	useController,
	useFieldArray,
	useForm,
	useFormContext,
	useWatch,
} from "react-hook-form";
import { useEffect, useState } from "react";
import {
	Button,
	Combobox,
	Input,
	SLDSModal,
} from "@salesforce/design-system-react";
import { Desktop, Mobile } from "@/Responsive";
import classes from "@/features/media-manager/components/filters/Filter.module.scss";
import assetStyles from "./Filters.module.scss";
import { useFilterPanel } from "@/features/media-manager/hooks/useFilterPanel";
import { useLazySavedFilters } from "../../hooks/useFilters";
import { filterProperties } from "./properties";
import { nanoid } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { useFilterAssetList } from "../../api/useFilterAssetList";
import {
	DEFAULT_FILTER_KEYS,
	useLazyAssetListView,
} from "../../api/useLazyAssetList";
import { camelCase } from "lodash";
import { useAssetListParams } from "../../api/useAssetListParams";
import { words } from "lodash";
import { upperFirst } from "lodash";
import { useSavedListViews } from "@/features/saved-list-views/hooks/useSavedListViews";
import { useConfirmationDialog } from "@/Components/ConfirmationDialog/ConfirmationDialog";
import { useFeature } from "@/providers/feature-flags/FeatureProvider";
import { PRICING_WORKSHEET_APPROVAL_STATUS } from "@/features/asset/constants";
import { PRICING_WORKSHEET_FEATURE_FLAG } from "@/providers/feature-flags/constants";

const Filters = () => {
	const { addSavedFilters } = useLazySavedFilters();
	const {
		handleSubmit,
		getValues,
		formState: { isDirty },
		reset,
		setValue,
	} = useFormContext();
	const { trigger } = useFilterAssetList();
	const filterFields = useWatch({ name: "filters" });
	const { trigger: triggerView } = useLazyAssetListView();
	const submit = async (data) => {
		await addSavedFilters({
			...data,
			filters: data.filters.filter(
				(item) => item.value && item.propertyPath && item.operation
			),
		});
		triggerView(camelCase(data.name));
		reset();
	};
	const [{ view, filtered }, { setView }] = useAssetListParams();

	const saveName = useWatch({ name: "name" }) ?? "";

	const { savedFilters, removeSavedFilter } = useSavedListViews();

	const confirmationModal = useConfirmationDialog();

	const deleteWithConfirmation = () => {
		confirmationModal.show({
			title: "Confirm Delete",
			message: "Are you sure you want to delete this list?",
			confirmButtonText: "Delete",
			action: async () => {
				await removeSavedFilter(view);
				setView("myAssets");
			},
		});
	};

	useEffect(() => {
		if (isDirty && !filtered) {
			setValue("name", "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDirty, filtered]);

	return (
		<div>
			<form onSubmit={handleSubmit(submit)}>
				<div
					style={{
						display: "flex",
						width: "100%",
						marginBottom: ".5rem",
					}}
				>
					{isDirty || filtered ? (
						<>
							<NameInput />
							<Button
								disabled={
									saveName?.length < 2 ||
									DEFAULT_FILTER_KEYS.includes(camelCase(saveName))
								}
								className={assetStyles.save}
								variant="brand"
								type="submit"
							>
								Save
							</Button>
						</>
					) : (
						<div
							style={{
								display: "flex",
								width: "95%",
								justifyContent: "space-between",
							}}
						>
							<h2
								className="slds-text-heading_small"
								style={{ padding: "0 1rem" }}
							>
								{formatView(view)}
							</h2>
							{Object.keys(savedFilters).includes(view) && (
								<Button
									variant="bare"
									onClick={deleteWithConfirmation}
									iconVariant="utility"
									iconName="delete"
								></Button>
							)}
						</div>
					)}
				</div>
				{filterFields?.map((field, index) => {
					return (
						<div
							className="slds-filters__item"
							key={`filter-field-${index}`}
							style={{
								display: "grid",
								gridTemplateColumns: "3fr 1fr",
								minHeight: "6rem",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
								}}
							>
								<Field
									style={{ width: "100%" }}
									key={`property-${field.id}`}
									name={`filters[${index}].propertyPath`}
								/>
								<Operation
									key={`operation-${field.id}`}
									search={`filters[${index}].propertyPath`}
									name={`filters[${index}].operation`}
								/>
								<Value
									key={`value-${field.id}`}
									search={`filters[${index}].propertyPath`}
									operation={`filters[${index}].operation`}
									name={`filters[${index}].value`}
								/>
							</div>
							<div className={assetStyles.filterButtons}>
								<Button
									key={`apply-${field.id}`}
									onClick={() => {
										trigger(
											getValues("filters").filter(
												(item) =>
													item.value && item.propertyPath && item.operation
											)
										);
									}}
								>
									Apply
								</Button>
								<Button
									key={`discard-${field.id}`}
									onClick={() => {
										trigger(
											getValues("filters").filter((item, ind) => {
												return (
													ind !== index &&
													item.value &&
													item.propertyPath &&
													item.operation
												);
											})
										);
									}}
									iconCategory="utility"
									iconName="delete"
									iconSize="medium"
									variant="icon"
								></Button>
							</div>
						</div>
					);
				})}
			</form>
		</div>
	);
};

export const AssetListFilterPanel = () => {
	const { setIsOpenFilterPanel } = useFilterPanel();
	const { append } = useFieldArray({
		name: "filters",
	});
	const { filters } = useFilterAssetList();
	const { reset } = useFormContext();

	const footer = (
		<div className="slds-filters__footer slds-grid slds-shrink-none">
			<button
				onClick={() => {
					append({ id: nanoid() });
				}}
				className="slds-button_reset slds-text-link"
				type="button"
			>
				Add Filter
			</button>

			<button
				className="slds-button_reset slds-text-link slds-col_bump-left"
				type="button"
				onClick={() => {
					reset({ filters: [] });
				}}
			>
				Remove All
			</button>
		</div>
	);

	const header = (
		<div className="slds-filters__header slds-grid slds-has-divider_bottom-space">
			<h2 className="slds-align-middle slds-text-heading_small">
				Search Parameters
			</h2>
			<Desktop>
				<Button
					className="slds-button slds-col_bump-left"
					variant="icon"
					iconCategory="utility"
					iconName="close"
					onClick={() => {
						setIsOpenFilterPanel(false);
					}}
				/>
			</Desktop>
		</div>
	);

	return (
		<div
			className={`${classes.FilterPanel} ${
				filters?.length > 0 ? classes.hasFilters : ""
			}`}
			style={{
				padding: ".25rem",
				backgroundColor: "white",
				borderLeft: "1px solid #ccc",
				borderTop: "1px solid #ccc",
			}}
		>
			{header}
			<Filters />
			{footer}
		</div>
	);
};

const Field = ({ control, name, ...props }) => {
	const isEnabledPricingWorksheet = useFeature(PRICING_WORKSHEET_FEATURE_FLAG);

	const pricingWorksheetFeatureEnabled = (filter) =>
		!(
			filter.propertyPath === PRICING_WORKSHEET_APPROVAL_STATUS.path &&
			!isEnabledPricingWorksheet
		);

	const options = Object.values(filterProperties)
		.filter(pricingWorksheetFeatureEnabled)
		.map((option) => {
			return {
				...option,
				id: option.propertyPath,
				value: option.propertyPath,
			};
		});
	const { field } = useController({ control, name, defaultValue: "" });
	const fieldSelected = options.find((item) => item.id === field.value);
	const selection = fieldSelected ? [fieldSelected] : [];

	return (
		<Combobox
			{...props}
			style={{ width: "100%" }}
			name={field.name}
			inputRef={field.ref}
			events={{
				onSelect: (e, { selection: [selection] }) => {
					field.onChange(selection?.value ?? "");
				},
			}}
			selection={selection}
			variant="readonly"
			options={options}
			disabled={false}
		/>
	);
};

const Operation = ({ control, search, name, ...props }) => {
	const { field } = useController({ control, name, defaultValue: "" });
	const searchValue = useWatch({ name: search });
	const options = Object.values(filterProperties)
		.find((item) => item.propertyPath === searchValue)
		?.operations.map((op) => {
			return {
				...op,
				id: op.value,
			};
		});
	const operation = options?.find((option) => option.value === field.value);
	const selection = operation ? [operation] : [];
	if (options) {
		return (
			<Combobox
				{...props}
				name={field.name}
				disabled={!searchValue}
				inputRef={field.ref}
				events={{
					onSelect: (e, { selection: [selection] }) => {
						field.onChange(selection?.value);
					},
				}}
				selection={selection}
				variant="readonly"
				options={options || []}
			/>
		);
	}
	return <></>;
};

const Value = ({ control, search, name, operation, ...props }) => {
	const searchValue = useWatch({ name: search });
	const {
		field: { onChange, name: fieldName, value, onBlur },
	} = useController({ control, name, defaultValue: "" });
	const Element = Object.values(filterProperties).find(
		(item) => item.propertyPath === searchValue
	)?.valueSelect;
	if (Element) {
		return (
			<div className={assetStyles.valueWrapper}>
				<Element
					{...props}
					operation={operation}
					onChange={onChange}
					name={fieldName}
					value={value}
					onBlur={onBlur}
				/>
			</div>
		);
	}
	return <></>;
};

export const AssetListFilterPanelContainer = () => {
	const { isOpenFilterPanel, setIsOpenFilterPanel } = useFilterPanel();
	const { filters } = useFilterAssetList();
	const [filterState, setFilterState] = useState([]);
	const [{ view, filtered }] = useAssetListParams();
	const {
		control,
		reset,
		formState: { isDirty },
		...methods
	} = useForm({
		defaultValues: { filters, name: formatView(view) },
	});

	useEffect(() => {
		if (
			control._formValues?.filters &&
			!isEqual(filters, control._formValues?.filters)
		) {
			setFilterState(filters);
			reset({
				filters,
				name: isDirty || filtered ? "" : formatView(view),
			});
		} else if (!isEqual(filters, filterState)) {
			setFilterState(filters);
			reset({ filters, name: "" });
		}
		//eslint-disable-next-line
	}, [filters, view]);

	return (
		<FormProvider
			{...{
				control,
				reset,
				...methods,
				formState: { ...methods.formState, isDirty },
			}}
		>
			<Desktop>
				{isOpenFilterPanel && (
					<div>
						<AssetListFilterPanel />
					</div>
				)}
			</Desktop>
			<Mobile>
				<SLDSModal
					onRequestClose={() => setIsOpenFilterPanel(false)}
					isOpen={isOpenFilterPanel}
					contentStyle={{ minHeight: "70vh" }}
					size="large"
				>
					<AssetListFilterPanel />
				</SLDSModal>
			</Mobile>
		</FormProvider>
	);
};

function NameInput() {
	const { field } = useController({
		name: "name",
	});

	return (
		<div className={assetStyles.nameInput}>
			<Input
				style={{ borderRadius: "0px" }}
				placeholder="Save Search"
				defaultValue=""
				{...field}
			/>
		</div>
	);
}

function formatView(view) {
	return words(upperFirst(view)).join(" ");
}
