import { FaFacebook, FaTwitter } from "react-icons/fa";

export const FBShare = ({ photoURL, ...props }) => {
	return (
		<a href={`https://facebook.com/sharer/sharer.php?u=${photoURL}`} target="_blank" rel="noopener noreferrer">
			<FaFacebook size="2em" {...props} />
		</a>
	);
};

export const TwitterShare = ({ photoURL, ...props }) => {
	return (
		<a href={`https://twitter.com/intent/tweet?url=${photoURL}`} target="_blank" rel="noopener noreferrer">
			<FaTwitter size="2em" {...props} />
		</a>
	);
};
