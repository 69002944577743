import { useState } from "react";
import { useSelector } from "react-redux";
import { selectMedia } from "../api/selectors";
import { useUpdateMediaPropertiesMutation } from "../api/endpoints";

export const useTitle = (image) => {
	const title = useSelector(selectMedia(image.id)).title;
	const [update] = useUpdateMediaPropertiesMutation();
	const [isEditingTitle, setIsEditingTitle] = useState(false);

	const updateTitle = (newTitle) => {
		update({ ...image, title: newTitle });
		setIsEditingTitle(false);
	};

	return { title, isEditingTitle, setIsEditingTitle, updateTitle };
};
