import {
	dropdownOperations,
	noRangeDateOperations,
} from "@/features/asset-list/components/Filters/properties";
import { Task } from "../../Types";
import { useGetAllUsersQuery } from "@/features/user/api/endpoints";
import { useFormContext } from "react-hook-form";
import { TaskFilterForm } from "./TasksFilters";
import { Combobox, SLDSInput } from "@salesforce/design-system-react";
import {
	useGetTaskStatusTypesQuery,
	useGetTaskTypesQuery,
} from "../../api/endpoints";
import dayjs from "dayjs";
import { useState } from "react";

type TaskValueOption = {
	label: string;
	value: string;
	key: string;
	id: string;
};

const filterValueConvert = (val: string[]) => {
	if (val.length === 0) {
		return "";
	}
	if (val.length === 1) {
		return val[0];
	} else {
		return `[${val.join(",")}]`;
	}
};

const UserFilter = ({ index }: { index: number }) => {
	const { setValue, watch } = useFormContext<TaskFilterForm>();
	const { data } = useGetAllUsersQuery();
	const name: `filters.${number}.value` = `filters.${index}.value`;

	const value = watch(name);
	const options = data
		?.map((item) => {
			return {
				label: `${item.firstName} ${item.lastName}`,
				value: item.id,
				key: item.id,
				id: item.id,
			};
		})
		.sort((a, b) => a.label.localeCompare(b.label));
	const selection = options?.filter((item) => value?.includes(item.id));
	return (
		<DropdownFilter
			name={name}
			options={options ?? []}
			selection={selection}
			onChange={(val: string[]) => setValue(name, filterValueConvert(val))}
		/>
	);
};

const StatusFilter = ({ index }: { index: number }) => {
	const { setValue, watch } = useFormContext<TaskFilterForm>();
	const { data } = useGetTaskStatusTypesQuery();
	const name: `filters.${number}.value` = `filters.${index}.value`;
	const value = watch(name);
	const options = data?.map((item) => {
		return {
			label: item.name,
			value: item.id,
			id: item.id,
			key: item.id,
		};
	});
	const selection = options?.filter((item) => value?.includes(item.id));
	return (
		<DropdownFilter
			name={name}
			options={options ?? []}
			selection={selection}
			onChange={(val: string[]) => setValue(name, filterValueConvert(val))}
		/>
	);
};

const TaskTypeFilter = ({ index }: { index: number }) => {
	const { setValue, watch } = useFormContext<TaskFilterForm>();
	const { data } = useGetTaskTypesQuery();
	const name: `filters.${number}.value` = `filters.${index}.value`;
	const value = watch(name);
	const options = data?.map((item) => {
		return {
			label: item.name,
			value: item.id,
			id: item.id,
			key: item.id,
		};
	});
	const selection = options?.filter((item) => value?.includes(item.id));
	return (
		<DropdownFilter
			name={name}
			options={options ?? []}
			selection={selection}
			onChange={(val: string[]) => setValue(name, filterValueConvert(val))}
		/>
	);
};

export const DateFilter = ({ index }: { index: number }) => {
	const { setValue, watch } = useFormContext<TaskFilterForm>();

	const name: `filters.${number}.value` = `filters.${index}.value`;
	const value = watch(name);
	const op = watch(`filters.${index}.operation`);

	const isISODate = dayjs(value).isValid() && !/^\d{4}-\d\d-\d\d$/.test(value);

	const convertValue = (val: string) =>
		isISODate ? dayjs(val).utc(false).format("YYYY-MM-DD") : val ?? "";

	const [inputValue, setInputValue] = useState(convertValue(value));

	const onChangeHandler = (e: any, { value }: { value: string }) => {
		setInputValue(value);
		if (dayjs(value).isValid()) {
			if (op === "<=") {
				setValue(name, dayjs(value).add(1, "second").utc(true).toISOString());
			} else {
				setValue(name, dayjs(value).utc(true).toISOString());
			}
		} else {
			setValue(name, "");
		}
	};

	return (
		<SLDSInput
			value={inputValue}
			name={name}
			type="date"
			onChange={onChangeHandler}
			maxValue={dayjs("2100-12-31").unix()}
			minValue={dayjs("1980-01-01").unix()}
		/>
	);
};

export const TASK_FILTER_FIELDS: {
	propertyPath: keyof Task;
	label: string;
	operations: { value: string; label: string }[];
	ValueSelect: (props: { index: number }) => JSX.Element;
}[] = [
	{
		propertyPath: "assignedTo",
		label: "Assigned To",
		operations: dropdownOperations,
		ValueSelect: (props) => <UserFilter {...props} />,
	},
	{
		propertyPath: "createdBy",
		label: "Created By",
		operations: dropdownOperations,
		ValueSelect: (props) => <UserFilter {...props} />,
	},
	{
		propertyPath: "statusTypeId",
		label: "Status",
		operations: dropdownOperations,
		ValueSelect: (props) => <StatusFilter {...props} />,
	},
	{
		propertyPath: "taskTypeId",
		label: "Task Type",
		operations: dropdownOperations,
		ValueSelect: (props) => <TaskTypeFilter {...props} />,
	},
	{
		propertyPath: "dueDate",
		label: "Follow Up",
		operations: noRangeDateOperations,
		ValueSelect: (props) => <DateFilter {...props} />,
	},
	{
		propertyPath: "updatedDate",
		label: "Last Edited",
		operations: noRangeDateOperations,
		ValueSelect: (props) => <DateFilter {...props} />,
	},
	{
		propertyPath: "createdDate",
		label: "Created On",
		operations: noRangeDateOperations,
		ValueSelect: (props) => <DateFilter {...props} />,
	},
	{
		propertyPath: "modifiedBy",
		label: "Last Updated By",
		operations: dropdownOperations,
		ValueSelect: (props) => <UserFilter {...props} />,
	},
];

const DropdownFilter = ({
	name,
	options,
	selection,
	onChange,
}: {
	name: `filters.${number}.value`;
	options: TaskValueOption[];
	selection:
		| { label: string; value: string; key: string; id: string }[]
		| undefined;
	onChange: (val: string[]) => void;
}) => {
	return (
		<Combobox
			name={name}
			placeholder="Select"
			variant="readonly"
			value=""
			selection={selection || []}
			options={options || []}
			menuPosition="relative"
			multiple
			events={{
				onSelect: (_: any, { selection }: { selection: TaskValueOption[] }) => {
					onChange(selection.map((item) => item.value));
				},
				onRequestRemoveSelectedOption: (
					_: any,
					{ selection }: { selection: TaskValueOption[] }
				) => {
					onChange(selection.map((item) => item.value));
				},
			}}
		/>
	);
};
