import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
	startingLocation: {
		id: "homeOffice",
		latitude: 35.809811,
		longitude: -83.94836,
		accountNumber: "Clayton Home Office",
		address: "5000 Clayton Road",
		state: "TN",
		city: "Maryville",
		zip: "37804",
	},
	endingLocation: {
		id: "homeOffice",
		latitude: 35.809811,
		longitude: -83.94836,
		accountNumber: "Clayton Home Office",
		address: "5000 Clayton Road",
		state: "TN",
		city: "Maryville",
		zip: "37804",
	},
	locationsSelected: [],
	totalMiles: "0",
	totalHours: "0:00",
};

export const tripPlanningSlice = createSlice({
	name: "tripPlanningSlice",
	initialState,
	reducers: {
		reset: () => initialState,
		setStartingLocation: (state, action) => {
			const result = action.payload;
			state.startingLocation = result;
			return state;
		},
		setEndingLocation: (state, action) => {
			const result = action.payload;
			state.endingLocation = result;
			return state;
		},
		toggleLocation: (state, action) => {
			const location = action.payload;
			let items = state.locationsSelected;
			const index = items.findIndex((val) => val.id === location.id);
			if (index > -1) {
				items.splice(index, 1);
			} else {
				items.push(location);
			}
			state.locationsSelected = items;
			return state;
		},
		addMultipleLocations: (state, action) => {
			const locations = action.payload;
			const withoutCurrentlySelected = locations?.filter((item) => {
				const index = state.locationsSelected.findIndex(
					(val) => val.id === item.id
				);
				return index === -1;
			});
			state.locationsSelected = [
				...state.locationsSelected,
				...withoutCurrentlySelected,
			];
			return state;
		},
		setLocations: (state, action) => {
			const items = action.payload;
			state.locationsSelected = items;
			return state;
		},
		setTripDetails: (state, action) => {
			const { totalMiles, totalHours } = action.payload;
			state.totalMiles = totalMiles;
			state.totalHours = totalHours;
			return state;
		},
	},
});

export const {
	addMultipleLocations,
	setStartingLocation,
	setLocations,
	setEndingLocation,
	setTripDetails,
	toggleLocation,
	reset,
} = tripPlanningSlice.actions;

export const selectTripPlanning = (state) => state.tripPlanning;

export const selectLocationsSelected = createSelector(
	selectTripPlanning,
	(state) => state.locationsSelected
);

export const selectStartingLocation = createSelector(
	selectTripPlanning,
	(state) => state.startingLocation
);

export const selectEndingLocation = createSelector(
	selectTripPlanning,
	(state) => state.endingLocation
);

export const selectRoute = createSelector(
	selectLocationsSelected,
	selectStartingLocation,
	selectEndingLocation,
	(locations, starting, ending) => {
		let route = [];
		if (starting.id) {
			route.push(starting);
		}
		locations.forEach((element) => {
			route.push(element);
		});
		if (ending.id) {
			route.push(ending);
		}
		return route;
	}
);

export const selectSelectedLatLong = createSelector(selectRoute, (locations) =>
	locations.map(({ latitude, longitude }) => ({
		latitude,
		longitude,
	}))
);

export const selectTripMileage = createSelector(
	selectTripPlanning,
	(state) => state.totalMiles
);

export const selectTripDuration = createSelector(
	selectTripPlanning,
	(state) => state.totalHours
);
