import React from "react";
import {
	PageHeader,
	Button,
	PageHeaderControl,
	Icon,
	Dropdown,
	Tooltip,
} from "@salesforce/design-system-react";
import classes from "./ImagingHeader.module.scss";
import { useUploadImagingModal } from "../upload-imaging/UploadImaging";
import { useIsMobile } from "@/Responsive";
import { useTabs } from "@/hooks/useTabs";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAvailableDoctypes,
	selectImagingDocTypes,
	setDocTypes,
} from "../api/ImagingSlice";

export const ImagingHeader = ({
	info,
	setCardsPerRow,
	close,
	cardsPerRow,
	accountNumber,
}) => {
	const isMobile = useIsMobile();
	const rowOptions = isMobile
		? [
				{ type: "header", label: "Cards Per Row" },
				{ label: "1 Per Row", value: "OnePerRow" },
				{ label: "2 Per Row", value: "TwoPerRow" },
		  ]
		: [
				{ type: "header", label: "Cards Per Row" },
				{ label: "1 Per Row", value: "OnePerRow" },
				{ label: "2 Per Row", value: "TwoPerRow" },
				{ label: "4 Per Row", value: "FourPerRow" },
				{ label: "6 Per Row", value: "SixPerRow" },
				{ label: "8 Per Row", value: "EightPerRow" },
		  ];

	const uploadModal = useUploadImagingModal();

	// Make Sideview a query param
	const { currentTab, setCurrentTab } = useTabs();
	// Is mobile here to check for initial state of cards per row
	const sideView = currentTab !== "imaging" && !isMobile;

	const dispatch = useDispatch();
	const actions = () => (
		<PageHeaderControl>
			<Tooltip content="Add Imaging Doc">
				<Button
					variant="icon"
					iconCategory="utility"
					iconVariant="border-filled"
					iconName="add"
					onClick={() => uploadModal.show({ accountNumber })}
				/>
			</Tooltip>
			{!sideView && (
				<Dropdown
					iconCategory="utility"
					iconName="settings"
					align="right"
					iconVariant="border-filled"
					checkmark
					onSelect={(value) => {
						setCardsPerRow(value.value);
					}}
					options={rowOptions}
					value={cardsPerRow}
				/>
			)}
			{!isMobile && (
				<Tooltip content={sideView ? "Expand" : "Contract"}>
					<Button
						variant="icon"
						iconCategory="utility"
						iconName={sideView ? "expand" : "contract"}
						iconVariant="border-filled"
						onClick={() => {
							sideView ? setCurrentTab("imaging") : setCurrentTab("details");
						}}
					/>
				</Tooltip>
			)}
			{!isMobile && sideView && (
				<Tooltip content="Close Side Module">
					<Button
						variant="icon"
						iconCategory="utility"
						iconName="close"
						iconVariant="border-filled"
						onClick={() => close()}
					/>
				</Tooltip>
			)}
		</PageHeaderControl>
	);

	const selectedDocTypes = useSelector(selectImagingDocTypes(accountNumber));
	const options =
		useSelector(selectAvailableDoctypes(accountNumber)).map((item) => {
			return {
				id: item,
				value: item,
				label: item,
			};
		}) ?? [];

	const title =
		selectedDocTypes.length < 1
			? "All Items"
			: `${selectedDocTypes.length} Selected`;

	return (
		<PageHeader
			className={classes.ImagingHeader}
			icon={
				<Icon
					assistiveText={{ label: "Home" }}
					category="standard"
					style={{
						backgroundColor: "#33A1FF",
						fill: "#ffffff",
					}}
					name="file"
				/>
			}
			nameSwitcherDropdown={
				<Dropdown
					width="x-small"
					align="right"
					assistiveText={{ icon: "Name Switcher" }}
					buttonClassName="slds-button_icon-small"
					buttonVariant="icon"
					iconCategory="utility"
					iconName="down"
					id="page-header-name-switcher-dropdown"
					checkmark
					value={selectedDocTypes}
					onSelect={(selection) => {
						dispatch(setDocTypes({ docType: selection.value, accountNumber }));
					}}
					multiple={true}
					options={options}
				/>
			}
			info={<div className={classes.info}>{info}</div>}
			label="Imaging"
			onRenderActions={actions}
			title={title}
			variant="object-home"
		/>
	);
};
