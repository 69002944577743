import { Button } from "@salesforce/design-system-react";
import styles from "./spinner.module.scss";
import { ReactNode } from "react";

interface SpinnerButtonProps {
	isSpinning?: boolean;
	children: ReactNode;
	variant?: string;
	type?: string;
	form?: string;
	disabled?: boolean;
}

export const SpinnerButton = ({
	isSpinning,
	children,
	...props
}: SpinnerButtonProps) => {
	return (
		<Button
			{...props}
			{...(isSpinning && {
				iconClassName: styles.spinner,
				iconName: "spinner",
				iconPosition: "left",
				iconCategory: "utility",
			})}
		>
			{children}
		</Button>
	);
};
