import { Button } from "@salesforce/design-system-react";
import { usePricingWorksheet } from "../usePricingWorksheet";
import spinner from "./spinner.module.scss";

export const CreateWorksheetButton = ({ assetId }: { assetId: string }) => {
	const { isFetching, isLoading, createNewWorksheet } =
		usePricingWorksheet(assetId);

	const spinnerProps = {
		iconClassName: spinner.spinner,
		iconName: "spinner",
	};
	return (
		<Button
			iconCategory="utility"
			iconName={"add"}
			iconPosition="left"
			onClick={createNewWorksheet}
			{...((isLoading || isFetching) && spinnerProps)}
		>
			New Worksheet
		</Button>
	);
};
