import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";

export const useAssetHeader = (assetId: string) => {
	const { data: asset, isSuccess: isSuccessAsset } = useGetAssetQuery(assetId, {
		selectFromResult: ({ data, isSuccess }) => {
			return {
				data,
				isSuccess,
			};
		},
	});

	return {
		asset,
		isSuccess: isSuccessAsset,
	};
};
