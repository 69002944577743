import { vestResolver } from "@hookform/resolvers/vest";
import { nanoid } from "@reduxjs/toolkit";
import { useForm, FormProvider as HookFormProvider } from "react-hook-form";
import { Button } from "@salesforce/design-system-react";
import { useFormContext } from "react-hook-form";

export const SubmitButtons = ({ form, cancel }) => {
	const {
		formState: { dirtyFields },
	} = useFormContext();

	return (
		<div
			style={{
				display: "grid",
				background: "rgba(236, 235, 234, 0.9)",
				placeContent: "center",
				width: "100%",
				height: "4rem",
			}}
		>
			<div>
				<Button onClick={cancel}>Cancel</Button>
				<Button
					variant="brand"
					type="submit"
					form={form}
					disabled={!Object.keys(dirtyFields).length}
				>
					Save
				</Button>
			</div>
		</div>
	);
};

export const Form = ({ onSubmit = () => {}, children, schema = null }) => {
	const { reset, setFocus, control, ...form } = useForm({
		...(schema && { resolver: vestResolver(schema) }),
		mode: "onBlur",
	});
	return (
		<form
			id={nanoid()}
			name="conditionReportForm"
			onSubmit={form.handleSubmit(onSubmit)}
		>
			<HookFormProvider
				{...{
					setFocus,
					control,
					reset,
				}}
				{...form}
			>
				{children}
			</HookFormProvider>
		</form>
	);
};
