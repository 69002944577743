import { ChangeEvent, DragEvent, useRef, useState } from "react";
import {
	useForm,
	FormProvider,
	useController,
	Path,
	useFormContext,
} from "react-hook-form";
import classes from "./TaskForm.module.scss";
import { NewAssociatedEntity, NewTask, Task, TaskLookupType } from "../Types";
import {
	Button,
	Combobox,
	Icon,
	InputIcon,
	SLDSInput,
} from "@salesforce/design-system-react";
import {
	useCreateTaskMutation,
	useGetEntityTypesQuery,
	useGetTaskStatusTypesQuery,
	useGetTaskTypesQuery,
	useUpdateTaskMutation,
} from "../api/endpoints";
import dayjs from "dayjs";
import { useGetAllUsersQuery } from "@/features/user/api/endpoints";
import { AssetAssociation } from "./AssetAssocation";
import { useUser } from "@/features/user/hooks/useUser";
import Spinner from "@/Components/UI/Spinner/Spinner";
import { vestResolver } from "@hookform/resolvers/vest";
import { taskFormValidation } from "./taskFormValidation";
import { GiCloudUpload } from "react-icons/gi";
import { GrAttachment } from "react-icons/gr";
import { getFormattedDate, readableFileSize } from "@/Utilities/utils";
import { grey70 } from "@/styles/colors";
import { TaskSubtype } from "./TaskSubtype";
import { TaskComments } from "./TaskComments";
import { useUploadTaskDocumentMutation } from "@/features/imaging/api/endpoints";
import { SpinnerButton } from "@/Components/SpinnerButton/SpinnerButton";
import { CancellationReason } from "./CancellationReason";
import { InvoiceInfo } from "./InvoiceInfo";
export interface TaskFormType extends NewTask {
	files: File[];
	invoice?: {
		supplierName: string;
		invoiceNumber: string;
		amountApproved: string;
	};
}

const parseExistingTask = (task: NewTask): TaskFormType => {
	const invoiceEntity = task.associatedEntities.find((item) => {
		const propertyBag = item.propertyBag ? JSON.parse(item.propertyBag) : {};
		return (
			propertyBag.supplierName &&
			propertyBag.invoiceNumber &&
			propertyBag.amountApproved
		);
	});

	const invoice = invoiceEntity?.propertyBag
		? {
				supplierName: JSON.parse(invoiceEntity.propertyBag).supplierName,
				invoiceNumber: JSON.parse(invoiceEntity.propertyBag).invoiceNumber,
				amountApproved: JSON.parse(invoiceEntity.propertyBag).amountApproved,
		  }
		: undefined;

	return { ...task, files: [], invoice };
};

const createDefaultTask = () => {
	return {
		dueDate: dayjs().add(1, "day").toISOString(),
		comments: [],
		priority: 1,
		associatedEntities: [],
		files: [],
	};
};

export const TaskForm = ({
	task,
	onSubmitSuccess,
	onCancel,
}: {
	task?: NewTask | undefined;
	onSubmitSuccess: () => void;
	onCancel: () => void;
}) => {
	const [formError, setFormError] = useState<{
		isError: boolean;
		message: string;
	}>({ isError: false, message: "Something went wrong" });

	const { data: currentUser, isLoading: userLoading } = useUser();
	const {
		data: statusTypes,
		isLoading: statusLoading,
		isError,
	} = useGetTaskStatusTypesQuery();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const { data: entityTypes } = useGetEntityTypesQuery();

	const [updateTask] = useUpdateTaskMutation();

	const [createTask] = useCreateTaskMutation();

	const [uploadTaskFile] = useUploadTaskDocumentMutation();

	const methods = useForm<TaskFormType>({
		defaultValues: task ? parseExistingTask(task) : createDefaultTask(),
		resolver: vestResolver(taskFormValidation),
	});

	const isLoading = userLoading || statusLoading;

	const onSubmit = async (data: TaskFormType) => {
		setIsSubmitting(true);
		// upload files and create the associated entities.
		const files = data.files;

		const uploadFiles = await Promise.all(
			files.map((file) => {
				return uploadTaskFile({
					document: file,
					userEmail: currentUser?.email ?? "",
				});
			})
		);

		const documentType =
			entityTypes?.find((item) => item.name === "Document")?.id ?? "";

		const docEntities: NewAssociatedEntity[] = uploadFiles
			.filter((item) => "data" in item)
			.map((item) => {
				return {
					entityId: item.data.toString(),
					entityTypeId: documentType,
					activeDate: dayjs().toISOString(),
					createdBy: currentUser?.id ?? "",
					createdDate: dayjs().toISOString(),
					name: item.data.toString(),
				};
			});

		// Entity ID for invoices is invoiceType
		const invoiceType =
			entityTypes?.find((item) => item.name === "Invoice")?.id ?? "";

		const existingInvoiceInformation = data.associatedEntities.find(
			(item) => item.entityTypeId === invoiceType
		);

		const invoiceEntity: NewAssociatedEntity[] = data.invoice
			? existingInvoiceInformation
				? [
						{
							...existingInvoiceInformation,
							propertyBag: JSON.stringify({
								supplierName: data.invoice?.supplierName,
								invoiceNumber: data.invoice?.invoiceNumber,
								amountApproved: data.invoice?.amountApproved,
							}),
						},
				  ]
				: [
						{
							name: "Invoice Information",
							entityId: data.invoice?.invoiceNumber ?? "",
							entityTypeId: invoiceType,
							activeDate: dayjs().toISOString(),
							createdBy: currentUser?.id ?? "",
							createdDate: dayjs().toISOString(),
							propertyBag: JSON.stringify({
								supplierName: data.invoice?.supplierName,
								invoiceNumber: data.invoice?.invoiceNumber,
								amountApproved: data.invoice?.amountApproved,
							}),
						},
				  ]
			: [];

		if (task?.id) {
			if (currentUser?.id) {
				const status = statusTypes?.find(
					(item) => item.id === data.statusTypeId
				);
				const cancellationReason = data.cancellationReason?.reason
					? {
							cancelledBy: currentUser.id,
							cancelledDate: dayjs().toISOString(),
							reason: data.cancellationReason.reason,
							title: data.cancellationReason.reason,
					  }
					: undefined;
				// Update task
				const submission: NewTask = {
					...data,
					associatedEntities: [
						...data.associatedEntities.filter(
							(item) => item.entityTypeId !== invoiceType
						),
						...docEntities,
						...invoiceEntity,
					],
					modifiedBy: currentUser.id,
					updatedDate: dayjs().toISOString(),
					completedBy:
						status?.name === "Completed" ? currentUser.id : data.completedBy,
					cancellationReason: cancellationReason,
				};
				const update = await updateTask(submission);
				if ("error" in update) {
					setFormError({
						isError: true,
						message: "Something went wrong when updating the task",
					});
					setIsSubmitting(false);
					return;
				}
				setIsSubmitting(false);
				onSubmitSuccess();
			}
		} else {
			// Find "New" status type
			const status = statusTypes?.find((item) => item.name === "New");

			// Create task
			const submission: NewTask = {
				...data,
				associatedEntities: [
					...data.associatedEntities,
					...docEntities,
					...invoiceEntity,
				],
				createdBy: currentUser?.id ?? "",
				createdDate: dayjs().toISOString(),
				modifiedBy: currentUser?.id ?? "",
				updatedDate: dayjs().toISOString(),
				priority: 1,
				statusTypeId: status?.id ?? "",
			};
			const creation = await createTask(submission);
			if ("error" in creation) {
				setFormError({
					isError: true,
					message: "Something went wrong when creating the task",
				});
				return;
			}
			setIsSubmitting(false);
			onSubmitSuccess();
		}
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return (
			<div>
				<p>Something went wrong loading Task Statuses</p>
			</div>
		);
	}

	return (
		<>
			<FormProvider {...methods}>
				<form
					className={classes.form}
					onSubmit={methods.handleSubmit(onSubmit)}
				>
					<div style={{ display: "flex", gap: "1rem", width: "100%" }}>
						<div style={{ width: "100%" }}>
							<TaskType />
						</div>
						<TaskSubtype />
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							gap: "1rem",
						}}
					>
						<TaskDate label="Follow Up Date" name="dueDate" />
						<TaskUser name="assignedTo" label="Assigned To" />
					</div>

					<AssetAssociation />
					<InvoiceInfo />
					{task?.id && <TaskStatus />}
					<CancellationReason />
					<TaskComments />
					<Attachments />
					<div className={classes.submitButtons}>
						<Button className={classes.button} onClick={() => onCancel()}>
							Cancel
						</Button>
						<SpinnerButton
							type="submit"
							variant="brand"
							isSpinning={isSubmitting}
							disabled={isSubmitting}
						>
							Submit
						</SpinnerButton>
					</div>
				</form>
			</FormProvider>
			{formError.isError && (
				<div className={classes.errorContainer}>
					<p>{formError.message}</p>
					<Button
						assistiveText={{ icon: "Icon Bare Small" }}
						iconCategory="utility"
						iconName="close"
						iconSize="small"
						iconVariant="bare"
						variant="icon"
						onClick={() => setFormError({ isError: false, message: "" })}
					/>
				</div>
			)}
		</>
	);
};

const Attachments = () => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const { watch, setValue } = useFormContext<TaskFormType>();
	const [isDraggedOver, setIsDraggedOver] = useState(false);
	const files = watch("files");
	const handleDroppedUpload = (e: DragEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		// Only supporting application/pdf as of now
		setValue("files", [...(files ?? []), file], {
			shouldDirty: true,
			shouldTouch: true,
		});
		setIsDraggedOver(false);
	};

	const handleFileUpload = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleClick = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const newFiles: FileList = e.target.files;
			const newFilesArray = Array.from(newFiles);
			setValue("files", [...(files ?? []), ...newFilesArray], {
				shouldDirty: true,
				shouldTouch: true,
			});
		}
	};

	const handleRemoveFile = (file: File) => {
		setValue(
			"files",
			files?.filter((f) => f !== file),
			{
				shouldDirty: true,
				shouldTouch: true,
			}
		);
	};

	return (
		<div>
			<label className="slds-form-element__label" htmlFor="file-upload">
				Attachments
			</label>
			<div
				className={`${classes.dropContainer} ${
					isDraggedOver ? classes.draggedOver : classes.notDraggedOver
				}`}
				onDragOver={(e) => {
					e.preventDefault();
					setIsDraggedOver(true);
				}}
				onDragLeave={() => setIsDraggedOver(false)}
				onDrop={handleDroppedUpload}
			>
				<GiCloudUpload
					style={{ fill: "#18A0FB", width: "3rem", height: "3rem" }}
				/>
				<p style={{ margin: ".5rem" }}>Drag and Drop a PDF to Upload or</p>
				<Button
					htmlFor="file-upload"
					assistiveText={{ icon: "Add attachments" }}
					onClick={() => handleFileUpload()}
				>
					Choose file
				</Button>
				<input
					style={{ display: "none" }}
					type="file"
					id="file-upload-hidden"
					accept="*"
					multiple={true}
					ref={fileInputRef}
					onChange={handleClick}
				/>
			</div>
			<div className={classes.attachments}>
				{files?.map((file, index) => (
					<div key={index} className={classes.attachmentItem}>
						<div
							style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
						>
							<GrAttachment style={{ height: 20, width: 20 }} />
							<div>
								<p style={{ fontWeight: "bold" }}>{file.name}</p>
								<p style={{ color: grey70 }}>{readableFileSize(file.size)}</p>
							</div>
						</div>
						<Button
							onClick={() => handleRemoveFile(file)}
							assistiveText={{ icon: "Icon Border-filled medium" }}
							iconCategory="utility"
							iconName="close"
							iconVariant="border-filled"
							variant="icon"
						/>
					</div>
				))}
			</div>
		</div>
	);
};

const TaskStatus = () => {
	const { data } = useGetTaskStatusTypesQuery();

	const {
		field: { ref, value, ...rest },
		fieldState: { error },
	} = useController({ name: "statusTypeId" });

	const comboboxValue = value?.toString();

	const options =
		data?.map((option, index) => {
			return {
				id: `status-option-${option.name}-${option.id}`,
				value: option.id,
				label: option.name,
			};
		}) ?? [];

	const selection = options.filter((option) => {
		return option?.value?.toString() === value?.toString();
	});

	return (
		<div>
			<Combobox
				required
				variant="readonly"
				options={options}
				selection={selection}
				events={{
					onSelect: (
						e: any,
						{
							selection: [selection],
						}: { selection: { value: string; label: string }[] }
					) => {
						rest.onChange(selection?.value || null);
					},
				}}
				inputRef={ref}
				errorText={error?.message}
				iconLeft={<InputIcon name="error" category="utility" />}
				labels={{ label: "Status" }}
				{...rest}
				value={comboboxValue}
			/>
		</div>
	);
};

const TaskType = () => {
	const { data } = useGetTaskTypesQuery();
	const {
		field: { ref, value, ...rest },
		fieldState: { error },
	} = useController({ name: "taskTypeId" });

	const comboboxValue = value?.toString();

	const options =
		data
			?.filter((item) => !item.inactiveDate)
			.map((option, index) => {
				return {
					id: `tasktype-option-${option.name}-${option.id}`,
					value: option.id,
					label: option.name,
					subtypes: option.taskSubTypes,
				};
			}) ?? [];
	const selection = options.filter((option) => {
		return option?.value?.toString() === value?.toString();
	});

	const { watch, setValue } = useFormContext<TaskFormType>();

	const id = watch("id");

	if (id) {
		return (
			<div className="slds-form-element">
				<label
					className="slds-form-element__label"
					htmlFor="existing-task-type"
				>
					Task Type
				</label>
				<div className="slds-form-element__control" id="existing-task-type">
					<p>{selection[0].label}</p>
				</div>
			</div>
		);
	}

	return (
		<Combobox
			variant="readonly"
			options={options}
			selection={selection}
			required
			events={{
				onSelect: (
					e: any,
					{
						selection: [selection],
					}: {
						selection: {
							value: string;
							label: string;
							subtypes: TaskLookupType[];
						}[];
					}
				) => {
					rest.onChange(selection?.value || null);
					setValue("invoice", undefined, {
						shouldDirty: true,
						shouldTouch: true,
					});
					if (selection?.subtypes?.length > 1) {
						// If more than one, don't default to anything, and let the user decide
						setValue("taskSubtypeId", undefined, {
							shouldDirty: true,
							shouldTouch: true,
						});
						setValue("name", "", {
							shouldDirty: true,
							shouldTouch: true,
						});
					} else if (selection?.subtypes?.length === 1) {
						// if there is only one, then default it to that one
						setValue("taskSubtypeId", selection?.subtypes[0].id, {
							shouldDirty: true,
							shouldTouch: true,
						});
						setValue("name", selection.subtypes[0].name, {
							shouldDirty: true,
							shouldTouch: true,
						});
					} else {
						// No subtypes found for given task type, clear the subtype and name
						setValue("taskSubtypeId", undefined, {
							shouldDirty: true,
							shouldTouch: true,
						});
						setValue("name", "", {
							shouldDirty: true,
							shouldTouch: true,
						});
					}
				},
			}}
			inputRef={ref}
			errorText={error?.message}
			iconLeft={<InputIcon name="error" category="utility" />}
			labels={{ label: "Task Type" }}
			{...rest}
			value={comboboxValue}
		/>
	);
};

const TaskDate = ({ name, label }: { name: Path<Task>; label: string }) => {
	const {
		field: { ref, value, onChange, ...rest },
		fieldState: { error },
	} = useController({ name });

	const convertValue = () => getFormattedDate(value);

	const [inputValue, setInputValue] = useState(convertValue());
	const onChangeHandler = (e: any, { value }: { value: string }) => {
		setInputValue(value);
		if (dayjs(value).isValid()) {
			onChange(dayjs(value).add(12, "hours").toISOString());
		} else {
			onChange(null);
		}
	};
	return (
		<div style={{ width: "100%" }}>
			<SLDSInput
				required
				label={label}
				inputRef={ref}
				errorText={error?.message}
				value={inputValue}
				{...rest}
				type="date"
				onChange={onChangeHandler}
			/>
		</div>
	);
};

const TaskUser = ({ name, label }: { name: Path<Task>; label: string }) => {
	const { data } = useGetAllUsersQuery(undefined);
	const {
		field: { ref, value, ...rest },
		fieldState: { error },
	} = useController<Task>({ name });

	const [searchState, setSearchState] = useState<string>("");

	const options =
		data?.map((option) => {
			return {
				id: option.id,
				value: option.id,
				label: `${option.firstName} ${option.lastName}`,
				subTitle: option.email,
				icon: (
					<Icon
						assistiveText={{ label: option.email }}
						category="standard"
						name="people"
					/>
				),
			};
		}) ?? [];

	const selection = options.filter((option) => {
		return option?.value?.toString() === value?.toString();
	});

	return (
		<div style={{ width: "100%" }}>
			<Combobox
				variant="inline-listbox"
				options={options.filter((option) => {
					return (
						option.label.toLowerCase().includes(searchState.toLowerCase()) ||
						option.subTitle.toLowerCase().includes(searchState.toLowerCase())
					);
				})}
				selection={selection}
				menuItemVisibleLength={5}
				predefinedOptionsOnly
				events={{
					onChange: (
						e: ChangeEvent<HTMLInputElement>,
						{ value }: { value: string }
					) => {
						setSearchState(value);
					},
					onSelect: (
						e: any,
						{
							selection: [selection],
						}: { selection: { value: string; label: string }[] }
					) => {
						rest.onChange(selection?.value || null);
					},
					onRequestRemoveSelectedOption: () => {
						rest.onChange(null);
					},
					onSubmit: (event: any) => {
						console.log(event);
					},
				}}
				inputRef={ref}
				errorText={error?.message}
				iconLeft={<InputIcon name="error" category="utility" />}
				labels={{ label: label }}
				{...rest}
				value={selection.length > 0 ? selection[0].label : searchState}
			/>
		</div>
	);
};
