import {
	ControlledPriceCheckbox,
	ControlledPriceInput,
	ControlledCheckboxDisable,
} from "../components/Form/ControlledPrice";
import {
	BATHROOM_SINK,
	BATH_FAUCET,
	CARPET_ROOM,
	CEILING_FAN,
	FLOOR_REGISTER,
	INTERIOR_DOOR,
	INTERIOR_TRIM_WORK,
	KILZ_FLOOR_PER_ROOM,
	labelsIndex,
	LIGHT_FIXTURE,
	ONE_INCH_BLINDS,
	OUTLET_LIGHT_REPAIR,
	PAINT_CEILING,
	PAINT_DOOR,
	PAINT_WALLS,
	REPLACE_FLOOR_DECKING,
	REPLACE_TUB_OR_SHOWER,
	REPLACE_WALL_BOARD,
	SMOKE_ALARM,
	TOILET,
	TOILET_SEAT,
	TOWEL_HOLDER,
	TUB_PAINT_OR_EPOXY,
	WINDOW,
} from "../constants/constants";

export const bathroomConfig = [
	{
		component: ControlledPriceCheckbox,
		props: {
			name: CEILING_FAN,
			costName: CEILING_FAN,
			label: labelsIndex[CEILING_FAN],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: INTERIOR_TRIM_WORK,
			costName: INTERIOR_TRIM_WORK,
			label: labelsIndex[INTERIOR_TRIM_WORK],
		},
	},
	{
		component: ControlledCheckboxDisable,
		props: {
			name: PAINT_WALLS,
			watchField: "general.paintEntireHome",
			costName: PAINT_WALLS,
			label: labelsIndex[PAINT_WALLS],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: PAINT_CEILING,
			costName: PAINT_CEILING,
			label: labelsIndex[PAINT_CEILING],
		},
	},
	{
		component: ControlledCheckboxDisable,
		props: {
			name: CARPET_ROOM,
			watchField: "general.carpetEntireHome",
			costName: CARPET_ROOM,
			label: labelsIndex[CARPET_ROOM],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: REPLACE_TUB_OR_SHOWER,
			costName: REPLACE_TUB_OR_SHOWER,
			label: labelsIndex[REPLACE_TUB_OR_SHOWER],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: TOILET,
			costName: TOILET,
			label: labelsIndex[TOILET],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: TOILET_SEAT,
			costName: TOILET_SEAT,
			label: labelsIndex[TOILET_SEAT],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: TUB_PAINT_OR_EPOXY,
			costName: TUB_PAINT_OR_EPOXY,
			label: labelsIndex[TUB_PAINT_OR_EPOXY],
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: ONE_INCH_BLINDS,
			costName: ONE_INCH_BLINDS,
			label: labelsIndex[ONE_INCH_BLINDS],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: BATH_FAUCET,
			costName: BATH_FAUCET,
			label: labelsIndex[BATH_FAUCET],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: BATHROOM_SINK,
			costName: BATHROOM_SINK,
			label: labelsIndex[BATHROOM_SINK],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: FLOOR_REGISTER,
			costName: FLOOR_REGISTER,
			label: labelsIndex[FLOOR_REGISTER],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: KILZ_FLOOR_PER_ROOM,
			costName: KILZ_FLOOR_PER_ROOM,
			label: labelsIndex[KILZ_FLOOR_PER_ROOM],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: INTERIOR_DOOR,
			costName: INTERIOR_DOOR,
			label: labelsIndex[INTERIOR_DOOR],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: LIGHT_FIXTURE,
			costName: LIGHT_FIXTURE,
			label: labelsIndex[LIGHT_FIXTURE],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: OUTLET_LIGHT_REPAIR,
			costName: OUTLET_LIGHT_REPAIR,
			label: labelsIndex[OUTLET_LIGHT_REPAIR],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: PAINT_DOOR,
			costName: PAINT_DOOR,
			label: labelsIndex[PAINT_DOOR],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: REPLACE_FLOOR_DECKING,
			costName: REPLACE_FLOOR_DECKING,
			label: labelsIndex[REPLACE_FLOOR_DECKING],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: REPLACE_WALL_BOARD,
			costName: REPLACE_WALL_BOARD,
			label: labelsIndex[REPLACE_WALL_BOARD],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: SMOKE_ALARM,
			costName: SMOKE_ALARM,
			label: labelsIndex[SMOKE_ALARM],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: TOWEL_HOLDER,
			costName: TOWEL_HOLDER,
			label: labelsIndex[TOWEL_HOLDER],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: WINDOW,
			costName: WINDOW,
			label: labelsIndex[WINDOW],
			type: "number",
		},
	},
];
