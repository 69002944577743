import {
	FormProvider,
	useFieldArray,
	useForm,
	useFormContext,
} from "react-hook-form";
import { useTaskList } from "../../api/useTaskList";
import { TaskFilteringCriteria } from "../../Types";
import { useEffect, useState } from "react";
import { Button, Combobox } from "@salesforce/design-system-react";
import { TASK_FILTER_FIELDS } from "./filters";
import classes from "./TasksFilters.module.scss";
import { useUpdateTaskLists } from "../../api/useUpdateTaskLists";

export type TaskFilterForm = {
	filters: TaskFilteringCriteria[];
	searchName?: string;
};

export const TasksFilters = () => {
	const { query, setQuery, filtersOpen, setFiltersOpen } = useTaskList();
	const methods = useForm<TaskFilterForm>({
		defaultValues: { filters: query.filteringCriteria },
	});

	// When filtering criteria changes because of a list change, reset the form to the query filtering criteria
	useEffect(() => {
		methods.reset({ filters: query.filteringCriteria });
		//eslint-disable-next-line
	}, [query.filteringCriteria]);

	const handleFilterChange = (data: TaskFilterForm) => {
		setQuery({
			...query,
			filteringCriteria: data.filters.filter(
				(item) => item.operation && item.propertyPath && item.value
			),
			paginationCriteria: {
				...query.paginationCriteria,
				pageNumber: 0,
			},
		});
	};

	const { fields, append, remove } = useFieldArray<TaskFilterForm, "filters">({
		control: methods.control,
		name: "filters",
	});

	return (
		<div
			className={classes.container}
			style={filtersOpen ? { display: "flex" } : { display: "none" }}
		>
			<div className="slds-filters__header slds-grid slds-has-divider_bottom-space">
				<h2 className="slds-align-middle slds-text-heading_small">
					Search Parameters
				</h2>
				<Button
					className="slds-button slds-col_bump-left"
					variant="icon"
					iconCategory="utility"
					iconName="close"
					onClick={() => setFiltersOpen(false)}
				/>
			</div>

			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(handleFilterChange)}>
					<div className={classes.saveSearchContainer}>
						<div className={classes.nameInput}>
							<input
								className="slds-input"
								{...methods.register("searchName")}
								id="tasks-search-name"
								placeholder="Save Search"
							/>
						</div>
						<SaveListButton />
					</div>
					{fields?.map((field, index) => {
						return (
							<div
								className="slds-filters__item"
								key={`task-filter-field-${index}`}
								style={{
									display: "grid",
									gridTemplateColumns: "3fr 1fr",
									minHeight: "6rem",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
									}}
								>
									<Field index={index} />
									<Operation index={index} />
									<Value index={index} />
								</div>
								<div className={classes.filterButtons}>
									<Button
										key={`discard-${field.id}`}
										onClick={() => remove(index)}
										iconCategory="utility"
										iconName="delete"
										iconSize="medium"
										variant="icon"
									/>
								</div>
							</div>
						);
					})}
					<div
						style={{
							paddingTop: "0.5rem",
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}
					>
						<Button
							variant="base"
							onClick={() =>
								append({
									propertyPath: "assignedTo",
									operation: "==",
									value: "",
								})
							}
						>
							Add Filter
						</Button>
						<Button
							variant="base"
							onClick={() => methods.reset({ filters: [] })}
						>
							Remove All
						</Button>
						<Button variant="brand" type="submit">
							Search
						</Button>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};

const SaveListButton = () => {
	const { query, setQuery } = useTaskList();
	const { watch } = useFormContext<TaskFilterForm>();

	const searchName = watch("searchName");
	const { saveNewList } = useUpdateTaskLists();

	const [isSavingList, setIsSavingList] = useState<boolean>(false);
	const [isSavingError, setIsSavingError] = useState<{
		error: boolean;
		message: string;
	}>({
		error: false,
		message: "",
	});

	const handleSaveFilters = async () => {
		setIsSavingList(true);
		const { error, message } = await saveNewList({
			filters: query.filteringCriteria,
			searchName: searchName ?? "",
		});

		if (error) {
			setIsSavingError({ error: true, message });
			setTimeout(
				() =>
					setIsSavingError({
						error: false,
						message: "",
					}),
				5000
			);
		}
		setIsSavingList(false);
		setQuery({
			...query,
			name: searchName ?? "",
		});
	};
	return (
		<>
			{isSavingError.error ? (
				<p>{isSavingError.message}</p>
			) : (
				<Button
					className={classes.saveButton}
					onClick={() => handleSaveFilters()}
					variant="brand"
					disabled={isSavingList || !searchName}
				>
					Save
				</Button>
			)}
		</>
	);
};

const Field = ({ index }: { index: number }) => {
	const { watch, setValue } = useFormContext<TaskFilterForm>();

	const options = TASK_FILTER_FIELDS.map((option) => {
		return {
			label: option.label,
			id: option.propertyPath,
			value: option.propertyPath,
		};
	});

	const value = watch(`filters.${index}.propertyPath`);

	const fieldSelected = options.find((item) => item.id === value);
	const selection = fieldSelected ? [fieldSelected] : [];

	return (
		<Combobox
			style={{ width: "100%" }}
			events={{
				onSelect: (
					e: any,
					{ selection: [selection] }: { selection: typeof options }
				) => {
					setValue(`filters.${index}.propertyPath`, selection?.value ?? "");
				},
			}}
			selection={selection}
			variant="readonly"
			options={options}
			disabled={false}
		/>
	);
};

const Operation = ({ index }: { index: number }) => {
	const { watch, setValue } = useFormContext();
	const searchValue = watch(`filters.${index}.propertyPath`);

	const value = watch(`filters.${index}.operation`);

	const options = TASK_FILTER_FIELDS.find(
		(item) => item.propertyPath === searchValue
	)?.operations.map((op) => {
		return {
			...op,
			id: op.value,
		};
	});
	const operation = options?.find((option) => option.value === value);
	const selection = operation ? [operation] : [];
	if (options) {
		return (
			<Combobox
				disabled={!searchValue}
				events={{
					onSelect: (
						e: any,
						{ selection: [selection] }: { selection: typeof options }
					) => {
						setValue(`filters.${index}.operation`, selection?.value ?? "");
					},
				}}
				selection={selection}
				variant="readonly"
				options={options || []}
			/>
		);
	}
	return <></>;
};

const Value = ({ index }: { index: number }) => {
	const { watch } = useFormContext<TaskFilterForm>();
	const searchValue = watch(`filters.${index}.propertyPath`);
	const Element = TASK_FILTER_FIELDS.find(
		(item) => item.propertyPath === searchValue
	)?.ValueSelect;

	if (Element) {
		return <Element index={index} />;
	}
	return <></>;
};
