import React from "react";
import ReactPlayer from "react-player";

export const VideoPlayer = ({ url, ...props }) => {
	return (
		<ReactPlayer
			data-testid="videoPlayer"
			height="99%"
			width="auto"
			url={url}
			controls={true}
		/>
	);
};
