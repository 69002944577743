import { Button } from "@salesforce/design-system-react";
import classes from "./StaticTextArea.module.scss";
export const StaticTextArea = ({
	onClickEdit,
	readOnly,
	errorText,
	...props
}) => {
	const isBool = typeof props.value === "boolean";
	const boolValue = props.value ? "Yes" : "No";
	const value = isBool ? boolValue : props.value;

	return (
		<div
			onDoubleClick={() => {
				!readOnly && onClickEdit(false);
			}}
		>
			<div className="slds-form-element">
				<label
					className="slds-form-element__label"
					id={`static-textarea-${props.name}`}
				>
					{props.label}
				</label>
				<div
					className={`${classes.staticTextAreaContainer} ${
						errorText ? classes.isError : ""
					}`}
				>
					<p
						name={props.name}
						onDoubleClick={() => {
							!readOnly && onClickEdit(false);
						}}
						className={classes.textArea}
						aria-labelledby={`static-textarea-${props.name}`}
					>
						{value}
					</p>
					{!readOnly && (
						<div className={classes.buttonContainer}>
							<Button
								assistiveText={{ icon: "Icon Bare Small" }}
								iconCategory="utility"
								title={`Edit ${props.label}`}
								iconName="edit"
								name={`edit ${props.label}`}
								iconSize="small"
								iconVariant="bare"
								className={classes.button}
								onClick={() => {
									onClickEdit();
								}}
								variant="icon"
							/>
						</div>
					)}
				</div>
				{errorText && (
					<p className={classes.errorText}>{errorText}</p>
				)}
			</div>
		</div>
	);
};
