import useVirtual from "react-cool-virtual";
import { useTaskList } from "../api/useTaskList";
import { TaskListResponse } from "../Types";
import classes from "./MobileCards.module.scss";
import { SmallCard } from "./SmallCard";

export const MobileCards = ({ items: tasks, totalItems }: TaskListResponse) => {
	const { query, setQuery } = useTaskList();

	const { outerRef, innerRef, items } = useVirtual({
		itemCount: totalItems,
		scrollDuration: 50,
		itemSize: 200,
		resetScroll: true,
		loadMoreCount: 20,
		isItemLoaded: (index) => {
			const currentPage = query.paginationCriteria.pageNumber;
			return currentPage === index;
		},
		loadMore: (event) => {
			if (event.loadIndex !== 0) {
				setQuery({
					...query,
					paginationCriteria: {
						...query.paginationCriteria,
						pageNumber: event.loadIndex,
					},
				});
			}
		},
	});
	return (
		<div
			ref={outerRef as React.LegacyRef<HTMLDivElement>}
			className={classes.container}
		>
			<div ref={innerRef as React.LegacyRef<HTMLDivElement>}>
				{items.map(({ index, measureRef }) => {
					if (tasks[index]?.id) {
						return (
							<div
								ref={measureRef}
								key={`mobile-table-${tasks[index]?.id ?? index}`}
								className={classes.card}
							>
								<SmallCard task={tasks[index]} />
							</div>
						);
					}
					return (
						<div
							ref={measureRef}
							key={`mobile-table-${index}`}
							className={classes.card}
						></div>
					);
				})}
			</div>
		</div>
	);
};
