import { gatewayApi } from "@/ReduxToolkit";
import {
	useAssetListParams,
	useSetAssetListParams,
} from "./useAssetListParams";
import { useCallback, useMemo } from "react";
import { getQueryName, useDefaultFilters } from "./useLazyAssetList";
import { useSelector } from "react-redux";
import { intersection } from "lodash";
import { useGetColumns } from "./useGetColumns";
import { getAllAssets, getSortPath } from "./endpoints";

export const useSearchAssetList = () => {
	const [{ view: name, sort: property, direction }] = useAssetListParams();
	const { setSearch } = useSetAssetListParams();

	const [trigger, { status }] = getAllAssets.useLazyQuery();

	const [queryNameWithSearch, queryNameWithoutSearch] = useMemo(() => {
		return [
			getQueryName({
				property,
				direction,
				name, //: filtered ? "filtered" : name,
				q: "search",
			}),
			getQueryName({ property, direction, name }),
		];
	}, [direction, name, property]);

	const withoutSearch = useSelector(
		getAllAssets.select(queryNameWithoutSearch)
	);

	const withSearch = useSelector(getAllAssets.select(queryNameWithSearch));

	const getDefaultFilters = useDefaultFilters();

	const getSearchFilters = useGetSearchFilters();

	const searchPaths = (search) =>
		getSearchFilters(search).map((search) => search.propertyPath);

	const getColumns = useGetColumns();
	const triggerSearch = async (search) => {
		const defaultFilters = await getDefaultFilters();
		const filters = await defaultFilters[name]();
		trigger({
			name: name,
			sortCriteria: {
				direction,
				propertyPath: getSortPath(property),
			},
			paginationCriteria: {
				itemsPerPage: 20,
				page: 0,
			},
			filteringCriteria: filters,
			...(search?.length && {
				fuzzySearchCriteria: {
					propertyPath: intersection(getColumns(), searchPaths(search)),
					value: search,
				},
			}),
		});
		setSearch(search);
	};

	return {
		trigger: triggerSearch,
		status,
		withoutSearch,
		withSearch,
	};
};

function getFilterProperties(statuses) {
	return (search) => {
		const re =
			/(?<numeric>^\d+$)?(?<string>^\D+$)?(?<serial>^\D+\d+)?(?<address>^\d+ \D+)?/;

		const [match, num, str, serial, address] = search.match(re);

		const statusMatches = Object.entries(statuses)
			.filter(([key, value]) => value.toLowerCase().includes(search))
			.map(([key]) => key);

		if (!match) return { data: [] };

		const isMatch = (match) => match !== undefined && match.length > 0;
		const numberMatch = isMatch(num);
		const stringMatch = isMatch(str);
		const serialMatch = isMatch(serial);
		const addressMatch = isMatch(address);

		const properties = [
			numberMatch && "account.accountNumber",
			numberMatch && num.length <= 4 && "year",
			numberMatch && num.length <= 5 && "physicalLocation.zip",
			(numberMatch || stringMatch || addressMatch) &&
				"physicalLocation.address",
			(numberMatch || serialMatch) && "stockNumber",
			stringMatch && "physicalLocation.city",
			stringMatch && "account.customerName",
			// stringMatch && str.length <= 2 && "physicalLocation.state",
		]?.filter((x) => x);

		return { properties, statusMatches };
	};
}

export function useGetSearchFilters() {
	const { data: statuses } = useSelector(
		gatewayApi.endpoints.getRemarketingStatuses.select()
	);

	const searchCallback = useCallback(
		(search) => {
			const { properties, statusMatches } =
				getFilterProperties(statuses)(search);
			return [
				...properties.map((property) => ({
					propertyPath: property,
					operation: "@=*",
					value: search,
				})),
				{
					propertyPath: "remarketingStatusTypeId",
					operation: "==",
					value: statusMatches,
				},
			];
		},
		[statuses]
	);

	return searchCallback;
}
