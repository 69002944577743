import { ExpandableSection } from "@salesforce/design-system-react";
import { WebForm } from "./components/WebForm";
import { ListingComboboxes } from "../listing/ListingCheckboxes";
import { SummaryAssistInput } from "./components/SummaryAssistInput";
import { FreeFormInput } from "@/features/listing/components/FreeformInput";
import { RBINumberInput } from "./components/RBINumberInput";
import {
	ASSET_MANAGER_NOTES,
	MISCELLANEOUS,
} from "../listing/components/constants";
import { useAssetId } from "../asset/components/AssetIdProvider";
import { SummaryAssistWebForm } from "@/features/summary-assist/InitialWebForm";
import { useFeature } from "@/providers/feature-flags/FeatureProvider";
import { PRICING_WORKSHEET_FEATURE_FLAG } from "@/providers/feature-flags/constants";

export const Web = () => {
	const { assetId } = useAssetId();
	const isEnabledPricingWorksheet = useFeature(PRICING_WORKSHEET_FEATURE_FLAG);

	return (
		<>
			{isEnabledPricingWorksheet ? (
				<WebForm assetId={assetId}>
					<ExpandableSection title="Listing Details">
						<ListingComboboxes />
						<SummaryAssistInput assetId={assetId} />
						<RBINumberInput />
						<div style={{ display: "flex" }}>
							<FreeFormInput
								name={MISCELLANEOUS.path}
								label={MISCELLANEOUS.label}
							/>
							<FreeFormInput
								name={ASSET_MANAGER_NOTES.path}
								label={ASSET_MANAGER_NOTES.label}
							/>
						</div>
					</ExpandableSection>
				</WebForm>
			) : (
				<SummaryAssistWebForm assetId={assetId} />
			)}
		</>
	);
};
