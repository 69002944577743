import { useSelector } from "react-redux";
import {
	useGetNoteReasonsQuery,
	useGetNoteSourcesQuery,
	useGetNotesQuery,
	useGetVantageNotesQuery
} from "./endpoints";
import { selectDocumentation } from "./selectors";

export const useDocumentation = (accountId: string, homeId: string) => {
	const {
		data: notes,
		isSuccess: isSuccessNotes,
		isError: isErrorNotes
	} = useGetNotesQuery(accountId);

	const {data: vantageNotes} = useGetVantageNotesQuery(homeId);
	const {
		data: reasons,
		isSuccess: isSuccessReasons,
		isError: isErrorReasons
	} = useGetNoteReasonsQuery(undefined);

	const {
		data: sources,
		isSuccess: isSuccessSources,
		isError: isErrorSources
	} = useGetNoteSourcesQuery(undefined);

	const isError = isErrorNotes || isErrorReasons || isErrorSources;
	const isSuccess = isSuccessNotes && isSuccessReasons && isSuccessSources;
	const documentation = useSelector(selectDocumentation(accountId, homeId));

	const notesLength = notes?.length ?? 0;
	const vantageLength = vantageNotes?.length ?? 0;
	return {
		notes: documentation,
		total: notesLength + vantageLength,
		isError,
		reasons,
		sources,
		isSuccess
	};
};
