import { Spinner } from "@salesforce/design-system-react";
import React, { useEffect, useRef, useState } from "react";
import "tui-image-editor/dist/tui-image-editor.css";
import "./ImageEditor.scss";
import {
	useBrightness,
	useCrop,
	useHistory,
	useLoadImage,
	useRotate,
	useSaveData,
	useZoom,
} from "./ToastHooks";
import {
	setDimensions,
	setZoomLevel,
	setCursorPosition,
	setUndoStack,
	setLoaded,
	setCssDimensions,
	setImageURL,
} from "./ToastSlice";

import { useImageEditor } from "./ToastUIContext";
import { useSelector } from "react-redux";
import { selectMedia } from "../../api/selectors";
import { useSaveImage } from "../../api/hooks/useSaveImage";
import { selectIsOnline } from "@/ReduxToolkit/offlineAssetSlice";

let TuiImageEditor;
export function useToastUI(rootEl, url) {
	const editorInstance = useRef(null);
	const [{ loaded }, dispatch] = useImageEditor();
	const [isTuiLoaded, setIsTuiLoaded] = useState(false);

	useEffect(() => {
		if (!isTuiLoaded) {
			import("tui-image-editor").then((value) => {
				TuiImageEditor = value.default;
				setIsTuiLoaded(true);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!loaded && rootEl && TuiImageEditor) {
			editorInstance.current = new TuiImageEditor(rootEl.current, {
				cssMaxHeight: 2000,
				cssMaxWidth: 2000,
				selectionStyle: {
					cornerSize: 20,
					rotatingPointOffset: 70,
				},
				usageStatistics: false,
			});
			editorInstance.current.on("undoStackChanged", (length) =>
				dispatch(setUndoStack(length))
			);
			editorInstance.current.loadImageFromURL(`${url}&canvas=true`, "image");
			dispatch(setImageURL(url));
			dispatch(setLoaded(true));
		}
		return () => {
			dispatch(setLoaded(false));
			return editorInstance?.current?.destroy();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rootEl, TuiImageEditor]);

	return { editor: editorInstance.current };
}

export function AssetImage({ ...props }) {
	const rootEl = useRef(null);
	const { editor } = useToastUI(rootEl, props.url);
	const [{ loaded, cssDimensions, imageURL, ...state }, dispatch] =
		useImageEditor();
	const { path } = useSelector(selectMedia(props.image.id));
	const { isUpdating } = useSaveImage();
	const isOnline = useSelector(selectIsOnline);

	useEffect(() => {
		if (loaded && props.url !== imageURL) {
			dispatch(setImageURL(props.url));
		}
	}, [dispatch, imageURL, loaded, props.url]);

	useLoadImage(loaded, editor);
	useCrop(loaded, editor);
	useBrightness(loaded, editor);
	useZoom(loaded, editor);
	useRotate(loaded, editor);
	useHistory(loaded, editor);
	useSaveData(loaded, editor, props.image);

	const handleImageLoad = (e) => {
		dispatch(
			setDimensions({
				width:
					e.target.naturalWidth < e.target.width
						? e.target.naturalWidth
						: e.target.width,
				height:
					e.target.naturalHeight < e.target.height
						? e.target.naturalHeight
						: e.target.height,
			})
		);
		dispatch(
			setCssDimensions({
				width:
					e.target.naturalWidth < e.target.width
						? e.target.naturalWidth
						: e.target.width,
				height:
					e.target.naturalHeight < e.target.height
						? e.target.naturalHeight
						: e.target.height,
			})
		);
	};

	const getXY = (e) => {
		if (state.isZoomActive && state.zoom === "in") {
			dispatch(
				setCursorPosition({
					x: e.nativeEvent.offsetX,
					y: e.nativeEvent.offsetY,
				})
			);
			const zoomIn =
				state.zoomLevel + 0.1 <= 5 ? state.zoomLevel + 0.1 : state.zoomLevel;
			dispatch(setZoomLevel(zoomIn));
		}
		if (state.isZoomActive && state.zoom === "out") {
			dispatch(
				setCursorPosition({
					x: e.nativeEvent.offsetX,
					y: e.nativeEvent.offsetY,
				})
			);
			const zoomOut =
				state.zoomLevel - 0.1 >= 1 ? state.zoomLevel - 0.1 : state.zoomLevel;
			dispatch(setZoomLevel(zoomOut));
		}
	};
	return (
		<>
			{isUpdating && (
				<Spinner
					variant="inverse"
					containerStyle={{ backgroundColor: "rgb(30,30,30,.5)" }}
				/>
			)}
			<div
				ref={rootEl}
				style={
					state.isEditing || state.isSaving
						? { display: "block", ...state.dimensions }
						: { display: "none" }
				}
				onClick={getXY}
			></div>
			{!state.isEditing && !state.isSaving && (
				<img
					src={path}
					onLoad={handleImageLoad}
					alt=""
					{...(!isOnline && {
						crossOrigin: "annonymous",
					})}
				/>
			)}
		</>
	);
}
