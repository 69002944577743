import { useFormContext } from "react-hook-form";
import { TaskFormType } from "./TaskForm";
import {
	useGetCommentTypesQuery,
	useGetTaskStatusTypesQuery,
} from "../api/endpoints";

export const CancellationReason = () => {
	const { watch, register } = useFormContext<TaskFormType>();

	const { data: statuses } = useGetTaskStatusTypesQuery();
	const { data: commentTypes } = useGetCommentTypesQuery();
	const status = watch("statusTypeId");

	const cancelledStatus = statuses?.find(
		(item) => item.name === "Cancelled"
	)?.id;

	if (!statuses || !commentTypes || !status || status !== cancelledStatus) {
		return <></>;
	}

	return (
		<div>
			<label className="slds-form-element__label">Cancellation Reason</label>
			<input
				className="slds-input"
				{...register("cancellationReason.reason")}
				required
			/>
		</div>
	);
};
