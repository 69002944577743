import React from "react";
import classes from "../RolesAndFlags.module.scss";
import { Checkbox } from "@salesforce/design-system-react";
import { ROLES } from "@/providers/roles/constants";
import { useRoles, useRoleSwitcher } from "@/features/user/hooks/useRoles";

export const Roles = () => {
	const { isSuccess } = useRoles();
	const { isDisabledRoleSwitcher: isDisabled, toggleDisableRoleSwitcher } =
		useRoleSwitcher();

	return isSuccess ? (
		<div className={classes.container}>
			<h2 className={classes.subtitle}>Roles</h2>
			<div style={{ marginBottom: "1rem" }}>
				<Checkbox
					labels={{ label: "Disable Role Switcher" }}
					checked={isDisabled}
					// value={isDisabled}
					onChange={() => toggleDisableRoleSwitcher()}
					containerStyle={{ marginBottom: "1rem" }}
				/>
			</div>
			<div className={classes.rolesGrid}>
				{ROLES.map((value) => {
					return (
						<Role
							key={value}
							title={value}
							value={value}
							disabled={isDisabled}
						/>
					);
				})}
			</div>
		</div>
	) : null;
};

const Role = ({ title, value, ...props }) => {
	const { roles, isSuccess } = useRoles();
	const isRole = isSuccess && (roles[value] ?? false);
	const { toggleRole } = useRoleSwitcher();

	return isSuccess ? (
		<>
			<Checkbox
				labels={{
					label: title,
				}}
				id={`role-${title}`}
				checked={isRole}
				onChange={() => {
					toggleRole(value);
				}}
				{...props}
			/>
		</>
	) : null;
};
