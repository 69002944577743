import { useEffect, useRef, useState } from "react";
import classes from "./DescriptionGenerator.module.scss";
import { Button } from "@salesforce/design-system-react";
import { useGenerateDescription } from "./api/useGenerateDescription";
import { Asset } from "@/features/asset/types";
import { PromptEditor } from "./PromptEditor";

export const DescriptionGenerator = ({
	asset,
	promptOpen,
}: {
	asset: Asset;
	promptOpen: boolean;
}) => {
	const ref = useRef<HTMLTextAreaElement>(null);
	const {
		isLoading,
		data,
		error,
		prompt: initialPrompt,
		trigger,
	} = useGenerateDescription(asset.id);

	const initialState = "I will generate a description for this asset. Try it!";
	const [response, setResponse] = useState<string>(initialState);
	const [copied, setCopied] = useState<boolean>(false);
	const [height, setHeight] = useState<number>(25);
	const [prompt, setPrompt] = useState<string>(initialPrompt(asset));
	const handleCopy = () => {
		setCopied(true);
		navigator.clipboard.writeText(response);
		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};

	useEffect(() => {
		if (ref?.current) {
			setHeight(40 + ref?.current.scrollHeight);
		}
	}, [response]);

	return (
		<div className={classes.webTab}>
			{promptOpen && <PromptEditor prompt={prompt} setPrompt={setPrompt} />}
			<textarea
				style={{ height: height }}
				ref={ref}
				className={classes.textArea}
				onChange={(event) => {
					setHeight(1);
					setResponse(event.target.value);
				}}
				value={isLoading ? "Generating description..." : response}
			></textarea>
			<p>
				Summary Assist can make mistakes. Please read the description carefully
				and make changes as needed!
			</p>
			{error ? <p className={classes.error}>{error.toString()}</p> : null}
			<div className={classes.buttonContainer}>
				<Button
					className={classes.button}
					onClick={handleCopy}
					disabled={isLoading}
					style={{ marginLeft: 0 }}
				>
					{copied ? "Copied!" : "Copy"}
				</Button>
				<Button
					variant="brand"
					className={classes.button}
					disabled={isLoading}
					onClick={async () => {
						const result = trigger(prompt);

						const description = await result.unwrap();
						setHeight(1);
						setResponse(description ?? "Uh oh, something went wrong!");
					}}
				>
					{data !== "" ? "Regenerate" : "Generate"}
				</Button>
			</div>
		</div>
	);
};
