import {
	useGetCurrentUserQuery,
	useUpdateUserMutation,
} from "@/features/user/api/endpoints";
import { camelCase } from "lodash";

export const useSavedListViews = () => {
	const { data: savedFilters, isSuccess } = useGetCurrentUserQuery(undefined, {
		selectFromResult: ({ data, ...rest }) => {
			return { data: data?.savedFilters, ...rest };
		},
	});

	const [updateUser, { isLoading }] = useUpdateUserMutation();

	const removeSavedFilter = (filterName) => {
		const entries = Object.entries(savedFilters).filter(
			([key, _]) => key !== filterName
		);
		updateUser({ savedFilters: Object.fromEntries(entries) });
	};

	// replaces currentName with newName
	const updateName = (currentName, newName) => {
		const entries = Object.entries(savedFilters);

		const currentIndex = entries.findIndex(([key, _]) => key === currentName);

		entries[currentIndex][0] = camelCase(newName);

		updateUser({ savedFilters: Object.fromEntries(entries) });
	};

	const removeAll = () => {
		updateUser({ savedFilters: {} });
	};

	return {
		updateName,
		removeAll,
		savedFilters,
		isSuccess,
		isLoading,
		removeSavedFilter,
	};
};
