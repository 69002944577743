import React from "react";
import Spinner from "@/Components/UI/Spinner/Spinner";
import {
	PhotoSizeSlider,
	PhotoSliderContext,
} from "@/features/media-manager/components/PhotoSizeSlider/PhotoSizeSlider";
import { useLegacyPostMessage } from "./hooks/useLegacyPostMessage";
import { WebReadyBucket } from "@/features/media-manager/components/WebReadyBucket/WebReadyBucket";
import { AllMediaBucket } from "@/features/media-manager/components/AllMediaBucket/AllMediaBucket";
import { useAllMedia } from "./api/hooks/useAllMedia";
import { useGetAssetMediaQuery } from "./api/endpoints";
import { useMoveWebReady } from "./api/hooks/useMoveWebReady";
import { useWebReadyMedia } from "./api/hooks/useWebReadyMedia";
import { useAddtoWebReady } from "./api/hooks/useAddToWebReady";
import { useRemoveFromWebReady } from "./api/hooks/useRemoveFromWebReady";
import { useAssetId } from "../asset/components/AssetIdProvider";
import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import {
	DndContext,
	DragOverlay,
	MouseSensor,
	PointerSensor,
	pointerWithin,
	TouchSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { useState } from "react";
import { MediaCard } from "./components/MediaCard/MediaCard";
import { CSS } from "@dnd-kit/utilities";
import { Desktop, Mobile } from "@/Responsive";
import { useIsOnline } from "../offline-use";
import { ErrorContainer } from "../errors/ErrorContainer";
// import { useIncludeWebReady } from "./api/hooks/useIncludeWebReady";

export const AssetMediaManager = ({ container }) => {
	const { assetId } = useAssetId();
	const isOnline = useIsOnline();
	const { isSuccess, isLoading, isError, error } =
		useGetAssetMediaQuery(assetId);
	const { data: accountNumber, isSuccess: isSuccessAsset } = useGetAssetQuery(
		assetId,
		{
			selectFromResult: (result) => ({
				...result,
				data: result?.data?.account?.accountNumber,
			}),
		}
	);

	const { data: nonWebReadies } = useAllMedia(assetId);
	const { data: webReadies } = useWebReadyMedia(assetId);
	const { addToWebReady } = useAddtoWebReady(assetId);
	const { moveWebReady } = useMoveWebReady(assetId);
	const { removeFromWebReady } = useRemoveFromWebReady(assetId);

	useLegacyPostMessage(accountNumber);

	const [activeId, setActiveId] = useState(null);
	const [mediaId, setMediaId] = useState(null);
	const [activeTransform, setActiveTransform] = useState(null);
	const [activeType, setActiveType] = useState(null);
	const [isDraggedOver, setIsDraggedOver] = useState(null);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(MouseSensor),
		useSensor(TouchSensor)
	);

	const handleDragStart = (e) => {
		setActiveId(e.active.id);
		setActiveTransform(e.active.data.current.position);
		setMediaId(e.active.data.current.mediaId);
		setActiveType(e.active.data.current.type);
	};

	const handleDragEnd = (e) => {
		setActiveId(null);
		setIsDraggedOver(null);
		if (
			activeType === "allMedia" &&
			e.over?.data?.current?.type === "webReady"
		) {
			const index = nonWebReadies.findIndex((image) => image.id === mediaId);
			addToWebReady([nonWebReadies[index]]);
		}

		if (activeType === "webReady" && e.over?.id === "AllMedia") {
			const index = webReadies.findIndex((image) => image.id === mediaId);
			removeFromWebReady(webReadies[index]);
		}

		if (
			activeType === "webReady" &&
			e.over?.data?.current?.type === "webReady"
		) {
			const sourceIndex = webReadies.findIndex((media) => media.id === mediaId);
			const destinationIndex = e.over.data.current.sortable.index;

			moveWebReady(webReadies[sourceIndex], destinationIndex);
		}
	};

	return (
		<>
			{isLoading && <Spinner />}
			{isError && <ErrorContainer error={error} />}
			{isSuccess && isSuccessAsset && (
				<>
					<PhotoSliderContext>
						<Mobile>
							<PhotoSizeSlider mobile={true} />
						</Mobile>
						<Desktop>
							<PhotoSizeSlider />
						</Desktop>
						<DndContext
							autoScroll={
								(activeType === "webReady" && isDraggedOver === "AllMedia") ||
								(activeType === "allMedia" && isDraggedOver === "AllMedia")
									? false
									: true
							}
							onDragStart={handleDragStart}
							onDragEnd={handleDragEnd}
							onDragOver={(e) => {
								if (
									activeType === "allMedia" &&
									e?.over?.data?.current?.type === "webReady"
								) {
									setIsDraggedOver("WebReady");
								} else if (
									activeType === "webReady" &&
									e?.over?.id === "AllMedia"
								) {
									setIsDraggedOver("AllMedia");
								} else {
									setIsDraggedOver(null);
								}
							}}
							sensors={sensors}
							collisionDetection={pointerWithin}
						>
							{isOnline && (
								<WebReadyBucket isDraggedOver={isDraggedOver === "WebReady"} />
							)}
							<AllMediaBucket
								container={container}
								isDraggedOver={isDraggedOver === "AllMedia"}
							/>
							<DragOverlay
								dropAnimation={{
									keyframes: ({ transform, active }) => {
										if (active?.data?.current?.sortable)
											return [
												{
													transform: CSS.Transform.toString(transform.initial),
												},
												{
													transform: CSS.Transform.toString(transform.final),
												},
											];
										return [
											{
												transform: CSS.Transform.toString(transform.initial),
											},
											{
												transform: CSS.Transform.toString({
													...transform.final,
													x: transform.final.x + activeTransform?.colStart,
													y: transform.final.y + activeTransform?.rowStart,
												}),
											},
										];
									},
								}}
							>
								{activeId ? (
									<div
										style={{
											...(activeTransform && {
												transform: `translateX(${activeTransform.colStart}px) translateY(${activeTransform.rowStart}px)`,
											}),
										}}
									>
										<MediaCard
											media={{ groupingId: assetId, id: mediaId }}
											assetId={activeId}
											isDraggable
											isDragging
										/>
									</div>
								) : null}
							</DragOverlay>
						</DndContext>
					</PhotoSliderContext>
				</>
			)}
		</>
	);
};
