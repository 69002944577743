import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { useEffect, useRef, useState } from "react";
import { createContext } from "react";

type SubmitButtonsContainerContext =
	| {
			openForm: string | null;
			setOpenForm: Dispatch<SetStateAction<string | null>>;
			submitButtonsContainerElement?: HTMLDivElement;
	  }
	| undefined;

export const FormsContext = createContext(
	undefined as SubmitButtonsContainerContext
);

export const FormsProvider = ({ children }: { children: ReactNode }) => {
	const submitRef = useRef<HTMLDivElement>(null);
	const [openForm, setOpenForm] = useState<string | null>(null);
	const [submitContainer, setSubmitContainer] = useState<HTMLDivElement>();

	useEffect(() => {
		if (submitRef?.current) {
			setSubmitContainer(submitRef.current);
		}
	}, []);

	return (
		<FormsContext.Provider
			value={{
				openForm,
				setOpenForm,
				submitButtonsContainerElement: submitContainer,
			}}
		>
			<div style={{ position: "relative", background: "white" }}>
				{children}
				<div ref={submitRef} style={{ position: "sticky", bottom: 0 }}></div>
			</div>
		</FormsContext.Provider>
	);
};
