import { gatewayApi } from "@/ReduxToolkit/GatewayApi";

const communitiesEndpoints = (builder) => ({
	getCommunityById: builder.query({
		query: (id) => {
			return {
				url: `/api/Community/${id}`,
				method: "get",
			};
		},
	}),
	searchCommunities: builder.mutation({
		query: (update) => ({
			url: "/api/Community",
			method: "POST",
			body: update,
		}),
	}),
});

gatewayApi.injectEndpoints({
	endpoints: communitiesEndpoints,
	overrideExisting: true,
});

export const {
	useGetCommunityByIdQuery,
	useSearchCommunitiesMutation,
	endpoints: { getCommunityById, searchCommunities },
} = gatewayApi;
