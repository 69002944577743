import { ALL_MEDIA } from "@/ReduxToolkit/MediaSlice";
import { createSelector } from "@reduxjs/toolkit";
import { selectAllMedia, selectAllMediaState } from "./allMedia";
import { selectWebReadyMedia, selectWebReadyMediaState } from "./webReadyMedia";

export const selectSelectedMedia = ({ bucket, assetId }) =>
	createSelector(
		bucket === ALL_MEDIA
			? selectAllMediaState(assetId)
			: selectWebReadyMediaState(assetId),
		(state) => state?.selected
	);

export const selectSelectedAllMedia = (assetId) =>
	selectSelectedMedia({ bucket: ALL_MEDIA, assetId });

export const selectSelectedMediaEntities = ({ bucket, assetId }) =>
	createSelector(
		bucket === ALL_MEDIA
			? selectAllMedia(assetId)
			: selectWebReadyMedia(assetId),
		selectSelectedMedia({ bucket, assetId }),
		(media, selected) => media.filter((media) => selected[media.id])
	);

export const selectSelectedMediaIds = ({ bucket, assetId }) =>
	createSelector(selectSelectedMedia({ bucket, assetId }), (selected) =>
		Object.entries(selected)
			.filter(([key, value]) => value)
			.map(([key, _value]) => key)
	);

// export const selectSelectedAllMediaEntities = (assetId) =>
// 	selectSelectedMediaEntities({ bucket: ALL_MEDIA, assetId });

// export const selectSelectedWebReadyMediaEntities = (assetId) =>
// 	selectSelectedMediaEntities({ bucket: WEB_READY_MEDIA, assetId });
