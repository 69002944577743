import React from "react";
import { ListingConfig } from "./ListingConfig";
import classes from "@/Components/ControlledForm.module.scss";
import { Fields } from "@/Components/Form";
import { useFormContext } from "react-hook-form";
import { FormSection } from "@/Components/Form/ControlledForm/FormSection";
import { LISTING_SECTION_TITLE } from "./constants";

const ListingFields = () => {
	const { isLoaded } = useFormContext();
	return (
		<>
			{isLoaded && (
				<div className={classes.formGrid}>
					<Fields config={ListingConfig} />
				</div>
			)}
		</>
	);
};

export const Listing = ({ assetId, ...props }) => {
	return (
		<>
			<FormSection title={LISTING_SECTION_TITLE} {...props}>
				<ListingFields />
			</FormSection>
		</>
	);
};
