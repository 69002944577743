import { GlobalHeaderProfile, Popover } from "@salesforce/design-system-react";
import { GlobalSearch } from "../GlobalSearch/GlobalSearch";
import GlobalHeaderProfileContent from "./GlobalHeaderProfile";
import { useNavigate } from "react-router-dom";

const GlobalAppHeader = () => {
	const navigate = useNavigate();
	return (
		<header className="slds-global-header_container">
			<div className="slds-global-header slds-grid slds-grid_align-spread">
				<div className="slds-global-header__item">
					<div
						onClick={() => navigate("/")}
						className="slds-global-header__logo"
						style={{
							backgroundImage: "url(/homebase_logo.png)",
							cursor: "pointer",
						}}
					></div>
				</div>
				<GlobalSearch />

				<div
					className="slds-global-header__item"
					style={{
						display: "flex",
						justifyContent: "flex-end",
						width: "232px",
					}}
				>
					<GlobalHeaderProfile
						popover={
							<Popover
								body={<GlobalHeaderProfileContent />}
								id="header-profile-popover-id"
							/>
						}
					/>
				</div>
			</div>
		</header>
	);
};

export default GlobalAppHeader;
