import { ALL_MEDIA, filterAdaptor } from "@/ReduxToolkit/MediaSlice";
import { selectIsOnline } from "@/ReduxToolkit/offlineAssetSlice";
import { combineFilters } from "@/Utilities/filtering";
import { createSelector } from "@reduxjs/toolkit";
import { selectAllAssetMedia, selectAssetMediaState } from "./assetMedia";

export const selectAllMediaState = (assetId) =>
	createSelector(selectAssetMediaState(assetId), (media) => media?.[ALL_MEDIA]);

const selectMediaFilters = (assetId) =>
	createSelector(
		selectAllMediaState(assetId),
		(media) => media?.filters ?? filterAdaptor.getInitialState()
	);

export const selectAllMediaFilters = (assetId) => {
	const { selectAll } = filterAdaptor.getSelectors(selectMediaFilters(assetId));

	return selectAll;
};

export const selectIncludeWebReady = (assetId) =>
	createSelector(
		selectAllMediaState(assetId),
		(media) => media?.includeWebReady
	);

export const selectSortParameters = (assetId) =>
	createSelector(selectAllMediaState(assetId), (media) => media?.sort);

const selectFilters = (assetId) =>
	createSelector(
		selectAllMediaFilters(assetId),
		selectIncludeWebReady(assetId),
		(filters, includeWebReady) =>
			includeWebReady
				? filters
				: [
						...filters,
						{
							property: "webReady",
							comparison: "isEqual",
							predicate: false,
						},
				  ]
	);

const selectSortedMedia = (assetId) =>
	createSelector(
		selectAllAssetMedia(assetId),
		selectSortParameters(assetId),
		(media, sort) => {
			const sorted = [...media].sort((a, b) => {
				const direction = sort.direction === "desc" ? -1 : 1;

				const first =
					sort.property === "dateModified"
						? a.dateModified ?? a.dateUploaded
						: a[sort.property];
				const second =
					sort.property === "dateModified"
						? b.dateModified ?? b.dateUploaded
						: b[sort.property];

				if (!first && !second) {
					return 0;
				} else if (!first) {
					return 1;
				} else if (!second) {
					return -1;
				} else {
					return direction * first.localeCompare(second);
				}
			});
			return sorted;
		}
	);

export const selectAllMedia = (assetId) =>
	createSelector(selectSortedMedia(assetId), selectFilters(assetId), (a, b) =>
		a.filter(combineFilters(b))
	);

export const selectOfflineMedia = (assetId) =>
	createSelector(selectAllAssetMedia(assetId), (media) =>
		media.sort((a, b) => {
			if (a.isPrimary) {
				return -1;
			} else if (b.isPrimary) {
				return 1;
			} else {
				return 0;
			}
		})
	);

export const selectAllMediaIds = (assetId) =>
	createSelector(
		selectIsOnline,
		selectAllMedia(assetId),
		selectOfflineMedia(assetId),
		(isOffline, allMedia, offlineMedia) =>
			isOffline
				? allMedia.map((media) => media.id)
				: offlineMedia.map((media) => media.id)
	);
