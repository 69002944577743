import { Radio } from "@salesforce/design-system-react";
import { useEffect, useState } from "react";
import classes from "./ManualLocation.module.scss";
import { CustomAddress } from "./CustomAddress";
import {
	setStartingLocation,
	setEndingLocation,
} from "../../store/tripPlanningSlice";
import { useDispatch } from "react-redux";

const CLAYTON_HOME_OFFICE = {
	latitude: 35.809811,
	longitude: -83.94836,
	city: "Maryville",
	zip: "37804",
	state: "TN",
	address: "5000 Clayton Road",
	accountNumber: "Clayton Home Office",
};

export const ManualLocation = ({ isStartingLocation = false }) => {
	const [userLocation, setUserLocation] = useState({
		latitude: 0,
		longitude: 0,
	});
	const [locationAllowed, setLocationAllowed] = useState(true);

	const [radioChecked, setRadioChecked] = useState("homeOffice");

	const dispatch = useDispatch();

	/*
        Add user selection to the beginning/end of LocationsSelected
        if it has already been added (has a startingLocation or ending location), then remove the index before adding it.
    */
	const setLocation = (item) => {
		if (isStartingLocation) {
			dispatch(setStartingLocation(item));
		} else {
			dispatch(setEndingLocation(item));
		}
	};

	useEffect(() => {
		let mounted = true;
		navigator.geolocation.getCurrentPosition(
			//Success Function
			(location) => {
				if (mounted) {
					const latitude = location.coords.latitude;
					const longitude = location.coords.longitude;
					setUserLocation({ latitude, longitude });
				}
			},
			//Failure Function
			(err) => {
				if (mounted) {
					setLocationAllowed(false);
				}
			}
		);
		return () => {
			mounted = false;
		};

		//eslint-disable-next-line
	}, []);
	return (
		<div className={classes.container}>
			<h2>
				{isStartingLocation
					? "Select Starting location: "
					: "Select Ending Location: "}
			</h2>
			<Radio
				checked={radioChecked === "homeOffice"}
				onChange={() => {
					setLocation({
						id: "homeOffice",
						latitude: CLAYTON_HOME_OFFICE.latitude,
						longitude: CLAYTON_HOME_OFFICE.longitude,
						accountNumber: CLAYTON_HOME_OFFICE.accountNumber,
						address: CLAYTON_HOME_OFFICE.address,
						state: CLAYTON_HOME_OFFICE.state,
						city: CLAYTON_HOME_OFFICE.city,
						zip: CLAYTON_HOME_OFFICE.zip,
					});
					setRadioChecked("homeOffice");
				}}
				labels={{ label: "Clayton home office (Maryville, TN)" }}
			/>
			{locationAllowed ? (
				<Radio
					checked={radioChecked === "userLocation"}
					disabled={!locationAllowed}
					onChange={() => {
						setLocation({
							id: "userLocation",
							accountNumber: "Custom Location",
							latitude: userLocation.latitude,
							longitude: userLocation.longitude,
						});
						setRadioChecked("userLocation");
					}}
					labels={{ label: "Use current location" }}
				/>
			) : (
				<p>Allow homebase to access your location to use this tool</p>
			)}
			<Radio
				checked={radioChecked === "customSearch"}
				onChange={() => {
					setRadioChecked("customSearch");
				}}
				labels={{ label: "Custom Location" }}
			/>
			{radioChecked === "customSearch" && (
				<CustomAddress changeLocation={setLocation} />
			)}
		</div>
	);
};
