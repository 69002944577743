import { useEffect, useMemo } from "react";

import { assetListAdapter, getAllAssets, getSortPath } from "./endpoints";
import {
	useLazyDefaultFilters,
	useLazyFavoriteAssetsFilters,
	useLazyMyAssetsFilters,
	useLazyOfflineAssetsFilters,
	useLazySavedFilters,
} from "../hooks/useFilters";
import { useSelector } from "react-redux";
import { combineFiltersAssetListOr } from "@/Utilities/filtering";
import { find } from "lodash";
import * as home from "@/features/asset/constants";
import { defaultQuery, useAssetListParams } from "./useAssetListParams";
import { useIsOnline } from "@/features/offline-use";
import { useGetSearchFilters } from "./useSearchAssetList";
import { gatewayApi } from "@/ReduxToolkit";

export const DEFAULT_FILTER_KEYS = [
	"allAssets",
	"myAssets",
	"fieldUse",
	"favoriteAssets",
];

export function useDefaultFilters() {
	const [getMyAssetsFilters] = useLazyMyAssetsFilters();

	const [getOfflineAssetsFilters] = useLazyOfflineAssetsFilters();

	const [getAllAssets] = useLazyDefaultFilters();

	const [getFavoriteAssets] = useLazyFavoriteAssetsFilters();

	const { trigger } = useLazySavedFilters();

	const getDefaultViews = async () => {
		const savedFilters = await trigger();
		return {
			myAssets: getMyAssetsFilters,
			fieldUse: getOfflineAssetsFilters,
			favoriteAssets: getFavoriteAssets,
			allAssets: getAllAssets,
			...savedFilters,
		};
	};

	return getDefaultViews;
}

export const useDefaultViews = () => {
	const { data } = useSelector(gatewayApi.endpoints.getCurrentUser.select());
	const savedFilters = useMemo(() => {
		return data?.savedFilters ?? {};
	}, [data?.savedFilters]);

	return useMemo(() => {
		return [...DEFAULT_FILTER_KEYS, ...Object.keys(savedFilters)];
	}, [savedFilters]);
};

/**
 * lazy view hook, use this to retrieve the current "view" of the asset list, and/or trigger a view change
 */
export const useLazyAssetListView = () => {
	const [{ view, sort, direction, isLoading, isFetching }, { setView }] =
		useAssetListParams();

	const [trigger] = getAllAssets.useLazyQuerySubscription();

	const getDefaultFilters = useDefaultFilters();

	const triggerView = async (view) => {
		const defaultFilters = await getDefaultFilters();
		const filters = await defaultFilters[view]();

		trigger({
			name: view,
			paginationCriteria: {
				itemsPerPage: 20,
				pageNumber: 0,
			},
			filteringCriteria: filters,
			sortCriteria: { direction, propertyPath: getSortPath(sort) },
		});
		setView(view);
	};

	return {
		view: isLoading ? "Loading" : view,
		trigger: triggerView,
		isLoading,
		isFetching,
	};
};

export const getQueryName = ({ name, property, direction, filtered, q }) => {
	return q
		? `getAllAssets(${
				filtered ? "filtered" : name
		  }-${direction}-${property}-search)`
		: `getAllAssets(${filtered ? "filtered" : name}-${direction}-${property})`;
};

const selectAll = (items) => items.ids.map((id) => items.entities[id]);

export const useSelectAssetList = () => {
	const [{ view, sort: property, direction, filtered, q, isSuccess }] =
		useAssetListParams();

	const query = filtered
		? { name: "filtered", property, direction }
		: { name: view, property, direction };

	const queryResult = useSelector(
		getAllAssets.select(getQueryName({ ...query, q }))
	);

	const queryResultWithoutSearch = useSelector(
		getAllAssets.select(getQueryName(query))
	);
	const hasSearch = q !== undefined && q.length > 0;

	const getSearchFilters = useGetSearchFilters();

	return (
		isSuccess &&
		(hasSearch &&
		queryResultWithoutSearch?.data?.length &&
		queryResult.status !== "fulfilled"
			? {
					...queryResult,
					...{
						data: {
							...queryResult.data,
							items: assetListAdapter(
								queryResultWithoutSearch?.originalArgs?.sortCriteria ?? {
									propertyPath: defaultQuery.sort,
									direction: defaultQuery.direction,
								}
							).upsertMany(
								{ ids: [], entities: {} },
								queryResultWithoutSearch?.data?.items?.ids
									?.map(
										(id) => queryResultWithoutSearch.data.items.entities[id]
									)
									.filter(combineFiltersAssetListOr(getSearchFilters(q)))
							),
						},
					},
			  }
			: queryResult)
	);
};

/**
 * hook to trigger the initial loading of the asset list and pull the data from the retrieval
 */
export const useAssetList = () => {
	const [
		{ view, sort: property, direction, isSuccess },
		{ removeSearchAndFilter },
	] = useAssetListParams();

	const [trigger] = getAllAssets.useLazyQuerySubscription();

	const getDefaultFilters = useDefaultFilters();

	const { data, ...rest } = useSelectAssetList();

	const items = useMemo(() => data?.items ?? { ids: [], entities: {} }, [data]);

	const assets = useMemo(() => selectAll(items) || [], [items]);

	const sort = find(home, (item) => item.key === property).path;
	const isOnline = useIsOnline();

	const [getUserData] = gatewayApi.endpoints.getCurrentUser.useLazyQuery();

	const getAssets = async () => {
		const userResult = getUserData(undefined, false);
		const userData = await userResult.unwrap();
		const userDefaultView = userData?.defaultAssetListView;
		const assetListView =
			userDefaultView && userDefaultView !== view ? userDefaultView : view;
		const defaultFilters = await getDefaultFilters();
		const filters = await defaultFilters[assetListView]();

		trigger({
			name: view,
			paginationCriteria: {
				itemsPerPage: 20,
				pageNumber: 0,
			},
			filteringCriteria: filters,
			sortCriteria: { direction, propertyPath: sort },
		});
		removeSearchAndFilter();
	};

	useEffect(() => {
		if (isSuccess) {
			getAssets();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOnline, isSuccess]);

	return { data: assets, ...rest };
};
