import React, { useContext } from "react";
import { Button } from "@salesforce/design-system-react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useFormReset } from "../hooks/useFormReset";
import { useFormSection } from "../hooks/useFormSection";
import classes from "./Submitbuttons.module.scss";
import spinner from "./spinner.module.scss";
import { FormsContext } from "./Forms";
import { createPortal } from "react-dom";
import { SpinnerButton } from "@/Components/SpinnerButton/SpinnerButton";

export const SubmitButtons = ({ form }: { form: string }) => {
	const {
		formState: { isSubmitting, isSubmitSuccessful, dirtyFields },
	} = useFormContext();

	const formsContext = useContext(FormsContext);

	const reset = useFormReset();
	const { isStatic } = useFormSection();

	const handleReset = () => {
		reset();
	};

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset();
		}
	}, [isSubmitSuccessful, reset]);

	return formsContext?.submitButtonsContainerElement
		? createPortal(
				(!isStatic || Object.keys(dirtyFields).length > 0) && (
					<div className={classes.container}>
						<div>
							<Button onClick={handleReset}>Cancel</Button>
							<Button
								variant="brand"
								type="submit"
								form={form}
								disabled={!Object.keys(dirtyFields).length || isSubmitting}
								{...(isSubmitting && {
									iconClassName: spinner.spinner,
									iconName: "spinner",
									iconPosition: "left",
									iconCategory: "utility",
								})}
							>
								Save
							</Button>
						</div>
					</div>
				),
				formsContext.submitButtonsContainerElement
		  )
		: (!isStatic || Object.keys(dirtyFields).length > 0) && (
				<div className={classes.container}>
					<div>
						<Button onClick={handleReset}>Cancel</Button>
						<SpinnerButton
							variant="brand"
							type="submit"
							form={form}
							disabled={!Object.keys(dirtyFields).length || isSubmitting}
						>
							Save
						</SpinnerButton>
					</div>
				</div>
		  );
};
