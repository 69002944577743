import { Buyer } from "../../types";
import { BuyerFormType } from "./NewBuyer";
import { useForm } from "react-hook-form";
import { vestResolver } from "@hookform/resolvers/vest";
import { buyerValidation } from "./buyerValidation";
import { BuyerForm } from "./BuyerForm";

export const EditBuyer = ({
	buyer,
	editBuyer,
	isEditingBuyer,
}: {
	buyer: Buyer;
	editBuyer: (buyer: Buyer) => void;
	isEditingBuyer: boolean;
}) => {
	const { reset, handleSubmit, control, watch } = useForm<BuyerFormType>({
		resolver: vestResolver(buyerValidation),
		defaultValues: buyer,
		reValidateMode: "onBlur",
		shouldFocusError: false,
	});

	const onSubmit = handleSubmit((data) => {
		const submissionData: BuyerFormType = {
			...buyer,
			...data,
		};

		if (
			!submissionData.driversLicense?.number &&
			!submissionData.driversLicense?.state
		) {
			submissionData.driversLicense = null;
		}

		if (!submissionData.phoneNumber2) {
			//@ts-ignore
			submissionData.phoneNumber2 = null;
		}

		editBuyer(submissionData);
	});

	return (
		<div>
			<form onSubmit={onSubmit}>
				<BuyerForm
					control={control}
					watch={watch}
					reset={reset}
					isSubmitting={isEditingBuyer}
				/>
			</form>
		</div>
	);
};
