import {
	ADDRESS,
	ACCOUNT_NUMBER,
	CUSTOMER_NAME,
	PRICING_WORKSHEET_APPROVAL_STATUS,
	REMARKETING_STATUS_TYPE_ID,
	STATE,
	DISPOSITION_OF_TITLE,
	PURCHASE_OPTION,
	ASSET_MANAGER_ID,
	RETAIL_ASKING_PRICE,
} from "@/features/asset/constants";
import {
	CASE_INSENSITIVE_CONTAINS,
	EQUALS,
	GREATER_THAN_OR_EQUAL,
	LESS_THAN_OR_EQUAL,
} from "../Filters/properties";
import { dispositionOfTitle, purchaseOption, states } from "@/Utilities/enums";
import { Combobox, SLDSCheckbox } from "@salesforce/design-system-react";
import { ChangeEvent, ReactNode } from "react";
import { useSelectRemarketingStatuses } from "@/features/lookups";
import { ControllerRenderProps } from "react-hook-form";
import { useGetAllUsersQuery } from "@/features/user/api/endpoints";

export interface Filter {
	propertyPath: string;
	operation: string;
	value: string;
}

const createFilter = (path: string, operation: string) => (input: string) => ({
	propertyPath: path,
	operation: operation,
	value: input,
});

export const legacyFilters: Record<string, (input: string) => Filter> = {
	address: createFilter(ADDRESS.path, CASE_INSENSITIVE_CONTAINS.value),
	accountNumber: createFilter(
		ACCOUNT_NUMBER.path,
		CASE_INSENSITIVE_CONTAINS.value
	),
	customerName: createFilter(
		CUSTOMER_NAME.path,
		CASE_INSENSITIVE_CONTAINS.value
	),
	state: createFilter(STATE.path, EQUALS.value),
	status: createFilter(REMARKETING_STATUS_TYPE_ID.path, EQUALS.value),
	sold: createFilter(REMARKETING_STATUS_TYPE_ID.path, EQUALS.value),
	assetManagerId: createFilter(ASSET_MANAGER_ID.path, EQUALS.value),
	dispositionOfTitle: createFilter(DISPOSITION_OF_TITLE.path, EQUALS.value),
	purchaseOption: createFilter(PURCHASE_OPTION.path, EQUALS.value),
	priceMin: createFilter(RETAIL_ASKING_PRICE.path, GREATER_THAN_OR_EQUAL.value),
	priceMax: createFilter(RETAIL_ASKING_PRICE.path, LESS_THAN_OR_EQUAL.value),
	activePricingWorksheetApprovalStatus: createFilter(
		PRICING_WORKSHEET_APPROVAL_STATUS.path,
		EQUALS.value
	),
};

interface SelectOption {
	id: string;
	key: string;
	title: string;
	label: string;
	value: string | number | boolean;
}

interface LegacyFilterInput {
	key: string;
	label: string;
	path: string;
	component?: (props: any) => ReactNode;
	props?: any;
}

export const inputs: LegacyFilterInput[] = [
	{ ...ADDRESS },
	{ ...ACCOUNT_NUMBER },
	{ ...CUSTOMER_NAME },
	{
		...STATE,
		component: Select,
		props: {
			options: Object.entries(states).map(([key, value]) => ({
				id: `option-${value?.toLowerCase()}`,
				key: `option-${value?.toLowerCase()}`,
				title: value,
				label: value,
				value: key,
			})),
		},
	},
	{
		key: "status",
		path: REMARKETING_STATUS_TYPE_ID.path,
		label: "Status",
		component: StatusSelect,
	},
	{
		key: "sold",
		path: REMARKETING_STATUS_TYPE_ID.path,

		label: "Sold",
		component: Checkbox,
	},
	{ ...ASSET_MANAGER_ID, component: AssetManagerSelect },

	{
		...DISPOSITION_OF_TITLE,
		component: Select,
		props: {
			options: dispositionOfTitle.map((label, index) => ({
				id: `option-dot-${index}`,
				key: `option-dot-${index}`,
				label,
				value: index.toString(),
			})),
		},
	},
	{
		...PURCHASE_OPTION,
		component: Select,
		props: {
			options: purchaseOption.map((label, index) => ({
				id: `option-po-${index}`,
				key: `option-po-${index}`,
				label,
				value: index.toString(),
			})),
		},
	},
	{
		key: "priceMin",
		path: RETAIL_ASKING_PRICE.path,
		label: "Min Price",
	},
	{
		key: "priceMax",
		path: RETAIL_ASKING_PRICE.path,
		label: "Max Price",
	},
	{
		...PRICING_WORKSHEET_APPROVAL_STATUS,
		component: Select,
		props: {
			options: Object.entries({
				InProgress: "In Progress",
				PendingApproval: "Pending Approval",
				Approved: "Approved",
			}).map(([key, value]) => ({
				id: `option-pw-${key}`,
				key: `option-pw-${key}`,
				label: value,
				value: key,
			})),
		},
	},
];

function Select({
	field: { value, onChange },
	label,
	options,
}: {
	field: ControllerRenderProps;
	label: string;
	options: SelectOption[];
}) {
	return (
		<Combobox
			labels={{ label: label }}
			value={value}
			selection={[
				options?.find((option: SelectOption) => option?.value === value),
			]}
			options={options}
			events={{
				onSelect: (
					_e: any,
					{ selection: [{ value }] }: { selection: SelectOption[] }
				) => {
					onChange(value);
				},
				onChange: (
					_e: any,
					{ selection: [{ value }] }: { selection: SelectOption[] }
				) => {
					onChange(value);
				},
			}}
			variant="readonly"
		/>
	);
}

function StatusSelect(props: any) {
	const statuses = useSelectRemarketingStatuses();

	return (
		<Select
			{...props}
			options={Object.entries(statuses).map(([key, value]) => {
				return {
					id: `option-${key}`,
					key: `option-${key}`,
					title: value,
					label: value,
					value: key,
				};
			})}
		/>
	);
}

interface LegacyFilterInputProps {
	field: ControllerRenderProps;
	label: string;
}

function AssetManagerSelect(props: LegacyFilterInputProps) {
	const { data } = useGetAllUsersQuery(undefined);

	const options = data?.map((item) => {
		return {
			label: `${item.firstName} ${item.lastName} (${item.resCode})`,
			value: item.id,
			key: item.id,
			id: item.id,
		};
	}) as SelectOption[];

	return <Select {...props} options={options} />;
}

function Checkbox({
	field: { value, onChange },
	label,
}: {
	field: ControllerRenderProps;
	label: string;
}) {
	const statuses: Record<string, string> = useSelectRemarketingStatuses();

	const id = Object.entries(statuses).reduce(
		(acc, [key, value]) => (/^sold$/i.test(value) ? key : acc),
		""
	);

	return (
		<div style={{ display: "flex", alignItems: "center", paddingTop: "1rem" }}>
			<SLDSCheckbox
				label={label}
				checked={!!value}
				onChange={(
					_e: ChangeEvent<HTMLInputElement>,
					{ checked }: { checked: boolean }
				) => {
					return checked ? onChange(id) : onChange("");
				}}
			/>
		</div>
	);
}
