import { SourceOfFunds } from "./SourceOfFunds";
import { SaleIdProvider } from "./SaleContext";
import classes from "../Booking.module.scss";
import { useSales } from "../hooks/useSales";
import { SalesInformation } from "./SalesInformation";
import { usePayments } from "../hooks/usePayments";
import Spinner from "@/Components/UI/Spinner/Spinner";
import { Buyer } from "./Buyer/Buyer";
import { Disposition } from "@/features/asset/types";

export const Worksheet = ({
	disposition,
	assetId,
}: {
	disposition: Disposition;
	assetId: string;
}) => {
	/*
		Data here holds any sale info 
		i.e. did someone sell the house, and the total sale amount
		if !isSuccess then no sale has been made
	*/
	const {
		data: salesInfo,
		isSuccess: isSuccessSales,
		isLoading: isLoadingSales,
	} = useSales(disposition?.saleId);

	/*
		funds here contains any payment history information
	*/
	const {
		data: funds,
		isLoading: isLoadingPayments,
		submit,
	} = usePayments(disposition?.saleId);

	// Wait for sales info before trying to render the rest of the form
	if (isLoadingSales || isLoadingPayments || !salesInfo) {
		return <Spinner />;
	}

	return (
		<div className="logrocket-hide">
			<div className={classes.section}>
				<SaleIdProvider saleId={disposition?.saleId}>
					<Buyer />
					<SalesInformation sale={salesInfo} assetId={assetId} />
					{isSuccessSales && funds && (
						<SourceOfFunds
							totalSaleAmount={salesInfo.totalAmount}
							funds={funds}
							onSubmit={submit}
						/>
					)}
				</SaleIdProvider>
			</div>
		</div>
	);
};
