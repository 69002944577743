import { useFormContext } from "react-hook-form";
import { useGetTaskTypesQuery } from "../api/endpoints";
import { Combobox, Input, InputIcon } from "@salesforce/design-system-react";
import { TaskFormType } from "./TaskForm";
import { ChangeEvent, useState } from "react";

export const TaskSubtype = () => {
	const [search, setSearch] = useState<string>("");

	const {
		watch,
		setValue,
		formState: { errors },
	} = useFormContext<TaskFormType>();

	const taskTypeId = watch("taskTypeId");
	const value = watch("name");

	const { data } = useGetTaskTypesQuery();

	const selectedTaskType = data?.find((item) => item.id === taskTypeId);

	const options =
		selectedTaskType?.taskSubTypes
			.filter((item) => !item.inactiveDate)
			.map((item) => {
				return {
					label: item.name,
					value: item.name,
					id: item.id,
				};
			}) ?? [];
	const id = watch("id");
	const selection = options?.filter((option) => {
		return option.value === value;
	});

	if (!taskTypeId || !data) {
		return null;
	}

	if (id) {
		return (
			<div style={{ width: "100%" }} className="slds-form-element">
				<label
					className="slds-form-element__label"
					htmlFor="existing-task-type"
				>
					Name
				</label>
				<div className="slds-form-element__control" id="existing-task-type">
					<p>{value}</p>
				</div>
			</div>
		);
	}

	if (options.length === 0) {
		return (
			<div style={{ width: "100%" }}>
				<Input
					label="Name"
					required
					value={value}
					errorText={errors?.name?.message}
					onChange={(e: any) => setValue("name", e.target.value)}
				/>
			</div>
		);
	}

	return (
		<div style={{ width: "100%" }}>
			<Combobox
				required
				variant="inline-listbox"
				options={options.filter((item) =>
					item.label.toLowerCase().includes(search.toLowerCase())
				)}
				selection={selection}
				menuItemVisibleLength={5}
				events={{
					onChange: (
						e: ChangeEvent<HTMLInputElement>,
						{ value }: { value: string }
					) => {
						setSearch(value);
					},
					onSelect: (
						e: any,
						{
							selection: [selection],
						}: { selection: { value: string; label: string; id: string }[] }
					) => {
						setValue("name", selection.label);
						setValue("taskSubtypeId", selection.id);
					},
					onRequestRemoveSelectedOption: () => {
						setValue("name", "");
						setValue("taskSubtypeId", undefined);
					},
				}}
				iconLeft={<InputIcon name="error" category="utility" />}
				labels={{ label: "Name" }}
				errorText={errors?.name?.message}
				value={selection.length > 0 ? selection[0].label : search}
			/>
		</div>
	);
};
