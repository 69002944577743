import {
	Button,
	Checkbox,
	Combobox,
	ExpandableSection,
	Input as SLDSInput,
} from "@salesforce/design-system-react";
import type { ComponentPropsWithoutRef } from "react";
import inputClasses from "../Booking.module.scss";
import {
	FormProvider,
	useController,
	useFieldArray,
	useForm,
	useFormContext,
	useWatch,
} from "react-hook-form";

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useGetPaymentTypesQuery } from "../api/endpoints";
import { vestResolver } from "@hookform/resolvers/vest";
import { sourceOfFundsValidation } from "./sourceOfFundsValidation";
import { FaTrashAlt } from "react-icons/fa";
import classes from "./SalesInformation.module.scss";
import { Fund } from "../types";

export type SourceOfFundsForm = {
	funds: Fund[];
	totalSaleAmount: number;
};

export const SourceOfFunds = ({
	funds,
	onSubmit,
	totalSaleAmount,
}: {
	funds: Fund[];
	onSubmit: (funds: SourceOfFundsForm) => void;
	totalSaleAmount: number;
}) => {
	const { reset, ...methods } = useForm<SourceOfFundsForm>({
		resolver: vestResolver(sourceOfFundsValidation),
		defaultValues: {
			funds,
			totalSaleAmount: totalSaleAmount,
		},
		mode: "onChange",
	});

	useEffect(() => {
		if (funds && funds[0]?.id && methods.control._formValues?.funds) {
			reset({ ...funds, totalSaleAmount });
		}
		//eslint-disable-next-line
	}, [funds]);

	const formSubmit = (data: SourceOfFundsForm) => {
		onSubmit(data);
	};

	return (
		<ExpandableSection title="Funds">
			<FormProvider {...methods} reset={reset}>
				<form
					name="Source of Funds"
					onSubmit={methods.handleSubmit(formSubmit)}
				>
					<FundsArray />
					{methods.formState.isDirty && (
						<div className={classes.submitButtons}>
							<Button
								disabled={!methods.formState.isDirty}
								onClick={() => reset()}
							>
								Reset
							</Button>
							<Button
								disabled={!methods.formState.isDirty}
								variant="brand"
								type="submit"
							>
								Save
							</Button>
						</div>
					)}
				</form>
			</FormProvider>
		</ExpandableSection>
	);
};

const FundsArray = () => {
	const { fields, append, remove } = useFieldArray({
		name: "funds",
	});

	return (
		<div>
			{fields.map((item, index) => {
				return (
					<div
						className={inputClasses.paymentContainer}
						key={`payment-${index}`}
					>
						<div className={inputClasses.containerHeader}>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<span style={{ fontSize: ".7rem" }}>
									{/* @ts-ignore */}
									{dayjs(item.paymentDate)
										.utc(true)
										.local()
										.format("MM/DD/YYYY hh:mm A")}
								</span>
								{/* @ts-ignore */}
								{item.modified && (
									<span style={{ fontSize: ".7rem" }}>
										Modified - {/* @ts-ignore */}
										{dayjs(item.modified)
											.utc(true)
											.local()
											.format("MM/DD/YYYY hh:mm A")}
									</span>
								)}
							</div>

							<Button
								variant="text-destructive"
								style={{
									backgroundColor: "transparent",
									border: "transparent",
								}}
								onClick={() => remove(index)}
							>
								<FaTrashAlt style={{ width: "1rem", height: "1rem" }} />
							</Button>
						</div>
						<div className={inputClasses.formGrid}>
							{/* Dropdown, Check/Wire Pay Type */}
							<PaymentTypes
								name={`funds[${index}].paymentTypeId`}
								cbpName={`funds[${index}].checkByPhone`}
								label="Check/Wire Pay Type"
							/>
							{/* Checkbox, only available if Check/Wire Pay type is 'P' */}
							<CheckByPhone
								name={`funds[${index}].checkByPhone`}
								label="CBP"
								watch={`funds[${index}].paymentTypeId`}
							/>
							{/* String Input, Check/Wire # */}
							<Input
								name={`funds[${index}].paymentInstrumentId`}
								//@ts-ignore
								className={inputClasses.spanTwo}
								label="Check/Wire #"
							/>
							{/* Datetime, Check/Wire Date */}
							<Date
								name={`funds[${index}].paymentDate`}
								//@ts-ignore
								className={inputClasses.spanTwo}
								label="Check/Wire Date"
							/>
							{/* Number, Check/Wire Total */}
							<Input
								name={`funds[${index}].totalPaymentAmount`}
								//@ts-ignore
								type="number"
								fixedTextLeft="$"
								className={inputClasses.spanFour}
								step={0.01}
								label="Check/Wire Total"
							/>
							{/* Array, Payment Allocations */}
							<Input
								//@ts-ignore
								label="Amount to Apply to Loan"
								type="number"
								className={inputClasses.spanFour}
								fixedTextLeft="$"
								step={0.01}
								name={`funds[${index}].paymentAllocations[0].allocatedAmount`}
							/>
						</div>
					</div>
				);
			})}
			<div className={inputClasses.addPayment}>
				<Button
					onClick={() =>
						append({
							totalPaymentAmount: 0,
							paymentTypeId: null,
							paymentDate: dayjs().local().toISOString().replace("Z", ""),
							checkByPhone: false,
							paymentInstrumentId: "",
							paymentAllocations: [{ allocatedAmount: 0 }],
						})
					}
				>
					Add a Payment
				</Button>
			</div>
		</div>
	);
};

const PaymentTypes = ({
	name,
	cbpName,
	label,
}: {
	name: string;
	cbpName: string;
	label: string;
}) => {
	const {
		field: { ...rest },
		fieldState: { error },
	} = useController({ name });

	const { setValue } = useFormContext();

	const { data: paymentTypes } = useGetPaymentTypesQuery();
	const options = paymentTypes
		?.map((item) => {
			return {
				label: `${item.code} - ${item.description}`,
				value: item.id,
				id: item.id,
				disabled: false,
			};
		})
		.sort((a, b) => a.label.localeCompare(b.label));

	const selection = options?.filter((option) => {
		return option?.value?.toString() === rest.value?.toString();
	});

	return (
		<div className={inputClasses.mobileFull}>
			<Combobox
				labels={{ label, placeholder: "select a payment type" }}
				options={options}
				selection={selection}
				variant="readonly"
				errorText={error?.message}
				{...rest}
				events={{
					onSelect: (
						e: any,
						{ selection: [selection] }: { selection: any }
					) => {
						const isNumeric = Number(selection?.value).toString() !== "NaN";
						rest.onChange(
							isNumeric ? Number(selection?.value) : selection?.value || null
						);
						setValue(cbpName, false);
					},
				}}
			/>
		</div>
	);
};

interface InputGroupProps extends ComponentPropsWithoutRef<"input"> {
	label: string;
	name: string;
	fixedTextLeft?: string;
}
export const Input = ({ name, label, ...props }: InputGroupProps) => {
	const {
		field: { ...rest },
		fieldState: { error },
	} = useController({ name: name });

	return (
		<SLDSInput label={label} {...props} errorText={error?.message} {...rest} />
	);
};

const CheckByPhone = ({
	name,
	label,
	watch,
}: {
	name: string;
	label: string;
	watch: string;
}) => {
	const {
		field: { ref, ...rest },
		fieldState: { error },
	} = useController({ name: name });

	const paymentType = useWatch({ name: watch });

	const { data: paymentTypes } = useGetPaymentTypesQuery();

	const shouldHide =
		paymentTypes?.filter((item) => item.id === paymentType)[0]?.code !== "P";

	if (shouldHide) {
		return <></>;
	}

	return (
		<div className="slds-form-element" style={{ gridColumn: "span 2" }}>
			<label htmlFor={`checkbox-${name}`} className="slds-form-element__label">
				{label}
			</label>
			<Checkbox
				id={`checkbox-${name}`}
				errorText={error?.message}
				checked={rest.value}
				{...rest}
			/>
		</div>
	);
};

interface InputGroupProps extends ComponentPropsWithoutRef<"input"> {
	label: string;
	name: string;
	fixedTextLeft?: string;
}
export const Date = ({ name, label, ...props }: InputGroupProps) => {
	const {
		field: { value, ref, onChange, ...rest },
		fieldState: { error },
	} = useController({ name: name });

	const isISODate = dayjs(value).isValid() && !/^\d{4}-\d\d-\d\d$/.test(value);
	const convertValue = (value: string) =>
		isISODate ? dayjs(value).utc(false).format("YYYY-MM-DD") : value ?? "";

	const [inputValue, setInputValue] = useState(convertValue(value));
	const onChangeHandler = (e: any, { value }: { value: string }) => {
		setInputValue(value);
		if (dayjs(value).isValid()) {
			onChange(dayjs(value).utc(true).toISOString());
		} else {
			onChange(null);
		}
	};

	return (
		<SLDSInput
			label={label}
			inputRef={ref}
			errorText={error?.message}
			value={inputValue}
			type="date"
			{...props}
			{...rest}
			onChange={onChangeHandler}
		/>
	);
};
