import { getFormData } from "./utils";
import { gatewayApi } from "@/ReduxToolkit";
import { packPropertyBag } from "@/ReduxToolkit/MediaProperties";
import { nanoid } from "@reduxjs/toolkit";

export const uploadMediaEndpoint = {
	queryFn: async (
		{ assetId, files, mediaTypeId },
		{ getState },
		_extraOptions,
		baseQuery
	) => {
		const lookupMediaTypes =
			gatewayApi.endpoints.getMediaTypes.select()(getState())?.data;

		const types = Object.fromEntries(
			Object.entries(lookupMediaTypes ?? {}).map(([key, value]) => [value, key])
		);

		const photoType = types?.photo;
		const videoType = types?.video;

		const uploadFile = async (file) => {
			const fileTypeId =
				mediaTypeId ?? file?.type?.split("/")[0] === "video"
					? videoType
					: photoType;

			const properties = packPropertyBag({
				propertyBag: [],
			});

			const upload =
				typeof file === "string"
					? file
					: new File([file], `${nanoid()}.${getFileExtension(file)}`);
			const data = {
				createdBy: "055b1771-f2e1-4b05-9418-c5dc2d594c1a",
				groupingId: assetId,
				file: upload,
				mediaTypeId: fileTypeId, //getFileType(file, photoType, videoType),
				propertyBag: JSON.stringify(properties),
			};

			const response = await baseQuery({
				url: `/api/Media`,
				method: "POST",
				headers: {
					"Content-Type": undefined,
				},
				body: getFormData(data),
				responseHandler: () => file.name,
			});

			if (response.error) throw response.error;

			return response;
		};

		const allDone = await Promise.allSettled(
			Array.from(files).map((file) => {
				return uploadFile(file);
			})
		);

		const errors = allDone.filter((upload) => upload.status === "rejected");

		if (errors.length) return { error: errors };

		return { data: allDone.map((res) => res.value.data) };
	},
	invalidatesTags: (_result, _error, media) => [
		{ type: "AssetMedia", id: media.assetId },
	],
};

function getFileExtension(file) {
	return file.name.split(".")[file.name.split(".").length - 1];
}
