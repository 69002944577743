import { ImSpinner3 } from "react-icons/im";
import { useMediaQuery } from "react-responsive";
import styles from "./LoadingIndicator.module.scss";

export const LoadingIndicator = ({ loadingMessage }) => {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	return (
		<div className={isMobile ? styles.mobileWrapper : styles.wrapper}>
			<span className={styles.alignMiddle}>
				<ImSpinner3 className={styles.spinner} />
				{loadingMessage}...
			</span>
		</div>
	);
};
