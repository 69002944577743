import { ChangeEvent, useEffect, useState } from "react";
import { AssetListPayload } from "@/features/asset-list/api/endpoints";
import { Combobox, Icon } from "@salesforce/design-system-react";
import { useGetAssetsMutation } from "@/features/asset-list/api/endpoints";
import { Asset } from "@/features/asset/types";
import { Path, useFormContext } from "react-hook-form";
import { NewTask } from "../Types";
import { useGetEntityTypesQuery } from "../api/endpoints";
import { useGetCurrentUserQuery } from "@/features/user/api/endpoints";
import dayjs from "dayjs";
import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";

const defaultPaths: Path<Asset>[] = [
	"account.accountNumber",
	"physicalLocation.zip",
	"physicalLocation.address",
	"physicalLocation.city",
	"physicalLocation.state",
	"account.customerName",
];

type option = {
	label: string;
	id: string;
	subTitle: string;
	type: string;
	link: string;
	icon: JSX.Element;
};

export const AssetAssociation = () => {
	const { watch, setValue } = useFormContext<NewTask>();

	const associatedEntities = watch("associatedEntities");
	const { data: user } = useGetCurrentUserQuery();
	const { data: entityTypes, isLoading, isError } = useGetEntityTypesQuery();

	const assetEntityType = entityTypes?.find((item) => {
		return item.name === "Asset";
	})?.id;

	const selectedAsset = associatedEntities?.find(
		(item) => item.entityTypeId === assetEntityType && !item.inactiveDate
	);

	const {
		data: asset,
		isFetching,
		isSuccess: isSuccessAsset,
	} = useGetAssetQuery(selectedAsset?.entityId ?? "", {
		skip: !selectedAsset,
	});

	const [searchValue, setSearchValue] = useState<AssetListPayload>({
		filteringCriteria: [],
		paginationCriteria: { itemsPerPage: 20, pageNumber: 0 },
		fuzzySearchCriteria: { propertyPath: defaultPaths, value: "" },
		sortCriteria: { direction: "desc", propertyPath: "account.assignedDate" },
		name: "global-search",
	});

	const [trigger, { data }] = useGetAssetsMutation({
		selectFromResult: (result) => {
			return { data: result.data?.items ?? [] };
		},
	});

	const options = selectedAsset
		? [
				{
					label: isSuccessAsset
						? `#${asset?.account.accountNumber} - ${asset?.account.customerName}`
						: "Loading Asset info",
					id: selectedAsset.entityId,
					subTitle: `${asset?.physicalLocation.address}, ${asset?.physicalLocation.city} ${asset?.physicalLocation.state}, ${asset?.physicalLocation.zip}`,
					type: "account",
					link: `/account/${asset?.account.id}/asset/${asset?.id}?tab=details`,
					icon: (
						<Icon
							assistiveText={{ label: "Home" }}
							category="standard"
							name="home"
							style={{
								backgroundColor: "rgb(13, 105, 13)",
								fill: "#ffffff",
							}}
						/>
					),
				},
		  ]
		: data.map((item: Asset) => {
				return {
					label: `#${item.account.accountNumber} - ${item.account.customerName}`,
					id: item.id,
					subTitle: `${item.physicalLocation.address}, ${item.physicalLocation.city} ${item.physicalLocation.state}, ${item.physicalLocation.zip}`,
					type: "account",
					link: `/account/${item.account.id}/asset/${item.id}?tab=details`,
					icon: (
						<Icon
							assistiveText={{ label: "Home" }}
							category="standard"
							name="home"
							style={{
								backgroundColor: "rgb(13, 105, 13)",
								fill: "#ffffff",
							}}
						/>
					),
				};
		  });

	const onChangeSearch = (val: string) => {
		const newSearch = {
			...searchValue,
			fuzzySearchCriteria: { ...searchValue.fuzzySearchCriteria, value: val },
		};
		trigger(newSearch);
		setSearchValue(newSearch);
	};

	useEffect(() => {
		trigger(searchValue);
		//eslint-disable-next-line
	}, []);

	const id = watch("id");

	if (isLoading || isError) {
		return <></>;
	}

	if (id) {
		return (
			<div style={{ width: "100%" }} className="slds-form-element">
				<label
					className="slds-form-element__label"
					htmlFor="existing-task-type"
				>
					Asset
				</label>
				<div className="slds-form-element__control" id="existing-task-type">
					<p>{selectedAsset ? options[0]?.label : "No Association"}</p>
				</div>
			</div>
		);
	}
	return (
		<Combobox
			variant="inline-listbox"
			assistiveText={{ label: "Search Homebase" }}
			menuItemVisibleLength={5}
			disabled={id}
			events={{
				onChange: (
					e: ChangeEvent<HTMLInputElement>,
					{ value }: { value: string }
				) => {
					onChangeSearch(value);
				},
				onSelect: (
					e: ChangeEvent<HTMLSelectElement>,
					{ selection }: { selection: option[] | null }
				) => {
					if (selection && assetEntityType && user) {
						setValue("associatedEntities", [
							...associatedEntities,
							{
								entityId: selection[0].id,
								entityTypeId: assetEntityType,
								name: `${selection[0].label} - ${selection[0].subTitle}`,
								activeDate: dayjs().toISOString(),
								createdBy: user?.email,
								createdDate: dayjs().toISOString(),
							},
						]);
					}
				},
				onRequestRemoveSelectedOption: () => {
					let newAssociatedEntities = associatedEntities.map((item) => {
						// if item has an id, then the item is already associated in the server, we need to set as inactive
						if (
							item.id &&
							item.entityId === selectedAsset?.entityId &&
							!item.inactiveDate
						) {
							return { ...item, inactiveDate: dayjs().toISOString() };
						}
						return item;
					});

					newAssociatedEntities = newAssociatedEntities.filter((item) => {
						if (!item.id && item.entityId === selectedAsset?.entityId)
							return false;
						return true;
					});

					setValue("associatedEntities", newAssociatedEntities);
				},
			}}
			hasInputSpinner={isFetching}
			id="global-search-homebase"
			value={searchValue.fuzzySearchCriteria.value}
			labels={{ placeholder: "Search for Assets", label: "Asset" }}
			options={options}
			selection={
				selectedAsset
					? [options.find((item) => item.id === selectedAsset.entityId)]
					: []
			}
		/>
	);
};
