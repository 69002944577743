import { openToast } from "@/ReduxToolkit/ToastsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateMediaPropertiesMutation } from "../endpoints";
import { selectWebReadyMedia } from "../selectors";

const createRemoveFromWebReadyUpdate = ({ webReadyMedia, removed }) => {
	if (Array.isArray(removed)) {
		const removedIndices = removed.map((image) => image.order);
		const remaining = webReadyMedia.filter(
			(image) => !removedIndices.includes(image.order)
		);

		return [
			...removed.map((image) => ({
				...image,
				webReady: false,
				isPrimary: false,
				order: null,
			})),
			...remaining.map((image, index) => ({
				...image,
				order: index,
				...(index === 0 && { isPrimary: true }),
			})),
		];
	} else {
		const remaining = webReadyMedia.slice(removed.order + 1);
		return [
			{
				...removed,
				order: null,
				webReady: false,
				isPrimary: false,
			},
			...remaining.map((image, index) => ({
				...image,
				order: removed.order + index,
				...(removed.order + index === 0 && { isPrimary: true }),
			})),
		];
	}
};

export const useRemoveFromWebReady = (assetId) => {
	const webReadyMedia = useSelector(selectWebReadyMedia(assetId));
	const dispatch = useDispatch();

	const update = (removed) =>
		createRemoveFromWebReadyUpdate({ webReadyMedia, removed });

	const [trigger, result] = useUpdateMediaPropertiesMutation({
		fixedCacheKey: "remove-from-web",
	});

	const removeFromWebReady = (removed) => {
		trigger(update(removed));
	};

	useEffect(() => {
		if (result?.error) {
			dispatch(
				openToast({
					id: result.requestId,
					labels: {
						heading: `Error`,
						details: `Something went wrong when removing media from web ready`,
					},
					variant: "error",
					timeout: 5000,
				})
			);
		}
	}, [dispatch, result?.error, result.requestId]);

	return { removeFromWebReady, ...result };
};
