import { DesktopSelectionGroup } from "../DesktopSelectionGroup/DesktopSelectionGroup";
import { ShowContentButton } from "../../buttons/ShowContentButton/ShowContentButton";
import { Desktop, Mobile } from "@/Responsive";
import { ButtonGroup, Dropdown } from "@salesforce/design-system-react";
import { useSelectionDropdownActions } from "@/features/media-manager/hooks/useSelectionDropdownActions";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { useGetAssetMediaQuery } from "@/features/media-manager/api/endpoints";
import { WEB_READY_MEDIA } from "@/ReduxToolkit/MediaSlice";

export const MobileSelectionGroup = () => {
	const { assetId } = useAssetId();

	const selectAllOrNone = useSelectionDropdownActions({
		bucket: WEB_READY_MEDIA,
		assetId,
		selectionActions: ["selectAll", "selectNone"],
	});
	const selectionActions = useSelectionDropdownActions({
		bucket: WEB_READY_MEDIA,
		assetId,
		selectionActions: [
			"removeSelectedFromWeb",
			"addTagsToSelected",
			"removeTagsFromSelected",
			"emailSelected",
			"deleteSelected",
		],
	});

	const handleSelectAction = (e) => e.value();
	return (
		<>
			<ButtonGroup variant="list">
				<Dropdown
					iconName="multi_select_checkbox"
					iconCategory="utility"
					align="right"
					iconVariant="border-filled"
					options={selectAllOrNone}
					onSelect={handleSelectAction}
				/>
				<Dropdown
					iconName="threedots_vertical"
					iconCategory="utility"
					iconVariant="border-filled"
					align="right"
					options={selectionActions}
					onSelect={handleSelectAction}
				/>
			</ButtonGroup>
		</>
	);
};
export const WebReadyControls = ({ isVisible, onShowContent }) => {
	// const media = useSelector(selectWebReadies);

	const { assetId } = useAssetId();
	const { isSuccess } = useGetAssetMediaQuery(assetId);
	return (
		isSuccess && (
			<>
				<Desktop>
					<DesktopSelectionGroup
						// media={media}
						bucket={WEB_READY_MEDIA}
						selectionActions={[
							"removeSelectedFromWeb",
							"addTagsToSelected",
							"removeTagsFromSelected",
							"emailSelected",
							"deleteSelected",
						]}
					/>
				</Desktop>
				<Mobile>
					<MobileSelectionGroup />
				</Mobile>
				<ShowContentButton
					onShowContent={onShowContent}
					isVisible={isVisible}
				/>
			</>
		)
	);
};

WebReadyControls.displayName = "SLDSPageHeaderControl";
