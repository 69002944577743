import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	loaded: false,
	mode: "", // "zoom", "rotate", "crop", "brightness"
	isEditing: false,
	isSaving: false,
	imageURL: "",
	cropping: false,
	undoStack: 0,
	brightness: 0,
	rotate: 0,
	zoom: "in",
	zoomLevel: 1,
	isZoomActive: false,
	cursorPosition: {
		x: 0,
		y: 0,
	},
	dimensions: {
		width: 0,
		height: 0,
	},
	cssDimensions: {
		width: 0,
		height: 0,
	},
};
export const EditorSlice = createSlice({
	name: "Editor",
	initialState: initialState,
	reducers: {
		setLoaded(state, action) {
			state.loaded = action.payload;
		},
		setCssDimensions(state, action) {
			state.cssDimensions = action.payload;
		},
		setMode(state, action) {
			state.mode = action.payload;
		},
		setIsEditing(state, action) {
			state.isEditing = action.payload;
		},
		setImageURL(state, action) {
			state.imageURL = action.payload;
		},
		setCropping(state, action) {
			state.cropping = action.payload;
		},
		setDimensions(state, action) {
			state.dimensions = action.payload;
		},
		setBrightness(state, action) {
			state.brightness = action.payload;
		},
		setRotate(state, action) {
			state.rotate = action.payload;
		},
		setIsZoomActive(state, action) {
			state.isZoomActive = action.payload;
		},
		setZoomLevel(state, action) {
			state.zoomLevel = action.payload;
		},
		setZoom(state, action) {
			state.zoom = action.payload;
		},
		setCursorPosition(state, action) {
			state.cursorPosition = action.payload;
		},
		resetBrightness(state, action) {
			state.brightness = 0;
		},
		setUndoStack(state, action) {
			state.undoStack = action.payload;
		},
		resetEditorState(state) {
			state = { ...initialState, loaded: true };
			return state;
		},
		setIsSaving(state, action) {
			state.isSaving = action.payload;
		},
	},
});

export const editorReducer = EditorSlice.reducer;
export const {
	setCssDimensions,
	setBrightness,
	setCropping,
	setDimensions,
	setMode,
	setImageURL,
	setIsEditing,
	setIsZoomActive,
	setLoaded,
	setRotate,
	setZoom,
	setZoomLevel,
	setCursorPosition,
	resetBrightness,
	setUndoStack,
	resetEditorState,
	setIsSaving,
} = EditorSlice.actions;
