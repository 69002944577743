import { gatewayApi } from "@/ReduxToolkit";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const summaryAssistEndpoint = gatewayApi.injectEndpoints({
	endpoints: (builder) => ({
		generateDescription: builder.mutation<string, string>({
			queryFn: async (prompt: string) => {
				// if config.SUMMARY_ASSIST_URL is base value, then use proxy for local development, otherwise use value
				let url = process.env.REACT_APP_SUMMARY_ASSIST_URL;
				let apiKey = process.env.REACT_APP_SUMMARY_ASSIST_API_KEY;

				const headers: HeadersInit = new Headers();
				headers.set("Content-Type", "application/json");
				headers.set("x-api-key", apiKey as string);

				try {
					const response = await fetch(url ?? "", {
						method: "POST",
						body: JSON.stringify(prompt),
						headers,
						credentials: "omit",
					});
					if (!response.ok) {
						throw new Error(
							`Network response was not ok: ${response.status} - ${response.statusText}`
						);
					}
					const data = await response.text();
					return { data } as QueryReturnValue<string, any, undefined>;
				} catch (error) {
					return { error } as QueryReturnValue<string, any, undefined>;
				}
			},
		}),
	}),
});

export const { useGenerateDescriptionMutation } = summaryAssistEndpoint;
