import { useCallback, useEffect } from "react";
import {
	resetEditorState,
	setCropping,
	setDimensions,
	setIsEditing,
	setMode,
	setRotate,
} from "./ToastSlice";
import { useEditorState, useImageEditor } from "./ToastUIContext";
import { useSaveImage } from "../../api/hooks/useSaveImage";

function waitUntilImageEditorIsUnlocked(imageEditor) {
	return new Promise((resolve, reject) => {
		const interval = setInterval(() => {
			if (!imageEditor?._invoker?._isLocked) {
				clearInterval(interval);
				resolve(imageEditor);
			}
		}, 100);
	});
}

const executeEditorFunction =
	(editor, fn) =>
	async (...args) => {
		try {
			const unlockedEditor = await waitUntilImageEditorIsUnlocked(editor);
			const res = await unlockedEditor[fn](...args);
			return res;
		} catch (e) {
			if (process.env.NODE_ENV === "development") {
				console.log(e, `${fn}`);
			}
		}
	};

function createEditorCallbacks(editor, functions) {
	return functions
		.map((fn) => ({
			[fn]: ((editor) =>
				useCallback(
					(...args) => executeEditorFunction(editor, fn)(...args),
					[editor]
				))(editor),
		}))
		.reduce((acc, c) => {
			return {
				...acc,
				...c,
			};
		}, {});
}

export function useEditorFunction(editor) {
	const callbacks = createEditorCallbacks(editor, [
		"applyFilter",
		"hasFilter",
		"removeFilter",
		"changeCursor",
		"resetZoom",
		"zoom",
		"setAngle",
		"loadImageFromURL",
		"startDrawingMode",
		"stopDrawingMode",
		"crop",
		"getCropzoneRect",
		"resizeCanvasDimension",
		"undo",
		"clearUndoStack",
		"clearObjects",
		"toDataURL",
	]);

	return {
		...callbacks,
	};
}

export function useBrightness(loaded, editor) {
	const { mode, brightness, brightnessChecked } = useEditorState();
	const { applyFilter } = useEditorFunction(editor);

	useEffect(() => {
		if (loaded) {
			applyFilter(
				"brightness",
				{
					brightness: brightness,
				},
				true
			);
		}
	}, [applyFilter, brightness, brightnessChecked, loaded, mode]);
}

export function useZoom(loaded, editor) {
	const { zoom, isZoomActive, zoomLevel, cursorPosition } = useEditorState();
	const {
		changeCursor,
		resetZoom,
		zoom: executeZoom,
	} = useEditorFunction(editor);

	useEffect(() => {
		if (loaded && isZoomActive) {
			executeZoom({
				x: cursorPosition.x,
				y: cursorPosition.y,
				zoomLevel: zoomLevel,
			});
		}
	}, [zoomLevel, loaded, cursorPosition, isZoomActive, executeZoom]);

	useEffect(() => {
		if (loaded && zoom === "reset") {
			resetZoom();
		}
	}, [loaded, zoom, resetZoom]);

	useEffect(() => {
		if (loaded) {
			isZoomActive ? changeCursor(`zoom-${zoom}`) : changeCursor("default");
		}
	}, [zoom, loaded, isZoomActive, changeCursor]);
}

export function useRotate(loaded, editor) {
	const { rotate, mode } = useEditorState();
	const { setAngle } = useEditorFunction(editor);

	useEffect(() => {
		if (loaded) {
			setAngle(rotate, true);
		}
	}, [editor, loaded, mode, rotate, setAngle]);
}

export function useLoadImage(loaded, editor) {
	const { imageURL } = useEditorState();
	const { loadImageFromURL, clearUndoStack } = useEditorFunction(editor);

	useEffect(() => {
		if (loaded && imageURL) {
			const url = new URL(imageURL, window.location);
			if (url.protocol !== "data:") {
				loadImageFromURL(`${imageURL}?canvas=true`, "image");
				clearUndoStack();
			}
		}
	}, [clearUndoStack, imageURL, loadImageFromURL, loaded]);
}

export function useCrop(loaded, editor) {
	const [{ mode, brightness, cropping }, dispatch] = useImageEditor();
	const {
		startDrawingMode,
		stopDrawingMode,
		crop,
		applyFilter,
		setAngle,
		getCropzoneRect,
		resetZoom,
	} = useEditorFunction(editor);
	const { dimensions } = useEditorState();
	const applyCrop = useCallback(async () => {
		if (loaded) {
			try {
				await applyFilter("brightness", { brightness: 0 }, true);
				const rect = await getCropzoneRect();
				await crop(rect, true);
				await stopDrawingMode();
				await applyFilter(
					"brightness",
					{
						brightness: brightness,
					},
					true
				);
				await setAngle(0, true);
				dispatch(setRotate(0));
				const width =
					dimensions.width >= rect.width ? rect.width : dimensions.width;
				const height =
					dimensions.height >= rect.height ? rect.height : dimensions.height;
				dispatch(setDimensions({ width: width, height: height }));
				dispatch(setCropping("done"));
				dispatch(setMode(""));
			} catch (e) {
				console.log(e);
			}
		}
	}, [
		dimensions,
		applyFilter,
		brightness,
		crop,
		dispatch,
		getCropzoneRect,
		loaded,
		setAngle,
		stopDrawingMode,
	]);

	const startCrop = useCallback(async () => {
		await resetZoom();
		startDrawingMode("CROPPER");
	}, [resetZoom, startDrawingMode]);

	useEffect(() => {
		if (loaded && cropping === "cropping") {
			// dispatch(setIsZoomActive(false));
			startCrop();
		}
	}, [cropping, loaded, startCrop, startDrawingMode]);

	useEffect(() => {
		if (loaded && cropping === "apply") {
			applyCrop();
		}
	}, [loaded, cropping, applyCrop, dispatch]);

	useEffect(() => {
		if (loaded && cropping === "cropping" && mode !== "crop") {
			stopDrawingMode();
			dispatch(setCropping(""));
		}

		if (loaded && cropping === "cancel") {
			stopDrawingMode();
			dispatch(setMode(""));
		}
	}, [cropping, dispatch, editor, loaded, mode, stopDrawingMode]);
}

export function useHistory(loaded, editor) {
	const [{ mode, cssDimensions }, dispatch] = useImageEditor();
	const { undo } = useEditorFunction(editor);

	useEffect(() => {
		if (loaded && mode === "undo") {
			undo();
			dispatch(setDimensions(cssDimensions));
			dispatch(setCropping(""));
			dispatch(setMode(""));
		}
	});
}

export function useSaveData(loaded, editor, image) {
	const [{ mode }, dispatch] = useImageEditor();
	const { toDataURL, resetZoom } = useEditorFunction(editor);
	const { saveCopy, updateMedia, isUpdating } = useSaveImage();

	const saveACopy = useCallback(async () => {
		await resetZoom();
		const dataURL = await toDataURL({ format: "jpeg", quality: 0.8 });
		const file = dataURItoFile(dataURL);
		dispatch(resetEditorState());
		saveCopy({
			file: file,
			assetId: image.groupingId,
			id: image.id,
			tags: image.tags,
			title: image.title,
			imageURL: image.path,
			dataURL: dataURL,
		});
	}, [
		dispatch,
		image.groupingId,
		image.id,
		image.path,
		image.tags,
		image.title,
		resetZoom,
		saveCopy,
		toDataURL,
	]);

	const overwrite = useCallback(async () => {
		await resetZoom();
		const dataURL = await toDataURL({ format: "jpeg", quality: 0.8 });
		const file = dataURItoFile(dataURL);
		dispatch(resetEditorState());
		updateMedia({
			file: file,
			id: image.id,
			assetId: image.groupingId,
			dataURL,
		});
	}, [dispatch, image.groupingId, image.id, resetZoom, toDataURL, updateMedia]);

	useEffect(() => {
		if (loaded && mode === "saveOptions") {
			dispatch(setIsEditing(false));
		}

		if (isUpdating === "pending") {
			dispatch(resetEditorState());
		}

		if (loaded && mode === "overwrite") {
			overwrite();
		}

		if (loaded && mode === "saveCopy") {
			saveACopy();
		}
	}, [
		dispatch,
		image.path,
		loaded,
		mode,
		overwrite,
		saveACopy,
		toDataURL,
		isUpdating,
	]);
}

function dataURItoFile(dataURI) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	const byteString = atob(dataURI.split(",")[1]);

	// separate out the mime component
	const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

	// write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length);
	let ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new File([ab], "edit.jpg", { type: mimeString });
}
