import React from "react";
import "./Spinner.css";

const Spinner = () => {
	return (
		<>
			<div className="loader">loading...</div>
		</>
	);
};

export default Spinner;
