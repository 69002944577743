import { each, enforce, test, create } from "vest";
import { SourceOfFundsForm } from "./SourceOfFunds";

/**
 * Source of Funds Validation
 *
 * sale amount, payment amount, allocation amount all must be > 0, and only accept 2 decimal places
 *
 *
 * check # should be less than 32 characters
 */

export const sourceOfFundsValidation = create((data: SourceOfFundsForm) => {
	const { funds } = data;

	each(funds, (item, index) => {
		test(
			`funds[${index}].totalPaymentAmount`,
			"Total Payment must be > $0.00",
			() => {
				enforce(item.totalPaymentAmount).gt(0);
			}
		);

		test(
			`funds[${index}].paymentAllocations[0].allocatedAmount`,
			"Amount to Apply must be > $0.00",
			() => {
				enforce(item.paymentAllocations[0].allocatedAmount).gt(0);
			}
		);

		test(
			`funds[${index}].paymentAllocations[0].allocatedAmount`,
			"Must not be greater than Check/Wire Total",
			() => {
				enforce(item.paymentAllocations[0].allocatedAmount).lte(
					item.totalPaymentAmount
				);
			}
		);

		test(
			`funds[${index}].totalPaymentAmount`,
			"Must only have 2 decimal places",
			() => {
				enforce(item.totalPaymentAmount).matches(/^(\d*\.{0,1}\d{0,2}$)/);
			}
		);

		test(
			`funds[${index}].paymentAllocations[0].allocatedAmount`,
			"Must only have 2 decimal places",
			() => {
				enforce(item.paymentAllocations[0].allocatedAmount).matches(
					/^(\d*\.{0,1}\d{0,2}$)/
				);
			}
		);

		test(
			`funds[${index}].paymentDate`,
			"Check/Wire date must not be empty",
			() => {
				enforce(item.paymentDate).isNotNullish();
			}
		);

		test(`funds[${index}].paymentTypeId`, "Payment Type is Required", () => {
			enforce(item.paymentTypeId).isNotNull();
		});

		test(
			`funds[${index}].paymentInstrumentId`,
			"Check/Wire # is Required",
			() => {
				enforce(item.paymentInstrumentId).isNotNull();
				enforce(item.paymentInstrumentId).isNotEmpty();
			}
		);
	});
});
