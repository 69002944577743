import { useUser } from "@/features/user/hooks/useUser";
import {
	FlagProvider,
	useFlag,
	useUnleashContext,
} from "@unleash/proxy-client-react";
import { useEffect } from "react";

const UnleashUserProvider = ({ children }) => {
	const { data: user } = useUser();
	const updateContext = useUnleashContext();
	useEffect(() => {
		updateContext({ userId: user?.email });
	}, [updateContext, user]);

	return children;
};

export const UnleashFlagProvider = ({ children }) => {
	const unleashConfig = {
		url: process.env.REACT_APP_UNLEASH_URL,
		clientKey: process.env.REACT_APP_UNLEASH_API_KEY,
		refreshInterval: 15,
		appName: "HomeBase",
		environment: "itg",
	};

	return (
		<FlagProvider config={unleashConfig}>
			<UnleashUserProvider>{children}</UnleashUserProvider>
		</FlagProvider>
	);
};

export { useFlag };
