import { useGetMediaTypesQuery } from "@/features/lookups";
import { useIsOnline } from "@/features/offline-use";
import {
	addFilter,
	ALL_MEDIA,
	removeFilter,
	setAllMediaSelection,
	toggleSelection,
	updateSort,
} from "@/ReduxToolkit/MediaSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	useGetAssetMediaQuery,
	useGetAssetMediaSubscription,
	useGetMediaQuery,
} from "../endpoints";
import {
	selectAllMedia,
	selectAllMediaFilters,
	selectSelectedAllMedia,
	selectSortParameters,
} from "../selectors";
import { selectOfflineMedia } from "../selectors/allMedia";

export const useAllMedia = (assetId) => {
	const isOnline = useIsOnline();
	const {
		data: count,
		isSuccess,
		isLoading,
		...rest
	} = useGetAssetMediaQuery(assetId, {
		selectFromResult: ({ data, ...rest }) => ({
			data: data?.ids?.length,
			...rest,
		}),
	});

	const { isSuccess: isSuccessMediaTypes, isLoading: isLoadingMediaTypes } =
		useGetMediaTypesQuery();

	const allMedia = useSelector(selectAllMedia(assetId));
	const offlineMedia = useSelector(selectOfflineMedia(assetId));
	const selected = useSelector(selectSelectedAllMedia(assetId));
	const dispatch = useDispatch();

	useEffect(() => {
		const update = Object.fromEntries(
			allMedia.map((media) => [media.id, selected?.[media.id] === true])
		);

		dispatch(
			setAllMediaSelection({
				assetId,
				update,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allMedia.length]);

	const toggle = (mediaId) => {
		dispatch(toggleSelection({ assetId, bucket: ALL_MEDIA, mediaId }));
	};

	return {
		data: isOnline ? allMedia : offlineMedia,
		count,
		selected,
		toggleSelection: toggle,
		isSuccess: isSuccess && isSuccessMediaTypes,
		isLoading: isLoading && isLoadingMediaTypes,
		...rest,
	};
};

export const useAllMediaFilters = (assetId) => {
	useGetAssetMediaSubscription(assetId);
	const filters = useSelector(selectAllMediaFilters(assetId));
	const dispatch = useDispatch();
	const add = (filter) => dispatch(addFilter({ assetId, filter }));
	const remove = (filterId) => dispatch(removeFilter({ assetId, filterId }));

	return { filters, addFilter: add, removeFilter: remove };
};

export const useAllMediaSort = (assetId) => {
	useGetAssetMediaSubscription(assetId);
	const sortParameters = useSelector(selectSortParameters(assetId)) || {};
	const dispatch = useDispatch();

	const update = (parameters) => {
		dispatch(updateSort({ assetId, ...parameters }));
	};

	return [sortParameters, update];
};

export const useMedia = (mediaId) => {
	const { data, isSuccess } = useGetMediaQuery(mediaId);

	const { data: mediaTypes, isSuccess: isSuccessMediaTypes } =
		useGetMediaTypesQuery();

	useGetAssetMediaSubscription(data?.groupingId, { skip: !isSuccess });

	return {
		data,
		isSuccess: isSuccess && isSuccessMediaTypes,
		isPhoto: mediaTypes?.[data?.mediaTypeId] === "photo",
	};
};

export const useMediaType = (mediaTypeId) => {
	const { data: mediaTypes, isSuccess } = useGetMediaTypesQuery();

	return {
		isSuccess,
		isPhoto: mediaTypes?.[mediaTypeId] === "photo",
	};
};
