import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { setSelection } from "@/ReduxToolkit/MediaSlice";
import { useDispatch, useSelector } from "react-redux";
import {
	selectSelectedMedia,
	selectSelectedMediaEntities,
	selectSelectedMediaIds,
} from "../selectors";
import { useAddtoWebReady } from "./useAddToWebReady";
import { useRemoveFromWebReady } from "./useRemoveFromWebReady";
import { useEmailMediaModal } from "../../components/EmailMedia/EmailMedia";
import { useTagsActionModal } from "../../components/tags/TagsActionModal";
import { useConfirmationDialog } from "@/Components/ConfirmationDialog/ConfirmationDialog";
import { useDeleteMedia } from "./useDeleteMedia";

export const useSelectedMedia = ({ assetId, bucket }) => {
	const selected = useSelector(selectSelectedMedia({ bucket, assetId }));
	const selectedIds = useSelector(selectSelectedMediaIds({ bucket, assetId }));
	const selectedEntities = useSelector(
		selectSelectedMediaEntities({ bucket, assetId })
	);

	const dispatch = useDispatch();

	const selectAll = () => {
		dispatch(
			setSelection({
				assetId,
				bucket,
				update: Object.fromEntries(
					Object.entries(selected).map(([key, value]) => [key, true])
				),
			})
		);
	};

	const selectNone = () => {
		dispatch(
			setSelection({
				assetId,
				bucket,
				update: Object.fromEntries(
					Object.entries(selected).map(([key, value]) => [key, false])
				),
			})
		);
	};

	const numSelected = selectedIds.length;
	const allAreSelected = Object.values(selected).length === numSelected;
	const noneAreSelected = numSelected === 0;

	return {
		selected,
		selectedIds,
		selectedEntities,
		selectAll,
		selectNone,
		allAreSelected,
		noneAreSelected,
	};
};

export const useSelectedMediaActions = ({ assetId, bucket }) => {
	const { accountId } = useAssetId();
	const { selectedIds, selectedEntities } = useSelectedMedia({
		assetId,
		bucket,
	});

	const { addToWebReady } = useAddtoWebReady(assetId);
	const { removeFromWebReady } = useRemoveFromWebReady(assetId);

	const addSelectedToWeb = () => {
		addToWebReady(selectedEntities);
	};
	const removeSelectedFromWeb = () => {
		removeFromWebReady(selectedEntities);
	};

	const tagsModal = useTagsActionModal();

	const openAddTagsModal = () => {
		tagsModal.show({
			action: "add",
			selectedIds,
			assetId,
		});
	};

	const openRemoveTagsModal = () => {
		tagsModal.show({
			action: "remove",
			selectedIds,
			assetId,
		});
	};

	const emailMediaModal = useEmailMediaModal();

	const openEmailSelected = () => {
		emailMediaModal.show({
			selectedIds,
			accountId,
			assetId,
			bucket,
		});
	};

	const deleteModal = useConfirmationDialog();
	const { deleteMedia } = useDeleteMedia(assetId);

	const deleteWithConfirmation = () => {
		deleteModal.show({
			title: "Confirm Delete",
			message: `Are you sure you want to delete ${
				selectedIds.length > 1 ? selectedIds.length : "this"
			} item${selectedIds.length > 1 ? "s" : ""}?`,
			confirmButtonText: "Delete",
			action: () => deleteMedia(selectedIds),
		});
	};

	return {
		addSelectedToWeb,
		removeSelectedFromWeb,
		openAddTagsModal,
		openRemoveTagsModal,
		openEmailSelected,
		emailMedia: openEmailSelected,
		deleteSelected: deleteWithConfirmation,
	};
};
