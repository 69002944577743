import { removeSpecialCharacters } from "@/Utilities/utils";
import {
	Button,
	DynamicIcon,
	SLDSCombobox,
	Textarea,
} from "@salesforce/design-system-react";
import {
	FormProvider,
	useController,
	useForm,
	useFormContext,
	useWatch,
} from "react-hook-form";
import classes from "./DocumentationForm.module.scss";
import {
	useGetNoteReasonsQuery,
	useGetNoteSourcesQuery,
	useSubmitNoteMutation,
} from "../../api/endpoints";
import { useUser } from "@/features/user/hooks/useUser";
// @ts-ignore
import { vestResolver } from "@hookform/resolvers/vest";
import { documentationValidation } from "./documentationValidation";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { openToast } from "@/ReduxToolkit/ToastsSlice";
import { nanoid } from "@reduxjs/toolkit";
import { NoteReason, NoteSubmission } from "../../types";

export const DocumentationForm = ({
	onCancel,
	id,
}: {
	onCancel: Function;
	id: string;
}) => {
	const { data: user } = useUser();
	const { reset, ...methods } = useForm<NoteSubmission>({
		resolver: vestResolver(documentationValidation),
		defaultValues: {
			createdBy: user?.email,
			sourceId: 4,
			content: "",
			createdDate: new Date().toISOString(),
			accountId: id,
			reasonId: "",
		},
		mode: "onChange",
	});

	const { assetId } = useAssetId();
	const dispatch = useDispatch();
	const [trigger, { isLoading: isUpdating }] = useSubmitNoteMutation();

	const onSubmit = async (data: NoteSubmission) => {
		const res = await trigger(data);
		if (Object.hasOwn(res, "error")) {
			dispatch(
				openToast({
					id: assetId,
					labels: {
						heading: "Error",
						details: "Something went wrong creating documentation",
					},
					timeout: 3000,
					variant: "error",
				})
			);
		} else {
			reset();
		}
	};

	return (
		<>
			<FormProvider {...methods} reset={reset}>
				<div className={classes.container}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<div className={classes.optionsContainer}>
							<Source name="sourceId" reasonName="reasonId" />
							<Reason name="reasonId" watch="sourceId" />
						</div>

						<Content name="content" />

						<div style={{ display: "flex", justifyContent: "center" }}>
							<div className={classes.submitButtons}>
								<Button
									key="Cancel"
									label="Cancel"
									onClick={() => {
										methods.formState.isDirty ? reset() : onCancel();
									}}
									disabled={isUpdating}
								/>
								<Button
									type="submit"
									value="submit"
									variant="brand"
									disabled={isUpdating || !methods.formState.isDirty}
								>
									{isUpdating ? (
										<DynamicIcon
											title="waiting"
											variant="typing"
											size="xx-small"
										/>
									) : (
										"Send"
									)}
								</Button>
							</div>
						</div>
					</form>
				</div>
			</FormProvider>
		</>
	);
};

const Source = ({ name, reasonName }: { name: string; reasonName: string }) => {
	const {
		field: { ...rest },
		fieldState: { error },
	} = useController({ name });

	const { ...methods } = useFormContext();

	const { data } = useGetNoteSourcesQuery(undefined);

	const sources = data;
	const options = sources
		?.filter((item) => item.isWritable)
		.map(({ id, description }) => {
			return {
				id: `source-${id}-${description}`,
				value: id,
				label: `${id} - ${description}`,
			};
		});
	const selection = options?.filter((option) => {
		return option?.value?.toString() === rest.value?.toString();
	});
	return (
		<SLDSCombobox
			labels={{
				label: "Source",
				placeholder: "Select a source",
			}}
			events={{
				onSelect: (
					_e: ChangeEvent<HTMLSelectElement>,
					{ selection }: { selection: typeof options }
				) => {
					if (!selection) return;
					const item = selection[0];
					rest.onChange(item?.value ?? null);
					methods.setValue(reasonName, null);
				},
			}}
			selection={selection}
			errorText={error?.message}
			multiple={false}
			required={true}
			options={options}
			variant="readonly"
		/>
	);
};

const Reason = ({ name, watch }: { name: string; watch: string }) => {
	const [search, setSearch] = useState("");
	const {
		field: { ...rest },
		fieldState: { error },
	} = useController({ name });

	const { data } = useGetNoteReasonsQuery(undefined);
	const sourceId = useWatch({ name: watch });
	const searchFilter = (item: NoteReason) => {
		return `${item.id} - ${item.description}`
			.toLowerCase()
			.includes(search.toLowerCase());
	};
	const reasons = data;
	const options = reasons
		?.filter((item) => item.source === sourceId && searchFilter(item))
		.map(({ description, source, id }) => {
			return {
				id: `reason-${source}-${id}-${description}-${nanoid()}`,
				value: id,
				source,
				label: `${id} - ${description}`,
			};
		});
	const selection = options?.filter((option) => {
		return option?.value?.toString() === rest.value?.toString();
	});
	// If not a collections note, then don't show the Reasons component
	if (sourceId !== 1) {
		return <></>;
	}
	return (
		<SLDSCombobox
			labels={{
				label: "Reason",
				placeholder: "Select a reason",
			}}
			events={{
				// @ts-ignore
				onChange: (_, { value }) => {
					setSearch(value);
				},
				// @ts-ignore
				onSelect: (_, { selection: [selection] }) => {
					rest.onChange(selection?.value ?? null);
				},
				onRequestRemoveSelectedOption: () => {
					rest.onChange("");
				},
			}}
			selection={selection}
			menuItemVisibleLength={5}
			errorText={error?.message}
			multiple={false}
			required={true}
			variant="inline-listbox"
			options={options}
		/>
	);
};

const Content = ({ name }: { name: string }) => {
	const {
		field: { ...rest },
		fieldState: { error },
	} = useController({ name });
	return (
		<Textarea
			label="Content"
			required
			errorText={error?.message}
			{...rest}
			//@ts-ignore
			onChange={(e) => {
				rest.onChange(removeSpecialCharacters(e.target.value, false));
			}}
		/>
	);
};
