export const nursery = "#F2F8FC";
export const blueSky = "#00A0DD";
export const wetCement = "#51748B";
export const bedTime = "#06292F";
export const freshGrass = "#4F9E44";
export const claytonBlue = "#0077C8";
export const claytonGreen = "#97D700";
export const rubberDucky = "#FFC50A";
export const cheesePuff = "#F68C2C";
export const barnDoor = "#B21F28";

export const grey10 = "#E6E6E6";
export const grey30 = "#BCBEC0";
export const grey50 = "#939598";
export const grey70 = "#6D6E71";
export const grey90 = "#414042";
export const grey100 = "#231F20";
