import { get } from "lodash";
import { create, enforce, test, each } from "vest";
import {
	FREE_FORM_DOCUMENTATION,
	HUD_NUMBER_VERIFIED,
	KEY_LOCATION,
	LOCK_BOX_CODE,
	NumberFields,
	OVERVIEW,
	SERIAL_NUMBER_VERIFIED,
	SKIP_CONDITION_REPORT,
	SKIP_REASON,
} from "../../constants/constants";
import { toFlatPropertyMap } from "@/Utilities/utils";

export const ConditionReportValidation = create((data = {}) => {
	const dataFinder = (item) => {
		const arr = Object.entries(toFlatPropertyMap(data));
		const filtered = arr.filter(([key, value]) => key.includes(item));
		const final = Object.fromEntries(filtered);

		return final;
	};

	each([...NumberFields], (field) => {
		for (const [key] of Object.entries(dataFinder(field))) {
			test(key, `Must not be Negative`, () => {
				enforce(get(data, key)).oneOf(
					enforce.greaterThan(-1),
					enforce.isNullish()
				);
			});
		}
	});

	test(`${OVERVIEW}.${KEY_LOCATION}`, `Invalid Key Location`, () => {
		enforce(get(data, `${OVERVIEW}.${KEY_LOCATION}`)).oneOf(
			enforce.shorterThan(81),
			enforce.isNullish()
		);
	});

	test(`${OVERVIEW}.${LOCK_BOX_CODE}`, `Invalid Lock Box Code`, () => {
		enforce(get(data, `${OVERVIEW}.${LOCK_BOX_CODE}`)).oneOf(
			enforce.shorterThan(31),
			enforce.isNullish()
		);
	});

	test(
		`${OVERVIEW}.${FREE_FORM_DOCUMENTATION}`,
		`Invalid Free Form Documentation`,
		() => {
			enforce(get(data, `${OVERVIEW}.${FREE_FORM_DOCUMENTATION}`)).oneOf(
				enforce.shorterThan(500),
				enforce.isNullish()
			);
		}
	);

	test(
		`${OVERVIEW}.${SERIAL_NUMBER_VERIFIED}`,
		`Serial Number Verification Required`,
		() => {
			enforce(get(data, `${OVERVIEW}.${SERIAL_NUMBER_VERIFIED}`)).oneOf(
				enforce.isTruthy()
			);
		}
	);

	test(
		`${OVERVIEW}.${HUD_NUMBER_VERIFIED}`,
		`Hud Number Verification Required`,
		() => {
			enforce(get(data, `${OVERVIEW}.${HUD_NUMBER_VERIFIED}`)).oneOf(
				enforce.isTruthy()
			);
		}
	);
	test(
		`${OVERVIEW}.${SKIP_REASON}`,
		`Required if Skipping Condition Report`,
		() => {
			const skip = get(data, `${OVERVIEW}.${SKIP_CONDITION_REPORT}`);
			if (skip) {
				enforce(get(data, `${OVERVIEW}.${SKIP_REASON}`)).oneOf(
					enforce.greaterThan(0)
				);
			}
		}
	);
});
