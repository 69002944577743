import { gatewayApi } from "@/ReduxToolkit";
import { concatErrorCache } from "@/ReduxToolkit/GatewayApi";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const createLookupEndpoint = (lookup) => (builder) =>
	builder.query({
		query: () => ({
			url: `api/LookupData/${lookup}`,
			method: "GET",
		}),
		transformResponse: (response) => {
			return Object.fromEntries(
				response
					.sort((a, b) => {
						if (a.description > b.description) return 1;
						if (b.description > a.description) return -1;

						return 0;
					})
					.map((lookup) => [lookup.id, lookup.description])
			);
		},
		providesTags: (result, error, arg) => {
			if (error) {
				return concatErrorCache([`${lookup}`], error);
			}
			return [`${lookup}`];
		},
	});

export const lookupTypes = [
	"LoanTypes",
	"FoundationTypes",
	"RemarketingStatuses",
	"BSeals",
	"ParkCodes",
	"AssetLocations",
	"RoofTypes",
	"SidingTypes",
	"MediaTypes",
	"UserTypes",
	"PaymentAllocationPurposes",
	"DocumentCabinets",
	"DocumentSubtypes",
	"IdentificationTypeInformation",
];

export const lookupEndpoints = (builder) =>
	Object.fromEntries(
		lookupTypes.map((type) => [
			`get${type}`,
			createLookupEndpoint(type)(builder),
		])
	);

gatewayApi.injectEndpoints({
	endpoints: (builder) => ({ ...lookupEndpoints(builder) }),
	overrideExisting: true,
});

gatewayApi.enhanceEndpoints({
	addTagTypes: [...lookupTypes],
});

const { endpoints, ...gateway } = gatewayApi;

export const {
	useGetRemarketingStatusesQuery,
	useGetLoanTypesQuery,
	useGetFoundationTypesQuery,
	useGetRoofTypesQuery,
	useGetSidingTypesQuery,
	useGetAssetLocationsQuery,
	useGetBSealsQuery,
	useGetParkCodesQuery,
	useGetMediaTypesQuery,
	useGetUserTypesQuery,
	useGetDocumentReasonsQuery,
	useGetPaymentAllocationPurposesQuery,
	useGetDocumentCabinetsQuery,
	useGetDocumentSubtypesQuery,
	useGetIdentificationTypeInformationQuery,
} = gateway;

export const createLookupSelector = (lookup) => {
	const lookupSelectResult = endpoints[`get${lookup}`].select();

	const selector = createSelector(
		lookupSelectResult,
		(result) => result?.data ?? {}
	);

	return selector;
};

export const {
	useSelectRemarketingStatuses,
	useSelectLoanTypes,
	useSelectFoundationTypes,
	useSelectBSeals,
	useSelectParkCodes,
	useSelectAssetLocations,
	useSelectRoofTypes,
	useSelectSidingTypes,
	useSelectMediaTypes,
	useSelectUserTypes,
	useSelectDocumentCabinets,
	useSelectDocumentSubtypes,
	useSelectIdentificationTypeInformation,
} = Object.fromEntries(
	lookupTypes.map((lookup) => {
		return [
			`useSelect${lookup}`,
			() => useSelector(createLookupSelector(lookup)),
		];
	})
);

export const prefetchLookups = (dispatch) =>
	lookupTypes.forEach((type) => {
		dispatch(
			gatewayApi.util.prefetch(`get${type}`, undefined, {
				force: true,
			})
		);
	});
