import { ControlledCheckbox, ControlledInput } from "@/Components/Form";
import { ControlledDateInput } from "@/Components/Form/ControlledDateInput/ControlledDateInput";
import { AssetTypeSelect, MonthSelect } from "@/features/enums/EnumSelect";
import { LoanTypesSelect, RemarketingStatusesSelect } from "@/features/lookups";
import {
	ASSET_TYPE,
	BACKOUT_DATE,
	FHA_TITLE_II_FINANCING,
	FIRST_INSPECTION,
	FOLLOW_UP_DATE,
	INSPECTION_ORDERED,
	LAST_INSPECTION,
	LOAN_TYPE_ID,
	MARKET_READY_DATE,
	PROJECTED_RECOVERY_PERCENT,
	PROJECTED_SALE_MONTH,
	REMARKETING_STATUS_TYPE_ID,
	REPOSSESSION_DATE,
	RETAIL_ASKING_PRICE,
	SAFE_TO_SHOW,
	VMF_FACILITATION,
	WINTERIZED_DATE,
} from "@/features/asset/constants";

export const BookingConfig = [
	{
		component: ControlledDateInput,
		props: {
			name: BACKOUT_DATE.path,
			label: BACKOUT_DATE.label,
			type: "date",
			readOnly: true,
		},
	},
	{
		component: ControlledCheckbox,
		props: {
			name: FHA_TITLE_II_FINANCING.path,
			label: FHA_TITLE_II_FINANCING.label,
		},
	},
	{
		component: ControlledDateInput,
		props: {
			name: FIRST_INSPECTION.path,
			label: FIRST_INSPECTION.label,
			type: "date",
		},
	},
	{
		component: ControlledDateInput,
		props: {
			name: LAST_INSPECTION.path,
			label: LAST_INSPECTION.label,
			type: "date",
		},
	},
	{
		component: ControlledDateInput,
		props: {
			name: INSPECTION_ORDERED.path,
			label: INSPECTION_ORDERED.label,
			type: "date",
		},
	},
	{
		component: ControlledDateInput,
		props: {
			name: FOLLOW_UP_DATE.path,
			label: FOLLOW_UP_DATE.label,
			type: "date",
		},
	},
	{
		component: ControlledCheckbox,
		props: {
			name: SAFE_TO_SHOW.path,
			label: SAFE_TO_SHOW.label,
		},
	},
	{
		component: LoanTypesSelect,
		props: {
			name: LOAN_TYPE_ID.path,
			labels: { label: LOAN_TYPE_ID.label },
			readOnly: true,
			disabled: true,
		},
	},
	{
		component: RemarketingStatusesSelect,
		props: {
			name: REMARKETING_STATUS_TYPE_ID.path,
			labels: { label: REMARKETING_STATUS_TYPE_ID.label },
		},
	},
	{
		component: ControlledInput,
		props: {
			name: PROJECTED_RECOVERY_PERCENT.path,
			label: PROJECTED_RECOVERY_PERCENT.label,
			type: "number",
			fixedTextRight: "%",
		},
	},
	{
		component: MonthSelect,
		props: {
			name: PROJECTED_SALE_MONTH.path,
			labels: { label: PROJECTED_SALE_MONTH.label },
		},
	},
	{
		component: ControlledDateInput,
		props: {
			name: REPOSSESSION_DATE.path,
			label: REPOSSESSION_DATE.label,
			type: "date",
		},
	},
	{
		component: ControlledInput,
		props: {
			name: RETAIL_ASKING_PRICE.path,
			label: RETAIL_ASKING_PRICE.label,
			type: "number",
			fixedTextLeft: "$",
		},
	},
	{
		component: ControlledDateInput,
		props: {
			name: MARKET_READY_DATE.path,
			label: MARKET_READY_DATE.label,
			type: "date",
		},
	},
	{
		component: AssetTypeSelect,
		props: {
			name: ASSET_TYPE.path,
			labels: { label: ASSET_TYPE.label },
		},
	},
	{
		component: ControlledDateInput,
		props: {
			name: WINTERIZED_DATE.path,
			label: WINTERIZED_DATE.label,
			type: "date",
		},
	},
	{
		component: ControlledCheckbox,
		props: {
			name: VMF_FACILITATION.path,
			defaultValue: false,
			label: VMF_FACILITATION.label,
		},
	},
];
