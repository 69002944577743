import Spinner from "@/Components/UI/Spinner/Spinner";
import { Asset } from "../asset/types";
import {
	useGetEntityTypesQuery,
	useGetTasksByAssetIdQuery,
} from "./api/endpoints";
import { ErrorContainer } from "../errors/ErrorContainer";
import {
	Button,
	ButtonGroup,
	Icon,
	PageHeader,
	PageHeaderControl,
} from "@salesforce/design-system-react";
import { useTaskInfoModal } from "./components/InfoModal";
import { useGetTaskStatusTypesQuery } from "./api/endpoints";
import { useGetCurrentUserQuery } from "@/features/user/api/endpoints";
import { NewAssociatedEntity, NewTask, Task } from "./Types";
import dayjs from "dayjs";
import { SmallCard } from "./components/SmallCard";

export const AssetTasksHeader = ({
	asset,
	tasks,
}: {
	asset: Asset;
	tasks: Task[];
}) => {
	const { data: user, isSuccess: isSuccessUser } = useGetCurrentUserQuery();
	const { show } = useTaskInfoModal();
	const { data, isSuccess: isSuccessStatuses } = useGetTaskStatusTypesQuery();
	const { data: entityTypes, isSuccess: isSuccessEntities } =
		useGetEntityTypesQuery();

	const isSuccess = isSuccessUser && isSuccessStatuses && isSuccessEntities;

	const onCreateTask = () => {
		const assetAssociation: NewAssociatedEntity = {
			name: `${asset.physicalLocation.address}, ${asset.physicalLocation.city} ${asset.physicalLocation.state}, ${asset.physicalLocation.zip}`,
			entityTypeId:
				entityTypes?.find((item) => item.name === "Asset")?.id ?? "",
			entityId: asset.id,
			activeDate: dayjs().utc(true).toISOString(),
			createdBy: user?.id ?? "",
			createdDate: dayjs().utc(true).toISOString(),
		};

		const task: NewTask = {
			associatedEntities: [assetAssociation],
			statusTypeId: data?.find((item) => item.name === "New")?.id ?? "",
			priority: 1,
			dueDate: dayjs().utc(true).add(1, "day").toISOString(),
			createdBy: user?.id ?? "",
			createdDate: dayjs().utc(true).toISOString(),
			updatedDate: dayjs().utc(true).toISOString(),
			modifiedBy: user?.id ?? "",
			comments: [],
		};

		show({ task });
	};

	const actions = () => (
		<PageHeaderControl>
			<ButtonGroup
				className="slds-align-middle"
				variant="list"
				id="button-group-page-header-actions"
			>
				<Button disabled={!isSuccess} label="Create" onClick={onCreateTask} />
			</ButtonGroup>
		</PageHeaderControl>
	);

	return (
		<PageHeader
			icon={
				<Icon
					assistiveText={{
						label: "Tasks",
					}}
					category="standard"
					name="task"
					style={{
						backgroundColor: "#009688",
						fill: "#ffffff",
					}}
					title="Tasks"
				/>
			}
			info={`${tasks.length ?? 0} items`}
			onRenderActions={actions}
			title="Tasks"
			truncate
			variant="object-home"
		/>
	);
};

export const AssetTasks = ({ asset }: { asset: Asset }) => {
	const {
		data: tasks,
		isLoading,
		isError,
		error,
	} = useGetTasksByAssetIdQuery(asset.id);

	if (isLoading) {
		return <Spinner />;
	}
	if (isError) {
		return <ErrorContainer error={error} />;
	}

	if (!tasks) {
		return <p>No tasks found for this asset</p>;
	}
	return (
		<div>
			<AssetTasksHeader asset={asset} tasks={tasks} />
			<div style={{ marginTop: "0.5rem", display: "grid", gap: "0.5rem" }}>
				{[...tasks]
					.sort(
						(a, b) =>
							dayjs(b.updatedDate || 0).unix() -
							dayjs(a.updatedDate || 0).unix()
					)
					.map((task) => {
						return <SmallCard task={task} key={task.id} />;
					})}
			</div>
		</div>
	);
};
