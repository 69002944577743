import { PRICING_WORKSHEET_APPROVAL_STATUS } from "@/features/asset/constants";
import { useFeature } from "@/providers/feature-flags/FeatureProvider";
import { PRICING_WORKSHEET_FEATURE_FLAG } from "@/providers/feature-flags/constants";

export const usePricingWorksheetFeatureFilter = () => {
	const isEnabledPricingWorksheet = useFeature(PRICING_WORKSHEET_FEATURE_FLAG);
	return (key: string) =>
		!(
			key === PRICING_WORKSHEET_APPROVAL_STATUS.key &&
			!isEnabledPricingWorksheet
		);
};
