import { useSelector } from "react-redux";
import {
	selectRoute,
	selectTripDuration,
	selectTripMileage,
} from "../../store/tripPlanningSlice";
import classes from "./Map.module.scss";
import { Points } from "./Points";
import { RiPinDistanceLine } from "react-icons/ri";
import { BiTime } from "react-icons/bi";
import { MdAirlineStops } from "react-icons/md";

const Hours = () => {
	const hours = useSelector(selectTripDuration);
	const [hour, minutes] = hours.split(":");
	return (
		<div className={classes.rowStyle}>
			<BiTime />
			<p style={{ paddingLeft: ".1rem" }}>
				{hour} hrs {minutes} min
			</p>
		</div>
	);
};

const Stops = () => {
	const stops = useSelector(selectRoute);
	return (
		<div className={classes.rowStyle}>
			<MdAirlineStops />
			<p style={{ paddingLeft: ".1rem" }}>{stops.length} Locations</p>
		</div>
	);
};
const Miles = () => {
	const miles = useSelector(selectTripMileage);
	return (
		<div className={classes.rowStyle}>
			<RiPinDistanceLine />
			<p style={{ paddingLeft: ".1rem" }}>{miles} miles</p>
		</div>
	);
};

export const Map = () => {
	return (
		<div>
			<div className={classes.info}>
				<div className={classes.infoContainer}>
					<Stops />
					<Miles />
					<Hours />
				</div>
			</div>
			<div className={classes.mapContainer} id="trimbleMap" />
			<Points />
		</div>
	);
};
