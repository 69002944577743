export const STORAGE_KEY = "roles";
export const STORAGE_KEY_DISABLED = "rolesDisabled";

export const ASSET_MANAGEMENT = "Asset_Management";
export const BOOKING = "Booking";
export const ACCOUNTS_PAYABLE = "Accounts_Payable";
export const TITLING = "Titling";
export const MANAGEMENT = "Management";
export const SALES_MARKETING = "Sales_Marketing";
export const ADMIN = "Admin";
export const AUDIT_MISC = "Audit_Misc";
export const UAT = "UAT";
export const ROLES = [
	ASSET_MANAGEMENT,
	BOOKING,
	ACCOUNTS_PAYABLE,
	TITLING,
	MANAGEMENT,
	SALES_MARKETING,
	ADMIN,
	AUDIT_MISC,
	UAT,
];
