import { useCreateSaleMutation } from "../api/endpoints";
import { CreateSalePayload } from "../types";
import dayjs from "dayjs";
import { useAssetDetails } from "@/features/asset-details-tab/api/useAssetDetails";
import { useState } from "react";

export const useSetupSale = (assetId: string) => {
	const [isSettingUpSaleInfo, setIsSettingUpSaleInfo] =
		useState<boolean>(false);
	const [createSale] = useCreateSaleMutation();
	const { data: asset, update } = useAssetDetails(assetId);
	const newSaleInfo: CreateSalePayload = {
		saleDate: dayjs().toISOString(),
		totalAmount: 0.0,
	};

	// Function that will create a sale and add its id to the dispositions object on the home.
	const trigger = async () => {
		setIsSettingUpSaleInfo(true);
		const sale = await createSale(newSaleInfo);
		if ("error" in sale) {
			setIsSettingUpSaleInfo(false);
			return { isError: true, error: sale.error };
		}
		const submission = {
			...asset,
			dispositions: [
				...asset?.dispositions,
				{
					saleId: sale.data.id,
					homeId: assetId,
				},
			],
		};
		await update(submission);
		setIsSettingUpSaleInfo(false);
		return {
			isError: false,
			error: undefined,
		};
	};

	return { trigger, isSettingUpSaleInfo };
};
