import { ControlledSelect } from "@/Components/Form";
import { useSelector } from "react-redux";
import { lookupTypes, createLookupSelector } from "../api/lookupEndpoints";

const createLookupSelect = (lookup) => (props) => {
	const lookupObject = useSelector(createLookupSelector(lookup));

	const options = Object.entries(lookupObject).map(([id, description]) => ({
		label: description,
		value: id,
	}));

	return <ControlledSelect options={options} {...props} />;
};

const LookupSelect = lookupTypes.reduce(
	(acc, lookup) => ({
		...acc,
		[`${lookup}Select`]: createLookupSelect(lookup),
	}),
	{}
);

export const {
	RemarketingStatusesSelect,
	LoanTypesSelect,
	AssetLocationsSelect,
	FoundationTypesSelect,
	BSealsSelect,
	ParkCodesSelect,
	RoofTypesSelect,
	SidingTypesSelect,
	MediaTypesSelect,
	UserTypesSelect,
} = LookupSelect;
