import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isBefore from "dayjs/plugin/isSameOrBefore";
import isAfter from "dayjs/plugin/isSameOrAfter";
import { get } from "lodash";
import { startsWith } from "lodash";
import { endsWith } from "lodash";
dayjs.extend(isBetween);
dayjs.extend(isBefore);
dayjs.extend(isAfter);

const format = (val) => val.toString().toLowerCase();

const greaterThan = (a, b) => a > b;
const lessThan = (a, b) => a < b;
const greaterThanOrEqual = (a, b) => a >= b;
const lessThanOrEqual = (a, b) => a <= b;
const isEqual = (a, b) => a === b;
const between = (value, [a, b]) => a <= value <= b;
const isNotEqual = (a, b) => a !== b;

const includes = (array, value) => array.includes(value);
const doesNotInclude = (array, value) => !array.includes(value);
const doesNotEndWith = (a, b) => !a.endsWith(b);
const doesNotStartWith = (a, b) => !a.startsWith(b);

const caseInsensitiveContains = (a, b) => format(a).includes(format(b));
const caseInsensitiveNotContains = (a, b) => !format(a).includes(format(b));
const caseInsensitiveStringEquals = (a, b) => format(a) === format(b);
const caseInsensitiveStringNotEquals = (a, b) => format(a) !== format(b);
const caseInsensitiveStartWith = (a, b) => format(a).startsWith(format(b));
const caseInsensitiveNotStartWith = (a, b) => !format(a).startsWith(format(b));
const caseInsensitiveEndWith = (a, b) => format(a).endsWith(format(b));

export const timePredicates = {
	today: dayjs().endOf("day"),
	beginningOfYear: dayjs().startOf("year"),
	beginningOfMonth: dayjs().startOf("month"),
	beginningOfWeek: dayjs().startOf("week")
};

const dateBefore = (a, b) => dayjs(a).isBefore(timePredicates[b] ?? dayjs(b));
const dateAfter = (a, b) => dayjs(a).isAfter(timePredicates[a] ?? dayjs(b));

const dateBetween = (a, [b, c]) =>
	dayjs(a).isBetween(
		dayjs(timePredicates[b] ?? dayjs(b)),
		dayjs(timePredicates[c] ?? dayjs(c))
	);

const includesAny = (array, valueArray) => {
	for (let value of valueArray) {
		if (array.includes(value)) return true;
	}
	return false;
};

const includesAll = (array, valueArray) => {
	for (let value of valueArray) {
		if (!array.includes(value)) return false;
	}
	return true;
};

const operations = {
	greaterThan,
	lessThan,
	greaterThanOrEqual,
	lessThanOrEqual,
	isEqual,
	between,
	includes,
	doesNotInclude,
	includesAny,
	includesAll,
	dateBetween,
	dateBefore,
	dateAfter
};

export const createFilter =
	({ property, comparison, predicate }) =>
	(data) => {
		return operations[comparison](data[property], predicate);
	};

export const combineFilters = (filtersObject) => (data) => {
	for (let filter of filtersObject) {
		if (!createFilter(filter)(data)) return false;
	}
	return true;
};

export const convertOperation = (operation) => {
	switch (operation) {
		case "==":
			return isEqual;
		case "!=":
			return isNotEqual;
		case ">":
			return greaterThan;
		case "<":
			return lessThan;
		case ">=":
			return greaterThanOrEqual;
		case "<=":
			return lessThanOrEqual;
		case "@=":
			return includes;
		case "_=":
			return startsWith;
		case "_-=":
			return endsWith;
		case "!@=":
			return doesNotInclude;
		case "!_=":
			return doesNotStartWith;
		case "!_-=":
			return doesNotEndWith;
		case "@=*":
			return caseInsensitiveContains;
		case "_=*":
			return caseInsensitiveStartWith; // case insensitive
		case "_-=*":
			return caseInsensitiveEndWith; // case insensitive
		case "==*":
			return caseInsensitiveStringEquals;
		case "!=*":
			return caseInsensitiveStringNotEquals;
		case "!@=*":
			return caseInsensitiveNotContains;
		case "!_=*":
			return caseInsensitiveNotStartWith;

		default:
			return;
	}
};

export const createFilterAssetList =
	({ propertyPath: property, operation, value }) =>
	(data) => {
		const isArray = Array.isArray(value);
		const pathValue = get(data, property);
		if (isArray && operation === "!=") {
			return !value?.includes(pathValue);
		}
		return isArray
			? value?.some((item) => {
					return convertOperation(operation)(pathValue, item);
			  })
			: convertOperation(operation)(pathValue, value);
	};

export const combineFiltersAssetList = (filtersObject) => (data) => {
	for (let filter of filtersObject) {
		if (!createFilterAssetList(filter)(data)) return false;
	}
	return true;
};

export const combineFiltersAssetListOr = (filtersObject) => (data) => {
	for (let filter of filtersObject) {
		if (createFilterAssetList(filter)(data)) return true;
	}
	return false;
};
