import { createSelector } from "@reduxjs/toolkit";

const selectMutations = () => (state) =>
	Object.values(state.gatewayApi.mutations);

export const selectPresignedUploads = () =>
	createSelector(selectMutations(), (result) =>
		result.filter(
			(item) =>
				item.endpointName === "presignedUpload" && item.status === "pending"
		)
	);
