import { useSaleId } from "../SaleContext";
import { useGetSaleByIdQuery } from "../../api/endpoints";
import { Buyer } from "../../types";
import { nanoid } from "@reduxjs/toolkit";
import {
	TbCircleLetterA,
	TbCircleLetterC,
	TbCircleLetterL,
} from "react-icons/tb";

import classes from "./BuyerCard.module.scss";
import dayjs from "dayjs";

export const BuyerCards = () => {
	const { saleId } = useSaleId();
	const { data, isSuccess } = useGetSaleByIdQuery(saleId);
	if (!isSuccess) return <></>;
	return (
		<div className={classes.container}>
			{data?.buyerInterests?.map((item) => {
				return <BuyerCard buyer={item} key={item.id ?? nanoid()} />;
			})}
		</div>
	);
};

export const BuyerCard = ({ buyer }: { buyer: Buyer }) => {
	return (
		<div className={classes.card}>
			<div className={classes.row}>
				<div>
					<p id="buyerName">{`${buyer.firstName} ${buyer.lastName}`}</p>
					<p style={{ alignSelf: "flex-end" }}>
						{formatPhoneNumber(buyer.phoneNumber1)}
					</p>
					<p>{buyer.emailAddress}</p>
					<p style={{ alignSelf: "flex-end" }}>
						<span style={{ paddingRight: "4px", fontWeight: "bold" }}>
							{buyer?.driversLicense?.state}
						</span>
						{buyer?.driversLicense?.number}
						<span style={{ paddingLeft: "4px", fontWeight: "bold" }}>
							{buyer?.driversLicense?.expiration
								? dayjs(buyer?.driversLicense.expiration).format("MM/DD/YY")
								: ""}
						</span>
					</p>

					{buyer.ssein && (
						<p>
							SSEIN: <span>{buyer.ssein}</span>
						</p>
					)}
					{buyer.dealerLicenseNumber && (
						<p>
							DLN: <span>{buyer.dealerLicenseNumber}</span>
						</p>
					)}
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						textAlign: "right",
					}}
				>
					<p>{buyer.institutionName}</p>
					<p>{`${buyer.address1}, ${buyer.city}, ${buyer.state} ${buyer.zip}`}</p>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						{buyer.isLienHolder && (
							<TbCircleLetterL
								style={{ width: 28, height: 28, color: "orange" }}
							/>
						)}
						{buyer.isActive && (
							<TbCircleLetterA
								style={{ width: 28, height: 28, color: "#0070d2" }}
							/>
						)}
						{buyer.isInCareOf && (
							<TbCircleLetterC
								style={{ width: 28, height: 28, color: "indigo" }}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const formatPhoneNumber = (phoneNumberString: string) => {
	// Remove any non-digit characters
	const cleaned = ("" + phoneNumberString).replace(/\D/g, "");

	// Check if the cleaned string matches the 10-digit phone number pattern
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		// Format the phone number
		return `(${match[1]}) ${match[2]}-${match[3]}`;
	}

	// Return the original string if it doesn't match the pattern
	return phoneNumberString;
};
