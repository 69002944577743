import { useSelector } from "react-redux";
import { selectSelectedLatLong } from "../../store/tripPlanningSlice";
import { usePrintDirectionsMutation } from "../../api/endpoints";
import { Button } from "@salesforce/design-system-react";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import {
	BsArrow90DegLeft,
	BsArrow90DegRight,
	BsArrowUpLeft,
	BsArrowUpRight,
	BsArrowUp,
} from "react-icons/bs";
import { BiMapPin } from "react-icons/bi";
import { nanoid } from "@reduxjs/toolkit";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

const iconStyle = { width: "1.5rem", height: "1.5rem" };
const gridItemStyle = {
	display: "flex",
	alignItems: "center",
};

const turnInstructions = {
	TC_Straight: <BsArrowUp style={iconStyle} />,
	TC_Right: <BsArrow90DegRight style={iconStyle} />,
	TC_Left: <BsArrow90DegLeft style={iconStyle} />,
	TC_BearRight: <BsArrowUpRight style={iconStyle} />,
	TC_BearLeft: <BsArrowUpLeft style={iconStyle} />,
	TC_SharpLeft: <BsArrow90DegLeft style={iconStyle} />,
	TC_SharpRight: <BsArrow90DegRight style={iconStyle} />,
	TC_MergeLeft: <BsArrowUpLeft style={iconStyle} />,
	TC_MergeRight: <BsArrowUpRight style={iconStyle} />,
};

const Origin = ({ origin }) => {
	return (
		<div
			style={{
				display: "grid",
				gridTemplateColumns: ".5fr 5fr",
				padding: ".25rem .5rem",
			}}
		>
			<div style={gridItemStyle}>
				<BiMapPin style={iconStyle} />
			</div>
			<div style={gridItemStyle}>
				<p>
					Start at {origin.streetAddress}, {origin.city}, {origin.state},{" "}
					{origin.zip}
				</p>
			</div>
		</div>
	);
};

const Destination = ({ destination }) => {
	return (
		<div
			style={{
				display: "grid",
				gridTemplateColumns: ".5fr 5fr",
				padding: ".25rem .5rem",
			}}
		>
			<div style={gridItemStyle}>
				<BiMapPin style={iconStyle} />
			</div>
			<div style={gridItemStyle}>
				<p>
					Arrive at {destination.streetAddress}, {destination.city},{" "}
					{destination.state}, {destination.zip}
				</p>
			</div>
		</div>
	);
};

const EXCLUSION_LIST = [
	"Trimble Inc",
	"All drive times are approximate",
	"'~5 mins' indicates you should be on this segment for 'about 5 minutes'",
	"This suggested route is based on various data sources and may be incomplete",
	"inaccurate in some cases.  The user assumes full liability for any delay, loss",
	"or damage which may occur as a result of its use.  Please obey local traffic laws.",
];

const Directions = ({ directions }) => {
	const filteredDirections = directions.filter((item) => {
		const direction = item.direction;
		for (const exclusion of EXCLUSION_LIST) {
			if (direction.includes(exclusion)) {
				return false;
			}
		}
		return true;
	});
	return (
		<>
			{filteredDirections.map((item, index) => (
				<div
					style={{
						display: "grid",
						gridTemplateColumns: ".5fr .5fr 5fr",
						padding: ".25rem .5rem",
					}}
					key={nanoid()}
				>
					<div style={gridItemStyle}>
						{item.turnInstruction
							? turnInstructions[item.turnInstruction]
							: turnInstructions["TC_Straight"]}
					</div>
					<div style={gridItemStyle}>{index + 1}.</div>
					<div style={gridItemStyle}>{item.direction}</div>
				</div>
			))}
		</>
	);
};
/**
 *
 * @param {Array} route
 * @returns {JSX}
 */
const Route = ({ route }) => {
	const { destination, origin, routeInstructions: directions } = route;

	return (
		<div style={{ width: "480px" }}>
			<h3>
				{origin.city}, {origin.state} to {destination.city}, {destination.state}
			</h3>
			<Origin origin={origin} />
			<Directions directions={directions} />
			<Destination destination={destination} />
		</div>
	);
};

/**
 * Template for printing directions with Trimble Maps
 * @param {Array} routes
 * contains a list of route objects:
 * {
 * 	destination: {streetAddress, city, state, zip},
 *  origin: {streetAddress, city, state, zip},
 *  routeInstructions: [{direction, distance, turnInstruction}]
 * }
 * @returns {JSX}
 */
const Template = ({ routes }) => {
	const startingLocation = routes[0]?.origin;
	const endingLocation = routes[routes.length - 1]?.destination;
	return (
		<>
			<div style={{ backgroundColor: "white", padding: "16px" }}>
				<h1>Trip Directions</h1>

				<h2>Destinations</h2>
				<div>
					<ol>
						{routes.map((route, index, routes) => {
							return (
								<>
									<li style={{ marginBottom: ".5rem" }}>
										{index === 0 && (
											<div style={{ fontWeight: "bold" }}>
												Starting Location
											</div>
										)}
										<div>{route.origin.streetAddress}</div>
										<div>
											{route.origin.city}, {route.origin.state}{" "}
											{route.origin.zip}
										</div>
									</li>
									{index === routes.length - 1 && (
										<li>
											<div>
												<div style={{ fontWeight: "bold" }}>
													Ending Location
												</div>
												<div>{route.destination.streetAddress}</div>
												<div>
													{route.destination.city}, {route.destination.state}{" "}
													{route.destination.zip}
												</div>
											</div>
										</li>
									)}
								</>
							);
						})}
					</ol>
				</div>
				<div style={{ fontSize: "1.5rem" }}>
					{startingLocation?.city}, {startingLocation?.state} to{" "}
					{endingLocation?.city}, {endingLocation?.state}
				</div>
				{routes.map((item, index) => (
					<div key={`route-${index}`}>
						<Route route={item} />
					</div>
				))}
			</div>
		</>
	);
};

export const PrintDirections = NiceModal.create(({ data }) => {
	const frameRef = useRef();
	const container = frameRef?.current?.contentWindow?.document?.body;
	const modal = useModal();

	useEffect(() => {
		modal.resolve(frameRef.current.contentWindow);
	}, [modal]);

	return (
		<iframe
			ref={frameRef}
			style={{ display: "none" }}
			title="directions-iframe"
			id="directions-iframe"
		>
			{container && createPortal(<Template routes={data.routes} />, container)}
		</iframe>
	);
});

NiceModal.register("print-directions", PrintDirections);

export const PrintDirectionsButton = () => {
	const locations = useSelector(selectSelectedLatLong);
	const [trigger] = usePrintDirectionsMutation();
	const directions = useModal("print-directions");

	const handlePrintDirections = async () => {
		const { data } = await trigger({ stops: locations });
		const frame = await directions.show({ data });
		frame.focus();
		frame.print();
		directions.remove();
	};

	return (
		<Button
			assistiveText={{ icon: "Print Directions" }}
			iconCategory="utility"
			iconName="print"
			iconVariant="border-filled"
			variant="icon"
			onClick={handlePrintDirections}
			disabled={locations?.length < 1}
		/>
	);
};
