import {
	offlineAssetDB,
	offlineAssetEditsDB,
	offlineConditionReportUploadsDB,
	offlineDocumentationDB,
	offlineMediaUploadsDB,
} from "@/storage";

export const getPendingAssetDetailsEndpoint = {
	queryFn: async () => {
		const details = await offlineAssetEditsDB.keys();

		return { data: details };
	},
	providesTags: ["PendingAssetDetails"],
};

export const getPendingMediaUploadsEndpoint = {
	queryFn: async () => {
		const assets = await offlineAssetDB.keys();
		const mediaKeys = await offlineMediaUploadsDB.keys();
		const media = await Promise.all(
			mediaKeys.map(async (key) => await offlineMediaUploadsDB.getItem(key))
		);

		const mediaUploadsByAsset = assets.filter(
			(assetId) => media.filter((media) => media.assetId === assetId).length > 0
		);
		return { data: mediaUploadsByAsset };
	},
	providesTags: ["PendingMediaUploads"],
};

export const getPendingConditionReportEndpoint = {
	queryFn: async () => {
		const condition = await offlineConditionReportUploadsDB.keys();

		return { data: condition };
	},
	providesTags: ["PendingConditionReport"],
};

export const getPendingDocumentationEndpoint = {
	queryFn: async () => {
		const assets = await offlineAssetDB.keys();

		const offlineAssetCache = await caches.open("offline-asset");

		const documentationKeys = await offlineDocumentationDB.keys();

		const documentation = await Promise.all(
			documentationKeys.map(async (key) => {
				const item = await offlineDocumentationDB.getItem(key);
				return item.accountId;
			})
		);

		const documentationAssetIds = await Promise.all(
			assets.map(async (assetId) => {
				const keys = await offlineAssetCache.keys();

				const match = keys.find((key) => key.url.includes(assetId));

				const cachedResponse = await offlineAssetCache.match(match);

				const asset = await cachedResponse?.clone()?.json();

				return { assetId, accountId: asset?.account?.id };
			})
		).then((ids) =>
			ids
				.filter((id) => documentation.includes(id?.accountId))
				.map((id) => id?.assetId)
		);

		return {
			data: documentationAssetIds,
		};
	},
	providesTags: (result) => {
		return ["PendingDocumentation"];
	},
};
