import { ToastContainer, Toast, Button } from "@salesforce/design-system-react";
import classes from "./ServiceWorkerToast.module.scss";
import React from "react";

export const ServiceWorkerToast = ({ display = "none", ...props }) => {
	return (
		<div>
			<ToastContainer className={classes.container}>
				<Toast
					className={classes.container}
					labels={{
						heading: "Exciting changes have happened since you were here last!",
						details: [
							"Please wait a moment while we update HomeBase for you  ",
							<Button
								className={classes.updateButton}
								onClick={() => window.location.reload()}
								id="service-worker-toast-accept-update"
							>
								Update Now
							</Button>,
						],
					}}
					onRequestClose={() => props.closeToast()}
				/>
			</ToastContainer>
		</div>
	);
};
