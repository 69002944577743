import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const PrintModal = NiceModal.create(({ template }: { template: ReactNode }) => {
	const frameRef = useRef<HTMLIFrameElement | null>(null);
	const container = frameRef?.current?.contentWindow?.document?.body;
	const modal = useModal();

	useEffect(() => {
		if (frameRef.current !== null && container && template) {
			modal.resolve(frameRef.current);
		}
	}, [container, modal, template]);

	return (
		<iframe
			ref={frameRef}
			style={{ display: "none" }}
			title="print-iframe"
			id="print-iframe"
		>
			{container && createPortal(template, container)}
		</iframe>
	);
});

NiceModal.register("print", PrintModal);

export const usePrint = ({ template }: { template: ReactNode }) => {
	const printFrame = useModal("print");
	return async () => {
		const frame = (await printFrame.show({ template })) as HTMLIFrameElement;
		frame?.focus();
		frame?.contentWindow?.print();
		printFrame.remove();
	};
};
