import { each, enforce, test, omitWhen } from "vest";
import { get } from "lodash";
import {
	ACREAGE,
	ADDRESS,
	ADDRESS_2,
	ASSET_LOCATION_TYPE_ID,
	CITY,
	CONTACT_NAME,
	CONTACT_PERSON_TYPE,
	CONTACT_PHONE,
	COUNTY,
	DEALER_NUMBER,
	LAND_OWNER_NAME,
	LAND_OWNER_PHONE_NUMBER,
	LAND_RENT_AMOUNT,
	PARK_RENT_AMOUNT,
	SALES_CENTER,
	STATE,
	HOME_STAYS_ON_LAND,
	ZIP,
	LONGITUDE,
	LATITUDE,
} from "@/features/asset/constants";

import { store } from "@/ReduxToolkit/store";
import { createLookupSelector } from "@/features/lookups";
import { contactPersonType, states } from "@/Utilities/enums";
import { SPECIAL_INSTRUCTIONS } from "./constants";

export const locationValidation = (data = {}) => {
	const assetLocationId = get(data, ASSET_LOCATION_TYPE_ID.path);
	const locations = createLookupSelector("AssetLocations")(store.getState());

	const isPrivateProperty = locations[assetLocationId] === "Private Property";
	const isSalesCenter = /sales center/.test(locations[assetLocationId]);
	const isCommunitiesParks = locations[assetLocationId] === "Communities/Parks";

	each(
		[
			ADDRESS.path,
			ADDRESS_2.path,
			CITY.path,
			COUNTY.path,
			STATE.path,
			CONTACT_NAME.path,
		],
		(field) => {
			test(field, "Must be a string", () => {
				enforce(get(data, field)).oneOf(enforce.isString(), enforce.isNull());
			});
		}
	);

	test(ASSET_LOCATION_TYPE_ID.path, "Must be valid asset location", () => {
		enforce(get(data, ASSET_LOCATION_TYPE_ID.path)).oneOf(
			enforce.isUUID(),
			enforce.isNull()
		);
	});

	test(CONTACT_PERSON_TYPE.path, "Invalid Contact Person Type", () => {
		enforce(get(data, CONTACT_PERSON_TYPE.path)).oneOf(
			enforce.isNumber().isBetween(0, contactPersonType.length - 1),
			enforce.isNull()
		);
	});

	test(ZIP.path, "Must be a valid Zip Code (#####)", () => {
		enforce(get(data, ZIP.path)).oneOf(enforce.isZipCode(), enforce.isNull());
	});

	test(STATE.path, "Must be a valid state", () => {
		enforce(get(data, STATE.path)).oneOf(
			enforce.inside(Object.keys(states)),
			enforce.isNull()
		);
	});

	each([ACREAGE.path, DEALER_NUMBER.path], (field) => {
		test(field, "Must be a number", () => {
			enforce(get(data, field)).oneOf(enforce.isNumber(), enforce.isNull());
		});
	});

	each([LATITUDE.path, LONGITUDE.path], (field) => {
		test(field, "Must be a number", () => {
			enforce(get(data, field)).oneOf(enforce.isNumeric(), enforce.isNull());
		});
	});

	test(ACREAGE.path, "Must be a number", () => {
		enforce(get(data, ACREAGE.path)).oneOf(
			enforce.isNumber(),
			enforce.isNull()
		);
	});

	test(HOME_STAYS_ON_LAND.path, "Must be true or false", () => {
		enforce(get(data, HOME_STAYS_ON_LAND.path)).oneOf(
			enforce.isBoolean(),
			enforce.isNull()
		);
	});

	test(
		CONTACT_PHONE.path,
		"Must be a 10 digit phone number (##########)",
		() => {
			enforce(get(data, CONTACT_PHONE.path)).oneOf(
				enforce.isNull(),
				enforce.isNumeric()?.lengthEquals(10)
			);
		}
	);

	test(SPECIAL_INSTRUCTIONS, "Must be a string", () => {
		enforce(get(data, SPECIAL_INSTRUCTIONS)).isArrayOf(
			enforce.loose({
				note: enforce.oneOf(enforce.isString(), enforce.isNull()),
			})
		);
	});

	omitWhen(!assetLocationId || !isPrivateProperty, () => {
		test(
			LAND_OWNER_PHONE_NUMBER.path,
			"Must be a 10 digit phone number (##########)",
			() => {
				enforce(get(data, LAND_OWNER_PHONE_NUMBER.path)).anyOf(
					enforce.isNumeric().lengthEquals(10),
					enforce.isEmpty(),
					enforce.isNull()
				);
			}
		);

		test(LAND_OWNER_NAME.path, "Must be a string", () => {
			enforce(get(data, LAND_OWNER_NAME.path)).oneOf(
				enforce.isString(),
				enforce.isNull()
			);
		});

		test(LAND_RENT_AMOUNT.path, "Must be less than $10 Million", () => {
			enforce(get(data, LAND_RENT_AMOUNT.path)).oneOf(
				enforce.isNumber().lessThan(10000000),
				enforce.isNull()
			);
		});
	});

	omitWhen(!assetLocationId || !isSalesCenter, () => {
		test(SALES_CENTER.path, "Must be a string", () => {
			enforce(get(data, SALES_CENTER.path)).oneOf(
				enforce.isString(),
				enforce.isNull()
			);
		});
	});

	omitWhen(!assetLocationId || !isCommunitiesParks, () => {
		test(PARK_RENT_AMOUNT.path, "Must be less than $10 Million", () => {
			enforce(get(data, PARK_RENT_AMOUNT.path)).oneOf(
				enforce.isNumber().lessThan(10000000),
				enforce.isNull()
			);
		});
	});
};
