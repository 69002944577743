import { useMediaQuery } from "react-responsive";

// more information and examples are available here: https://github.com/contra/react-responsive

export function useIsMobile() {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	return isMobile;
}

export function Mobile({ children }) {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	return isMobile && children;
}

export function Desktop({ children }) {
	const isDesktop = useMediaQuery({ minWidth: 767 });
	return isDesktop && children;
}
export function LargeDesktop({ children }) {
	const isLargeDesktop = useMediaQuery({ minWidth: 1080 });
	return isLargeDesktop && children;
}
