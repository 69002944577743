import { ControlledInput as Input } from "@/Components/Form";
import { useFormSection } from "@/Components/Form/ControlledForm/FormSection";
import { InputIcon } from "@salesforce/design-system-react";
import { useFormContext } from "react-hook-form";
import { LATITUDE, LONGITUDE } from "@/features/asset/constants";

export const Coordinates = () => {
	const { setValue, setError, clearErrors } = useFormContext();
	const { isStatic } = useFormSection();

	/*
		Function to get the users position using navigator.geolocation
		If Succesful, should set latitude, longitude and clear any errors (if existing)
		If Unsuccesful, should set error state on the two fields
	*/
	const getUserLocation = () => {
		const success = (position) => {
			const { latitude, longitude } = position.coords;
			setValue(LATITUDE.path, latitude, {
				shouldDirty: true,
			});
			setValue(LONGITUDE.path, longitude, {
				shouldDirty: true,
			});
			clearErrors([LATITUDE.path, LONGITUDE.path]);
		};
		const error = (error) => {
			setError(LATITUDE.path, {
				message: "Allow Homebase to access your location",
			});
			setError(LONGITUDE.path, {
				message: "Allow Homebase to access your location",
			});
		};
		navigator.geolocation.getCurrentPosition(success, error);
	};
	const staticProps = isStatic
		? {}
		: {
				readOnly: true,
				iconRight: (
					<InputIcon
						assistiveText={{
							icon: "Clear",
						}}
						name="checkin"
						category="utility"
						onClick={() => getUserLocation()}
					/>
				),
		  };
	return (
		<>
			<Input name={LATITUDE.path} label="Latitude" span="2" {...staticProps} />
			<Input
				name={LONGITUDE.path}
				label="Longitude"
				span="2"
				{...staticProps}
			/>
		</>
	);
};
