import {
	Button,
	ButtonGroup,
	Dropdown,
	Icon,
	PageHeader,
	PageHeaderControl,
} from "@salesforce/design-system-react";
import { useTaskInfoModal } from "./InfoModal";
import {
	DEFAULT_TASK_LIST_NAMES,
	TaskListOption,
	useTaskTableList,
} from "../api/useTaskTableList";
import classes from "./TasksHeader.module.scss";
import { useTaskList } from "../api/useTaskList";
import { TaskListResponse } from "../Types";
import { useGetTaskStatusTypesQuery } from "../api/endpoints";
import { useUpdateMultipleTasks } from "../api/useUpdateMultipleTasks";
import { useGetCurrentUserQuery } from "@/features/user/api/endpoints";
import { Desktop } from "@/Responsive";
import { ReactElement } from "react";
import { useUpdateTaskLists } from "../api/useUpdateTaskLists";
import { useConfirmationDialog } from "@/Components/ConfirmationDialog/ConfirmationDialog";
import favoriteStyles from "@/Components/UI/FavoriteStar/FavoriteStar.module.scss";
import { useCancelReasonModal } from "./CancelReasonModal";

export const TasksHeader = ({
	results,
	isFetching,
}: {
	results: TaskListResponse | undefined;
	isFetching: boolean;
}) => {
	const { data: user } = useGetCurrentUserQuery();
	const {
		query,
		setQuery,
		selectedTasks,
		setSelectedTasks,
		filtersOpen,
		setFiltersOpen,
	} = useTaskList();

	const { show } = useTaskInfoModal();
	const options = useTaskTableList();

	const { data } = useGetTaskStatusTypesQuery();
	const { updateTasks, isSubmitting } = useUpdateMultipleTasks();
	const cancelModal = useCancelReasonModal();
	const statusOptions =
		data?.map((item) => {
			return {
				label: `Mark as ${item.name}`,
				value: item.id,
				disabled: selectedTasks.length === 0,
			};
		}) ?? [];

	const updateSelectedTasks = (status: string) => {
		const statusName = data?.find((item) => item.id === status)?.name;
		if (statusName === "Cancelled") {
			cancelModal.show({ tasks: selectedTasks });
			setSelectedTasks([]);
			return;
		} else {
			if (user?.id) {
				const newTasks = selectedTasks.map((item) => {
					// status hasn't changed
					if (item.statusTypeId === status) {
						return item;
					}
					const isCompleteStatus =
						data?.find((item) => item.name === "Complete")?.id === status;
					return {
						...item,
						statusTypeId: status,
						updatedDate: new Date().toISOString(),
						modifiedBy: user.id,
						completedBy: isCompleteStatus ? user.id : item.completedBy,
					};
				});
				updateTasks(newTasks);
				setSelectedTasks([]);
			}
		}
	};
	const actions = () => (
		<PageHeaderControl>
			<ButtonGroup
				className="slds-align-middle"
				variant="list"
				id="button-group-page-header-actions"
			>
				<Button label="Create" onClick={() => show()} />
				<Desktop>
					<Button
						onClick={() => setFiltersOpen(!filtersOpen)}
						iconCategory="utility"
						iconName="filterList"
						iconVariant="border-filled"
						variant="icon"
					/>
				</Desktop>
				<Desktop>
					<Dropdown
						onSelect={(item: { value: string }) =>
							updateSelectedTasks(item.value)
						}
						disabled={isSubmitting}
						align="right"
						assistiveText={{ icon: "More Options" }}
						iconCategory="utility"
						iconName="down"
						iconVariant="border-filled"
						id="page-header-setup-dropdown-actions"
						options={statusOptions}
					/>
				</Desktop>
			</ButtonGroup>
		</PageHeaderControl>
	);

	const onSelectList = (selection: TaskListOption) => {
		setQuery(selection);
	};

	return (
		<PageHeader
			icon={
				<Icon
					assistiveText={{
						label: "Tasks",
					}}
					category="standard"
					name="task"
					style={{
						backgroundColor: "#009688",
						fill: "#ffffff",
					}}
					title="Tasks"
				/>
			}
			label="Tasks"
			info={
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "0.5rem",
					}}
				>
					<p>{results?.totalItems ?? 0} items</p>
					{isFetching && (
						<Icon
							className={classes.spinner}
							assistiveText={{ label: "Announcement" }}
							category="utility"
							name="spinner"
							size="x-small"
						/>
					)}
				</div>
			}
			nameSwitcherDropdown={
				<Dropdown
					width="x-small"
					align="right"
					assistiveText={{ icon: "Name Switcher" }}
					buttonClassName="slds-button_icon-small"
					buttonVariant="icon"
					iconCategory="utility"
					iconName="down"
					id="page-header-name-switcher-dropdown"
					checkmark
					onSelect={onSelectList}
					options={options}
					value={query.name}
					className={classes.dropdown}
					listItemRenderer={ListMenu}
				/>
			}
			onRenderActions={actions}
			title={query.name}
			truncate
			variant="object-home"
		/>
	);
};

type ListMenuProps = {
	checkmark: boolean;
	data: TaskListOption;
	icon: ReactElement;
	index: number;
	inverted: boolean;
	isSelected: boolean;
	label: string;
	value: string;
};

const ListMenu = (args: ListMenuProps) => {
	const { deleteSavedList, saveDefaultList } = useUpdateTaskLists();
	const confirmationDialog = useConfirmationDialog();
	const { query, setQuery } = useTaskList();
	const lists = useTaskTableList();
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			<div title={args.isSelected ? "Currently Selected" : "Select List"}>
				{args.icon} {args.data.label}
			</div>
			<div>
				<>
					<Button
						title={args.data.isDefault ? "Default List" : "Set to default"}
						labels={{
							label: args.data.isDefault ? "Default List" : "Set to default",
						}}
						variant="icon"
						iconName={"favorite"}
						iconCategory="utility"
						className={
							args.data.isDefault
								? favoriteStyles.favorited
								: favoriteStyles.notFavorited
						}
						onClick={(e: any) => {
							e.preventDefault();
							e.stopPropagation();
							saveDefaultList(args.data.value);
							setQuery(args.data);
						}}
					></Button>
					{!DEFAULT_TASK_LIST_NAMES.includes(args.data.value) && (
						<Button
							variant="icon"
							iconCategory="utility"
							iconName="delete"
							onClick={(e: any) => {
								e.preventDefault();
								e.stopPropagation();
								confirmationDialog.show({
									title: "Remove List",
									message: `Are you sure you want to remove ${args.data.label} from your saved lists?`,
									confirmButtonText: "Remove",
									action: async () => {
										await deleteSavedList(args.data.value);
										if (query.name === args.data.value) {
											setQuery(
												lists.find((item) => item.name === "Open Tasks") ??
													lists[0]
											);
										}
									},
								});
							}}
						></Button>
					)}
				</>
			</div>
		</div>
	);
};
