import { useDispatch } from "react-redux";
import {
	addMultipleLocations,
	setLocations,
} from "../../store/tripPlanningSlice";
import classes from "./TableActions.module.scss";
import { useTripPlanning } from "../../api/useTripPlanning";
import {
	RiCheckboxMultipleBlankLine,
	RiCheckboxMultipleFill,
} from "react-icons/ri";
import { get } from "lodash";
import {
	ACCOUNT_NUMBER,
	ADDRESS,
	CITY,
	LATITUDE,
	LONGITUDE,
	STATE,
	ZIP,
} from "@/features/asset/constants";
import { Tooltip } from "@salesforce/design-system-react";

export const TableActions = () => {
	const { data } = useTripPlanning();
	const dispatch = useDispatch();
	return (
		<div className={classes.container}>
			<Tooltip content="Clear Route">
				<button onClick={() => dispatch(setLocations([]))}>
					<RiCheckboxMultipleBlankLine />
				</button>
			</Tooltip>
			<Tooltip content="Add All to Route">
				<button
					disabled={data.length > 10}
					onClick={() =>
						dispatch(
							addMultipleLocations(
								data.map((point) => {
									return {
										id: point.id,
										latitude: get(point, LATITUDE.path),
										longitude: get(point, LONGITUDE.path),
										accountNumber: get(point, ACCOUNT_NUMBER.path),
										address: get(point, ADDRESS.path),
										state: get(point, STATE.path),
										city: get(point, CITY.path),
										zip: get(point, ZIP.path),
									};
								})
							)
						)
					}
				>
					<RiCheckboxMultipleFill />
				</button>
			</Tooltip>
		</div>
	);
};
