import { states } from "@/Utilities/enums";
import { useSelectRemarketingStatuses } from "@/features/lookups";
import { useGetAllUsersQuery } from "@/features/user/api/endpoints";
import { Combobox, Input } from "@salesforce/design-system-react";
import { DateFilter } from "./DateFilter";
import {
	ACCOUNT_NUMBER,
	PRICING_WORKSHEET_APPROVAL_STATUS,
	ADDRESS,
	ASSET_MANAGER,
	ASSET_MANAGER_ID,
	ASSIGNED_DATE,
	BALANCE,
	CITY,
	CUSTOMER_NAME,
	LENGTH,
	REMARKETING_STATUS_TYPE_ID,
	STATE,
	STOCK_NUMBER,
	WIDTH,
	YEAR,
	ZIP,
	ACCOUNT_STATUS,
} from "@/features/asset/constants";

const InputFilter = ({ value, name, onChange, ...props }) => {
	return <Input {...props} name={name} value={value} onChange={onChange} />;
};

const StateFilter = ({ value, name, onChange }) => {
	const options = Object.entries(states).map(([key, value]) => {
		return {
			label: value,
			value: key,
			key: key,
			id: key,
		};
	});

	const selection = options?.filter((item) => value?.includes(item.id));
	return (
		<DropdownFilter
			name={name}
			options={options}
			selection={selection}
			onChange={onChange}
		/>
	);
};

export const AssetManagerFilter = ({ value, name, onChange }) => {
	const { data } = useGetAllUsersQuery();
	const options = data?.map((item) => {
		return {
			label: `${item.firstName} ${item.lastName} (${item.resCode})`,
			value: item.id,
			key: item.id,
			id: item.id,
			email: item.email,
		};
	});
	const selection = options?.filter((item) => value?.includes(item.id));
	return (
		<DropdownFilter
			name={name}
			options={options}
			selection={selection}
			onChange={onChange}
		/>
	);
};

export const RemarketingStatusFilter = ({ value, name, onChange }) => {
	const statuses = useSelectRemarketingStatuses();
	const options = Object.entries(statuses).map(([key, value]) => {
		return {
			label: value,
			id: key,
			key: key,
			value: key,
		};
	});

	const selection = options?.filter((item) => value?.includes(item.id));
	return (
		<DropdownFilter
			name={name}
			options={options}
			selection={selection}
			onChange={onChange}
		/>
	);
};

export const PricingWorksheetStatusFilter = ({ value, name, onChange }) => {
	const statuses = {
		InProgress: "In Progress",
		PendingApproval: "Pending Approval",
		Approved: "Approved",
	};

	const options = Object.entries(statuses).map(([key, value], index) => ({
		label: value,
		id: key,
		key: key,
		value: key,
	}));

	const selection = options?.filter((item) => value?.includes(item.id));
	return (
		<DropdownFilter
			name={name}
			options={options}
			selection={selection}
			onChange={onChange}
		/>
	);
};

const DropdownFilter = ({ name, options, selection, onChange }) => {
	return (
		<Combobox
			name={name}
			placeholder="Select"
			variant="readonly"
			value=""
			selection={selection || []}
			options={options || []}
			menuPosition="relative"
			multiple
			events={{
				onSelect: (_, { selection }) => {
					onChange(selection.map((item) => item.value));
				},
				onRequestRemoveSelectedOption: (_, { selection }) => {
					onChange(selection.map((item) => item.value));
				},
			}}
		/>
	);
};

/*
    List of valid operations for the filters
*/

export const {
	EQUALS,
	NOT_EQUALS,
	GREATER_THAN,
	LESS_THAN,
	LESS_THAN_OR_EQUAL,
	GREATER_THAN_OR_EQUAL,
	CONTAINS,
	STARTS_WITH,
	ENDS_WITH,
	NOT_CONTAIN,
	NOT_START_WITH,
	NOT_END_WITH,
	CASE_INSENSITIVE_CONTAINS,
	CASE_INSENSITIVE_STARTS_WITH,
	CASE_INSENSITIVE_ENDS_WITH,
	CASE_INSENSITIVE_EQUALS,
	CASE_INSENSITIVE_NOT_EQUALS,
	CASE_INSENSITIVE_DOESNT_CONTAIN,
	CASE_INSENSITIVE_NOT_START_WTIH,
	DATE_RANGE,
} = {
	EQUALS: {
		value: "==",
		label: "Equals",
	},
	NOT_EQUALS: {
		value: "!=",
		label: "Not Equals",
	},
	GREATER_THAN: {
		value: ">",
		label: "Greater Than",
	},
	LESS_THAN: {
		value: "<",
		label: "Less Than",
	},
	LESS_THAN_OR_EQUAL: {
		value: "<=",
		label: "Less Than or Equal",
	},
	GREATER_THAN_OR_EQUAL: {
		value: ">=",
		label: "Greater Than or Equal",
	},
	CONTAINS: {
		value: "@=",
		label: "Contains",
	},
	STARTS_WITH: {
		value: "_=",
		label: "Starts with",
	},
	ENDS_WITH: {
		value: "_-=",
		label: "Ends with",
	},
	NOT_CONTAIN: {
		value: "!@=",
		label: "Does not Contain",
	},
	NOT_START_WITH: {
		value: "!_=",
		label: "Does not Start With",
	},
	NOT_END_WITH: {
		value: "!_-=",
		label: "Does not End with",
	},
	CASE_INSENSITIVE_CONTAINS: {
		value: "@=*",
		label: "Contains",
	},
	CASE_INSENSITIVE_STARTS_WITH: {
		value: "_=*",
		label: "Starts with",
	},
	CASE_INSENSITIVE_ENDS_WITH: {
		value: "_-=*",
		label: "Ends with",
	},
	CASE_INSENSITIVE_EQUALS: {
		value: "==*",
		label: "Equals",
	},
	CASE_INSENSITIVE_NOT_EQUALS: {
		value: "!=*",
		label: "Not Equals",
	},
	CASE_INSENSITIVE_DOESNT_CONTAIN: {
		value: "!@=*",
		label: "Does Not Contain",
	},
	CASE_INSENSITIVE_NOT_START_WTIH: {
		value: "!_=*",
		label: "Does Not Start With",
	},
	DATE_RANGE: {
		value: "dateRange",
		label: "Date Range",
	},
};

export const inputOperations = [
	CASE_INSENSITIVE_CONTAINS,
	CASE_INSENSITIVE_DOESNT_CONTAIN,
	CASE_INSENSITIVE_STARTS_WITH,
	CASE_INSENSITIVE_ENDS_WITH,
	CASE_INSENSITIVE_NOT_START_WTIH,
];

/*
	Make these match media manager, plus equals operation, today 
*/
export const dateOperations = [
	{ ...LESS_THAN, label: "Before" },
	{ ...GREATER_THAN, label: "After" },
	{ ...LESS_THAN_OR_EQUAL, label: "On or Before" },
	{ ...GREATER_THAN_OR_EQUAL, label: "On or After" },
	DATE_RANGE,
];

export const noRangeDateOperations = [
	{ ...LESS_THAN, label: "Before" },
	{ ...GREATER_THAN, label: "After" },
	{ ...LESS_THAN_OR_EQUAL, label: "On or Before" },
	{ ...GREATER_THAN_OR_EQUAL, label: "On or After" },
];

export const dropdownOperations = [EQUALS, NOT_EQUALS];
export const numberOperations = [
	LESS_THAN,
	GREATER_THAN,
	GREATER_THAN_OR_EQUAL,
	LESS_THAN_OR_EQUAL,
	EQUALS,
	NOT_EQUALS,
];

export const numberAsStringOperations = [
	CASE_INSENSITIVE_CONTAINS,
	GREATER_THAN,
	LESS_THAN,
	GREATER_THAN_OR_EQUAL,
	LESS_THAN_OR_EQUAL,
];

/*
    filter properties to be used in the asset list filters
*/
export const filterProperties = {
	ASSET_MANAGER_FILTER: {
		propertyPath: ASSET_MANAGER_ID.path,
		label: ASSET_MANAGER.label,
		operations: dropdownOperations,
		valueSelect: AssetManagerFilter,
	},
	ACCOUNT_NUMBER_FILTER: {
		propertyPath: ACCOUNT_NUMBER.path,
		label: ACCOUNT_NUMBER.label,
		operations: numberAsStringOperations,
		valueSelect: InputFilter,
	},
	ACCOUNT_STATUS: {
		propertyPath: ACCOUNT_STATUS.path,
		label: ACCOUNT_STATUS.label,
		operations: dropdownOperations,
		valueSelect: InputFilter,
	},
	ADDRESS_FILTER: {
		propertyPath: ADDRESS.path,
		label: ADDRESS.label,
		operations: inputOperations,
		valueSelect: InputFilter,
	},
	ASSIGNED_DATE_FILTER: {
		propertyPath: ASSIGNED_DATE.path,
		label: ASSIGNED_DATE.label,
		operations: dateOperations,
		valueSelect: DateFilter,
	},
	BALANCE_FILTER: {
		propertyPath: BALANCE.path,
		label: BALANCE.label,
		operations: numberOperations,
		valueSelect: InputFilter,
	},
	CITY_FILTER: {
		propertyPath: CITY.path,
		label: CITY.label,
		operations: inputOperations,
		valueSelect: InputFilter,
	},
	CUSTOMER_NAME_FILTER: {
		propertyPath: CUSTOMER_NAME.path,
		label: CUSTOMER_NAME.label,
		operations: inputOperations,
		valueSelect: InputFilter,
	},
	LENGTH_FILTER: {
		propertyPath: LENGTH.path,
		label: LENGTH.label,
		operations: numberOperations,
		valueSelect: InputFilter,
	},
	PRICING_WORKSHEET_APPROVAL_STATUS_FILTER: {
		propertyPath: PRICING_WORKSHEET_APPROVAL_STATUS.path,
		label: PRICING_WORKSHEET_APPROVAL_STATUS.label,
		operations: dropdownOperations,
		valueSelect: PricingWorksheetStatusFilter,
	},
	REMARKETING_STATUS_TYPE_FILTER: {
		propertyPath: REMARKETING_STATUS_TYPE_ID.path,
		label: REMARKETING_STATUS_TYPE_ID.label,
		operations: dropdownOperations,
		valueSelect: RemarketingStatusFilter,
	},
	STATE_FILTER: {
		propertyPath: STATE.path,
		label: STATE.label,
		operations: dropdownOperations,
		valueSelect: StateFilter,
	},
	STOCK_NUMBER_FILTER: {
		propertyPath: STOCK_NUMBER.path,
		label: STOCK_NUMBER.label,
		operations: inputOperations,
		valueSelect: InputFilter,
	},
	WIDTH_FILTER: {
		propertyPath: WIDTH.path,
		label: WIDTH.label,
		operations: numberOperations,
		valueSelect: InputFilter,
	},
	YEAR_FILTER: {
		propertyPath: YEAR.path,
		label: YEAR.label,
		operations: numberAsStringOperations,
		valueSelect: InputFilter,
	},
	ZIP_CODE_FILTER: {
		propertyPath: ZIP.path,
		label: ZIP.label,
		operations: inputOperations,
		valueSelect: InputFilter,
	},
};
