import { RBI_NUMBER } from "@/features/listing/components/constants";
import { Checkbox, Input } from "@salesforce/design-system-react";
import { useController } from "react-hook-form";

export const RBINumberInput = () => {
	const { field } = useController({ name: RBI_NUMBER.path });

	return (
		<div style={{ display: "flex", width: "50%" }}>
			<Checkbox
				labels={{ label: RBI_NUMBER.label }}
				checked={field.value !== false}
				onChange={(_e: any, { checked }: { checked: boolean }) => {
					field.onChange(checked ? "RBI# 37392" : "");
				}}
			/>
			{field.value && (
				<Input value={field.value} onChange={field.onChange} disabled />
			)}
		</div>
	);
};
