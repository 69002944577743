import React from "react";
import classes from "./MediaCard.module.scss";
import { Checkbox, Dropdown } from "@salesforce/design-system-react";
import { File } from "@/Components/FileCard/FileCard";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useMediaType } from "../../api/hooks/useAllMedia";
import { useAddtoWebReady } from "../../api/hooks/useAddToWebReady";
import { useMoveWebReady } from "../../api/hooks/useMoveWebReady";
import { ALL_MEDIA, toggleSelection } from "@/ReduxToolkit/MediaSlice";
import { useSelector } from "react-redux";
import { selectMediaById } from "../../api/selectors/assetMedia";
import { forwardRef } from "react";
import { selectSelectedMedia } from "../../api/selectors";
import { memo } from "react";
import { useIsOnline } from "@/features/offline-use";
import { useMediaPreviewModal } from "../media-card-preview/MediaCardPreview";
import { useConfirmationDialog } from "@/Components/ConfirmationDialog/ConfirmationDialog";
import { useDeleteMedia } from "../../api/hooks/useDeleteMedia";
import { usePhotoSlider } from "../PhotoSizeSlider/PhotoSizeSlider";
import { round } from "lodash";

const useDropdown = (media) => {
	const { addToWebReady } = useAddtoWebReady(media?.groupingId);
	const { moveWebReady } = useMoveWebReady(media?.groupingId);

	const setAsPrimary = () => {
		if (media?.webReady) {
			moveWebReady(media, 0);
		} else {
			addToWebReady(media, 0);
		}
	};

	const confirmationDialog = useConfirmationDialog();
	const { deleteMedia } = useDeleteMedia(media?.groupingId);

	const removeOption = {
		label: "Delete Photo",
		value: () =>
			confirmationDialog.show({
				title: "Confirm Delete",
				message: "Are you sure you want to delete this item?",
				confirmButtonText: "Delete",
				action: () => deleteMedia(media.id),
			}),
	};

	const primaryOption = {
		label: "Set as Primary",
		value: setAsPrimary,
	};

	const dropDownOptions = media?.webReady
		? [removeOption, primaryOption]
		: [removeOption];

	const handleOnSelectDropdown = (e) => e.value();

	return { dropDownOptions, handleOnSelectDropdown };
};

const useSelection = ({ bucket, media }) => {
	const dispatch = useDispatch();
	const selected = useSelector(
		selectSelectedMedia({ bucket, assetId: media.groupingId })
	);

	const toggle = (mediaId) => {
		dispatch(toggleSelection({ assetId: media.groupingId, bucket, mediaId }));
	};

	return {
		isSelected: selected[media.id],
		toggleSelection: () => toggle(media.id),
	};
};

const Card = forwardRef(
	(
		{
			media: { groupingId, id },
			bucket,
			listeners,
			isDraggable,
			isDragging,
			isLoading,
		},
		ref
	) => {
		const isOnline = useIsOnline();
		const { photoSlider: thumbnailWidth } = usePhotoSlider();
		const media = useSelector((state) =>
			selectMediaById(groupingId)(state, id)
		);

		/*
			if online, this will append the thumbnail width onto the width 
		*/
		const getURLPath = () => {
			const size = round(thumbnailWidth);
			return isOnline
				? `${media?.path}&w=${size}&h=${size}`
				: `${media?.path}&offline&w=1000&h=1000`;
		};

		const { isSuccess, isPhoto } = useMediaType(media?.mediaTypeId);
		const previewModal = useMediaPreviewModal();
		// const showPreview = useShowPreview({ bucket, mediaId: media.id });
		const { dropDownOptions, handleOnSelectDropdown } = useDropdown(media);
		const { isSelected, toggleSelection } = useSelection({ bucket, media });

		const imagePreview =
			isSuccess && isPhoto ? getURLPath() : "/FigmaVideo.png";
		const dateFileCreated = dayjs(media?.dateFileCreated).format("MM/DD/YYYY");

		const cardWidth = "100%";
		const handleOpenPreview = () => {
			previewModal.show({ bucket, id: media.id });
		};

		return (
			<div style={{ minWidth: cardWidth }} className={classes.mediaCard}>
				<File
					id={`media-card-${media?.id}`}
					ref={ref}
					labels={{
						title: `${dateFileCreated}  ${media?.title ?? ""}`,
					}}
					style={{ minWidth: cardWidth }}
					crop="4-by-3"
					icon={
						<div style={{ display: "flex", justifyContent: "space-around" }}>
							<Checkbox
								className={classes.checkbox}
								checked={isSelected ?? false}
								onChange={toggleSelection}
							/>
							{bucket === ALL_MEDIA && media?.webReady && !media?.isPrimary && (
								<p
									style={{
										border: "1px solid lightgray",
										padding: "2px",
										borderRadius: "4px",
										color: "grey",
									}}
								>
									Web
								</p>
							)}
							{media?.webReady && media?.isPrimary && (
								<p
									style={{
										border: "1px solid lightgray",
										padding: "2px",
										borderRadius: "4px",
										color: "grey",
									}}
								>
									Primary
								</p>
							)}
							{media?.isPendingUpload && (
								<p
									style={{
										border: "1px solid lightgray",
										padding: "2px",
										borderRadius: "4px",
										color: "grey",
									}}
								>
									Pending
								</p>
							)}
						</div>
					}
					image={isSuccess ? imagePreview : ""}
					className={classes.card}
					onClickImage={handleOpenPreview}
					moreActionsDropdown={
						isOnline || media?.isPendingUpload ? (
							<Dropdown
								id={`more-actions-${media?.id}`}
								iconCategory="utility"
								iconName="down"
								iconVariant="bare"
								align="right"
								menuStyle={{ minWidth: "50px", width: "125px" }}
								onSelect={handleOnSelectDropdown}
								options={dropDownOptions}
								value="A0"
							/>
						) : undefined
					}
					listeners={listeners}
					isDraggable={isDraggable}
					isDragging={isDragging}
					isLoading={isLoading}
				/>
			</div>
		);
	}
);

export const MediaCard = memo(Card);
