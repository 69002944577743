import { AssetListContainer } from "@/Containers/Layout";
import { AccountNumber } from "@/features/account/AccountNumber";
import { AssetContainer } from "@/features/asset/AssetContainer";
import { InsuranceCancellationQueue } from "@/features/insurance-cancellation-queue/InsuranceCancellationQueue";
import { Notifications } from "@/features/notification-center/NotificationCenter";
import { RolesAndFlags } from "@/features/roles-and-flags/RolesAndFlags";
import { TaskPage } from "@/features/tasks/TaskPage";
import { Tasks } from "@/features/tasks/Tasks";
import { TripPlanning } from "@/features/trip-planning/TripPlanning";
import { isProd } from "@/Utilities/utils";
import { Navigate, Route, Routes } from "react-router-dom";

export const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/" Component={AssetListContainer} />
			<Route path="/assets" Component={AssetListContainer} />
			<Route path="/accountNumber/:accountNum" Component={AccountNumber} />
			<Route
				path="/account/:accountId/asset/:assetId"
				Component={AssetContainer}
			/>
			<Route path="/insuranceQueue" Component={InsuranceCancellationQueue} />
			<Route path="/notification" Component={Notifications} />
			{!isProd() && <Route path="/rolesSettings" Component={RolesAndFlags} />}
			<Route path="/trip-planning" Component={TripPlanning} />
			<Route path="/refreshToken" Component={() => <Navigate to="/" />}></Route>
			<Route path="/tasks" Component={Tasks} />
			<Route path="/tasks/:taskId" Component={TaskPage} />
		</Routes>
	);
};
