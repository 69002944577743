import { useState } from "react";
import { NewTask, Task } from "../Types";
import { useUpdateTaskMutation } from "./endpoints";

export const useUpdateMultipleTasks = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [update] = useUpdateTaskMutation();

	const updateTasks = async (tasks: Task[] | NewTask[]) => {
		setIsSubmitting(true);
		const promises = tasks.map((task) => {
			return update(task);
		});

		await Promise.allSettled(promises);
		setIsSubmitting(false);
	};

	return { updateTasks, isSubmitting };
};
