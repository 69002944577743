import {
	DataTableCell,
	Button,
	Tooltip,
} from "@salesforce/design-system-react";
import "../DesktopTable/DesktopTable.scss";
import { useNavigate, Link } from "react-router-dom";
import dayjs from "dayjs";
import { useSelectRemarketingStatuses } from "@/features/lookups";
import { FavoriteStar } from "@/Components/UI/FavoriteStar/FavoriteStar";
import { useGetAllUsersQuery } from "@/features/user/api/endpoints";
import { get } from "lodash";
import { useAssetListParams } from "../../api/useAssetListParams";
import styles from "../DesktopTable/DesktopTableView.module.scss";

export const AssetManager = ({ title, item, ...props }) => {
	const { data } = useGetAllUsersQuery();
	const manager = data?.find((manager) => manager.id === item.assetManagerId);
	const user = manager ? (
		<div
			style={{
				display: "grid",
				gridTemplateColumns: ".25fr 1fr",
				gap: ".5rem",
			}}
		>
			<span style={{ fontWeight: "bolder" }}>{manager.resCode}</span>
			<p>{` ${manager?.firstName} ${manager?.lastName}`} </p>
		</div>
	) : (
		"N/A"
	);
	return (
		<DataTableCell title={item[title]} {...props}>
			{user}
		</DataTableCell>
	);
};
AssetManager.displayName = DataTableCell.displayName;

export const AccountDataTableCell = ({ children, item, ...props }) => {
	const navigate = useNavigate();
	return (
		<DataTableCell title={item?.accountNumber} {...props}>
			<div
				className="AccountCell"
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Link to={`/account/${item?.accountId}/asset/${item?.id}?tab=details`}>
					<p>
						<Match>{children}</Match>
					</p>
				</Link>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "1fr 1fr",
						placeItems: "center",
						gap: ".1rem",
					}}
				>
					<Tooltip content="Open Media Manager">
						<Button
							iconCategory="utility"
							iconName="photo"
							iconVariant="bare"
							assistiveText={{ icon: "Media button" }}
							style={{
								color: "black",
								margin: 0,
								padding: 0,
							}}
							variant="icon"
							onClick={() =>
								navigate(
									"/account/" +
										item?.accountId +
										"/asset/" +
										item?.id +
										"?tab=media"
								)
							}
						/>
					</Tooltip>
					<FavoriteStar id={item.id} />
				</div>
			</div>
		</DataTableCell>
	);
};
AccountDataTableCell.displayName = DataTableCell.displayName;

export const DateCell = ({ children, title, ...props }) => {
	let date = dayjs(children).format("MM/DD/YYYY");

	return (
		<DataTableCell title={children.toString()} {...props}>
			<p>{date}</p>
		</DataTableCell>
	);
};
DateCell.displayName = DataTableCell.displayName;

export const StatusCell = ({ title, searchable, item, ...props }) => {
	const statuses = useSelectRemarketingStatuses();
	const value = get(item, title);

	return (
		<DataTableCell title="status" {...props}>
			{searchable ? (
				<p>
					<Match>{statuses[value]}</Match>
				</p>
			) : (
				<p>{statuses[value]}</p>
			)}
		</DataTableCell>
	);
};

export const Cell = ({ children, title, searchable, item, ...props }) => {
	return (
		<DataTableCell title={title} {...props}>
			{searchable ? (
				<p>
					<Match>{children}</Match>
				</p>
			) : (
				<p>{children}</p>
			)}
		</DataTableCell>
	);
};

Cell.displayName = DataTableCell.displayName;

export function Match({ children }) {
	const [{ q }] = useAssetListParams();
	const str = children?.toString();

	const re = new RegExp(q, "i");
	const match = str?.match(re);

	return match ? (
		<span>
			<span>{str.slice(0, match?.index)}</span>
			<span style={{ backgroundColor: "yellow" }}>{match?.[0]}</span>
			<span>{str.slice(match?.index + match?.[0]?.length)}</span>
		</span>
	) : (
		<span>{children}</span>
	);
}

StatusCell.displayName = DataTableCell.displayName;

export function MarketingPlanCell({ children, item }) {
	const options = ["", "In Progress", "Pending", "Approved"];
	const colors = ["", "#2E844A", "#C15701", "#0176D3"];
	const navigate = useNavigate();

	return (
		<DataTableCell title="Marketing Plan">
			{children > 0 && (
				<p
					className={styles.pricingWorksheetStatus}
					style={{
						color: colors[children],
						fontWeight: children < 1 ? "inherit" : "bold",
					}}
					onClick={() => {
						navigate(
							`/account/${item.accountId}/asset/${item.id}?tab=marketingPlan`
						);
					}}
				>
					{options[children]}
				</p>
			)}
		</DataTableCell>
	);
}

MarketingPlanCell.displayName = DataTableCell.displayName;
