import React from "react";
import { Tooltip, Button } from "@salesforce/design-system-react";
import { useIncludeWebReady } from "@/features/media-manager/api/hooks/useIncludeWebReady";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";

export const IncludeWebReadyButton = () => {
	const { assetId } = useAssetId();
	const [includeWebReady, toggleIncludeWebReady] = useIncludeWebReady(assetId);

	return (
		<Tooltip
			align="bottom"
			content={`${includeWebReady ? "Hide" : "Show"} Web Ready Media`}
		>
			<Button
				variant="icon"
				iconCategory="utility"
				iconName={includeWebReady ? "hide" : "preview"}
				iconVariant="border-filled"
				onClick={toggleIncludeWebReady}
			/>
		</Tooltip>
	);
};
