import {
	useGetEntityTypesQuery,
	useGetTaskByIdQuery,
	useGetTaskStatusTypesQuery,
} from "./api/endpoints";
import Spinner from "@/Components/UI/Spinner/Spinner";
import { TaskCard } from "./components/TaskCard";
import {
	Button,
	ButtonGroup,
	Icon,
	PageHeader,
	PageHeaderControl,
} from "@salesforce/design-system-react";
import { Task } from "./Types";
import { useTaskInfoModal } from "./components/InfoModal";
import { Link, useParams } from "react-router-dom";
import classes from "./TaskPage.module.scss";
import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import { wetCement } from "@/styles/colors";

export const TaskPage = () => {
	const { taskId } = useParams<{ taskId: string }>();

	const { data, isLoading } = useGetTaskByIdQuery(taskId ?? "");

	if (isLoading) {
		return (
			<div className={classes.container}>
				<Spinner />
			</div>
		);
	}

	if (!data) {
		return (
			<div className={classes.container}>
				<p>Something went wrong loading task {taskId}</p>
			</div>
		);
	}
	return (
		<div className={classes.container}>
			<TaskPageHeader task={data} />
			<TaskCard task={data} />
		</div>
	);
};

const TaskPageHeader = ({ task }: { task: Task }) => {
	const editModal = useTaskInfoModal();
	const { data, isSuccess } = useGetTaskStatusTypesQuery();

	const isCancelledOrCompleted =
		task.statusTypeId === data?.find((item) => item.name === "Cancelled")?.id ||
		task.statusTypeId === data?.find((item) => item.name === "Completed")?.id;
	const actions = () => (
		<PageHeaderControl>
			<ButtonGroup
				className="slds-align-middle"
				variant="list"
				id="button-group-page-header-actions"
			>
				<Button
					disabled={!isSuccess || isCancelledOrCompleted}
					label="Edit"
					onClick={() => editModal.show({ task })}
				/>
			</ButtonGroup>
		</PageHeaderControl>
	);

	return (
		<PageHeader
			icon={
				<Icon
					assistiveText={{
						label: "Task",
					}}
					category="standard"
					name="task"
					style={{
						backgroundColor: wetCement,
						fill: "#ffffff",
					}}
					title="Tasks"
				/>
			}
			onRenderActions={actions}
			title={task.name}
			trail={[<Trail task={task} />]}
			truncate
			variant="object-home"
		/>
	);
};

const Trail = ({ task }: { task: Task }) => {
	const { data: EntityTypes, isLoading: isLoadingEntity } =
		useGetEntityTypesQuery();

	const item = task.associatedEntities.find((item) => {
		const entityType = EntityTypes?.find(
			(type) => type.id === item.entityTypeId
		);
		return entityType?.name === "Asset" && !item.inactiveDate;
	});

	const { data: asset, isLoading: isLoadingAsset } = useGetAssetQuery(
		item?.entityId ?? "",
		{
			skip: !item,
		}
	);

	if (isLoadingAsset || isLoadingEntity) {
		return <p>loading...</p>;
	}

	if (!item || !asset) return <p>No Associated Asset</p>;

	return (
		<Link to={`/account/${asset.account.id}/asset/${asset.id}?tab=details`}>
			{item.name}
		</Link>
	);
};
