import React from "react";
import { FiCheck, FiActivity, FiPause, FiPlay, FiX } from "react-icons/fi";
import {
	claytonBlue,
	cheesePuff,
	barnDoor,
	freshGrass,
	wetCement,
} from "@/styles/colors";
import { useGetTaskStatusTypesQuery } from "../api/endpoints";
import { Tooltip } from "@salesforce/design-system-react";

const statusMap: {
	[key: string]: {
		icon: React.ComponentType<any>;
		color: string;
		description: string;
	};
} = {
	New: { icon: FiActivity, color: freshGrass, description: "New" },
	"In Progress": {
		icon: FiPlay,
		color: wetCement,
		description: "This Task is being actively worked on",
	},
	Completed: {
		icon: FiCheck,
		color: claytonBlue,
		description: "This Task has been marked as completed",
	},
	Cancelled: {
		icon: FiX,
		color: barnDoor,
		description: "This Task has been marked as cancelled",
	},
	"On Hold": {
		icon: FiPause,
		color: cheesePuff,
		description: "This task has been put on hold temporarily",
	},
};
export const TaskStatusBadge = ({
	statusTypeId,
	size = "normal",
}: {
	statusTypeId: string;
	size?: "big" | "normal";
}) => {
	const { data, isLoading, isError } = useGetTaskStatusTypesQuery();
	const status =
		data?.find((item) => item.id === statusTypeId)?.name ?? "Status not found";

	const badge = statusMap[status] ?? statusMap["Pending"];

	if (isLoading) return <p>Loading...</p>;
	if (isError) return <p>Error Loading Types</p>;

	return (
		<Tooltip content={badge.description} align="left">
			<div
				tabIndex={0}
				style={{
					padding: size === "normal" ? "0.25rem 0.5rem" : "0.5rem 1rem",
					backgroundColor: badge.color,
					display: "flex",
					alignItems: "center",
					gap: "0.25rem",
					color: "white",
					fontWeight: "bold",
					borderRadius: "0.25rem",
				}}
			>
				<badge.icon
					size={size === "normal" ? "1rem" : "1.5rem"}
					color={"white"}
				/>
				<span style={size === "normal" ? {} : { fontSize: "1rem" }}>
					{status}
				</span>
			</div>
		</Tooltip>
	);
};
