import { gatewayApi } from "@/ReduxToolkit";
import { packPropertyBag } from "@/ReduxToolkit/MediaProperties";
import dayjs from "dayjs";
import { mediaAdaptor } from ".";
import { selectMedia } from "../selectors";

export const updateMediaPropertiesEndpoint = {
	queryFn: async (changes, { getState }, _extra, baseQuery) => {
		const createUpdatedEntity = async ({ id, ...updates }) => {
			const update = packPropertyBag({ id, ...updates });

			const response = await baseQuery({
				url: `api/media/${id}/properties`,
				method: "put",
				body: update,
			});

			if (response.error) throw response.error;

			return response;
		};

		const update = Array.isArray(changes)
			? await Promise.allSettled(
					changes.map((change) => createUpdatedEntity(change))
			  )
			: await Promise.allSettled([createUpdatedEntity(changes)]);

		const errors = Array.isArray(update)
			? update.filter((update) => update.status === "rejected")
			: [update.error].filter((error) => error);

		if (errors.length) return { error: errors };

		return {
			data:
				update && Array.isArray(changes)
					? {
							assetId: selectMedia(changes[0].id)(getState()).groupingId,
							imageIds: changes.map((change) => change.id),
					  }
					: changes.id,
		};
	},
	onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
		const update = Array.isArray(arg) ? arg : [arg];
		const assetId = update[0].groupingId;

		const patchResult = dispatch(
			gatewayApi.util.updateQueryData("getAssetMedia", assetId, (draft) => {
				mediaAdaptor.updateMany(
					draft,
					update.map(({ id, ...changes }) => ({
						id,
						changes: { ...changes, dateModified: dayjs().toISOString() },
					}))
				);
			})
		);

		const mediaPatchResult = update.map(({ id, ...changes }) => {
			return dispatch(
				gatewayApi.util.updateQueryData("getMedia", id, (draft) => {
					Object.assign(draft, {
						...changes,
						dateModified: dayjs().toISOString(),
					});
				})
			);
		});

		try {
			await queryFulfilled;
		} catch (e) {
			patchResult.undo();
			mediaPatchResult.forEach((result) => result.undo());
		}
	},
	invalidatesTags: (result, error, changes) => {
		return Array.isArray(changes)
			? [
					{ type: "AssetMedia", id: changes[0].assetId },
					...changes.map((image) => ({
						type: "Media",
						id: image.id,
					})),
			  ]
			: [{ type: "Media", id: changes.id }];
	},
};
