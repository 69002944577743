import { gatewayApi } from "@/ReduxToolkit/GatewayApi";
import {
	NewTask,
	Task,
	TaskListPayload,
	TaskListResponse,
	TaskLookupType,
	TaskTypeLookup,
} from "../Types";
import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";

const enhancedApi = gatewayApi.enhanceEndpoints({
	addTagTypes: ["Tasks", "AssetTasks"],
});

export type TaskListAdapter = EntityState<Task>;

type TaskListResponseEntity = {
	items: TaskListAdapter;
	totalItems: number;
	itemsPerPage: number;
	pageNumber: number;
};

export const taskListAdapter = (sorting: {
	propertyPath: keyof Task;
	direction: string;
}) =>
	createEntityAdapter<Task>({
		selectId: (task) => task.id ?? "",
		sortComparer: (a, b) => {
			const { propertyPath, direction } = sorting ?? {};
			let val = 0;

			if ((a[propertyPath] ?? "") > (b[propertyPath] ?? "")) {
				val = 1;
			}
			if ((a[propertyPath] ?? "") < (b[propertyPath] ?? "")) {
				val = -1;
			}
			if (direction === "desc") {
				val *= -1;
			}
			return val;
		},
	});

export const taskEndpoints = enhancedApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllTasks: builder.query<Task[], void>({
			query: () => {
				return {
					url: "api/Task/getAll",
				};
			},
		}),
		getTasksByAssetId: builder.query<Task[], string>({
			query: (assetId) => {
				return {
					url: `api/Task/getAllByAssetId/${assetId}`,
				};
			},
			providesTags: (_res, _err, assetId) => [
				{ type: "AssetTasks", id: assetId },
			],
		}),
		getTaskById: builder.query<Task, string>({
			query: (id) => {
				return {
					url: `api/Task/${id}`,
				};
			},
			providesTags: (_res, _err, id) => [{ type: "Tasks", id }],
		}),
		createTask: builder.mutation<Task, NewTask>({
			query: (task) => {
				return {
					url: "api/Task",
					body: task,
					method: "POST",
				};
			},
			invalidatesTags: [{ type: "Tasks" }, { type: "AssetTasks" }],
		}),
		updateTask: builder.mutation<Task, NewTask>({
			query: (task) => {
				return {
					url: `api/Task`,
					body: task,
					method: "PUT",
				};
			},
			invalidatesTags: [{ type: "Tasks" }, { type: "AssetTasks" }],
		}),
		searchTasks: builder.query<TaskListResponseEntity, TaskListPayload>({
			query: (body) => {
				return {
					url: `api/Search/Tasks`,
					body: body,
					method: "POST",
				};
			},
			transformResponse: (res: TaskListResponse, _, { sortCriteria }) => {
				const { items, ...rest } = res;
				return {
					items: taskListAdapter(sortCriteria).upsertMany(
						taskListAdapter(sortCriteria).getInitialState(),
						items
					),
					...rest,
				};
			},
			merge(currentCacheData, responseData, { arg }) {
				const { sortCriteria } = arg;
				if (arg.paginationCriteria.pageNumber > 0) {
					taskListAdapter(sortCriteria).upsertMany(
						currentCacheData.items,
						Object.values(responseData.items.entities).filter(
							(item): item is Task => item !== undefined
						)
					);
				} else {
					taskListAdapter(sortCriteria).setAll(
						currentCacheData.items,
						Object.values(responseData.items.entities).filter(
							(item): item is Task => item !== undefined
						)
					);
				}
				currentCacheData.totalItems = responseData.totalItems;
				currentCacheData.pageNumber = responseData.pageNumber;
			},
			serializeQueryArgs: ({ queryArgs }) => {
				const { sortCriteria, filteringCriteria, name, fuzzySearchCriteria } =
					queryArgs;

				const key = name ? name : "filtered";
				return JSON.stringify({
					...sortCriteria,
					key,
					filteringCriteria,
					...fuzzySearchCriteria,
				});
			},
			providesTags: (_res, _err, body) => [{ type: "Tasks" }],
		}),

		// Task Lookups
		getCommentTypes: builder.query<TaskLookupType[], void>({
			query: () => {
				return {
					url: "api/LookupData/commentTypeGetAll",
				};
			},
		}),
		getEntityTypes: builder.query<TaskLookupType[], void>({
			query: () => {
				return {
					url: "api/LookupData/entityTypeGetAll",
				};
			},
		}),
		getTaskStatusTypes: builder.query<TaskLookupType[], void>({
			query: () => {
				return {
					url: "api/LookupData/statusTypeGetAll",
				};
			},
		}),
		getTaskTypes: builder.query<TaskTypeLookup[], void>({
			query: () => {
				return {
					url: "api/LookupData/taskTypeGetAll",
				};
			},
		}),
	}),
});

export const {
	useGetAllTasksQuery,
	useGetTasksByAssetIdQuery,
	useGetTaskByIdQuery,
	useCreateTaskMutation,
	useUpdateTaskMutation,
	useSearchTasksQuery,
	useLazySearchTasksQuery,
	// Lookups
	useGetTaskTypesQuery,
	useGetEntityTypesQuery,
	useGetTaskStatusTypesQuery,
	useGetCommentTypesQuery,
} = taskEndpoints;
