import localforage from "localforage";

export const offlineAssetDB = localforage.createInstance({
	name: "offline-use",
	storeName: "offline-assets",
});

export const assetDetailsConcurrencyDB = localforage.createInstance({
	name: "offline-use",
	storeName: "asset-details-concurrency",
});

export const offlineAssetEditsDB = localforage.createInstance({
	name: "offline-use",
	storeName: "asset-details-edits",
});

export const offlineAssetMedia = localforage.createInstance({
	name: "offline-use",
	storeName: "asset-media",
});

export const offlineMediaUploadsDB = localforage.createInstance({
	name: "offline-use",
	storeName: "media-upload-info",
});

export const offlineConditionReportUploadsDB = localforage.createInstance({
	name: "offline-use",
	storeName: "condition-report-uploads",
});

export const offlineDocumentationDB = localforage.createInstance({
	name: "offline-use",
	storeName: "documentation",
});

export const offlineImageUploadDB = localforage.createInstance({
	name: "offline-use",
	storeName: "image-uploads",
});

export const v1OfflineImageDB = localforage.createInstance({
	name: "offline-use",
	storeName: "media-uploads",
});
