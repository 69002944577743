import { StaticInput } from "@/Components/Form/StaticInput";
import {
	Combobox,
	InputIcon,
	SLDSInput,
} from "@salesforce/design-system-react";
import { useEffect, useRef } from "react";
import { useController } from "react-hook-form";
import { useFormFocus } from "@/Components/Forms";
import { useFormSection } from "@/Components/Forms";
import { useIsEditable } from "@/Components/Forms";

export type SelectProps = {
	options: string[];
	name: string;
	type: "enum";
	span?: number;
	label?: string;
	readOnly?: boolean;
	isStatic?: boolean;
	labels?: {
		label: string;
	};
	section?: string;
	history?: boolean;
};

interface SelectOption {
	id: string;
	label: string;
	value: number;
}

export const Select = ({
	options: optionsProp,
	name,
	span,
	...props
}: SelectProps) => {
	const {
		field: { ref, ...rest },
		fieldState: { error },
	} = useController({
		name: name,
	});

	const options = optionsProp.map((option, index) => ({
		id: `option-${name}-${index}`,
		label: option,
		value: index,
	}));

	const selection = options.filter((option) => {
		return option?.value === rest.value;
	});
	const staticDisplay = selection[0]?.label || "";
	const comboboxValue = rest.value?.toString();
	const { isStatic, editField: onClickEdit } = useFormSection(props?.section);

	const { focusField } = useFormFocus();
	const focusRef = useRef<HTMLInputElement | null>(null);
	const isEditable = useIsEditable();

	useEffect(() => {
		if (focusRef && focusField === name) {
			focusRef.current?.focus();
		}
	}, [focusField, name]);

	if (!isStatic && props.readOnly) {
		return (
			<span style={{ gridColumn: `span ${span ?? 2}` }}>
				<SLDSInput
					inputRef={ref}
					errorText={error?.message}
					label={props.labels?.label}
					{...props}
					{...rest}
					value={staticDisplay}
					readOnly={true}
				/>
			</span>
		);
	}

	return (
		<span
			ref={(ref) =>
				(focusRef.current = ref?.querySelector("input") as HTMLInputElement)
			}
			style={{ gridColumn: `span ${span ?? 2}` }}
		>
			{((isStatic && props.isStatic === undefined) || !isEditable) &&
			error === undefined ? (
				<StaticInput
					name={name}
					key={`field-${name}`}
					label={props.label || props.labels?.label}
					value={staticDisplay}
					onClickEdit={() => onClickEdit(name)}
					readOnly={props.readOnly || !isEditable}
					{...props}
				/>
			) : (
				<Combobox
					variant="readonly"
					options={options}
					selection={selection}
					events={{
						onSelect: (
							_e: Event,
							{ selection: [selection] }: { selection: SelectOption[] }
						) => {
							rest.onChange(selection.value);
						},
					}}
					inputRef={ref}
					errorText={error?.message}
					iconLeft={<InputIcon name="error" category="utility" />}
					{...props}
					labels={{ label: props.label || props.labels?.label }}
					{...rest}
					value={comboboxValue}
				/>
			)}
		</span>
	);
};
