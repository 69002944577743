import { useDispatch, useSelector } from "react-redux";
import { selectMedia } from "../api/selectors";
import { useUpdateMediaPropertiesMutation } from "../api/endpoints";
import { selectAllAssetMedia } from "../api/selectors/assetMedia";
import { useEffect } from "react";
import { openToast } from "@/ReduxToolkit/ToastsSlice";

export const approvedTags = [
	"AC Data Plate",
	"Coordinates",
	"Data Plate",
	"Field Manager",
	"HUD Plate",
	"Interior",
	"PostWorkOrder",
	"PreWorkOrder",
	"TrashOut",
	"Utility",
];

export const useTagsMany = (assetId) => {
	const dispatch = useDispatch();
	const media = useSelector(selectAllAssetMedia(assetId));
	const [updateProperties, result] = useUpdateMediaPropertiesMutation({
		fixedCacheKey: "update-tags-many",
	});
	const availableTags = approvedTags;

	const createAddTagsUpdate = (image, tags) => {
		const existingTags = image.tags;
		const update = [...new Set([...existingTags, ...tags])];

		return { ...image, tags: update };
	};

	const createRemoveTagsUpdate = (image, tags) => {
		const existingTags = image.tags;
		const difference = existingTags.filter((tag) => !tags.includes(tag));

		return { ...image, tags: difference };
	};

	const createUpdateFunction = (updateFn) => (selectedIds) => (tags) => {
		const updates = media
			?.filter((image) => selectedIds.includes(image.id))
			.map((image) => updateFn(image, tags));

		updateProperties(updates);
	};

	const addTags = createUpdateFunction(createAddTagsUpdate);
	const removeTags = createUpdateFunction(createRemoveTagsUpdate);

	useEffect(() => {
		if (result?.error) {
			dispatch(
				openToast({
					id: result.requestId,
					labels: {
						heading: `Error`,
						details: `Something went wrong when modifying tags`,
					},
					variant: "error",
					timeout: 5000,
				})
			);
		}
	}, [dispatch, result?.error, result.requestId]);

	return { availableTags, addTags, removeTags, ...result };
};

export const useTags = (image) => {
	const tags = useSelector(selectMedia(image.id)).tags;
	const availableTags = approvedTags.filter((tag) => !tags.includes(tag));
	const [updateProperties] = useUpdateMediaPropertiesMutation();

	const addTag = (tag) => {
		const update = [...new Set([...tags, tag])];
		updateProperties([{ ...image, tags: update }]);
	};

	const removeTag = (tag) => {
		const difference = tags.filter((existing) => !(existing === tag));
		updateProperties([{ ...image, tags: difference }]);
	};

	return { tags, addTag, removeTag, availableTags };
};
