import { Desktop } from "@/Responsive";
import { Header } from "./components/TripPlanning/Header";
import { LocationsTable } from "./components/TripPlanning/LocationsTable";
import { ManualLocation } from "./components/TripPlanning/ManualLocation";
import { Map } from "./components/TripPlanning/Map";
import { TableActions } from "./components/TripPlanning/TableActions";
import { TripPlanningProvider } from "./components/TripPlanningProvider";
import classes from "./TripPlanning.module.scss";

export const TripPlanning = () => {
	return (
		<TripPlanningProvider>
			<Header />
			<div style={{ backgroundColor: "white" }}>
				<div className={classes.container}>
					<div className={classes.manualLocations}>
						<ManualLocation isStartingLocation={true} />
						<ManualLocation />
					</div>

					<div className={classes.grid}>
						<Desktop>
							<div className={classes.table}>
								<TableActions />
								<LocationsTable />
							</div>
						</Desktop>
						<Map />
					</div>
				</div>
			</div>
		</TripPlanningProvider>
	);
};
