import { Button, Combobox, Popover } from "@salesforce/design-system-react";
import { columns } from "../columns/columns";
import { useSortAssetList } from "../../api/useSortAssetList";

export const sortDirectionOptions = [
	{
		id: "sort-desc",
		label: "Descending",
		value: "desc",
	},
	{
		id: "sort-asc",
		label: "Ascending",
		value: "asc",
	},
];

export const SortButton = () => {
	const { direction, property, trigger: triggerSort } = useSortAssetList();

	const options = Object.entries(columns).map(([key, entry]) => {
		return {
			id: entry.path,
			label: entry.label,
			value: key,
		};
	});

	const sortPropertySelection = options.filter(
		(option) => option.id === columns[property].path
	);
	const sortDirectionSelection = sortDirectionOptions.filter(
		(option) => option.value === direction
	);

	return (
		<Popover
			align="bottom right"
			heading="Sort Assets"
			body={
				<>
					<Combobox
						labels={{
							label: "Sort by",
						}}
						variant="readonly"
						options={options}
						selection={sortPropertySelection}
						value={property}
						events={{
							onSelect: (e, { selection: [selected] }) => {
								if (selected) {
									triggerSort({
										property: selected.value,
										direction: direction,
									});
								}
							},
						}}
						deselectOption={sortPropertySelection}
						hasDeselect
					/>
					<Combobox
						labels={{
							label: "Direction",
						}}
						variant="readonly"
						options={sortDirectionOptions}
						selection={sortDirectionSelection}
						value={direction}
						events={{
							onSelect: (e, { selection: [selected] }) => {
								if (selected)
									triggerSort({
										property: property,
										direction: selected.value,
									});
							},
						}}
						deselectOption={sortDirectionSelection}
						hasDeselect
					/>
				</>
			}
		>
			<Button
				variant="icon"
				iconCategory="utility"
				iconName="sort"
				iconVariant="border-filled"
			/>
		</Popover>
	);
};
