import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectWebReadyMedia } from "../api/selectors";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";

export const useLegacyPostMessage = (accountNumber) => {
	const { assetId } = useAssetId();
	const webReadies = useSelector(selectWebReadyMedia(assetId));
	useEffect(() => {
		const messageBuilder =
			"length:" + webReadies.length + ",accountNumber:" + accountNumber;
		if (window.opener) {
			window.opener.postMessage(
				messageBuilder,
				process.env.REACT_APP_LEGACY_AMP_URL
			);
		}
	}, [webReadies, accountNumber]);
};
