import { Asset } from "@/features/asset/types";
import { useConditionReport } from "../../api/useConditionReport";
import { completedDetails } from "../header/headerDetails";
import { usePrint } from "@/hooks/usePrint";
import { Button } from "@salesforce/design-system-react";

interface LineItem {
	description: string;
	quantity: number;
	unitPrice: number;
}

interface ConditionReport {
	conditionReport: {
		lineItems?: LineItem[];
	};
	asset: Asset;
	isSuccess: boolean;
}

export const PrintConditionReportButton = () => {
	// @ts-ignore
	const { conditionReport, asset, isSuccess }: ConditionReport =
		useConditionReport();

	const print = usePrint({
		template: isSuccess && (
			<PrintedConditionReport {...{ conditionReport, asset }} />
		),
	});

	return (
		<Button
			onClick={print}
			variant="icon"
			iconName="print"
			iconCategory="utility"
		></Button>
	);
};

function PrintedConditionReport({
	conditionReport,
	asset,
}: {
	conditionReport: ConditionReport["conditionReport"];
	asset: Asset;
}) {
	return (
		<div>
			<h2 style={{ margin: 0, fontWeight: "bold" }}>
				{asset.account.accountNumber}
			</h2>
			<p style={{ margin: 0 }}>{asset.physicalLocation.address}</p>
			<p
				style={{ margin: 0 }}
			>{`${asset.physicalLocation.city}, ${asset.physicalLocation.state} ${asset.physicalLocation.zip}`}</p>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr",
					// gap: "0.5rem",
					margin: "1rem 0",
				}}
			>
				{Object.values(completedDetails(conditionReport, asset)).map((item) => (
					<div style={{ display: "flex", flexDirection: "column" }}>
						<p style={{ marginBottom: 0, fontWeight: "bold" }}>{item.label}</p>
						<p style={{ margin: 0 }}>{item.content}</p>
					</div>
				))}
			</div>
			<div>
				{conditionReport?.lineItems?.map((lineItem: LineItem) => {
					return (
						<div
							style={{
								display: "flex",
								margin: ".2rem",
								width: "90%",
								justifyContent: "space-between",
								padding: ".25rem",
								borderBottom: "1px solid lightgray",
							}}
						>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<p style={{ fontWeight: "bold", margin: 0 }}>Item </p>
								<p>{lineItem.description}</p>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									gap: "3rem",
								}}
							>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<p style={{ fontWeight: "bold", margin: 0 }}>Quantity </p>
									<p>{lineItem.quantity}</p>
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<p style={{ fontWeight: "bold", margin: 0 }}>Unit Price </p>
									<p>${lineItem.unitPrice}</p>
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<p style={{ fontWeight: "bold", margin: 0 }}>Total Cost </p>
									<p>${lineItem.unitPrice * lineItem.quantity}</p>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
