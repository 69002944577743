import { gatewayApi } from "@/ReduxToolkit/GatewayApi";

const tripPlanningEndpoints = (builder) => ({
	saveTrip: builder.mutation({
		query: (trip) => ({
			url: "/api/trip",
			method: "POST",
			body: trip,
		}),
	}),
	printDirections: builder.mutation({
		query: (trip) => ({
			url: "api/trip/getDrivingDirections",
			method: "POST",
			body: trip,
		}),
	}),
});

gatewayApi.injectEndpoints({
	endpoints: tripPlanningEndpoints,
	overrideExisting: true,
});

export const { useSaveTripMutation, usePrintDirectionsMutation } = gatewayApi;
