import { AllMediaControls } from "../media-controls";
import { MediaContainerHeader } from "../MediaContainerHeader";
import { FilterPanelLayout } from "../layouts";
import {
	MediaFilterPanelContainer,
	FilterPanelContext,
} from "../../hooks/useFilterPanel";
import { MediaCard } from "../MediaCard/MediaCard";
import { useState } from "react";
import { Icon, Pill } from "@salesforce/design-system-react";
import { filterProperties } from "@/features/media-manager/components/filters/Filter";
import { useDispatch } from "react-redux";
import { useAllMedia, useAllMediaFilters } from "../../api/hooks/useAllMedia";
import { useMediaType } from "../../api/hooks/useMediaType";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { ALL_MEDIA } from "@/ReduxToolkit/MediaSlice";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { useMediaLoadingState } from "../../api/hooks/useMediaLoadingStates";
import useVirtual from "react-cool-virtual";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { useMemo } from "react";
import { Fragment } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useUploadMedia } from "../../api/hooks/useUploadMedia";
import classes from "@/features/media-manager/components/media-container/MediaContainer.module.scss";
import { forwardRef } from "react";
import { usePhotoSlider } from "../PhotoSizeSlider/PhotoSizeSlider";
import { useCallback } from "react";
import { useIsOnline } from "@/features/offline-use";

const DraggableGridItem = forwardRef(GridItem);

export const AllMediaBucket = ({
	title,
	onShowPreview = () => {},
	container,
	...props
}) => {
	const { assetId } = useAssetId();
	const isOnline = useIsOnline();

	const { data: media, isSuccess } = useAllMedia(assetId);

	const dispatch = useDispatch();

	const { filters, removeFilter } = useAllMediaFilters(assetId);

	const [showContent, setShowContent] = useState(true);
	const toggleShowContent = () => setShowContent(!showContent);
	const { isLoadingAllMedia, loadingMessage } = useMediaLoadingState(assetId);

	const [isDraggedOver, setIsDraggedOver] = useState(false);
	const { uploadFiles } = useUploadMedia(assetId);

	const addPhotoHandler = (files) => {
		uploadFiles([...files]);
	};

	const handleDroppedUpload = (e) => {
		e.stopPropagation();
		e.preventDefault();
		addPhotoHandler(e.dataTransfer.files);
		setIsDraggedOver(false);
	};
	return (
		<>
			{isSuccess && (
				<FilterPanelContext>
					<MediaContainerHeader
						label={isOnline ? "All Media" : "Offline Media"}
						media={media}
						controls={() => (
							<AllMediaControls
								isContentVisible={showContent}
								onShowContent={toggleShowContent}
							/>
						)}
						info={
							<>
								{isLoadingAllMedia && (
									<LoadingIndicator loadingMessage={loadingMessage} />
								)}
								<div
									style={{
										marginLeft: "3rem",
									}}
								>
									<div style={{ display: "flex" }}>
										{filters?.map((filter) => (
											<Pill
												labels={{
													label: filterProperties[filter.property].label,
												}}
												onRemove={() => dispatch(removeFilter(filter.id))}
												variant="option"
												key={filter.id}
												icon={
													<Icon
														category="utility"
														name="filterList"
														size="x-small"
													/>
												}
											></Pill>
										))}
									</div>
								</div>
							</>
						}
						sticky
						container={container}
					/>
					{showContent && (
						<div
							className={
								isDraggedOver || props.isDraggedOver
									? `${classes.contentWrapper} ${classes.draggedOver}`
									: classes.contentWrapper
							}
							onDragOver={(e) => {
								e.preventDefault();
								setIsDraggedOver(true);
							}}
							onDragLeave={() => setIsDraggedOver(false)}
							onDrop={handleDroppedUpload}
						>
							<AllMediaCards container={container} />
						</div>
					)}
				</FilterPanelContext>
			)}
		</>
	);
};
function AllMediaCards({ container }) {
	const { assetId } = useAssetId();

	const { data: media, isSuccess } = useAllMedia(assetId);
	const { photoSlider, nCols, setPhotoSlider } = usePhotoSlider();

	const [width, setWidth] = useState(0);

	const nCol = useMemo(() => {
		const cols = width ? Math.round(width / photoSlider) : nCols;
		return cols > 1 ? cols : 1;
	}, [nCols, photoSlider, width]);

	const cols = useCallback(
		(width) => {
			const size = Math.round(width / photoSlider);
			return width / size;
		},
		[photoSlider]
	);

	const nRow = useMemo(
		() => Math.ceil(media.length / nCol),
		[media.length, nCol]
	);

	const imageGrid = useMemo(() => {
		let result = [];
		for (let i = 0; i < nRow; i++) {
			result.push(media.slice(i * nCol, i * nCol + nCol));
		}
		return result;
	}, [media, nCol, nRow]);

	const row = useVirtual({
		itemCount: nRow,
		overscanCount: 3,
		itemSize: (_, w) => cols(w) * 0.76,
		onResize: ({ width }) => {
			return cols(width) * 0.76;
		},
	});

	const col = useVirtual({
		horizontal: true,
		itemCount: nCol,
		itemSize: (_, w) => {
			return cols(w);
		},
		onResize: ({ width }) => {
			if (nCol === 1 && width < photoSlider) {
				setPhotoSlider(width);
			}
			setWidth(width);
			return cols(width);
		},
		overscanCount: 6 * nCol,
		useIsScrolling: true,
	});

	const { setNodeRef } = useDroppable({ id: "AllMedia" });
	return (
		<FilterPanelLayout panel={<MediaFilterPanelContainer container={container} />}>
			{isSuccess && (
				<div ref={setNodeRef}>
					<div
						style={{
							minWidth: "100%",
							maxWidth: "90vw",
							width: "100%",
							height: "100%",
							minHeight: "5rem",
							maxHeight: "100vh",
							overflow: "auto",
						}}
						ref={(el) => {
							row.outerRef.current = el;
							col.outerRef.current = el;
						}}
					>
						<div
							style={{
								position: "relative",
							}}
							ref={(el) => {
								row.innerRef.current = el;
								col.innerRef.current = el;
							}}
						>
							{row.items.map((rowItem) => (
								<Fragment key={`row-${rowItem.index}`}>
									{col.items.map((colItem) => {
										const image = imageGrid?.[rowItem.index]?.[colItem.index];

										return (
											image && (
												<DraggableGridItem
													key={image?.id ?? `empty-${colItem.index}`}
													id={image?.id}
													image={image}
													height={rowItem.size}
													width={colItem.size}
													colStart={colItem.start}
													rowStart={rowItem.start}
												/>
											)
										);
									})}
								</Fragment>
							))}
						</div>
					</div>
				</div>
			)}
		</FilterPanelLayout>
	);
}
function GridItem(
	{ image, index, height, width, colStart, rowStart, isScrolling, ...props },
	measureRef
) {
	const { isPhoto, isSuccess } = useMediaType(props.id);
	const isDraggable = image && isSuccess && isPhoto(image) && !image?.webReady;

	const {
		setNodeRef,
		setActivatorNodeRef,
		isDragging,
		transform,
		attributes,
		listeners,
	} = useDraggable({
		id: `allMedia-${image?.id}`,
		disabled: !isDraggable,
		data: {
			type: "allMedia",
			mediaId: image?.id,
			position: { colStart, rowStart },
		},
	});

	return (
		<div
			ref={setNodeRef}
			key={`allMedia-${image?.id}`}
			{...attributes}
			style={{
				position: "absolute",
				height: `${height}px`,
				width: `${width}px`,
				transform: CSS.Transform.toString({
					x: transform ? transform?.x + colStart : colStart,
					y: transform ? transform?.y + rowStart : rowStart,
					scaleX: 0.98,
					scaleY: 0.98,
				}),
			}}
		>
			{image && !isDragging && (
				<MediaCard
					ref={setActivatorNodeRef}
					listeners={listeners}
					media={image}
					mediaId={image.id}
					bucket={ALL_MEDIA}
					isDraggable={isDraggable}
				/>
			)}
			{image && isDragging && (
				<div
					style={{
						height: `${height}px`,
						width: `${width}px`,
						transform: `translateX(${colStart}px) translateY(${rowStart}px)`,
					}}
				></div>
			)}
		</div>
	);
}
