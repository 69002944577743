import classes from "./ConditionReportContent.module.scss";

export const LineItem = ({ lineItem }) => {
	return (
		<>
			<div className={classes.lineItem}>
				<div className={classes.item}>
					<p className={classes.label}>Item </p>
					<p>{lineItem.description}</p>
				</div>
				<div className={classes.item}>
					<p className={classes.label}>Quantity </p>
					<p>{lineItem.quantity}</p>
				</div>
				<div className={classes.item}>
					<p className={classes.label}>Unit Price </p>
					<p>${lineItem.unitPrice}</p>
				</div>
				<div className={classes.item}>
					<p className={classes.label}>Total Cost </p>
					<p>${lineItem.unitPrice * lineItem.quantity}</p>
				</div>
			</div>
		</>
	);
};
