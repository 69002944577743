import { Button, Checkbox, Modal } from "@salesforce/design-system-react";
import { useColumns } from "../../hooks/useColumns";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import classes from "@/Components/Forms/components/Submitbuttons.module.scss";
import { SpinnerButton } from "@/Components/SpinnerButton/SpinnerButton";
import { usePricingWorksheetFeatureFilter } from "../../hooks/usePricingWorksheetFeatureFilter";

export const columnsModal = NiceModal.create(() => {
	const modal = useModal();
	const { columns, hideColumn, showColumn, isUpdating, isDirty, save, reset } =
		useColumns();
	const pricingWorksheetFeatureFilter = usePricingWorksheetFeatureFilter();

	return (
		<Modal
			isOpen={modal.visible}
			onRequestClose={async () => {
				if (isDirty) {
					await reset();
				}
				modal.remove();
			}}
		>
			<h2
				className="slds-text-heading_medium"
				style={{ paddingLeft: ".5rem", paddingTop: ".5rem" }}
			>
				Settings
			</h2>
			<h3
				className="slds-text-heading_small"
				style={{ paddingLeft: "1rem", paddingTop: ".25rem" }}
			>
				Show / Hide Columns
			</h3>
			<div style={{ padding: ".5rem 1.5rem" }}>
				{Object.entries(columns)
					.filter(([key]) => pricingWorksheetFeatureFilter(key))
					.map(([key, value]) => {
						return (
							<div key={`column-${key}`}>
								<Checkbox
									name={key}
									labels={{ label: value.label }}
									checked={value.show}
									disabled={isUpdating}
									onChange={(e) => {
										e.target.checked ? showColumn(key) : hideColumn(key);
									}}
								/>
							</div>
						);
					})}
			</div>
			<div className={classes.container}>
				<div>
					<Button
						onClick={() => {
							if (isDirty) {
								reset();
							}
						}}
					>
						Cancel
					</Button>
					<SpinnerButton
						variant="brand"
						isSpinning={isUpdating}
						onClick={save}
						disabled={isUpdating || !isDirty}
					>
						Save
					</SpinnerButton>
				</div>
			</div>
		</Modal>
	);
});

NiceModal.register("asset-list-columns", columnsModal);

export const useColumnsModal = () => useModal("asset-list-columns");
