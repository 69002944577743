export const CLUB_HOUSE = {
	path: "marketingInfo.listingDetails.clubHouse",
	label: "Club House",
};

export const CONCRETE_DRIVEWAYS_PATIOS = {
	path: "marketingInfo.listingDetails.concreteDrivewaysPatios",
	label: "Concrete Driveways & Patios",
};

export const CONVENIENT_TO_SCHOOLS = {
	path: "marketingInfo.listingDetails.convenientToSchools",
	label: "Convenient to Schools",
};

export const GATED_COMMUNITY = {
	path: "marketingInfo.listingDetails.gatedCommunity",
	label: "Gated Community",
};

export const LAUNDRY_FACILITIES = {
	path: "marketingInfo.listingDetails.laundryFacilities",
	label: "Laundry Facilities",
};

export const LIGHTED_STREETS = {
	path: "marketingInfo.listingDetails.lightedStreets",
	label: "Lighted Streets",
};

export const ON_SITE_MANAGEMENT_TEAM = {
	path: "marketingInfo.listingDetails.onSiteManagementTeam",
	label: "On-Site Management Team",
};

export const PLAYGROUND = {
	path: "marketingInfo.listingDetails.playground",
	label: "Playground",
};

export const POOL = {
	path: "marketingInfo.listingDetails.pool",
	label: "Pool",
};

export const SENIOR_COMMUNITY = {
	path: "marketingInfo.listingDetails.seniorCommunity",
	label: "Senior Community",
};

export const SMALL_PETS_ALLOWED = {
	path: "marketingInfo.listingDetails.smallPetsAllowed",
	label: "Small Pets Allowed",
};

export const TWO_X_SIX_WALLS = {
	path: "marketingInfo.listingDetails.twoBySixWalls",
	label: "2x6 Walls",
};

export const TWO_X_EIGHT_FLOORS = {
	path: "marketingInfo.listingDetails.twoByEightFloors",
	label: "2x8 Floors",
};

export const DIMENSIONAL_SHINGLES = {
	path: "marketingInfo.listingDetails.dimensionalShingles",
	label: "Dimensional Shingles",
};

export const GRANITE_COUNTER_TOP = {
	path: "marketingInfo.listingDetails.graniteCounterTop",
	label: "Granite Counter Top",
};

export const WOOD_CABINETS = {
	path: "marketingInfo.listingDetails.woodCabinets",
	label: "Wood Cabinets",
};

export const KITCHEN_HOOD = {
	path: "marketingInfo.listingDetails.kitchenHood",
	label: "Kitchen Hood",
};

export const KITCHEN_ISLAND = {
	path: "marketingInfo.listingDetails.kitchenIsland",
	label: "Kitchen Island",
};

export const PITCHED_ROOF = {
	path: "marketingInfo.listingDetails.pitchedRoof",
	label: "Pitched Roof",
};

export const STONE_VENEER_ACCENTS = {
	path: "marketingInfo.listingDetails.stoneVeneerAccents",
	label: "Stone Veneer Accents",
};

export const THERMAL_PANE_WINDOWS = {
	path: "marketingInfo.listingDetails.thermalPaneWindows",
	label: "Thermal Pane Windows",
};

export const TILE_FLOORS = {
	path: "marketingInfo.listingDetails.tileFloors",
	label: "Tile Floors",
};

export const TRIM_PACKAGE = {
	path: "marketingInfo.listingDetails.trimPackage",
	label: "Trim Package",
};

export const LARGE_DECK = {
	path: "marketingInfo.listingDetails.largeDeck",
	label: "Large Deck",
};

export const NEW_CARPET = {
	path: "marketingInfo.listingDetails.newCarpet",
	label: "New Carpet",
};

export const NEW_DECK = {
	path: "marketingInfo.listingDetails.newDeck",
	label: "New Deck",
};

export const NEW_PAINT = {
	path: "marketingInfo.listingDetails.newPaint",
	label: "New Paint",
};

export const NEW_ROOF = {
	path: "marketingInfo.listingDetails.newRoof",
	label: "New Roof",
};

export const RECENTLY_REMODELED = {
	path: "marketingInfo.listingDetails.recentlyRemodeled",
	label: "Recently Remodeled",
};

export const NEW_VINYL_FLOORING = {
	path: "marketingInfo.listingDetails.newLuxuryVinylPlankFloors",
	label: "New Luxury Vinyl Plank Floors",
};

export const NEW_SEPTIC = {
	path: "marketingInfo.listingDetails.newSeptic",
	label: "New Septic",
};

export const NEW_WATER_WELL = {
	path: "marketingInfo.listingDetails.newWaterWell",
	label: "New Water Well",
};

export const CATHEDRAL_CEILING = {
	path: "marketingInfo.listingDetails.cathedralCeiling",
	label: "Cathedral Ceiling",
};

export const CEILING_FAN = {
	path: "marketingInfo.listingDetails.ceilingFan",
	label: "Ceiling Fan",
};

export const FIREPLACE = {
	path: "marketingInfo.listingDetails.fireplace",
	label: "Fireplace",
};

export const GARDEN_TUB = {
	path: "marketingInfo.listingDetails.gardenTub",
	label: "Garden tub",
};

export const LAUNDRY_ROOM = {
	path: "marketingInfo.listingDetails.laundryRoom",
	label: "Laundry Room",
};

export const PANTRY = {
	path: "marketingInfo.listingDetails.pantry",
	label: "Pantry",
};

export const SKYLIGHT = {
	path: "marketingInfo.listingDetails.skylight",
	label: "Skylight",
};

export const SPLIT_FLOOR_PLAN = {
	path: "marketingInfo.listingDetails.splitFloorPlan",
	label: "Split Floor Plan",
};

export const SUN_ROOM = {
	path: "marketingInfo.listingDetails.sunRoom",
	label: "Sun Room",
};

export const WALK_IN_CLOSETS = {
	path: "marketingInfo.listingDetails.walkInClosets",
	label: "Walk-in Closets",
};

export const NEW_DISHWASHER = {
	path: "marketingInfo.listingDetails.newDishwasher",
	label: "New Dishwasher",
};

export const DISHWASHER = {
	path: "marketingInfo.listingDetails.dishwasher",
	label: "Dishwasher",
};

export const NEW_DRYER = {
	path: "marketingInfo.listingDetails.newDryer",
	label: "New Dryer",
};

export const DRYER = {
	path: "marketingInfo.listingDetails.dryer",
	label: "Dryer",
};

export const NEW_MICROWAVE = {
	path: "marketingInfo.listingDetails.newMicrowave",
	label: "New Microwave",
};

export const MICROWAVE = {
	path: "marketingInfo.listingDetails.microwave",
	label: "Microwave",
};

export const NEW_RANGE = {
	path: "marketingInfo.listingDetails.newRange",
	label: "New Range",
};

export const RANGE = {
	path: "marketingInfo.listingDetails.range",
	label: "Range",
};

export const NEW_REFRIGERATOR = {
	path: "marketingInfo.listingDetails.newRefrigerator",
	label: "New Refrigerator",
};

export const REFRIGERATOR = {
	path: "marketingInfo.listingDetails.refrigerator",
	label: "Refrigerator",
};

export const NEW_WASHER = {
	path: "marketingInfo.listingDetails.newWasher",
	label: "New Washer",
};

export const WASHER = {
	path: "marketingInfo.listingDetails.washer",
	label: "Washer",
};

export const CORNER_LOT = {
	path: "marketingInfo.listingDetails.cornerLot",
	label: "Corner lot",
};

export const COVERED_PARKING = {
	path: "marketingInfo.listingDetails.coveredParking",
	label: "Covered Parking",
};

export const CUL_DE_SAC = {
	path: "marketingInfo.listingDetails.culDeSac",
	label: "Cul de sac",
};

export const FENCED_YARD = {
	path: "marketingInfo.listingDetails.fencedYard",
	label: "Fenced Yard",
};

export const GARAGE = {
	path: "marketingInfo.listingDetails.garage",
	label: "Garage",
};

export const GREAT_VIEWS = {
	path: "marketingInfo.listingDetails.greatViews",
	label: "Great Views",
};

export const NICELY_LANDSCAPED_LOT = {
	path: "marketingInfo.listingDetails.nicelyLandscapedLot",
	label: "Nicely Landscaped Lot",
};

export const OUT_BUILDING = {
	path: "marketingInfo.listingDetails.outBuilding",
	label: "Out-Building",
};

export const FIXER_UPPER = {
	path: "marketingInfo.listingDetails.fixerUpper",
	label: "Fixer Upper",
};

export const HANDY_MAN_SPECIAL = {
	path: "marketingInfo.listingDetails.handyManSpecial",
	label: "Handy Man Special",
};

export const CENTRALLY_LOCATED = {
	path: "marketingInfo.listingDetails.centrallyLocated",
	label: "Centrally Located",
};

export const GREAT_LOCATION = {
	path: "marketingInfo.listingDetails.greatLocation",
	label: "Great Location",
};

export const INVESTMENT_OPPORTUNITY = {
	path: "marketingInfo.listingDetails.investmentOpportunity",
	label: "Investment Opportunity",
};

export const LAKE_VIEWS_LAKE_ACCESS = {
	path: "marketingInfo.listingDetails.lakeViewsOrLakeAccess",
	label: "Lake Views / Lake Access",
};

export const MINUTES_FROM_TOWN = {
	path: "marketingInfo.listingDetails.minutesFromTown",
	label: "Minutes From Town",
};

export const PERFECT_FOR_FIRST_TIME_HOME_BUYER = {
	path: "marketingInfo.listingDetails.perfectForFirstTimeHomeBuyers",
	label: "Perfect for First Time Home Buyers",
};

export const QUIET_COUNTRY_HOME_WITH_ACREAGE = {
	path: "marketingInfo.listingDetails.quietCountryHomeWithAcreage",
	label: "Quiet Country Home with Acreage",
};

export const RURAL_COUNTRY_SETTING = {
	path: "marketingInfo.listingDetails.ruralOrCountrySetting",
	label: "Rural / Country Setting",
};

export const NICE_WOODED_LOT = {
	path: "marketingInfo.listingDetails.niceWoodedLot",
	label: "Nice Wooded Lot",
};

export const MISCELLANEOUS = {
	path: "marketingInfo.listingDetails.miscellaneous",
	label: "Miscellaneous",
};

export const ASSET_MANAGER_NOTES = {
	path: "marketingInfo.listingDetails.assetManagerNotes",
	label: "Asset Manager Notes",
};

export const DESCRIPTION = {
	path: "marketingInfo.listingDetails.description",
	label: "Description",
};

export const FINISHED_DRYWALL = {
	path: "marketingInfo.listingDetails.finishedDrywall",
	label: "Finished Drywall",
};

export const PERMANENT_FOUNDATION = {
	path: "marketingInfo.listingDetails.permanentFoundation",
	label: "Permanent Foundation",
};

export const PLYWOOD_FLOORS = {
	path: "marketingInfo.listingDetails.plywoodFloors",
	label: "Plywood Floors",
};

export const RBI_NUMBER = {
	path: "marketingInfo.listingDetails.rbiNumber",
	label: "RBI Number",
};

export const SHOW_ADDRESS = {
	path: "marketingInfo.listingDetails.showAddress",
	label: "Show Address",
};

export const SHOW_MAP = {
	path: "marketingInfo.listingDetails.showMap",
	label: "Show Map",
};

export const GENERAL = {
	label: "General",
	fields: [
		CENTRALLY_LOCATED,
		GREAT_LOCATION,
		INVESTMENT_OPPORTUNITY,
		LAKE_VIEWS_LAKE_ACCESS,
		MINUTES_FROM_TOWN,
		PERFECT_FOR_FIRST_TIME_HOME_BUYER,
		QUIET_COUNTRY_HOME_WITH_ACREAGE,
		RURAL_COUNTRY_SETTING,
		NICE_WOODED_LOT,
	],
};

export const COMMUNITY = {
	label: "Community",
	fields: [
		CLUB_HOUSE,
		CONCRETE_DRIVEWAYS_PATIOS,
		CONVENIENT_TO_SCHOOLS,
		GATED_COMMUNITY,
		LAUNDRY_FACILITIES,
		LIGHTED_STREETS,
		ON_SITE_MANAGEMENT_TEAM,
		PLAYGROUND,
		POOL,
		SENIOR_COMMUNITY,
		SMALL_PETS_ALLOWED,
	],
};

export const FEATURES = {
	label: "Features",
	fields: [
		TWO_X_SIX_WALLS,
		TWO_X_EIGHT_FLOORS,
		DIMENSIONAL_SHINGLES,
		GRANITE_COUNTER_TOP,
		KITCHEN_HOOD,
		KITCHEN_ISLAND,
		PLYWOOD_FLOORS,
		PITCHED_ROOF,
		STONE_VENEER_ACCENTS,
		THERMAL_PANE_WINDOWS,
		TILE_FLOORS,
		TRIM_PACKAGE,
		WOOD_CABINETS,
	],
};

export const UPDATES = {
	label: "Updates",
	fields: [
		NEW_CARPET,
		NEW_DECK,
		NEW_PAINT,
		NEW_ROOF,
		RECENTLY_REMODELED,
		NEW_VINYL_FLOORING,
		NEW_SEPTIC,
		NEW_WATER_WELL,
	],
};

export const INTERIOR = {
	label: "Interior",
	fields: [
		CATHEDRAL_CEILING,
		CEILING_FAN,
		FINISHED_DRYWALL,
		FIREPLACE,
		GARDEN_TUB,
		LAUNDRY_ROOM,
		PANTRY,
		SKYLIGHT,
		SPLIT_FLOOR_PLAN,
		SUN_ROOM,
		WALK_IN_CLOSETS,
	],
};

export const APPLIANCES = {
	label: "Appliances",
	fields: [
		DISHWASHER,
		NEW_DISHWASHER,
		DRYER,
		NEW_DRYER,
		MICROWAVE,
		NEW_MICROWAVE,
		RANGE,
		NEW_RANGE,
		REFRIGERATOR,
		NEW_REFRIGERATOR,
		WASHER,
		NEW_WASHER,
	],
};

export const EXTERIOR = {
	label: "Exterior",
	fields: [
		CORNER_LOT,
		COVERED_PARKING,
		CUL_DE_SAC,
		FENCED_YARD,
		GARAGE,
		GREAT_VIEWS,
		LARGE_DECK,
		NICELY_LANDSCAPED_LOT,
		OUT_BUILDING,
		PERMANENT_FOUNDATION,
	],
};

export const CONDITION = {
	label: "Condition",
	fields: [FIXER_UPPER, HANDY_MAN_SPECIAL],
};

export const NEW_LISTING_DETAILS = {
	assetManagerNotes: "",
	cathedralCeiling: false,
	ceilingFan: false,
	centrallyLocated: false,
	clubHouse: false,
	concreteDrivewaysPatios: false,
	concurrencyToken: "",
	convenientToSchools: false,
	cornerLot: false,
	coveredParking: false,
	culDeSac: false,
	description: "",
	dimensionalShingles: false,
	dishwasher: false,
	dryer: false,
	fencedYard: false,
	finishedDrywall: false,
	fireplace: false,
	fixerUpper: false,
	garage: false,
	gardenTub: false,
	gatedCommunity: false,
	graniteCounterTop: false,
	greatLocation: false,
	greatViews: false,
	handyManSpecial: false,
	id: "",
	investmentOpportunity: false,
	kitchenHood: false,
	kitchenIsland: false,
	lakeViewsOrLakeAccess: false,
	largeDeck: false,
	laundryFacilities: false,
	laundryRoom: false,
	lightedStreets: false,
	microwave: false,
	minutesFromTown: false,
	miscellaneous: "",
	newCarpet: false,
	newDeck: false,
	newDishwasher: false,
	newDryer: false,
	newLuxuryVinylPlankFloors: false,
	newMicrowave: false,
	newPaint: false,
	newRange: false,
	newRefrigerator: false,
	newRoof: false,
	newSeptic: false,
	newWasher: false,
	newWaterWell: false,
	niceWoodedLot: false,
	nicelyLandscapedLot: false,
	onSiteManagementTeam: false,
	outBuilding: false,
	pantry: false,
	perfectForFirstTimeHomeBuyers: false,
	permanentFoundation: false,
	pitchedRoof: false,
	playground: false,
	plywoodFloors: false,
	pool: false,
	quietCountryHomeWithAcreage: false,
	range: false,
	rbiNumber: "",
	recentlyRemodeled: false,
	refrigerator: false,
	retailConsignment: false,
	ruralOrCountrySetting: false,
	seniorCommunity: false,
	showAddress: false,
	showMap: false,
	skylight: false,
	smallPetsAllowed: false,
	splitFloorPlan: false,
	stoneVeneerAccents: false,
	sunRoom: false,
	thermalPaneWindows: false,
	tileFloors: false,
	trimPackage: false,
	twoByEightFloors: false,
	twoBySixWalls: false,
	walkInClosets: false,
	washer: false,
	woodCabinets: false,
};
