import { useNavigate } from "react-router-dom";
import { useUser } from "@/features/user/hooks/useUser";
import { pca, useIsAuth } from "@/auth";
import { Button } from "@salesforce/design-system-react";
import { isProd } from "@/Utilities/utils";

const HeaderProfileCustomContent = () => {
	const navigate = useNavigate();
	const { data: user, isSuccess } = useUser();
	/*
		Replace this isProd variable with something better eventually
	*/
	const isAuth = useIsAuth();

	const version_number = process.env.REACT_APP_VERSION_NUMBER;

	return (
		<div id="header-profile-custom-popover-content">
			<div className="slds-m-around_medium">
				<div className="slds-tile slds-tile_board slds-m-horizontal_small">
					{isSuccess && (
						<>
							<p className="tile__title slds-text-heading_small">{`${user?.firstName} ${user?.lastName}`}</p>
							<div className="slds-tile__detail">
								<p className="slds-truncate">
									{!isProd() && (
										<Button
											variant="link"
											onClick={() => navigate("/rolesSettings")}
										>
											Profile Settings
										</Button>
									)}
								</p>
							</div>
						</>
					)}
					{isAuth ? (
						<Button onClick={() => pca.logoutRedirect()} variant="link">
							Log Out
						</Button>
					) : (
						<Button onClick={() => pca.loginRedirect()} variant="link">
							Login
						</Button>
					)}
					<p>Version {version_number}</p>
				</div>
			</div>
		</div>
	);
};

export default HeaderProfileCustomContent;
