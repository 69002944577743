export const STORAGE_KEY = "homebase_feature_flags_v2";

export const PRICING_WORKSHEET_FEATURE_FLAG = {
	label: "Pricing Worksheet",
	value: "vmf-rmktg.homebase.pricing-worksheet-features",
};

export const TASK_TRACKER_FEATURE_FLAG = {
	label: "Task Tracker",
	value: "vmf-rmktg.homebase.task-tracker-features",
};

export const FEATURE_FLAGS = [
	PRICING_WORKSHEET_FEATURE_FLAG,
	TASK_TRACKER_FEATURE_FLAG,
];
