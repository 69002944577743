import React, { useState } from "react";
import { Button, Checkbox, Modal } from "@salesforce/design-system-react";
import { useTagsMany } from "../../hooks/useTags";
import { Tag } from "./Tag";
import classes from "./TagsActionModal.module.scss";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export const TagsActionModal = NiceModal.create(
	({ selectedIds, action, assetId, ...props }) => {
		const modal = useModal();
		const { availableTags, addTags, removeTags } = useTagsMany(assetId);

		const initialState = Object.fromEntries(
			availableTags.map((tag) => [tag, false])
		);

		const [selectedTags, setSelectedTags] = useState(initialState);

		const selectedTagsArray = Object.entries(selectedTags)
			.filter(([_, value]) => value)
			.map(([key, _]) => key);

		const actions = {
			add: { label: "Add", fn: addTags(selectedIds) },
			remove: { label: "Remove", fn: removeTags(selectedIds) },
		};

		const handleClickTagsAction = () => {
			actions?.[action].fn(selectedTagsArray);
			modal.remove();
		};

		const toggleSelectedTag = (tag) => {
			setSelectedTags((tags) => ({ ...tags, [tag]: !tags[tag] }));
		};

		return (
			<Modal
				isOpen={modal.visible}
				onRequestClose={() => {
					modal.remove();
				}}
				header={
					<>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								padding: "0 1rem",
								margin: 0,
							}}
						>
							<div style={{ padding: 0 }}>
								<h3 style={{ fontSize: "1.5rem" }}>
									{actions[action]?.label} Tags
								</h3>
								<p>{selectedIds.length} Images</p>
							</div>
							<Button
								variant={action === "add" ? "brand" : "destructive"}
								onClick={handleClickTagsAction}
							>
								{actions[action]?.label}
							</Button>
						</div>
					</>
				}
				size="small"
			>
				<div style={{ display: "flex", flexWrap: "wrap" }}>
					{availableTags.map((tag) => (
						<div key={tag} className={classes.tag}>
							<Checkbox
								label={<Tag value={tag} />}
								onChange={() => toggleSelectedTag(tag)}
							/>
						</div>
					))}
				</div>
			</Modal>
		);
	}
);

NiceModal.register("media-tags-modal", TagsActionModal);

export const useTagsActionModal = () => useModal("media-tags-modal");
