import { isProd } from "@/Utilities/utils";
import { ROLES, STORAGE_KEY } from "@/providers/roles/constants";
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { useGetCurrentUserQuery } from "../api/endpoints";

const RolesContext = createContext({});
const RolesDispatchContext = createContext({});

const initialRoles = Object.fromEntries(
	ROLES.map((role) => [role, process.env.NODE_ENV === "test"])
);

const useRolesProd = () => {
	const { data: roles, isSuccess } = useGetCurrentUserQuery(undefined, {
		selectFromResult: ({ data, ...rest }) => {
			return { data: data?.roles, ...rest };
		},
	});

	return {
		roles: Object.fromEntries(
			ROLES.map((role) => [role, roles?.includes(role)])
		),
		isSuccess,
	};
};

export const RolesProvider = ({ children }) => {
	const { roles: actualRoles, isSuccess } = useRolesProd();

	const initialDisabled = !(
		localStorage.getItem("rolesDisabled") === "false" ||
		process.env.NODE_ENV === "test"
	);

	const [isDisabledRoleSwitcher, setIsDisabledRoleSwitcher] =
		useState(initialDisabled);

	const [roles, setRoles] = useState(
		initialDisabled
			? actualRoles ?? {}
			: JSON.parse(localStorage.getItem(STORAGE_KEY)) ?? initialRoles
	);

	useEffect(() => {
		if (isDisabledRoleSwitcher && isSuccess) {
			setRoles(actualRoles);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess]);

	const toggleDisableRoleSwitcher = useCallback(() => {
		if (isDisabledRoleSwitcher) {
			const roles =
				JSON.parse(localStorage.getItem(STORAGE_KEY)) ?? initialRoles;
			setRoles(roles);
		} else {
			setRoles(actualRoles);
		}

		localStorage.setItem("rolesDisabled", !isDisabledRoleSwitcher);
		setIsDisabledRoleSwitcher(!isDisabledRoleSwitcher);

		return;
	}, [actualRoles, isDisabledRoleSwitcher]);

	const toggleRole = (role) => {
		const update = { ...roles, [role]: !roles[role] };
		localStorage.setItem(STORAGE_KEY, JSON.stringify(update));
		setRoles(update);
	};

	return isProd() ? (
		<RolesContext.Provider value={{ roles: actualRoles, isSuccess }}>
			{children}
		</RolesContext.Provider>
	) : (
		<RolesContext.Provider value={{ roles, isSuccess, isDisabledRoleSwitcher }}>
			<RolesDispatchContext.Provider
				value={{ toggleRole, toggleDisableRoleSwitcher }}
			>
				{children}
			</RolesDispatchContext.Provider>
		</RolesContext.Provider>
	);
};

const useRolesDev = () => {
	const { roles, isSuccess } = useContext(RolesContext);

	return { roles, isSuccess };
};

export const useRoleSwitcher = () => {
	const { isDisabledRoleSwitcher } = useContext(RolesContext);
	const { toggleRole, toggleDisableRoleSwitcher } =
		useContext(RolesDispatchContext);

	return { isDisabledRoleSwitcher, toggleRole, toggleDisableRoleSwitcher };
};

export const useRoles = isProd() ? useRolesProd : useRolesDev;
