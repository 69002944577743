import {
	setWebReadySelection,
	toggleSelection,
	WEB_READY_MEDIA,
} from "@/ReduxToolkit/MediaSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAssetMediaQuery } from "../endpoints";
import { selectSelectedMedia } from "../selectors";

export const useWebReadyMedia = (assetId) => {
	const { data: webReadyMedia, ...rest } = useGetAssetMediaQuery(assetId, {
		selectFromResult: ({ data, ...rest }) => {
			const webReadyMedia = data?.ids
				.map((id) => data.entities[id])
				.filter((media) => media.webReady)
				.sort((a, b) => a.order - b.order);

			return {
				data: webReadyMedia ?? [], //?.map((media) => media.id),
				...rest,
			};
		},
	});

	const selected = useSelector(
		selectSelectedMedia({ bucket: WEB_READY_MEDIA, assetId })
	);

	const dispatch = useDispatch();

	useEffect(() => {
		const update = Object.fromEntries(
			webReadyMedia.map((media) => [media.id, selected?.[media.id] === true])
		);

		dispatch(
			setWebReadySelection({
				assetId,
				update,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [webReadyMedia.length]);

	const toggle = (mediaId) => {
		dispatch(toggleSelection({ assetId, bucket: WEB_READY_MEDIA, mediaId }));
	};

	return { data: webReadyMedia, selected, toggleSelection: toggle, ...rest };
};
