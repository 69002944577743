import { useGetMediaTypesQuery } from "@/features/lookups";
import {
	addAssetMediaState,
	removeAssetMediaState,
} from "@/ReduxToolkit/MediaSlice";
import { uploadMediaEndpoint } from "./uploadMedia";
import { updateMediaPropertiesEndpoint } from "./updateMediaProperties";
import { deleteMediaEndpoint } from "./deleteMedia";
import { saveCopyEndpoint } from "./saveCopy";
import { updateMediaEndpoint } from "./updateMedia";

import { gatewayApi } from "@/ReduxToolkit";
import { shapeData, shapeMediaObject } from "./utils";
import { createEntityAdapter, isAnyOf } from "@reduxjs/toolkit";
import { concatErrorCache } from "@/ReduxToolkit/GatewayApi";
import { parsePropertyBag } from "@/ReduxToolkit/MediaProperties";
import { listenerMiddleware } from "@/ReduxToolkit/listenerMiddleware";
import { syncMedia, syncMediaDone } from "@/ReduxToolkit/offlineAssetSlice";
import { offlineMediaUploadsDB } from "@/storage";
import { presignedUploadMedia } from "./presignedUpload";

export const mediaAdaptor = createEntityAdapter({
	sortComparer: (a, b) => b.dateFileCreated.localeCompare(a.dateFileCreated),
});

const getFormData = (object) =>
	Object.keys(object).reduce((formData, key) => {
		formData.append(key, object[key]);
		return formData;
	}, new FormData());

const mediaEndpoints = (builder) => ({
	getAssetMedia: builder.query({
		query: (assetId) => ({
			url: `/api/Search/Asset/${assetId}/Media`,
			method: "GET",
		}),
		transformResponse: (response) => {
			const data = shapeData(response);
			return mediaAdaptor.setAll({}, data);
		},
		providesTags: (result, error, id) => {
			if (error) return concatErrorCache([{ type: "AssetMedia", id }], error);

			const media = Object.keys(result?.entities ?? {}).map((key) => ({
				type: "Media",
				id: key,
			}));

			return [{ type: "AssetMedia", id }, "OfflineUse", ...media];
		},
		onCacheEntryAdded: async (arg, { dispatch, cacheEntryRemoved }) => {
			dispatch(addAssetMediaState(arg));

			cacheEntryRemoved.then(() => {
				dispatch(removeAssetMediaState(arg));
			});
		},
	}),
	getMedia: builder.query({
		query: (id) => ({
			url: `/api/Media/${id}`,
			method: "GET",
		}),
		transformResponse: (response) => {
			return shapeMediaObject(parsePropertyBag(response));
		},
		providesTags: (_result, error, id) => {
			if (error) return concatErrorCache([{ type: "Media", id }], error);

			return [{ type: "Media", id }];
		},
	}),
	createMedia: builder.mutation({
		query: (media) => ({
			url: `/api/Media`,
			method: "POST",
			headers: {
				"content-type": "multipart/formdata",
			},
			body: getFormData(media),
		}),
		invalidatesTags: (_result, _error, media) => [
			{ type: "AssetMedia", id: media.groupingId },
		],
	}),
	uploadMedia: builder.mutation(uploadMediaEndpoint),
	updateMedia: builder.mutation(updateMediaEndpoint),
	updateMediaProperties: builder.mutation(updateMediaPropertiesEndpoint),
	emailMedia: builder.mutation({
		query: (email) => ({
			url: `/api/Media/emailmedia`,
			method: "PUT",
			body: email,
		}),
	}),
	deleteMedia: builder.mutation(deleteMediaEndpoint),
	saveCopy: builder.mutation(saveCopyEndpoint),
	presignedUpload: builder.mutation(presignedUploadMedia),
});

gatewayApi.injectEndpoints({
	endpoints: mediaEndpoints,
	overrideExisting: true,
});
gatewayApi.enhanceEndpoints({ addTagTypes: ["AssetMedia", "Media"] });

export const {
	useGetAssetMediaQuery,
	useGetMediaQuery,
	useCreateMediaMutation,
	useUpdateMediaMutation,
	useUpdateMediaPropertiesMutation,
	useEmailMediaMutation,
	useUploadMediaMutation,
	useDeleteMediaMutation,
	useSaveCopyMutation,
	usePresignedUploadMutation,
	endpoints: {
		getAssetMedia,
		getMedia,
		createMedia,
		uploadMedia,
		updateMedia,
		updateMediaProperties,
		emailMedia,
		deleteMedia,
		presignedUpload,
	},
} = gatewayApi;

export const { useQuerySubscription: useGetAssetMediaSubscription } =
	getAssetMedia;

export const useGetMediaTypeId = () => {
	const result = useGetMediaTypesQuery(undefined, {
		selectFromResult: ({ data, ...rest }) => {
			const result = data ?? {};
			return {
				data:
					Object.fromEntries(
						Object.entries(result).map(([key, value]) => [value, key])
					) ?? {},
				...rest,
			};
		},
	});

	return result;
};

export const useSelectMediaTypeId = (type) => {
	const mediaTypes = useGetMediaTypesQuery();

	return Object.entries(mediaTypes ?? {}).find(
		([key, value]) => value === type
	)?.[0];
};

listenerMiddleware.startListening({
	matcher: isAnyOf(getAssetMedia.matchFulfilled, getAssetMedia.matchRejected),
	effect: async (action, api) => {
		api.cancelActiveListeners();
		api.dispatch(gatewayApi.util.invalidateTags(["OfflineAsset"]));
	},
});

listenerMiddleware.startListening({
	actionCreator: syncMedia,
	effect: async (action, api) => {
		api.cancelActiveListeners();

		const fileNames = await offlineMediaUploadsDB.keys();

		if (fileNames.length === 0) {
			api.dispatch(syncMediaDone());
			return;
		}

		const mediaUploads = await Promise.all(
			fileNames.map(async (id) => {
				const file = await offlineMediaUploadsDB.getItem(id);
				const upload = api.dispatch(uploadMedia.initiate(file));

				try {
					const result = await upload.unwrap();
					return { result, unsubscribe: upload.unsubscribe };
				} catch (error) {
					return { error, unsubscribe: upload.unsubscribe };
				}
			})
		);

		mediaUploads.forEach((upload) => upload.unsubscribe());

		api.dispatch(syncMediaDone());
	},
});

listenerMiddleware.startListening({
	type: "assetMediaCacheDidUpdate",
	effect: async (action, api) => {
		const data = shapeData(action.payload.update);

		api.dispatch(
			gatewayApi.util.upsertQueryData(
				"getAssetMedia",
				action.payload.id,
				mediaAdaptor.setAll({}, data)
			)
		);
	},
});
