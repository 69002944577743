import { useMemo } from "react";

const {
	useGetPendingAssetDetailsQuery,
	useGetPendingMediaUploadsQuery,
	useGetPendingConditionReportQuery,
	useGetPendingDocumentationQuery,
} = require("../endpoints");

export const usePendingSyncItems = (assetId = null) => {
	const { data: assetDetails } = useGetPendingAssetDetailsQuery(undefined, {
		selectFromResult: ({ data }) => {
			const result = data ?? [];
			return { data: assetId ? result.includes(assetId) : result };
		},
	});
	const { data: mediaUploads } = useGetPendingMediaUploadsQuery(undefined, {
		selectFromResult: ({ data }) => {
			const result = data ?? [];
			return { data: assetId ? result.includes(assetId) : result };
		},
	});
	const { data: conditionReport } = useGetPendingConditionReportQuery(
		undefined,
		{
			selectFromResult: ({ data }) => {
				const result = data ?? [];
				return { data: assetId ? result.includes(assetId) : result };
			},
		}
	);
	const { data: documentation } = useGetPendingDocumentationQuery(undefined, {
		selectFromResult: ({ data }) => {
			const result = data ?? [];
			return { data: assetId ? result.includes(assetId) : result };
		},
	});

	return {
		assetDetails,
		mediaUploads,
		conditionReport,
		documentation,
	};
};

export const useAllPendingSyncItems = () => {
	const { assetDetails, mediaUploads, conditionReport, documentation } =
		usePendingSyncItems();

	const assets = useMemo(() => {
		const syncItems = new Set([
			...assetDetails,
			...mediaUploads,
			...conditionReport,
			...documentation,
		]);

		return [...syncItems.values()];
	}, [assetDetails, conditionReport, documentation, mediaUploads]);

	return assets;
};
