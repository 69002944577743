import { useFilterPanel } from "@/features/media-manager/hooks/useFilterPanel";
import { Desktop, Mobile } from "@/Responsive";
import { Button, Combobox, SLDSModal } from "@salesforce/design-system-react";
import {
	Control,
	FormProvider,
	useController,
	useFieldArray,
	useForm,
	useFormContext,
	useWatch,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectFilters } from "../../api/selectors";
import { nanoid } from "@reduxjs/toolkit";
import { DateFilter } from "@/features/asset-list/components/Filters/DateFilter";
import { noRangeDateOperations } from "@/features/asset-list/components/Filters/properties";
import classes from "./FilterPanel.module.scss";
import { removeFilter, setFilters } from "@/ReduxToolkit/DocumentationSlice";
import { useEffect } from "react";
import { isEqual } from "lodash";

export const DocumentationPanelContainer = ({
	accountId,
}: {
	accountId: string;
}) => {
	const filters = useSelector(selectFilters(accountId));
	const { reset, ...methods } = useForm({
		defaultValues: { filters: [...filters] },
	});

	// reset filters when either selector value changes, or accountId changes
	useEffect(() => {
		if (
			methods.control._formValues?.filters &&
			!isEqual(filters, methods.control._formValues?.filters)
		) {
			reset({ filters: filters });
		}
		//eslint-disable-next-line
	}, [filters, accountId]);

	const { isOpenFilterPanel, setIsOpenFilterPanel } = useFilterPanel();
	return (
		<FormProvider {...methods} reset={reset}>
			<Desktop>
				{isOpenFilterPanel && (
					<DocumentationFilterPanel accountId={accountId} />
				)}
			</Desktop>
			<Mobile>
				<SLDSModal
					onRequestClose={() => setIsOpenFilterPanel(false)}
					isOpen={isOpenFilterPanel}
					size="large"
				>
					<DocumentationFilterPanel accountId={accountId} />
				</SLDSModal>
			</Mobile>
		</FormProvider>
	);
};

const Operation = ({
	name,
	control,
	...props
}: {
	name: string;
	control: Control;
}) => {
	const { field } = useController({ control, name, defaultValue: "" });
	const options = noRangeDateOperations.map((item) => {
		return {
			...item,
			id: item.value,
		};
	});
	const operation = options?.find((option) => option.value === field.value);
	const selection = operation ? [operation] : [];
	return (
		<Combobox
			{...props}
			name={field.name}
			inputRef={field.ref}
			events={{
				// @ts-ignore
				onSelect: (e, { selection: [selection] }) => {
					field.onChange(selection?.value);
				},
			}}
			selection={selection}
			variant="readonly"
			options={options || []}
		/>
	);
};

const Filters = ({ accountId }: { accountId: string }) => {
	const { getValues, control } = useFormContext();

	const dispatch = useDispatch();
	const filterFields = useWatch({ name: "filters", control, exact: true });

	return (
		<>
			{filterFields?.map((field: any, index: any) => (
				<div
					className="slds-filters__item"
					key={`filter-field-${index}`}
					style={{
						display: "grid",
						gridTemplateColumns: "3fr 1fr",
						minHeight: "6rem",
					}}
				>
					<div>
						<Operation control={control} name={`filters[${index}].operation`} />
						<Value control={control} name={`filters[${index}].value`} />
					</div>
					<div className={classes.filterButtons}>
						<Button
							key={`apply-${field.id}`}
							onClick={() => {
								const filterObject = {
									id: accountId,
									filters: getValues("filters").filter(
										(item: any) => item.id && item.operation && item.value
									),
								};
								dispatch(setFilters(filterObject));
							}}
						>
							Apply
						</Button>
						<Button
							key={`discard-${field.id}`}
							onClick={() => {
								dispatch(removeFilter({ id: accountId, filterId: field.id }));
							}}
							iconCategory="utility"
							iconName="delete"
							iconSize="medium"
							variant="icon"
						/>
					</div>
				</div>
			))}
		</>
	);
};

const Value = ({ name, control }: { name: string; control: Control }) => {
	const {
		field: { onChange, name: fieldName, value },
	} = useController({ name, defaultValue: "", control });
	return <DateFilter onChange={onChange} name={fieldName} value={value} />;
};

export const DocumentationFilterPanel = ({
	accountId,
}: {
	accountId: string;
}) => {
	const { setIsOpenFilterPanel } = useFilterPanel();
	const { reset } = useFormContext();
	const { append } = useFieldArray({
		name: "filters",
	});
	const dispatch = useDispatch();
	const footer = (
		<div className="slds-filters__footer slds-grid slds-shrink-none">
			<button
				onClick={() => {
					append({ id: nanoid() });
				}}
				className="slds-button_reset slds-text-link"
				type="button"
			>
				Add Filter
			</button>

			<button
				className="slds-button_reset slds-text-link slds-col_bump-left"
				type="button"
				onClick={() => {
					reset({ filters: [] });
					dispatch(setFilters({ id: accountId, filters: [] }));
				}}
			>
				Remove All
			</button>
		</div>
	);

	const header = (
		<div className="slds-filters__header slds-grid slds-has-divider_bottom-space">
			<h2 className="slds-align-middle slds-text-heading_small">
				Notes Filters
			</h2>
			<Desktop>
				<Button
					className="slds-button slds-col_bump-left"
					variant="icon"
					iconCategory="utility"
					iconName="close"
					onClick={() => {
						setIsOpenFilterPanel(false);
					}}
				/>
			</Desktop>
		</div>
	);

	return (
		<div
			style={{
				padding: ".25rem",
				backgroundColor: "white",
				borderLeft: "1px solid #ccc",
				borderTop: "1px solid #ccc",
			}}
		>
			{header}
			<Filters accountId={accountId} />
			{footer}
		</div>
	);
};
