import { createSelector } from "@reduxjs/toolkit";
import { getCurrentUser } from "./endpoints";

export const selectCurrentUserResult = (state) =>
	getCurrentUser.select()(state);

export const selectCurrentUser = createSelector(
	selectCurrentUserResult,
	(result) => result.data ?? {}
);

export const selectRoles = createSelector(
	selectCurrentUser,
	(user) => user?.roles
);

export const selectRole = role => createSelector(selectRoles, roles => roles?.includes(role))
