import {
	Combobox,
	InputIcon,
	SLDSInput,
} from "@salesforce/design-system-react";
import { StaticInput } from "../StaticInput";
import { useController, useFormContext } from "react-hook-form";
import { useEffect, useRef } from "react";
import { ControlledConcurrency } from "../ControlledConcurrency/ControlledConcurrency";
import { useFormSection } from "../ControlledForm/FormSection";

export const Select = ({ options: optionsProp, name, span, ...props }) => {
	const {
		field: { ref, ...rest },
		fieldState: { error },
	} = useController({
		name: name,
	});
	const options = optionsProp.map((option, index) => {
		return typeof option === "string"
			? {
					id: `option-${name}-${index}`,
					label: option,
					value: option,
			  }
			: {
					id: `${props.formName}-option-${name}-${option.value}`,
					value: option.value,
					label: option.label,
			  };
	});
	const selection = options.filter((option) => {
		return option?.value?.toString() === rest.value?.toString();
	});
	const staticDisplay = selection[0]?.label || "";
	const comboboxValue = rest.value?.toString();
	const { isStatic, onClickEdit, isReadOnly } = useFormSection();
	const { focusField } = useFormContext();
	const focusRef = useRef(null);
	useEffect(() => {
		if (focusRef && focusField === name) {
			focusRef.current?.focus();
		}
	}, [focusField, name]);

	if (!isStatic && props.readOnly) {
		return (
			<span style={{ gridColumn: `span ${span ?? 2}` }}>
				<SLDSInput
					inputRef={ref}
					errorText={error?.message}
					label={props.labels.label}
					{...props}
					{...rest}
					value={staticDisplay}
					readOnly={true}
				/>
			</span>
		);
	}

	return isStatic ? (
		<StaticInput
			name={name}
			key={`field-${name}`}
			label={props.label || props.labels.label}
			value={staticDisplay}
			onClickEdit={() => onClickEdit(name)}
			readOnly={isReadOnly || props.readOnly}
			{...props}
		/>
	) : (
		<span
			ref={(ref) => (focusRef.current = ref?.querySelector("input"))}
			style={{ gridColumn: `span ${span ?? 2}` }}
		>
			<Combobox
				name={name}
				variant="readonly"
				options={options}
				selection={selection}
				events={{
					onSelect: (e, { selection: [selection] }) => {
						const isNumeric = Number(selection?.value).toString() !== "NaN";
						rest.onChange(
							isNumeric ? Number(selection?.value) : selection?.value || null
						);
					},
				}}
				inputRef={ref}
				errorText={error?.message}
				iconLeft={<InputIcon name="error" category="utility" />}
				{...props}
				labels={{ label: props.label || props.labels.label }}
				{...rest}
				value={comboboxValue}
			/>
		</span>
	);
};

export const ControlledSelect = ({
	options: optionsProp,
	name,
	span,
	...props
}) => {
	const {
		field: { ref, ...rest },
	} = useController({
		name: name,
	});
	const selection = optionsProp.filter((option) => {
		return option?.value?.toString() === rest.value?.toString();
	});
	const staticDisplay = selection[0]?.label || "";
	const { isConcurrencyError, concurrencyDiff } = useFormContext();
	const { focusField } = useFormContext();
	const focusRef = useRef(null);
	useEffect(() => {
		if (focusRef && focusField === name) {
			focusRef.current?.focus();
		}
	}, [focusField, name]);
	return !isConcurrencyError ? (
		<>
			<Select
				options={optionsProp}
				name={name}
				style={{ gridColumn: `span ${span ?? 2}` }}
				{...props}
			/>
		</>
	) : (
		isConcurrencyError && (
			<span style={{ gridColumn: `span ${props.span ?? 2}` }}>
				{concurrencyDiff[name] && (
					<ControlledConcurrency
						{...props}
						name={name}
						options={concurrencyOptions(optionsProp, concurrencyDiff[name])}
					/>
				)}
				{!concurrencyDiff[name] && (
					<SLDSInput
						name={name}
						key={`field-${name}`}
						label={props.label || props.labels.label}
						value={staticDisplay}
						readOnly={true}
						span={span}
					/>
				)}
			</span>
		)
	);
};

const concurrencyOptions = (options, concurrencyFields) => {
	return concurrencyFields.map((errorOption) => {
		const concurrencyOptions =
			errorOption === 0 || errorOption
				? options[
						options.findIndex(
							(val) => val.value.toString() === errorOption.toString()
						)
				  ]
				: {
						label: "-- Leave Blank --",
						value: errorOption,
				  };
		return concurrencyOptions;
	});
};
