import { offlineMediaUploadsDB } from "@/storage";
import { selectMediaById } from "../selectors/assetMedia";

export const deleteMediaEndpoint = {
	queryFn: async (
		{ assetId, media },
		{ dispatch, getState },
		_extra,
		baseQuery
	) => {
		const deleteMedia = async (id) => {
			const { isPendingUpload } = selectMediaById(assetId)(getState(), id);

			const response = isPendingUpload
				? await (async () => {
						await offlineMediaUploadsDB.removeItem(id);
						return { data: id };
				  })()
				: await baseQuery({
						url: `api/Media/${id}`,
						method: "DELETE",
				  });

			if (response.error) throw response.error;

			return response;
		};

		const update = Array.isArray(media)
			? await Promise.allSettled(media.map((id) => deleteMedia(id)))
			: await Promise.allSettled([deleteMedia(media)]);

		const errors = Array.isArray(update)
			? update.filter((update) => update.status === "rejected")
			: [update.error].filter((error) => error);

		if (errors.length) return { error: errors };

		return {
			data: assetId,
		};
	},
	invalidatesTags: (assetId, _error, id) => [
		{ type: "AssetMedia", id: assetId },
	],
};
