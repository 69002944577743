import React, { useEffect, useState } from "react";
import { AssetContainer } from "@/features/asset/AssetContainer";
import classes from "./SplitView.module.scss";
import { useSplitViewAsset } from "../../hooks/useSplitViewAsset";
import { MobileTableView } from "../MobileTableView/MobileTableView";
import { Button } from "@salesforce/design-system-react";
import { useAssetList } from "../../api/useLazyAssetList";

const AssetListSplitView = () => {
	const { data } = useAssetList();
	const { assetId, setAssetId } = useSplitViewAsset();
	const [isFullView, setIsFullView] = useState(false);

	useEffect(() => {
		if (!assetId && data.length) {
			setAssetId(data[0].id);
		}
	}, [assetId, data, setAssetId]);

	return (
		<div
			className={isFullView ? classes.fullView : classes.gridContainer}
			style={{ height: "80vh" }}
		>
			{!isFullView && (
				<div>
					<MobileTableView data={data} />
				</div>
			)}
			<div>
				<Button
					style={{ height: "100%" }}
					iconCategory="utility"
					iconSize="x-small"
					iconVariant="border-filled"
					iconName={isFullView ? "right" : "left"}
					variant="icon"
					onClick={() => setIsFullView(!isFullView)}
				/>
			</div>
			<div style={{ overflow: "auto" }}>
				<AssetContainer />
			</div>
		</div>
	);
};

export default AssetListSplitView;
