import { useFormContext } from "react-hook-form";
import { TaskFormType } from "./TaskForm";
import { useGetTaskTypesQuery } from "../api/endpoints";
import classes from "./InvoiceInfo.module.scss";
import { FaDollarSign } from "react-icons/fa6";
import { useState } from "react";

export const InvoiceInfo = () => {
	const { data: taskTypes } = useGetTaskTypesQuery();

	const { watch, register } = useFormContext<TaskFormType>();

	const taskType = watch("taskTypeId");
	const taskTypeData = taskTypes?.find((item) => item.id === taskType);

	if (taskTypeData?.name !== "Pay Invoice") {
		return null;
	}

	/**
	 * Supplier Name VARCHAR(100) Required Field
	 * Invoice # VARCHAR(50) Required Field
	 * Amount Approved (Currency) Requried Field   Non-zero value (negative and positive values are valid)
	 */
	return (
		<div>
			<label className="slds-form-element__label">
				<abbr className="slds-required" title="required">
					*
				</abbr>
				Supplier Name
			</label>
			<input
				className="slds-input"
				{...register("invoice.supplierName")}
				placeholder="Supplier Name"
				required
			/>
			<label className="slds-form-element__label">
				<abbr className="slds-required" title="required">
					*
				</abbr>
				Invoice #
			</label>
			<input
				className="slds-input"
				{...register("invoice.invoiceNumber")}
				placeholder="Invoice #"
				required
			/>
			<label className="slds-form-element__label">
				<abbr className="slds-required" title="required">
					*
				</abbr>
				Amount Approved
			</label>
			<CurrencyInput />
		</div>
	);
};

const CurrencyInput = () => {
	const { watch, setValue } = useFormContext<TaskFormType>();
	const rawValue = watch("invoice.amountApproved");

	// Local state for controlled input
	const [inputValue, setInputValue] = useState(rawValue?.toString() || "");

	const formatCurrency = (value: number) => {
		return new Intl.NumberFormat("en-US", {
			style: "decimal",
			minimumFractionDigits: value % 1 === 0 ? 0 : 2,
			maximumFractionDigits: 2,
		}).format(value);
	};

	const parseCurrency = (value: string) => {
		// Allow only numbers, a single decimal, and an optional leading negative sign
		const cleanedValue = value.replace(/[^0-9.-]/g, "");

		// Ensure only one leading negative sign
		const negativeMatch = cleanedValue.match(/^(-?)(.*)/);
		const negativeSign = negativeMatch ? negativeMatch[1] : "";
		let numberPart = negativeMatch ? negativeMatch[2] : "";

		// Handle multiple decimals (keep only the first)
		const parts = numberPart.split(".");
		if (parts.length > 2) {
			numberPart = `${parts[0]}.${parts.slice(1).join("")}`;
		}

		// Limit to two decimal places
		if (numberPart.includes(".")) {
			const [intPart, decimalPart] = numberPart.split(".");
			numberPart = `${intPart}.${decimalPart.slice(0, 2)}`;
		}

		return negativeSign + numberPart;
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const raw = e.target.value;
		const parsed = parseCurrency(raw);

		setInputValue(parsed);
		setValue("invoice.amountApproved", parsed);
	};

	const handleBlur = () => {
		if (inputValue === "" || inputValue === "-") return;

		const numericValue = parseFloat(inputValue);
		if (!isNaN(numericValue)) {
			const formatted = formatCurrency(numericValue);
			setInputValue(formatted);
			setValue("invoice.amountApproved", numericValue.toString());
		}
	};

	return (
		<div className={classes.currencyContainer}>
			<FaDollarSign className={classes.dollarSign} />
			<input
				value={inputValue}
				onChange={handleChange}
				onBlur={handleBlur}
				className={classes.currencyInput}
				placeholder="Amount Approved"
				type="text"
				required
			/>
		</div>
	);
};
