import { useSelectionDropdownActions } from "@/features/media-manager/hooks/useSelectionDropdownActions";
import { useFilterPanelActions } from "@/features/media-manager/hooks/useFilterPanelActions";
import { Desktop, Mobile } from "@/Responsive";
import { ButtonGroup, Dropdown } from "@salesforce/design-system-react";
import { FileUploadButton } from "../../buttons/AddPhotoButton/FileUploadButton";
import { FilterPanelButton } from "../../buttons/FilterPanelButton/FilterPanelButton";
import { IncludeWebReadyButton } from "../../buttons/IncludeWebReadyButton/IncludeWebReadyButton";
import { ShowContentButton } from "../../buttons/ShowContentButton/ShowContentButton";
import { SortMediaButton } from "../../buttons/SortMediaButton/SortMediaButton";
import { DesktopSelectionGroup } from "../DesktopSelectionGroup/DesktopSelectionGroup";
import { useGetAssetMediaQuery } from "@/features/media-manager/api/endpoints";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { useIncludeWebReady } from "@/features/media-manager/api/hooks/useIncludeWebReady";
import { ALL_MEDIA } from "@/ReduxToolkit/MediaSlice";
import { useIsOnline } from "@/features/offline-use";

export const MobileSelectionGroup = () => {
	const { assetId } = useAssetId();
	const { isOpenFilterPanel, toggleFilterPanel } = useFilterPanelActions();
	const [includeWebReady, toggleIncludeWebReady] = useIncludeWebReady(assetId);

	const selectAllOrNone = useSelectionDropdownActions({
		bucket: ALL_MEDIA,
		assetId,
		selectionActions: ["selectAll", "selectNone"],
	});
	const selectionActions = useSelectionDropdownActions({
		bucket: ALL_MEDIA,
		assetId,
		selectionActions: [
			"addSelectedToWeb",
			"addTagsToSelected",
			"removeTagsFromSelected",
			"emailSelected",
			"deleteSelected",
		],
	});

	const toggles = [
		{
			label: "Open Filter Panel",
			value: toggleFilterPanel,
			disabled: isOpenFilterPanel,
		},
		{
			label: `${includeWebReady ? "Hide" : "Show"} Web Ready Media`,
			value: toggleIncludeWebReady,
		},
	];

	const handleSelectAction = (e) => e.value();
	return (
		<>
			<Dropdown
				iconName="multi_select_checkbox"
				iconCategory="utility"
				align="right"
				iconVariant="border-filled"
				options={selectAllOrNone}
				onSelect={handleSelectAction}
			/>
			<Dropdown
				iconName="threedots_vertical"
				iconCategory="utility"
				iconVariant="border-filled"
				align="right"
				options={[...toggles, ...selectionActions]}
				onSelect={handleSelectAction}
			/>
		</>
	);
};
export const AllMediaControls = ({ isContentVisible, onShowContent }) => {
	const { assetId } = useAssetId();
	const { isSuccess } = useGetAssetMediaQuery(assetId);
	const isOnline = useIsOnline();
	return (
		isSuccess && (
			<>
				<Desktop>
					<DesktopSelectionGroup
						bucket={ALL_MEDIA}
						selectionActions={[
							"addSelectedToWeb",
							"addTagsToSelected",
							"removeTagsFromSelected",
							"emailSelected",
							"deleteSelected",
						]}
					/>
				</Desktop>
				{isOnline ? (
					<ButtonGroup variant="list">
						<FileUploadButton />
						<Desktop>
							<IncludeWebReadyButton />
						</Desktop>
						<SortMediaButton />
						<Desktop>
							<FilterPanelButton />
						</Desktop>
						<Mobile>
							<MobileSelectionGroup />
						</Mobile>
					</ButtonGroup>
				) : (
					<FileUploadButton />
				)}
				<ShowContentButton
					isVisible={isContentVisible}
					onShowContent={onShowContent}
				/>
			</>
		)
	);
};

MobileSelectionGroup.displayName = "SLDSPageHeaderControl";
AllMediaControls.displayName = "SLDSPageHeaderControl";
