import { Button } from "@salesforce/design-system-react";
import { useLazyGetOfflineSyncItemsQuery } from "../../api/endpoints/syncAssetDetails";
import { useDispatch, useSelector } from "react-redux";
import {
	selectIsSyncing,
	startSyncing
} from "@/ReduxToolkit/offlineAssetSlice";

export const SyncRetry = () => {
	const [trigger] = useLazyGetOfflineSyncItemsQuery();
	const isSyncing = useSelector(selectIsSyncing);

	const dispatch = useDispatch();

	const sync = async () => {
		const { data } = await trigger();
		dispatch(startSyncing(data));
	};
	return (
		<Button
			iconCategory="utility"
			iconName="sync"
			iconVariant="border-filled"
			variant="icon"
			onClick={sync}
			disabled={isSyncing}
		/>
	);
};
