import React, { useState } from "react";
import { Button, SLDSModal } from "@salesforce/design-system-react";
import cardStyles from "./MediaCardPreview.module.scss";
import { AssetImage } from "@/features/media-manager/components/image-editor/ImageEditor";
import {
	ImageEditorProvider,
	useEditorState,
} from "../image-editor/ToastUIContext";
import { PhotoToolBar } from "../PhotoToolbar/PhotoToolBar";
import { MediaPreviewTags } from "../tags/MediaPreviewTags";
import { FBShare, TwitterShare } from "../SocialShare/SocialShare";
import classes from "../PhotoToolbar/PhotoToolbar.module.scss";
import { MediaPreviewTitle } from "./MediaPreviewTitle";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { useSelector } from "react-redux";
import { selectAllMediaIds } from "../../api/selectors";
import { selectWebReadyMediaIds } from "../../api/selectors/webReadyMedia";
import { useMediaType } from "../../api/hooks/useMediaType";
import { ALL_MEDIA } from "@/ReduxToolkit/MediaSlice";
import { useMedia } from "../../api/hooks/useAllMedia";
import { useSaveImage } from "../../api/hooks/useSaveImage";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export const MediaCardPreview = NiceModal.create(({ id, bucket }) => {
	const [currentImageId, setCurrentImageId] = useState(id);
	const { data: image, isSuccess } = useMedia(currentImageId || id);

	// const { isOpen } = useSelector(selectModal);
	// const dispatch = useDispatch();
	const modal = useModal();

	const imageIds = useSelector(
		bucket === ALL_MEDIA
			? selectAllMediaIds(image?.groupingId)
			: selectWebReadyMediaIds(image?.groupingId)
	);
	const currentIndex = imageIds?.indexOf(currentImageId || id);

	const onNextImage = () =>
		currentIndex + 1 === imageIds.length
			? setCurrentImageId(imageIds[0])
			: setCurrentImageId(imageIds[currentIndex + 1]);

	const onPreviousImage = () =>
		currentIndex === 0
			? setCurrentImageId(imageIds[imageIds.length - 1])
			: setCurrentImageId(imageIds[currentIndex - 1]);

	const handleClose = () => {
		setCurrentImageId(null);
		modal.remove();
	};

	return (
		isSuccess && (
			<>
				<SLDSModal
					onRequestClose={handleClose}
					isOpen={modal.visible}
					className="new-modal"
					portalClassName="new-portal"
					containerClassName={cardStyles.container}
					contentClassName={cardStyles.content}
					size="large"
				>
					<ImageEditorProvider>
						<EditorModal
							image={image}
							src={image?.path}
							onPreviousImage={onPreviousImage}
							onNextImage={onNextImage}
						/>
					</ImageEditorProvider>
				</SLDSModal>
			</>
		)
	);
});

NiceModal.register("media-card-preview", MediaCardPreview);

export const useMediaPreviewModal = () => useModal("media-card-preview");

function EditorModal(props) {
	const state = useEditorState();
	const { isPhoto, isVideo } = useMediaType();
	const { isUpdating } = useSaveImage();

	return (
		<>
			{state.isEditing || state.isSaving || isUpdating ? (
				<div className={cardStyles.photosNavPrev}></div>
			) : (
				<div className={cardStyles.photosNavPrev}>
					<Button
						type="button"
						className={[cardStyles.photosNavPrev, cardStyles.prevButton]}
						iconCategory="utility"
						iconName="chevronleft"
						inverse
						onClick={props.onPreviousImage}
					/>
				</div>
			)}

			<div className={cardStyles.infoContainer}>
				<div className={classes.toolbar}>
					<MediaPreviewTitle image={props.image} />
					{isPhoto(props.image) && (
						<div className={classes.editButtons}>
							<PhotoToolBar image={props.image} />
						</div>
					)}
				</div>
				<MediaPreviewTags image={props.image} />
				<div className={classes.editShareContainer}>
					<FBShare photoURL={props.image?.path} fill="white" />
					<TwitterShare photoURL={props.image?.path} fill="white" />
				</div>
			</div>

			{isPhoto(props.image) && (
				<div className={cardStyles.photoContainer}>
					<AssetImage url={props.src} image={props.image} />
				</div>
			)}

			{isVideo(props.image) && (
				<div className={cardStyles.videoContainer}>
					<VideoPlayer url={props.src} />
				</div>
			)}

			{state.isEditing || state.isSaving || isUpdating ? (
				<div className={cardStyles.photosNavNext}></div>
			) : (
				<div className={cardStyles.photosNavNext}>
					<Button
						type="button"
						className={[cardStyles.photosNavNext, cardStyles.nextButton]}
						iconCategory="utility"
						iconName="chevronright"
						inverse
						onClick={props.onNextImage}
					/>
				</div>
			)}
		</>
	);
}
