import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal } from "@salesforce/design-system-react";
import classes from "./InfoModal.module.scss";
import { Task } from "../Types";
import { TaskForm } from "./TaskForm";

export const TaskInfoModal = NiceModal.create(
	({ task }: { task?: Task | undefined }) => {
		const modal = useModal();
		const onClose = () => {
			modal.hide();
		};

		return (
			<Modal
				heading={task?.id ? "Update Task" : "Create Task"}
				isOpen={modal.visible}
				contentClassName={classes.container}
				onRequestClose={onClose}
				size="large"
			>
				<TaskForm task={task} onSubmitSuccess={onClose} onCancel={onClose} />
			</Modal>
		);
	}
);

NiceModal.register("task-info-modal", TaskInfoModal);

export const useTaskInfoModal = () => useModal("task-info-modal");
