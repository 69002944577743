import { gatewayApi } from "@/ReduxToolkit";
import { concatErrorCache } from "@/ReduxToolkit/GatewayApi";
const accountEndpoints = (builder) => ({
	getByAccountNumber: builder.query({
		method: "GET",
		query: (accountNumber) =>
			`api/Search/Account/GetByAccountNumber/${accountNumber}`,
		providesTags: (result, error, id) => {
			if (error) return concatErrorCache([{ type: "Account", id }], error);

			return [{ type: "Account", id }];
		},
		transformResponse: (response) => {
			return response;
		},
	}),
});

gatewayApi.injectEndpoints({
	endpoints: accountEndpoints,
	overrideExisting: true,
});

export const { useGetByAccountNumberQuery } = gatewayApi;
