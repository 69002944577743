import { Modal } from "@salesforce/design-system-react";
import dayjs from "dayjs";
import { Button } from "@salesforce/design-system-react";
import { AiOutlineArrowRight } from "react-icons/ai";
import classes from "./InspectionUpdate.module.scss";
import { useAssetDetails } from "@/features/asset-details-tab/api/useAssetDetails";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export const InspectionUpdate = NiceModal.create(({ newDate, assetId }) => {
	const modal = useModal();
	const { data: asset, update } = useAssetDetails(assetId);
	const lastInspection = asset?.lastInspection;
	const firstInspection = asset?.salesInfo?.firstInspection;
	const handleIgnore = () => {
		const ignoredDates = JSON.parse(
			localStorage.getItem("ignoreInspectionDate")
		);
		const newDates = {
			...ignoredDates,
			[assetId]: dayjs().toISOString(),
		};
		localStorage.setItem("ignoreInspectionDate", JSON.stringify(newDates));
		modal.remove();
	};

	const updateInspectionDates = () => {
		const date = dayjs(newDate).utc(true).toISOString();
		const submission = !firstInspection
			? {
					...asset,
					id: assetId,
					salesInfo: {
						...asset.salesInfo,
						firstInspection: date,
					},
					lastInspection: date,
			  }
			: {
					...asset,
					id: assetId,
					lastInspection: date,
			  };
		update(submission);
		modal.remove();
	};
	return (
		<Modal
			footer={
				<div className={classes.footer}>
					<Button label="No" onClick={() => handleIgnore()} />
					<Button
						label="Yes"
						variant="brand"
						onClick={() => updateInspectionDates("submit")}
					/>
				</div>
			}
			heading="Update Last Inspection"
			dismissOnClickOutside={false}
			isOpen={modal.visible}
			onRequestClose={modal.remove}
		>
			<div className={classes.contentContainer}>
				<p>Would you like to update the Inspection Date to match this photo?</p>
				<div className={classes.inspectionDates}>
					<div className={classes.date}>
						<label htmlFor="lastInspection">Last Inspection</label>
						<p id="lastInspection">
							{lastInspection
								? dayjs(lastInspection).format("MM/DD/YYYY")
								: "None"}
						</p>
					</div>
					<AiOutlineArrowRight />
					<div className={classes.date}>
						<label htmlFor="newInspection">Last Inspection</label>
						<p id="newInspection">{dayjs(newDate).format("MM/DD/YYYY")}</p>
					</div>
				</div>
				{!firstInspection && (
					<div className={classes.inspectionDates}>
						<div className={classes.date}>
							<label htmlFor="newFirstInspection">First Inspection</label>
							<p id="newFirstInspection">
								{dayjs(newDate).format("MM/DD/YYYY")}
							</p>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
});

NiceModal.register("inspection-update", InspectionUpdate);

export const useInspectionUpdateModal = () => useModal("inspection-update");
