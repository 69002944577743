import { useController, useFormContext, useWatch } from "react-hook-form";
import {
	Checkbox,
	Combobox,
	Pill,
	Popover,
} from "@salesforce/design-system-react";
import {
	COMMUNITY,
	FEATURES,
	GENERAL,
	INTERIOR,
	UPDATES,
	APPLIANCES,
	EXTERIOR,
	CONDITION,
} from "./components/constants";
import { ChangeEvent, useMemo } from "react";

import styles from "./ListingCheckboxes.module.scss";
const listingDetailsSections = [
	GENERAL,
	COMMUNITY,
	FEATURES,
	UPDATES,
	INTERIOR,
	APPLIANCES,
	EXTERIOR,
	CONDITION,
];

interface SectionField {
	label: string;
	path: string;
}

interface Section {
	label: string;
	fields: SectionField[];
}

export const ListingCheckboxes = () => {
	return (
		<div style={{ display: "flex", flexWrap: "wrap" }}>
			{listingDetailsSections.map((section) => {
				return (
					<div style={{ margin: ".5rem" }}>
						<h2 className="slds-text_title slds-text-heading_small">
							{section.label}
						</h2>
						{section.fields.map((field) => {
							return (
								<ListingCheckbox
									key={`field-${field.path}`}
									name={field.path}
									label={field.label}
								/>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};

function ListingCheckbox({ name, label }: { name: string; label: string }) {
	const { field } = useController({ name });

	return (
		<Checkbox
			name={field.name}
			labels={{ label }}
			checked={field.value ?? false}
			onChange={(_e: ChangeEvent, value: { checked: boolean }) => {
				field.onChange(value.checked);
			}}
		/>
	);
}

interface ListingOption {
	id: string;
	key: string;
	label: string;
	value: boolean;
}

export const ListingComboboxes = () => {
	return (
		<>
			<label className="slds-form-element__label">Details</label>
			<div style={{ display: "flex", flexWrap: "wrap", gap: ".5rem" }}>
				{listingDetailsSections.map((section) => (
					<ListingCombobox
						section={section}
						key={`combobox-${section.label}`}
					/>
				))}
			</div>
		</>
	);
};

function ListingCombobox({ section }: { section: Section }) {
	const { setValue } = useFormContext();
	const values = useWatch({ name: section.fields.map((field) => field.path) });
	const options: ListingOption[] = useMemo(
		() =>
			section.fields.map((field, index) => ({
				id: field.path,
				key: field.path,
				label: field.label,
				value: values[index],
			})),
		[values, section.fields]
	);

	const selected = options.filter((option) => option.value === true);
	return (
		<div style={{ width: "calc(25% - 0.5rem)" }}>
			<Combobox
				multiple
				className={styles.webCombobox}
				options={options}
				selection={selected}
				variant="popover"
				value={section.label}
				inheritWidthOf="menu"
				popover={
					<Popover
						body={
							<>
								{section.fields.map((field) => {
									return (
										<ListingCheckbox
											key={`field-${field.path}`}
											name={field.path}
											label={field.label}
										/>
									);
								})}
							</>
						}
					/>
				}
				events={{
					onSelect: (
						_e: ChangeEvent,
						{ selection }: { selection: ListingOption[] }
					) => {
						selection.forEach((selected) => setValue(selected.id, true));
					},
				}}
			></Combobox>
			{selected.length > 0 && (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						padding: ".5rem",
					}}
				>
					{selected.map((item) => {
						return (
							<p key={`pill-${item.key}`}>
								<Pill
									labels={{ label: item.label }}
									variant="option"
									onRemove={() =>
										setValue(item.key, false, { shouldDirty: true })
									}
								/>
							</p>
						);
					})}
				</div>
			)}
		</div>
	);
}
