import React, { useState } from "react";
import {
	PageHeader,
	Icon,
	Dropdown,
	Button,
	PageHeaderControl,
} from "@salesforce/design-system-react";
import { setFilterValues } from "../store/insuranceQueueSlice";
import { useDispatch } from "react-redux";
import { useAutoRefunds } from "../hooks/useAutoRefunds";
export const InsuranceTableHeader = (props) => {
	const dispatch = useDispatch();

	const filterChangedHandler = (attribute, value) => {
		dispatch(setFilterValues({ attribute, value }));
	};
	const { approveAllAutoRefunds } = useAutoRefunds();
	const [title, setTitle] = useState("All Items");
	const actions = () => (
		<PageHeaderControl>
			{title === "Auto Refund" ? (
				<Button
					onClick={() => dispatch(approveAllAutoRefunds())}
					style={{ padding: "4px" }}
				>
					Approve All
				</Button>
			) : null}
		</PageHeaderControl>
	);
	return (
		<PageHeader
			icon={
				<Icon
					assistiveText={{ label: "Home" }}
					category="standard"
					name="case"
				/>
			}
			info={props.info || ""}
			label="Insurance Cancellation Queue"
			onRenderActions={actions}
			nameSwitcherDropdown={
				<Dropdown
					width="x-small"
					align="right"
					assistiveText={{ icon: "Name Switcher" }}
					buttonClassName="slds-button_icon-small"
					buttonVariant="icon"
					iconCategory="utility"
					iconName="down"
					length="7"
					id="page-header-name-switcher-dropdown"
					checkmark
					onSelect={(value) => {
						setTitle(value.label);
						filterChangedHandler("reviewStatus", value.value);
					}}
					options={[
						{ label: "All Items", value: "" },
						{ type: "divider" },
						{ label: "Needs Review", value: "needsReview" },
						{ label: "Auto Refund", value: "autoRefund" },
						{ label: "Pending", value: "pending" },
						{ label: "Completed", value: "completed" },
					]}
				/>
			}
			title={title}
			variant="object-home"
		/>
	);
};
