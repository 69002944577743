import { gatewayApi } from "../../../ReduxToolkit";
import {
	Sale,
	Fund,
	PaymentType,
	BuyerSearchPayload,
	BuyerSearchResponse,
	StateValidation,
	CreateSalePayload,
	LoanDetails,
} from "../types";
const enhancedApi = gatewayApi.enhanceEndpoints({
	addTagTypes: ["Sales", "Funds", "Loans"],
});

export const bookingEndpoints = enhancedApi.injectEndpoints({
	endpoints: (builder) => ({
		getSaleById: builder.query<Sale, string>({
			query: (id) => ({
				url: `api/Sales/${id}`,
				method: "GET",
			}),
			providesTags: (_res, _err, id) => [{ type: "Sales", id }],
		}),
		deleteSaleById: builder.mutation<void, string>({
			query: (id) => ({
				url: `api/Sales/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (_res, _error, id) => [{ type: "Sales", id }],
		}),
		createSale: builder.mutation<Sale, CreateSalePayload>({
			query: (body) => ({
				url: "api/Sales",
				method: "POST",
				body,
			}),
		}),
		updateSale: builder.mutation<void, Sale>({
			query: (body) => ({
				url: "api/Sales",
				method: "PUT",
				body,
			}),

			invalidatesTags: (_res, _error, { id }) => [{ type: "Sales", id }],
		}),
		getFundsById: builder.query<Fund, string>({
			query: (id) => ({ url: `api/Funds/${id}` }),
			providesTags: (_res, _error, id) => [{ type: "Funds", id }],
		}),
		deleteFundsById: builder.mutation<void, string>({
			query: (id) => ({
				url: `api/Funds/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (_res, _error, id) => [{ type: "Funds", id }],
		}),
		createFunds: builder.mutation<Fund, Fund>({
			query: (body) => ({
				url: "api/Funds",
				method: "POST",
				body,
			}),
			invalidatesTags: (result, _err, body) => [
				{ type: "Sales", id: body.paymentAllocations[0].saleId },
				{ type: "Funds", id: result?.id },
			],
		}),
		updateFunds: builder.mutation<void, Fund>({
			query: (body) => ({
				url: "api/Funds",
				method: "PUT",
				body,
			}),
			invalidatesTags: (_res, _err, body) => [
				{ type: "Sales", id: body.paymentAllocations[0].saleId },
			],
		}),
		getPayments: builder.query<Fund[], string | undefined>({
			query: (id) => ({ url: `api/Search/Payments?saleId=${id ?? 0}` }),
			providesTags: (_result, _error, id) => [{ type: "Funds", id }],
		}),
		getPaymentTypes: builder.query<PaymentType[], void>({
			query: () => ({ url: "api/LookupData/PaymentTypes" }),
		}),
		buyersSearch: builder.query<BuyerSearchResponse, BuyerSearchPayload>({
			query: (body) => ({
				url: `api/Search/Buyers?${buyerSearchParametize(body)}`,
			}),
		}),
		getStates: builder.query<StateValidation[], void>({
			query: () => ({
				url: "api/LookupData/States",
			}),
		}),
		getNewLoanInfo: builder.query<LoanDetails[], string | undefined>({
			query: (id) => ({ url: `applications/${id ?? 0}` }),
			providesTags: (_result, _error, id) => [{ type: "Loans", id }],
		}),
	}),
});

const buyerSearchParametize = (params: BuyerSearchPayload) => {
	const queryString = new URLSearchParams();
	Object.entries(params).forEach(([key, value]) => {
		if (value || value === 0) {
			queryString.append(key, value.toString());
		}
	});
	return queryString.toString();
};

export const {
	useGetSaleByIdQuery,
	useBuyersSearchQuery,
	useCreateFundsMutation,
	useCreateSaleMutation,
	useDeleteFundsByIdMutation,
	useDeleteSaleByIdMutation,
	useGetPaymentsQuery,
	useGetFundsByIdQuery,
	useUpdateSaleMutation,
	useUpdateFundsMutation,
	useGetPaymentTypesQuery,
	useGetStatesQuery,
	useGetNewLoanInfoQuery,
	endpoints: { getSaleById },
	util: bookingUtilities,
} = bookingEndpoints;
