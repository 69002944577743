import { DataTableCell, Input } from "@salesforce/design-system-react";
import { get } from "lodash";
import { ChangeEvent, useEffect } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { BASE_PRICE } from "../constants";
import { useFormSection } from "@/Components/Forms";
import { StaticInput } from "./StaticInput";
import { getColumn } from "./utils";
import classes from "./PercentCell.module.scss";
import { useNextField } from "../hooks/useNextField";
import { WorksheetCellProps } from "../types";

export const PercentCell = ({ children, ...props }: WorksheetCellProps) => {
	const column = getColumn(children as string);
	const percentName = `${children}Percent`;
	const percent = get(props.data, `${children}Percent`);

	const {
		field: { ref: percentRef, value: percentValue, onChange },
		fieldState: { error },
	} = useController({ name: percentName });
	const { isEditable } = useFormSection();

	const {
		formState: { isDirty },
	} = useFormContext();

	const {
		field: { onChange: setValue },
	} = useController({ name: children as string });

	const [pct, base] = useWatch({
		name: [percentName, `${column}.${BASE_PRICE.key}`],
	});

	const { isStatic, editField } = useFormSection(column);

	const initialValue = get(props.data, children as string);
	const calculation = Number(((pct / 100) * Number(base)).toFixed(2));

	useEffect(() => {
		if (
			isDirty &&
			isEditable &&
			(Number(pct) || Number(base)) &&
			calculation !== initialValue
		) {
			setValue(
				Number(((pct / 100) * Number(base)).toFixed(2)) ??
					Number(get(props.data, children as string))
			);
		}
	}, [
		base,
		calculation,
		children,
		initialValue,
		isDirty,
		pct,
		props.data,
		setValue,
		isEditable,
	]);

	const nextField = useNextField({ name: percentName });

	const handleEnter = (e: KeyboardEvent) => {
		if (e.key === "Enter") {
			e.preventDefault();
			nextField();
		}
	};
	return (
		<DataTableCell title={`${props.label} ${props.item?.category}`} {...props}>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<div className={classes.container}>
					{(isStatic || !isEditable) && error === undefined ? (
						<div className={classes.staticPercentWrapper}>
							<StaticInput
								editable={true}
								onClickEdit={() => editField(percentName)}
								containerStyle={{ paddingLeft: "4rem" }}
							>
								{percentValue}%
							</StaticInput>
						</div>
					) : (
						<Input
							type="number"
							styleInput={{
								width: "4rem",
								minHeight: "unset",
								lineHeight: "1.5rem",
								paddingRight: 0,
								textAlign: "right",
							}}
							fixedTextRight="%"
							inputRef={percentRef}
							onChange={(_e: ChangeEvent, { value }: { value: string }) => {
								onChange(Number(value));
							}}
							defaultValue={percent}
							value={Number(percentValue)?.toString()}
							disabled={!isEditable}
							onKeyPress={handleEnter}
						/>
					)}
					<StaticInput
						editable={false}
						containerStyle={{
							paddingRight: isStatic ? "1.5rem" : "1rem",
						}}
					>
						{((pct / 100) * Number(base)).toFixed(2) ??
							Number(get(props.data, children as string))}
					</StaticInput>
				</div>
			</div>
		</DataTableCell>
	);
};
PercentCell.displayName = DataTableCell.displayName;
