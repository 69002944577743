import { useEffect } from "react";
import { Task, TaskListPayload, TaskListResponse } from "../Types";
import { TaskListAdapter, useLazySearchTasksQuery } from "./endpoints";

export const useTasksQuery = (query: TaskListPayload) => {
	const [trigger, { data, ...rest }] = useLazySearchTasksQuery();

	const taskData: TaskListResponse = {
		...data,
		items: data ? selectAll(data.items) : [],
		totalItems: data?.totalItems ?? 0,
		itemsPerPage: data?.itemsPerPage ?? 0,
		pageNumber: data?.pageNumber ?? 0,
	};

	useEffect(() => {
		trigger(query);
	}, [query, trigger]);

	return {
		data: taskData,
		...rest,
	};
};

const selectAll = (items: TaskListAdapter): Task[] =>
	items?.ids
		.map((id) => items.entities[id])
		.filter((item): item is Task => item !== undefined);

export const DEFAULT_TASK_QUERY: TaskListPayload = {
	paginationCriteria: {
		itemsPerPage: 20,
		pageNumber: 0,
	},
	filteringCriteria: [],
	sortCriteria: {
		direction: "desc",
		propertyPath: "createdDate",
	},
	fuzzySearchCriteria: {
		propertyPath: ["name"],
		value: "",
	},
};
