import styles from "../PricingWorksheet.module.scss";
import {
	CASH_EQUITY,
	HOME_INVOICE_PRICE,
	HOME_VALUE,
	LAND_VALUE,
	NADA_BASE,
	ORIGINAL_LAND_APPRAISAL,
	ORIGINAL_SALES_PRICE,
	WEB_PRICE,
	PURCHASE_OPTION,
	RECOVERY_LAND_CREDIT,
} from "../constants";
import { HoverHistoryTooltip } from "./HoverHistoryTooltip";
import { WorksheetInput } from "./WorksheetInput";
import { useLazyGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import { vestResolver } from "@hookform/resolvers/vest";
import { pricingInfoValidation } from "./pricingInfoValidation";
import { Form } from "@/Components/Forms";
import { usePatchAssetMutation } from "@/features/asset-details-tab/api/endpoints";
import { Asset } from "@/features/asset/types";

export const formFields = {
	HOME_INVOICE_PRICE,
	ORIGINAL_LAND_APPRAISAL,
	ORIGINAL_SALES_PRICE,
	CASH_EQUITY,
	WEB_PRICE,
	PURCHASE_OPTION,
	RECOVERY_LAND_CREDIT,
	HOME_VALUE,
	LAND_VALUE,
	NADA_BASE,
};

export type PricingInfoHistory = {
	propertyPath: string;
	user: string;
	modifiedDate: string;
	value: number;
};

export interface PricingInfoType {
	homeInvoicePrice: number;
	originalLandAppraisal: number;
	originalSalesPrice: number;
	cashEquity: number;
	webPrice: number;
	purchaseOption: number;
	recoveryLandCredit: number;
	homeValue: number;
	landValue: number;
	nadaBase: number;
	pricingInfoHistory: PricingInfoHistory[];
	concurrencyToken: string;
}

const defaultPricingInfo: PricingInfoType = {
	homeInvoicePrice: 0,
	originalLandAppraisal: 0,
	originalSalesPrice: 0,
	cashEquity: 0,
	webPrice: 0,
	purchaseOption: 0,
	recoveryLandCredit: 0,
	homeValue: 0,
	landValue: 0,
	nadaBase: 0,
	pricingInfoHistory: [],
	concurrencyToken: "",
};

export const PricingInfoForm = ({ homeId }: { homeId: string }) => {
	const selectData = (data: Asset | undefined) => {
		return data?.marketingInfo?.pricingInfo ?? defaultPricingInfo;
	};

	const [fetchAsset, { data, isFetching, isLoading }] = useLazyGetAssetQuery({
		selectFromResult: ({ data, ...rest }) => {
			return {
				data: selectData(data),
				...rest,
			};
		},
	});

	const [patchAsset, { isFetching: isPatching }] = usePatchAssetMutation();

	const onSubmit = async (data: PricingInfoType): Promise<void> => {
		await patchAsset({ id: homeId, marketingInfo: { pricingInfo: data } });
	};

	return (
		<Form<PricingInfoType>
			formId="pricing-info-form"
			defaultValues={async () => {
				const { data } = await fetchAsset(homeId);
				return selectData(data);
			}}
			resolver={vestResolver(pricingInfoValidation)}
			onSubmit={onSubmit}
			isFetching={isFetching}
			isLoading={isLoading}
			isPatching={isPatching}
			data={data}
		>
			<div className={styles.worksheetFieldContainer}>
				{Object.values(formFields).map((field) => (
					<WorksheetInput
						key={field.key}
						name={field.key}
						label={field.label}
						{...field.props}
						fieldLevelHelpTooltip={
							field.props?.history &&
							HoverHistoryTooltip({
								assetId: homeId,
								name: field.key,
								label: field.label,
							})
						}
					/>
				))}
			</div>
		</Form>
	);
};
