import classes from "./Tasks.module.scss";
import { TasksTable } from "./components/TasksTable";
import { Desktop, Mobile } from "@/Responsive";
import { TasksHeader } from "./components/TasksHeader";
import { TaskListProvider } from "./api/TasksListProvider";
import { useTaskList } from "./api/useTaskList";
import { ErrorContainer } from "../errors/ErrorContainer";
import { useTasksQuery } from "./api/useTasksQuery";
import { MobileCards } from "./components/MobileCards";
import { useTasks } from "./api/useTasks";
import { TaskLoadingSkeleton } from "./components/TaskLoadingSkeleton";

export const Tasks = () => {
	const { isSuccess, isLoading, defaultList } = useTasks();

	if (isLoading) {
		return <TaskLoadingSkeleton />;
	}

	if (isSuccess && defaultList) {
		return (
			<TaskListProvider defaultQuery={defaultList}>
				<TasksContent />
			</TaskListProvider>
		);
	}

	return <></>;
};

export const TasksContent = () => {
	const { query } = useTaskList();

	const { data, isLoading, isError, error, isFetching } = useTasksQuery(query);

	if (isLoading) {
		return <TaskLoadingSkeleton />;
	}
	if (isError) {
		return <ErrorContainer error={error} />;
	}
	return (
		<div className={classes.container}>
			<TasksHeader results={data ?? []} isFetching={isFetching} />
			<Mobile>
				<MobileCards {...data} />
			</Mobile>
			<Desktop>
				<TasksTable response={data} />
			</Desktop>
		</div>
	);
};
