import React from "react";
import classes from "./RolesAndFlags.module.scss";
import { Roles } from "./Roles/Roles";
import { FeatureFlags } from "./feature-flags/FeatureFlags";
import { useSelector } from "react-redux";
import { SwaggerLink } from "./swagger-link/SwaggerLink";
import { Button } from "@salesforce/design-system-react";
import { selectCurrentUser } from "../user/api/selectors";

export const RolesAndFlags = () => {
	const { email, firstName } = useSelector(selectCurrentUser);
	return (
		<div className="slds-box slds-theme_default">
			<div
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "baseline",
				}}
			>
				<h1 className={classes.title}>{firstName}</h1>
				<p className={classes.email}>({email})</p>
			</div>

			<h1 className={classes.title}>Configuration Settings</h1>

			<Roles />
			<FeatureFlags />
			<SwaggerLink />
			<div className={classes.container}>
				<Button
					onClick={() => {
						window.location.reload();
					}}
				>
					Refresh App
				</Button>
			</div>
		</div>
	);
};
