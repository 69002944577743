import { enforce, test, create } from "vest";

/**
 * Document type is required
 */
export const uploadImagingValidation = create((data = {}) => {
	test("documentSubtype", "Must select a document type", () => {
		enforce(data.documentSubtype).isNotEmpty();
	});
});
