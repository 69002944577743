import {
	Button,
	ButtonGroup,
	Input,
	Tooltip,
} from "@salesforce/design-system-react";
import {
	setBrightness,
	setCropping,
	setMode,
	setIsEditing,
	setIsZoomActive,
	setRotate,
	setZoom,
	setZoomLevel,
	resetBrightness,
	resetEditorState,
	setIsSaving,
} from "../image-editor/ToastSlice";
import classes from "./PhotoToolbar.module.scss";
import { CgCrop } from "react-icons/cg";
import { BsBrightnessHighFill } from "react-icons/bs";
import { BiRotateLeft } from "react-icons/bi";
import { AiOutlineRotateLeft, AiOutlineRotateRight } from "react-icons/ai";
import {
	useEditorDispatch,
	useImageEditor,
} from "../image-editor/ToastUIContext";
import { Desktop } from "@/Responsive";
import { BrightnessRange } from "./BrightnessRange";

export function ZoomGroup() {
	const dispatch = useEditorDispatch();

	const handleZoomIn = (e) => {
		dispatch(setMode("zoom"));
		dispatch(setIsZoomActive(true));
		dispatch(setZoom("in"));
	};

	const handleZoomOut = (e) => {
		dispatch(setMode("zoom"));
		dispatch(setIsZoomActive(true));
		dispatch(setZoom("out"));
	};

	const handleResetZoom = (e) => {
		dispatch(setZoom("reset"));
		dispatch(setZoomLevel(1));
		dispatch(setIsZoomActive(false));
	};

	return (
		<ButtonGroup variant="list">
			<Tooltip content="Zoom in">
				<Button
					iconCategory="utility"
					iconSize="large"
					iconName="zoomin"
					inverse
					onClick={handleZoomIn}
					style={{
						marginLeft: "3px",
						paddingTop: "2px",
						paddingBottom: "4px",
						height: "100%",
					}}
				></Button>
			</Tooltip>
			<Tooltip content="Zoom out">
				<Button
					iconCategory="utility"
					iconSize="large"
					iconName="zoomout"
					inverse
					onClick={handleZoomOut}
					style={{ paddingTop: "2px", paddingBottom: "4px", height: "100%" }}
				></Button>
			</Tooltip>
			<Tooltip content="Reset Zoom">
				<Button inverse onClick={handleResetZoom}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 30 30"
						width={30}
						height={30}
						stroke="currentColor"
						fill="currentColor"
						style={{ padding: "3px" }}
					>
						<path d="M22.448 21A10.855 10.855 0 0025 14 10.99 10.99 0 006 6.466V2H4v8h8V8H7.332a8.977 8.977 0 11-2.1 8h-2.04A11.012 11.012 0 0014 25a10.855 10.855 0 007-2.552L28.586 30 30 28.586z" />
					</svg>
				</Button>
			</Tooltip>
		</ButtonGroup>
	);
}

export function EditingGroup() {
	const [state, dispatch] = useImageEditor();

	return (
		<>
			<ButtonGroup variant="list" style={{}}>
				<Tooltip content="Start cropping">
					<Button
						style={{ paddingTop: "2px", paddingBottom: "2px" }}
						inverse
						onClick={() => {
							dispatch(setMode(state.mode === "crop" ? "" : "crop"));
							if (!state.undoStack) dispatch(setCropping("cropping"));
						}}
					>
						<CgCrop size="2em" fill="black" />
					</Button>
				</Tooltip>
				<Tooltip content="Change brightness">
					<Button
						style={{
							paddingTop: "2px",
							paddingBottom: "2px",
							height: "100%",
						}}
						inverse
						onClick={(e) => {
							dispatch(
								setMode(state.mode === "brightness" ? "" : "brightness")
							);
						}}
					>
						<BsBrightnessHighFill size="2em" />
					</Button>
				</Tooltip>
				<Tooltip content="Rotate image">
					<Button
						style={{ height: "100%", paddingTop: "2px", paddingBottom: "2px" }}
						inverse
						onClick={(e) => {
							dispatch(setMode(state.mode === "rotate" ? "" : "rotate"));
						}}
					>
						<BiRotateLeft size="2em" />
					</Button>
				</Tooltip>
			</ButtonGroup>
		</>
	);
}

export function PhotoToolBar({ ...props }) {
	const [state, dispatch] = useImageEditor();

	const editClicked = () => {
		dispatch(setMode(""));
		dispatch(setIsSaving(false));
		dispatch(setIsEditing(!state.isEditing));
	};

	const cancelCrop = () => {
		dispatch(setCropping("cancel"));
	};

	const endCrop = () => {
		dispatch(setCropping("apply"));
	};

	const changeBrightness = (val) => {
		dispatch(setBrightness(val));
	};

	const changeBrightnessInput = (value) => {
		let val = parseInt(value);
		if (isNaN(val)) {
			val = 0;
		}
		dispatch(setBrightness(val / 255));
	};

	const handleRotate = (value) => {
		const newValue = state.rotate + parseInt(value);

		const result =
			Math.abs(newValue) >= 360
				? Math.sign(newValue) * (Math.abs(newValue) - 360)
				: newValue;
		dispatch(setRotate(result));
	};

	const handleRotateSlider = (e, { value } = {}) => {
		dispatch(setRotate(parseInt(value)));
	};

	const handleResetBrightness = () => {
		dispatch(resetBrightness());
	};

	const handleClickSave = () => {
		dispatch(setIsSaving(true));
		dispatch(setMode("saveOptions"));
	};

	return (
		<>
			{state.isEditingText && <></>}
			{!state.isEditing && (
				<>
					<Desktop>
						<Button
							iconCategory="utility"
							iconName="edit"
							iconSize="small"
							iconPosition="left"
							variant="neutral"
							inverse
							onClick={() => editClicked()}
						>
							{state.mode === "saveOptions" ? "Continue Editing" : "Edit Photo"}
						</Button>
					</Desktop>
				</>
			)}
			{state.isEditing && (
				<>
					<Tooltip content="Save as Copy or Overwrite Original">
						<Button inverse onClick={handleClickSave}>
							Save
						</Button>
					</Tooltip>
					<Tooltip content="Discard Edits">
						<Button
							variant="destructive"
							inverse
							onClick={() => {
								dispatch(resetEditorState());
							}}
						>
							Cancel
						</Button>
					</Tooltip>
					<ZoomGroup />
					<EditingGroup />
				</>
			)}
			{state.mode === "saveOptions" && (
				<>
					<Button inverse onClick={() => dispatch(setMode("saveCopy"))}>
						Save a Copy
					</Button>
					<Button inverse onClick={() => dispatch(setMode("overwrite"))}>
						Overwrite Existing
					</Button>
					<Button inverse onClick={() => dispatch(resetEditorState())}>
						Discard Edits
					</Button>
				</>
			)}
			{state.isEditing && (
				<>
					{state.mode === "crop" && !!state.undoStack ? (
						<Button
							inverse
							style={{ height: "100%" }}
							variant="icon"
							onClick={() => dispatch(setMode("undo"))}
						>
							Undo Crop
						</Button>
					) : (
						<>
							{state.cropping === "cropping" && (
								<Button inverse variant="icon" onClick={endCrop}>
									Apply
								</Button>
							)}
							{state.cropping === "cropping" && (
								<Button inverse variant="icon" onClick={cancelCrop}>
									Cancel
								</Button>
							)}
						</>
					)}
					{state.mode === "brightness" && (
						<>
							<BrightnessRange
								value={state.brightness}
								onChange={changeBrightness}
							/>
							<Input
								minValue={-255}
								maxValue={255}
								styleInput={{
									backgroundColor: "transparent",
									color: "white",
									border: "none",
									width: "8rem",
								}}
								styleContainer={{
									display: "inline-block",
									width: "8rem",
								}}
								className={classes.numberInput}
								variant="counter"
								value={state.brightness * 255}
								onChange={(e, { value }) => changeBrightnessInput(value)}
							/>
							<Button inverse onClick={handleResetBrightness}>
								Reset
							</Button>
						</>
					)}
					{state.mode === "rotate" && (
						<>
							<Button
								variant="icon"
								inverse
								value={-90}
								onClick={() => handleRotate(-90)}
							>
								<AiOutlineRotateLeft size="1.5rem" />
							</Button>
							<label htmlFor="rotate">
								<Input
									className={classes.numberInput}
									name="rotate"
									min={-360}
									max={360}
									defaultValue={0}
									value={state.rotate}
									onChange={handleRotateSlider}
									onInput={handleRotateSlider}
									variant="counter"
									styleInput={{
										backgroundColor: "transparent",
										color: "white",
										border: "none",
										width: "8rem",
									}}
									styleContainer={{
										display: "inline-block",
										width: "8rem",
									}}
								/>
							</label>
							<Button
								variant="icon"
								inverse
								value={90}
								onClick={() => handleRotate(90)}
							>
								<AiOutlineRotateRight size="1.5rem" />
							</Button>
						</>
					)}
				</>
			)}
		</>
	);
}
