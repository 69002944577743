import {
	Button,
	DataTable,
	DataTableCell,
	DataTableColumn,
} from "@salesforce/design-system-react";
import { AssociatedEntity, Task, TaskListResponse } from "../Types";
import { TaskStatusBadge } from "./TaskStatusBadge";
import classes from "./TasksTable.module.scss";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
	useGetEntityTypesQuery,
	useGetTaskStatusTypesQuery,
	useGetTaskTypesQuery,
} from "../api/endpoints";
import { useGetAllUsersQuery } from "@/features/user/api/endpoints";
import { getAsset } from "@/ReduxToolkit/GatewayApi";
import { useTaskList } from "../api/useTaskList";
import { DEFAULT_TASK_QUERY } from "../api/useTasksQuery";
import { TasksFilters } from "./Filters/TasksFilters";
import { getFormattedISODatetime } from "@/Utilities/utils";

export const TasksTable = ({ response }: { response: TaskListResponse }) => {
	const { query, setQuery, selectedTasks, setSelectedTasks } = useTaskList();

	const handleRowSelection = (_event: any, data: { selection: Task[] }) => {
		setSelectedTasks(data.selection);
	};

	const { items: tasks, totalItems: totalTasks, pageNumber } = response;
	const { direction, propertyPath } = query.sortCriteria;

	const handleSort = (sort: {
		property: keyof Task | "age";
		sortDirection: "desc" | "asc";
	}) => {
		const { property, sortDirection } = sort;
		setQuery({
			...query,
			paginationCriteria: {
				...DEFAULT_TASK_QUERY.paginationCriteria,
			},
			sortCriteria: {
				propertyPath: property === "age" ? "createdDate" : property,
				direction: sortDirection,
			},
		});
	};

	const handleLoadMore = () => {
		// if the current resulting page number is the same as the query page number, increase the page number
		// this prevents the bug with the salesforce Datatable component calling the onLoadMore function multiple times
		if (query.paginationCriteria.pageNumber === pageNumber) {
			setQuery({
				...query,
				paginationCriteria: {
					...query.paginationCriteria,
					pageNumber: query.paginationCriteria.pageNumber + 1,
				},
			});
		}
	};

	const disabledItems = useDisabledItems(tasks);

	return (
		<div className={classes.container}>
			<div className={classes.table}>
				<DataTable
					items={tasks}
					id="DataTableExample-1"
					joined
					fixedHeader
					fixedLayout
					style={{
						fontSize: ".75rem",
						overflow: "auto",
						minWidth: "800px",
					}}
					selection={selectedTasks}
					disabledSelection={disabledItems}
					selectRows="checkbox"
					onSort={handleSort}
					onRowChange={handleRowSelection}
					onLoadMore={() => handleLoadMore()}
					hasMore={tasks.length < totalTasks}
				>
					<DataTableColumn
						label="Status"
						property="statusTypeId"
						width="120px"
						sortable
					>
						<StatusCell />
					</DataTableColumn>
					<DataTableColumn
						label="Name"
						property="name"
						key="name"
						width="140px"
						sortable
						isSorted={propertyPath === "name"}
						sortDirection={direction}
					>
						<TaskNameCell />
					</DataTableColumn>
					<DataTableColumn
						label="Task Type"
						property="taskTypeId"
						width="140px"
						sortable
						isSorted={propertyPath === "taskTypeId"}
						sortDirection={direction}
					>
						<TaskTypeCell />
					</DataTableColumn>

					<DataTableColumn
						label="Asset"
						property="associatedEntities"
						width="300px"
					>
						<AssociatedEntityCell />
					</DataTableColumn>
					<DataTableColumn
						label="Assigned To"
						property="assignedTo"
						sortable
						isSorted={propertyPath === "assignedTo"}
						sortDirection={direction}
						width="160px"
					>
						<AssignedToCell />
					</DataTableColumn>

					<DataTableColumn
						label="Created By"
						width="120px"
						property="createdBy"
						sortable
						isSorted={propertyPath === "createdBy"}
						sortDirection={direction}
					>
						<CreatedByCell />
					</DataTableColumn>
					<DataTableColumn
						label="Follow Up"
						property="dueDate"
						width="100px"
						sortable={true}
						isSorted={propertyPath === "dueDate"}
						sortDirection={direction}
					>
						<DueDateCell />
					</DataTableColumn>
					<DataTableColumn
						label="Age"
						property="age"
						id="age"
						sortable
						isSorted={propertyPath === "createdDate"}
						sortDirection={direction}
						width="120px"
					>
						<AgeCell />
					</DataTableColumn>
					<DataTableColumn
						label="Created Date"
						property="createdDate"
						sortable
						isSorted={propertyPath === "createdDate"}
						sortDirection={direction}
						width="180px"
					>
						<CreatedDateCell />
					</DataTableColumn>

					<DataTableColumn
						label="Last Update"
						property="updatedDate"
						width="240px"
						sortable
						isSorted={propertyPath === "updatedDate"}
						sortDirection={direction}
					>
						<LastUpdatedCell />
					</DataTableColumn>
				</DataTable>
			</div>
			<TasksFilters />
		</div>
	);
};

const useDisabledItems = (items: Task[]) => {
	const { data, isSuccess } = useGetTaskStatusTypesQuery();

	if (!isSuccess || !data) {
		return items;
	}
	const statusIds = data
		.filter((item) => item.name === "Cancelled" || item.name === "Completed")
		.map((item) => item.id);

	return items.filter((item) => statusIds.includes(item.statusTypeId));
};

const CreatedByCell = ({ ...props }) => {
	const { data: users } = useGetAllUsersQuery();

	const user = users?.find((item) => item.id === props.item.createdBy);

	return (
		<DataTableCell {...props}>
			<p>{user && `${user.firstName} ${user.lastName}`}</p>
		</DataTableCell>
	);
};
CreatedByCell.displayName = DataTableCell.displayName;

const AssignedToCell = ({ ...props }) => {
	const { data: users } = useGetAllUsersQuery();
	const user = users?.find((item) => item.id === props.item.assignedTo);

	return (
		<DataTableCell {...props}>
			<p>{user && `${user.firstName} ${user.lastName}`}</p>
		</DataTableCell>
	);
};
AssignedToCell.displayName = DataTableCell.displayName;

const LastUpdatedCell = ({ ...props }) => {
	const { data: users } = useGetAllUsersQuery();

	const user = users?.find((item) => item.id === props.item.modifiedBy);

	const updatedDate = props.item.updatedDate
		? getFormattedISODatetime(props.item.updatedDate)
		: "";
	return (
		<DataTableCell {...props}>
			<p>
				{user && `${user.firstName} ${user.lastName}`} - {updatedDate}
			</p>
		</DataTableCell>
	);
};
LastUpdatedCell.displayName = DataTableCell.displayName;

const ModifiedByCell = ({ ...props }) => {
	const { data: users } = useGetAllUsersQuery();

	const user = users?.find((item) => item.id === props.item.modifiedBy);

	return (
		<DataTableCell {...props}>
			<p>
				{user ? `${user.firstName} ${user.lastName}` : props.item.modifiedBy}
			</p>
		</DataTableCell>
	);
};
ModifiedByCell.displayName = DataTableCell.displayName;

const AssociatedEntityCell = ({ ...props }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { data: EntityTypes } = useGetEntityTypesQuery();
	const navigate = useNavigate();
	const item = props.item.associatedEntities.find((item: AssociatedEntity) => {
		const entityType = EntityTypes?.find(
			(type) => type.id === item.entityTypeId && !item.inactiveDate
		);
		return entityType?.name === "Asset";
	});

	const [fetchAsset] = getAsset.useLazyQuery();

	const onClick = async () => {
		setIsLoading(true);
		const result = await fetchAsset(item.entityId);
		setIsLoading(false);
		if ("error" in result || !result.data) {
			console.log(result.error);
			return;
		}
		const { data: asset } = result;
		navigate(`/account/${asset.account.id}/asset/${asset.id}?tab=details`);
	};

	return (
		<DataTableCell {...props}>
			{item ? (
				<Button
					className={classes.assetLink}
					variant="base"
					onClick={onClick}
					{...(isLoading && {
						iconClassName: classes.spinner,
						iconName: "spinner",
						iconPosition: "left",
						iconCategory: "utility",
					})}
				>
					{item.name}
				</Button>
			) : (
				<p>No Asset Association</p>
			)}
		</DataTableCell>
	);
};
AssociatedEntityCell.displayName = DataTableCell.displayName;

const TaskNameCell = ({ ...props }) => {
	return (
		<DataTableCell {...props} title={props.item.name}>
			<Link
				to={`/tasks/${props.item.id}`}
				style={{ display: "flex", flexDirection: "row" }}
			>
				<p>{props.item.name}</p>
			</Link>
		</DataTableCell>
	);
};
TaskNameCell.displayName = DataTableCell.displayName;

const TaskTypeCell = ({ ...props }) => {
	const { data } = useGetTaskTypesQuery();
	const taskType = data?.find((item) => item.id === props.item.taskTypeId);

	return (
		<DataTableCell {...props} title={props.item.name}>
			<p>{taskType && taskType.name}</p>
		</DataTableCell>
	);
};
TaskTypeCell.displayName = DataTableCell.displayName;

const DueDateCell = ({ ...props }) => {
	const dueDate = props.item.dueDate
		? dayjs(props.item.dueDate).format("MM/DD/YYYY")
		: "N/A";
	return <DataTableCell {...props}>{dueDate}</DataTableCell>;
};
DueDateCell.displayName = DataTableCell.displayName;

const AgeCell = ({ ...props }) => {
	const createdDate = props.item.createdDate
		? dayjs().diff(dayjs(props.item.createdDate), "day") + " days ago"
		: "N/A";
	return <DataTableCell {...props}>{createdDate}</DataTableCell>;
};
AgeCell.displayName = DataTableCell.displayName;

const CreatedDateCell = ({ ...props }) => {
	const createdDate = props.item.createdDate
		? getFormattedISODatetime(props.item.createdDate)
		: "N/A";
	return <DataTableCell {...props}>{createdDate}</DataTableCell>;
};
CreatedDateCell.displayName = DataTableCell.displayName;
const StatusCell = ({ ...props }) => {
	return (
		<DataTableCell {...props}>
			<TaskStatusBadge statusTypeId={props.item.statusTypeId} />
		</DataTableCell>
	);
};
StatusCell.displayName = DataTableCell.displayName;
