import { useSearchParams } from "react-router-dom";

export const useTabs = () => {
	const [params, setParams] = useSearchParams();

	const setCurrentTab = (newTab) => {
		const { tab, ...rest } = Object.fromEntries(params.entries());
		setParams({ ...rest, tab: newTab }, { replace: true });
	};

	return { currentTab: params.get("tab"), setCurrentTab };
};
