import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./ReduxToolkit/store.ts";
import { Provider } from "react-redux";
// Importing IconSettings at the top of the App file allows for the Salesforce Icon Settings to be used by Salesforce components throughout.
// Does not render anything to the DOM
import { IconSettings, Settings } from "@salesforce/design-system-react";
import {
	AuthProvider,
	ServiceWorkerProvider,
	FeatureProvider,
} from "./providers";
import "@/lib/vest";
import { ToastProvider } from "./providers/toasts/ToastProvider";
import { register } from "./serviceWorkerRegistration";
import { OfflineAssetProvider } from "./providers/offline-data/OfflineAssetProvider";
import "./offscreenCanvasPolyfill";
import { RolesProvider } from "./features/user/hooks/useRoles";
import NiceModal from "@ebay/nice-modal-react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "@/features/logrocket";

dayjs.extend(utc);

const rootEl = document.getElementById("root");
Settings.setAppElement(rootEl);

const Index = () => {
	return (
		<IconSettings iconPath={`/salesforce/icons`}>
			<Provider store={store}>
				<AuthProvider>
					<ServiceWorkerProvider>
						<FeatureProvider>
							<RolesProvider>
								<OfflineAssetProvider>
									<NiceModal.Provider>
										<ToastProvider>
											<App />
										</ToastProvider>
									</NiceModal.Provider>
								</OfflineAssetProvider>
							</RolesProvider>
						</FeatureProvider>
					</ServiceWorkerProvider>
				</AuthProvider>
			</Provider>
		</IconSettings>
	);
};

register({
	onUpdate: (registration) => {
		if (registration && registration.waiting) {
			registration.waiting.postMessage({ type: "SKIP_WAITING" });
		}
	},
	onSuccess: (registration) => {
		console.log(registration);
		if (registration && registration.active) {
			registration.active.postMessage({ type: "UPDATE_DATA" });
		}
	},
});

ReactDOM.render(<Index />, rootEl);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
