import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { useSelector } from "react-redux";
import { useTripPlanningProvider } from "../components/TripPlanningProvider";
import { selectRoute } from "../store/tripPlanningSlice";
import { useTripDetails } from "./useTripDetails";
import { useCallback, useEffect } from "react";

export const useRoute = () => {
	const { routeRef } = useTripPlanningProvider();
	const { getTripDetails } = useTripDetails();
	const route = useSelector(selectRoute);
	const updateRoute = useCallback(() => {
		getTripDetails(route);
		routeRef?.current?.update({
			stops: route?.map((val) => {
				return new TrimbleMaps.LngLat(val.longitude, val.latitude);
			}),
			frameRoute: route.length > 2 ? true : false,
		});
		//eslint-disable-next-line
	}, [route]);

	useEffect(() => {
		if (route.length > 0) {
			updateRoute();
		}

		//eslint-disable-next-line
	}, [route]);

	return { updateRoute };
};
