import { isProd } from "@/Utilities/utils";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

// only use logrocket in hosted environment, not local
//
(async () => {
	if (
		process.env.NODE_ENV !== "development" &&
		!process.env.REACT_APP_LOCAL_BUILD &&
		isProd()
	) {
		LogRocket.init("uaifoo/vmf-homebase", {
			dom: {
				// hide dom values by className with "logrocket-hide" className
				privateClassNameBlocklist: ["logrocket-hide"],
			},
			network: {
				requestSanitizer: (request) => {
					// if the url contains 'ignore'
					if (
						request.url.toLowerCase().includes("sales") ||
						request.url.toLowerCase().includes("buyer")
					) {
						// ignore the request response pair
						return null;
					}

					// otherwise log the request normally
					return request;
				},
			},
		});

		// setup react logrocket after calling LogRocket.init()
		setupLogRocketReact(LogRocket);
	}
})();
