import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal } from "@salesforce/design-system-react";
import classes from "./CancelReasonModal.module.scss";
import { AssociatedEntity, NewTask, Task } from "../Types";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {
	useGetEntityTypesQuery,
	useGetTaskStatusTypesQuery,
	useGetTaskTypesQuery,
} from "../api/endpoints";
import { SpinnerButton } from "@/Components/SpinnerButton/SpinnerButton";
import { useUpdateMultipleTasks } from "../api/useUpdateMultipleTasks";
import { useGetCurrentUserQuery } from "@/features/user/api/endpoints";

export const CancelReasonModal = NiceModal.create(
	({ tasks }: { tasks: Task[] }) => {
		const modal = useModal();
		const onClose = () => {
			modal.hide();
		};

		return (
			<Modal
				heading="Cancellation Reasons"
				isOpen={modal.visible}
				contentClassName={classes.container}
				onRequestClose={onClose}
				size="large"
			>
				<CancellationReasonForm onClose={onClose} tasks={tasks} />
			</Modal>
		);
	}
);

interface CancelReasonForm {
	tasks: Task[];
}

const CancellationReasonForm = ({
	tasks,
	onClose,
}: {
	tasks: Task[];
	onClose: () => void;
}) => {
	const { updateTasks, isSubmitting } = useUpdateMultipleTasks();

	const methods = useForm<CancelReasonForm>({
		defaultValues: {
			tasks: tasks,
		},
	});
	const tasksArray = useFieldArray<CancelReasonForm>({
		control: methods.control,
		name: "tasks",
	});
	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery();
	const { data: taskTypes, isLoading: isLoadingTaskTypes } =
		useGetTaskTypesQuery();
	const { data: statusTypes, isLoading: isLoadingStatusTypes } =
		useGetTaskStatusTypesQuery();

	const isLoading = isLoadingUser || isLoadingTaskTypes || isLoadingStatusTypes;

	const onSubmit = async (data: CancelReasonForm) => {
		const updatedTasks: NewTask[] = data.tasks.map((task, index) => {
			return {
				...task,
				cancellationReason: {
					cancelledBy: currentUser?.id ?? "",
					cancelledDate: new Date().toISOString(),
					title: task.cancellationReason?.reason ?? "",
					reason: task.cancellationReason?.reason ?? "",
				},
				statusTypeId:
					statusTypes?.find((item) => item.name === "Cancelled")?.id ?? "",
			};
		});

		await updateTasks(updatedTasks);
		onClose();
	};
	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className={classes.formContainer}
			>
				{tasksArray.fields.map((task, index) => (
					<div className={classes.taskItem} key={task.id}>
						<div>
							<h3>
								{tasks[index].name} -{" "}
								{taskTypes?.find((item) => item.id === tasks[index].taskTypeId)
									?.name ?? ""}
							</h3>
						</div>
						<div>
							<AssetAssociation
								associations={tasks[index].associatedEntities}
							/>
						</div>
						<div>
							<label className="slds-form-element__label">
								Cancellation Reason
							</label>
							<input
								className="slds-input"
								{...methods.register(
									`tasks.${index}.cancellationReason.reason`
								)}
								required
							/>
						</div>
					</div>
				))}
				<div className={classes.submitButtons}>
					<Button className={classes.button} onClick={() => onClose()}>
						Cancel
					</Button>
					<SpinnerButton
						type="submit"
						variant="brand"
						isSpinning={isSubmitting}
						disabled={isSubmitting || isLoading}
					>
						Submit
					</SpinnerButton>
				</div>
			</form>
		</FormProvider>
	);
};

const AssetAssociation = ({
	associations,
}: {
	associations: AssociatedEntity[];
}) => {
	const { data: EntityTypes } = useGetEntityTypesQuery();
	const item = associations.find((item: AssociatedEntity) => {
		const entityType = EntityTypes?.find(
			(type) => type.id === item.entityTypeId && !item.inactiveDate
		);
		return entityType?.name === "Asset";
	});

	return <p>{item ? item.name : "No Asset Association"}</p>;
};

NiceModal.register("task-cancel-reason-modal", CancelReasonModal);

export const useCancelReasonModal = () => useModal("task-cancel-reason-modal");
