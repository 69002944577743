import { PageHeader, Icon } from "@salesforce/design-system-react";
import { useGetAssetDetailsConcurrencyErrorsQuery } from "@/features/offline-use/api/endpoints/syncAssetDetails";
import classes from "./NotificationCenter.module.scss";
import { NotificationCard } from "./NotificationCard";
import { ErrorBadge } from "./ErrorBadge";

export const Notifications = () => {
	return (
		<>
			<PageHeader
				icon={
					<Icon
						assistiveText={{ label: "Field Use Assets" }}
						category="utility"
						name="notification"
						colorVariant="warning"
					/>
				}
				title="Notifications"
				label="All Notifications"
				variant="object-home"
			/>
			<div className={classes.bodyContainer}>
				<ConcurrencyErrors />
			</div>
		</>
	);
};

const ConcurrencyErrors = () => {
	const { data: concurrencyErrors, isSuccess } =
		useGetAssetDetailsConcurrencyErrorsQuery();
	return (
		<>
			{isSuccess && (
				<div className={classes.errorContainer}>
					{Object.entries(concurrencyErrors).map((item) => (
						<div key={item[0]}>
							<ConcurrencyErrorObject item={item[1]} />
						</div>
					))}
				</div>
			)}
		</>
	);
};

const ConcurrencyErrorObject = ({ item, key }) => {
	return (
		<NotificationCard
			item={item}
			error="Concurrency Error"
			Badge={ErrorBadge}
		/>
	);
};
