import { useContext } from "react";
import { TaskListContext } from "./TasksListProvider";

export const useTaskList = () => {
	const {
		query,
		setQuery,
		selectedTasks,
		setSelectedTasks,
		filtersOpen,
		setFiltersOpen,
	} = useContext(TaskListContext);

	return {
		query,
		setQuery,
		selectedTasks,
		setSelectedTasks,
		filtersOpen,
		setFiltersOpen,
	};
};
