import React from "react";
import { useParams, Navigate, useSearchParams } from "react-router-dom";
import { useGetByAccountNumberQuery } from "./api/getByAccountNumber";
import { ErrorContainer } from "../errors/ErrorContainer";
import { Account } from "./Account";
import Spinner from "@/Components/UI/Spinner/Spinner";

/*
    This component is used for when legacy wants to link to modern 
*/
export const AccountNumber = () => {
	let { accountNum } = useParams();
	const [search] = useSearchParams();
	const tab = search.get("tab");

	const { data, isFetching, isSuccess, isError, error } =
		useGetByAccountNumberQuery(accountNum);

	if (data?.assets?.length === 1) {
		const assetId = data.assets[0].id;
		const accountId = data.id;
		const url = `/account/${accountId}/asset/${assetId}`;
		return (
			<Navigate
				to={{
					pathname: url,
					search: tab ? `?tab=${tab}` : "?tab=media",
				}}
			/>
		);
	}
	return (
		<>
			{isSuccess && data && <Account account={data} />}
			{isFetching && <Spinner />}
			{isError && <ErrorContainer error={error} />}
		</>
	);
};
