import { useGetCurrentUserQuery } from "@/features/user/api/endpoints";
import { Task, TaskListPayload } from "../Types";
import { useGetTaskStatusTypesQuery } from "./endpoints";
import { DEFAULT_TASK_QUERY } from "./useTasksQuery";
import { useSearchParams } from "react-router-dom";

export interface TaskListOption extends TaskListPayload {
	label: string;
	value: string;
	isDefault: boolean;
}

export const DEFAULT_TASK_LIST_NAMES = [
	"All Tasks",
	"Open Tasks",
	"My Assigned Tasks",
	"My Created Tasks",
	"In Progress Tasks",
	"New Tasks",
	"Completed Tasks",
	"On Hold Tasks",
	"Cancelled Tasks",
];

export const useTaskTableList = () => {
	const { data: user } = useGetCurrentUserQuery();

	const { data: taskStatus } = useGetTaskStatusTypesQuery();

	// Sorting
	const [queryParams] = useSearchParams();

	const sortDirection =
		queryParams.get("direction") || DEFAULT_TASK_QUERY.sortCriteria.direction;
	const sortProperty =
		queryParams.get("sort") || DEFAULT_TASK_QUERY.sortCriteria.propertyPath;

	const sortState = {
		direction: sortDirection,
		property: sortProperty,
	};

	const defaultQuery: TaskListPayload = {
		...DEFAULT_TASK_QUERY,
		sortCriteria: {
			direction: sortState.direction as "asc" | "desc",
			propertyPath: sortState.property as keyof Task,
		},
	};
	const allTasks: TaskListOption = {
		...defaultQuery,
		...listData("All Tasks", user?.defaultTaskView),
	};
	const openTasks: TaskListOption[] = taskStatus
		? [
				{
					...defaultQuery,
					filteringCriteria: [
						{
							operation: "!=",
							propertyPath: "statusTypeId",
							value: `[${taskStatus
								.filter(
									(status) =>
										status.name === "Cancelled" || status.name === "Completed"
								)
								?.map((item) => item.id)
								.join(",")}]`,
						},
					],
					...listData("Open Tasks", user?.defaultTaskView),
				},
		  ]
		: [];
	const userAssignedTasks: TaskListOption[] = user
		? [
				{
					...defaultQuery,
					filteringCriteria: [
						{
							operation: "==",
							propertyPath: "assignedTo",
							value: user?.id ?? "",
						},
					],
					...listData("My Assigned Tasks", user?.defaultTaskView),
				},
		  ]
		: [];

	const userCreatedTasks: TaskListOption[] = user
		? [
				{
					...defaultQuery,
					filteringCriteria: [
						{
							operation: "==",
							propertyPath: "createdBy",
							value: user?.id ?? "",
						},
					],
					...listData("My Created Tasks", user?.defaultTaskView),
				},
		  ]
		: [];

	const inProgressTasks: TaskListOption[] = taskStatus
		? [
				{
					...defaultQuery,
					filteringCriteria: [
						{
							operation: "==",
							propertyPath: "statusTypeId",
							value:
								taskStatus.find((status) => status.name === "In Progress")
									?.id ?? "",
						},
					],
					...listData("In Progress Tasks", user?.defaultTaskView),
				},
		  ]
		: [];

	const newTasks: TaskListOption[] = taskStatus
		? [
				{
					...defaultQuery,
					filteringCriteria: [
						{
							operation: "==",
							propertyPath: "statusTypeId",
							value:
								taskStatus.find((status) => status.name === "New")?.id ?? "",
						},
					],
					...listData("New Tasks", user?.defaultTaskView),
				},
		  ]
		: [];

	const completedTasks: TaskListOption[] = taskStatus
		? [
				{
					...defaultQuery,
					filteringCriteria: [
						{
							operation: "==",
							propertyPath: "statusTypeId",
							value:
								taskStatus.find((status) => status.name === "Completed")?.id ??
								"",
						},
					],
					...listData("Completed Tasks", user?.defaultTaskView),
				},
		  ]
		: [];

	const onHoldTasks: TaskListOption[] = taskStatus
		? [
				{
					...defaultQuery,
					filteringCriteria: [
						{
							operation: "==",
							propertyPath: "statusTypeId",
							value:
								taskStatus.find((status) => status.name === "On Hold")?.id ??
								"",
						},
					],
					...listData("On Hold Tasks", user?.defaultTaskView),
				},
		  ]
		: [];
	const cancelledTasks: TaskListOption[] = taskStatus
		? [
				{
					...defaultQuery,
					filteringCriteria: [
						{
							operation: "==",
							propertyPath: "statusTypeId",
							value:
								taskStatus.find((status) => status.name === "Cancelled")?.id ??
								"",
						},
					],
					...listData("Cancelled Tasks", user?.defaultTaskView),
				},
		  ]
		: [];

	const userSavedViews = Object.keys(user?.savedTaskViews ?? {}).map((key) => {
		const view = user?.savedTaskViews[key];
		return {
			...defaultQuery,
			filteringCriteria: view ?? defaultQuery.filteringCriteria,
			...listData(key, user?.defaultTaskView),
		};
	});

	const options: TaskListOption[] = [
		...userSavedViews,
		allTasks,
		...openTasks,
		...userAssignedTasks,
		...userCreatedTasks,
		...inProgressTasks,
		...newTasks,
		...completedTasks,
		...onHoldTasks,
		...cancelledTasks,
	];
	return options;
};

const listData = (name: string, defaultView: string | undefined) => {
	return {
		label: name,
		value: name,
		name: name,
		isDefault: defaultView === name,
	};
};
