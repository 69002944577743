import { createContext, useContext, useState } from "react";
import { isProd } from "@/Utilities/utils";
import { FEATURE_FLAGS, STORAGE_KEY } from "./constants";
import { UnleashFlagProvider, useFlag } from "@/lib/unleash";

const FeatureContext = createContext();
const FeatureDispatchContext = createContext();

export const FeatureDispatchProvider = ({ children }) => {
	return <FeatureDispatchContext>{children}</FeatureDispatchContext>;
};

const DevFeatureProvider = ({ children }) => {
	const [features, setFeatures] = useState(
		JSON.parse(localStorage.getItem(STORAGE_KEY)) ??
			FEATURE_FLAGS.reduce(
				(acc, feature) => ({
					...acc,
					[feature.value]: { ...feature, enabled: true },
				}),
				{}
			)
	);

	const toggleFeature = (value) => {
		const updateFn = (features) => {
			return {
				...features,
				[features[value].value]: {
					...features[value],
					enabled: !features[value].enabled,
				},
			};
		};

		localStorage.setItem(
			STORAGE_KEY,
			JSON.stringify(
				updateFn(JSON.parse(localStorage.getItem(STORAGE_KEY)) ?? features)
			)
		);

		setFeatures(updateFn);
	};

	return (
		<FeatureContext.Provider value={features}>
			<FeatureDispatchContext.Provider value={toggleFeature}>
				{children}
			</FeatureDispatchContext.Provider>
		</FeatureContext.Provider>
	);
};

export const FeatureProvider = ({ children }) => {
	return isProd() ? (
		<UnleashFlagProvider>{children}</UnleashFlagProvider>
	) : (
		<UnleashFlagProvider>
			<DevFeatureProvider>{children}</DevFeatureProvider>
		</UnleashFlagProvider>
	);
};

export const useToggleFeature = (feature) => {
	const toggleFeature = useContext(FeatureDispatchContext);

	return () => {
		toggleFeature(feature.value);
	};
};

export const useFeature = (feature) => {
	const flag = useFlag(feature.value);
	const features = useContext(FeatureContext);

	return isProd()
		? flag
		: (flag || process.env.NODE_ENV === "development") &&
				features?.[feature.value]?.enabled;
};
