import { isRejectedWithValue } from "@reduxjs/toolkit";
import { openToast } from "@/ReduxToolkit/ToastsSlice";
const validErrorEndpoints = {
	updateWorkbook: "Workbook",
	updateHomeLocation: "Location Information",
	udpateHomeInformation: "Home Information",
	updateListing: "Listing",
};

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
	const endpoint = action?.meta?.arg?.endpointName;
	if (
		isRejectedWithValue(action) &&
		validErrorEndpoints[endpoint] &&
		action.payload?.status !== 409
	) {
		api.dispatch(
			openToast({
				id: endpoint,
				labels: {
					heading: `Error - ${validErrorEndpoints[endpoint]} - ${action.payload?.status}`,
					details: `Something went wrong with this request. Please Try again later. ${action.payload?.data?.title}`,
				},
				variant: "error",
				timeout: 5000,
			})
		);
	}
	return next(action);
};
