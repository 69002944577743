import { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, ButtonGroup, Modal } from "@salesforce/design-system-react";
import { NewBuyer, BuyerFormType } from "./NewBuyer";
import {
	useGetSaleByIdQuery,
	useUpdateSaleMutation,
} from "../../api/endpoints";
import { BuyerSearch } from "./BuyerSearch";
import { Buyer } from "../../types";
import { SaleIdProvider } from "../SaleContext";

export const BuyerModal = NiceModal.create(({ saleId }: { saleId: string }) => {
	const { data: sale } = useGetSaleByIdQuery(saleId);
	const [isAddingBuyer, setIsAddingBuyer] = useState<boolean>(false);
	const [trigger] = useUpdateSaleMutation();
	const addBuyer = async (buyer: BuyerFormType | Buyer) => {
		if (sale) {
			setIsAddingBuyer(true);
			const res = await trigger({
				...sale,
				buyerInterests: [...sale?.buyerInterests, buyer],
			});
			setIsAddingBuyer(false);
			if (!("error" in res)) {
				modal.remove();
			}
		}
	};
	const [viewingSearch, setViewingSearch] = useState<boolean>(true);

	const modal = useModal();

	return (
		<Modal
			heading="Add a Buyer to the Sale"
			dismissOnClickOutside={false}
			isOpen={modal.visible}
			size="large"
			onRequestClose={modal.remove}
		>
			<div className="logrocket-hide">
				<SaleIdProvider saleId={saleId}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							padding: "0.5rem",
						}}
					>
						<ButtonGroup>
							<Button
								variant={viewingSearch ? "brand" : "outline-brand"}
								onClick={() => setViewingSearch(true)}
							>
								Search for a Buyer
							</Button>
							<Button
								variant={!viewingSearch ? "brand" : "outline-brand"}
								onClick={() => setViewingSearch(false)}
							>
								Create a new Buyer
							</Button>
						</ButtonGroup>
					</div>
					<div>
						{viewingSearch ? (
							<BuyerSearch addBuyer={addBuyer} />
						) : (
							<NewBuyer addBuyer={addBuyer} isAddingBuyer={isAddingBuyer} />
						)}
					</div>
				</SaleIdProvider>
			</div>
		</Modal>
	);
});

NiceModal.register("add-buyer", BuyerModal);

export const useBuyerModal = () => useModal("add-buyer");
