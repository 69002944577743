import {
	Task,
	AddComment,
	NewAssociatedEntity,
	AssociatedEntity,
} from "../Types";
import classes from "./TaskCard.module.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import {
	Button,
	Card,
	DataTable,
	DataTableCell,
	DataTableColumn,
	ExpandableSection,
	Icon,
} from "@salesforce/design-system-react";
import { useUser } from "@/features/user/hooks/useUser";
import {
	useGetEntityTypesQuery,
	useGetTaskStatusTypesQuery,
	useGetTaskTypesQuery,
	useUpdateTaskMutation,
} from "../api/endpoints";
import { TaskStatusBadge } from "./TaskStatusBadge";
import {
	useGetAllUsersQuery,
	useGetCurrentUserQuery,
} from "@/features/user/api/endpoints";
import { ChangeEvent, useRef, useState } from "react";
import { useUploadTaskDocumentMutation } from "@/features/imaging/api/endpoints";
import { SpinnerButton } from "@/Components/SpinnerButton/SpinnerButton";
import {
	getFormattedISODate,
	getFormattedISODatetime,
} from "@/Utilities/utils";

export const TaskCard = ({ task }: { task: Task }) => {
	return (
		<div className={classes.card}>
			<div className={classes.header}>
				<h2 className={classes.title}>
					<TaskType id={task.taskTypeId ?? ""} /> - {task.name}
				</h2>
				<TaskStatusBadge statusTypeId={task.statusTypeId} size="big" />
			</div>

			<div className={classes.details}>
				{task.cancellationReason && (
					<ExpandableSection title="Cancellation Reason">
						<div className={classes.peopleGrid}>
							<label>Cancelled By:</label>
							<User id={task.cancellationReason.cancelledBy} />
							<label>Cancellation Reason: </label>
							<p>{task.cancellationReason.reason}</p>
							<label>Cancelled On:</label>
							<p>
								{getFormattedISODatetime(task.cancellationReason.cancelledDate)}
							</p>
						</div>
					</ExpandableSection>
				)}
				<InvoiceInformation associatedEntities={task.associatedEntities} />
				<ExpandableSection id="default-expandable-section" title="People">
					<div className={classes.peopleGrid}>
						<label>Assigned:</label>
						<User id={task.assignedTo ?? ""} />
						<p>Created By: </p>
						<User id={task.createdBy} />
						<label>Last Edited: </label>
						<User id={task.modifiedBy} />
					</div>
				</ExpandableSection>
				<ExpandableSection id="default-expandable-section" title="Dates">
					<div className={classes.peopleGrid}>
						<label>Last Edited: </label>
						<p>{getFormattedISODatetime(task.updatedDate)}</p>
						<label>Created On:</label>
						<p>{getFormattedISODatetime(task.createdDate)}</p>
						<label>Follow Up:</label>
						<p>{getFormattedISODate(task.dueDate)}</p>
					</div>
				</ExpandableSection>
				<ExpandableSection title="Comments">
					<div className={classes.comments}>
						{[...task.comments]
							.sort((a, b) => {
								const dateA = a.createdDate
									? new Date(a.createdDate).getTime()
									: 0;
								const dateB = b.createdDate
									? new Date(b.createdDate).getTime()
									: 0;
								return dateB - dateA;
							})
							.map((comment) => (
								<div key={comment.id} className={classes.comment}>
									<p className={classes.commentTitle}>{comment.title}</p>
									<p className={classes.commentContent}>{comment.content}</p>
									<p className={classes.commentMeta}>
										By <User id={comment.createdBy} /> on{" "}
										{comment.createdDate
											? getFormattedISODatetime(comment.createdDate)
											: "Unknown Date"}
									</p>
								</div>
							))}
						<CommentForm task={task} />
					</div>
				</ExpandableSection>
				<ExpandableSection title="Attachments">
					<Attachments task={task} />
				</ExpandableSection>
			</div>
		</div>
	);
};

const InvoiceInformation = ({
	associatedEntities,
}: {
	associatedEntities: AssociatedEntity[];
}) => {
	const { data: entityTypes, isSuccess } = useGetEntityTypesQuery();

	const invoiceType = entityTypes?.find((item) => item.name === "Invoice")?.id;

	const invoice = associatedEntities.find(
		(item) => item.entityTypeId === invoiceType
	);

	const parsedInfo = invoice?.propertyBag
		? JSON.parse(invoice?.propertyBag)
		: "";

	if (!isSuccess || !invoiceType || !invoice) {
		return <></>;
	}

	return (
		<ExpandableSection title="Invoice Information">
			<div className={classes.peopleGrid}>
				<label>Supplier Name:</label>
				<p>{parsedInfo?.supplierName}</p>
				<label>Invoice Number:</label>
				<p>{parsedInfo?.invoiceNumber}</p>
				<label>Amount Approved:</label>
				<p>${parsedInfo?.amountApproved}</p>
			</div>
		</ExpandableSection>
	);
};

const TaskType = ({ id }: { id: string }) => {
	const { data, isLoading, isError } = useGetTaskTypesQuery();

	if (isLoading) {
		return <span>...loading task type</span>;
	}

	if (isError) {
		return <span>error loading task type</span>;
	}

	const taskType = data?.find((item) => item.id === id)?.name;
	return <span>{taskType}</span>;
};

const Attachments = ({ task }: { task: Task }) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const { data: entityTypes } = useGetEntityTypesQuery();
	const [trigger, { isLoading: isSubmitting }] = useUpdateTaskMutation();
	const attachments = task.associatedEntities.filter(
		(item) =>
			item.entityTypeId ===
			entityTypes?.find((type) => type.name === "Document")?.id
	);

	const [hasError, setHasError] = useState<boolean>(false);

	const { data: currentUser } = useGetCurrentUserQuery();

	const [uploadTaskFile, { isLoading }] = useUploadTaskDocumentMutation();

	const handleFileUpload = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleClick = async (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const newFiles: FileList = e.target.files;

			const files = Array.from(newFiles);

			const uploadFiles = await Promise.all(
				files.map((file) => {
					return uploadTaskFile({
						document: file,
						userEmail: currentUser?.email ?? "",
					});
				})
			);

			const documentType =
				entityTypes?.find((item) => item.name === "Document")?.id ?? "";

			const docEntities: NewAssociatedEntity[] = uploadFiles
				.filter((item) => "data" in item)
				.map((item) => {
					return {
						entityId: item.data.toString(),
						entityTypeId: documentType,
						activeDate: new Date().toISOString(),
						createdBy: currentUser?.id ?? "",
						createdDate: new Date().toISOString(),
						name: item.data.toString(),
					};
				});

			const updatedTask = {
				...task,
				associatedEntities: [...task.associatedEntities, ...docEntities],
			};

			const result = await trigger(updatedTask);
			if ("error" in result) {
				setHasError(true);
			}
		}
	};

	const { data, isSuccess } = useGetTaskStatusTypesQuery();
	const isCancelledOrCompleted =
		task.statusTypeId === data?.find((item) => item.name === "Cancelled")?.id ||
		task.statusTypeId === data?.find((item) => item.name === "Completed")?.id;

	return (
		<div style={{ width: "100%", overflow: "auto" }}>
			<Card
				heading="Attachments"
				headerActions={
					<>
						<Button
							htmlFor="file-upload"
							assistiveText={{ icon: "Add media" }}
							onClick={() => handleFileUpload()}
							disabled={
								isCancelledOrCompleted ||
								!isSuccess ||
								isSubmitting ||
								isLoading
							}
							{...((isSubmitting || isLoading) && {
								iconClassName: classes.spinner,
								iconName: "spinner",
								iconPosition: "left",
								iconCategory: "utility",
							})}
						>
							Add
						</Button>
						<input
							style={{ display: "none" }}
							type="file"
							id="file-upload-hidden"
							accept="application/pdf"
							multiple={true}
							ref={fileInputRef}
							onChange={handleClick}
						/>
					</>
				}
				icon={<Icon category="standard" name="document" size="small" />}
			/>
			<DataTable items={attachments} id="attachments">
				<DataTableColumn label="Doc Id" property="entityId">
					<DocIdCell />
				</DataTableColumn>
				<DataTableColumn label="Created By" property="createdBy">
					<UserCell />
				</DataTableColumn>
				<DataTableColumn label="Created Date" property="createdDate">
					<CreatedDateCell />
				</DataTableColumn>
			</DataTable>
			{hasError && (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						padding: "0.5rem",
						border: "1px solid red",
						alignItems: "center",
						marginTop: "0.5rem",
						borderRadius: "0.25rem",
						color: "red",
					}}
				>
					<p>Something went wrong uploading attachments</p>
					<Button
						assistiveText={{ icon: "Icon Bare Small" }}
						iconCategory="utility"
						iconName="close"
						iconSize="small"
						iconVariant="bare"
						variant="icon"
						onClick={() => setHasError(false)}
					/>
				</div>
			)}
		</div>
	);
};

const DocIdCell = ({ ...props }) => {
	const docPopUrl = process.env.REACT_APP_ONBASE_DOC_POP_URL;

	return (
		<DataTableCell {...props}>
			<a
				target="_blank"
				rel="noreferrer"
				href={`${docPopUrl}?clienttype=html&docid=${props.item.entityId}`}
			>
				{props.item.name}
			</a>
		</DataTableCell>
	);
};
DocIdCell.displayName = DataTableCell.displayName;

const UserCell = ({ ...props }) => {
	return (
		<DataTableCell {...props}>
			<User id={props.item.createdBy} />
		</DataTableCell>
	);
};
UserCell.displayName = DataTableCell.displayName;

const CreatedDateCell = ({ ...props }) => {
	const date = props.item.createdDate
		? getFormattedISODatetime(props.item.createdDate)
		: "Unknown Date";
	return <DataTableCell {...props}>{date}</DataTableCell>;
};
CreatedDateCell.displayName = DataTableCell.displayName;

export const User = ({ id }: { id: string }) => {
	const { data: users, isLoading } = useGetAllUsersQuery();

	const selection = users?.find((item) => item.id === id);

	if (isLoading) return <span>...finding user</span>;

	if (selection)
		return (
			<span>
				{selection.firstName} {selection.lastName}
			</span>
		);
	if (!id) return <span>No one</span>;

	return <span>{id} (user not found)</span>;
};

const CommentForm = ({ task }: { task: Task }) => {
	const { data, isSuccess } = useGetTaskStatusTypesQuery();

	const [trigger, { isLoading: isSubmitting }] = useUpdateTaskMutation();
	const { register, watch, handleSubmit, reset } = useForm<AddComment>();
	const { data: user } = useUser();
	const contentVal = watch("content");

	const onSubmit: SubmitHandler<{ title: string; content: string }> = async (
		data
	) => {
		const updatedTask = {
			...task,
			comments: [
				...task.comments,
				{
					createdBy: user?.id ?? "unknown",
					createdDate: new Date().toISOString(),
					title: data.title,
					content: data.content,
				},
			],
		};
		const result = await trigger(updatedTask);
		if ("error" in result) {
			console.log(result.error);
		} else {
			reset();
		}
	};

	const isCancelledOrCompleted =
		task.statusTypeId === data?.find((item) => item.name === "Cancelled")?.id ||
		task.statusTypeId === data?.find((item) => item.name === "Completed")?.id;

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={classes.commentForm}>
			<input
				disabled={isCancelledOrCompleted || !isSuccess}
				className="slds-input"
				{...register("title")}
				placeholder="Optional Title"
			/>
			<textarea
				disabled={isCancelledOrCompleted || !isSuccess}
				{...register("content")}
				placeholder="Add a comment"
				className={classes.commentInput}
			/>
			<div
				style={{
					display: "flex",
					alignItems: "flex-end",
					justifyContent: "flex-end",
				}}
			>
				<SpinnerButton
					variant="brand"
					type="submit"
					disabled={!contentVal || isSubmitting}
					isSpinning={isSubmitting}
				>
					Add Comment
				</SpinnerButton>
			</div>
		</form>
	);
};
