import { enforce, test, create } from "vest";
import { TaskFormType } from "./TaskForm";

export const taskFormValidation = create((data: TaskFormType) => {
	test("taskTypeId", "Task Type is Required", () => {
		enforce(data.taskTypeId).isNotBlank();
	});
	test("name", "Name is Required", () => {
		enforce(data.name).isNotBlank();
	});
	test("dueDate", "Follow Up Date is Required", () => {
		enforce(data.dueDate).isNotBlank();
	});
});
