import { create, each, enforce, test } from "vest";
import { get } from "lodash";
import {
	HOME_INVOICE_PRICE,
	ORIGINAL_LAND_APPRAISAL,
	ORIGINAL_SALES_PRICE,
	CASH_EQUITY,
	WEB_PRICE,
	RECOVERY_LAND_CREDIT,
	HOME_VALUE,
	LAND_VALUE,
	NADA_BASE,
	PURCHASE_OPTION,
} from "../constants";

export const pricingInfoValidation = create((data = {}) => {
	test(PURCHASE_OPTION.key, "Invalid selection", () => {
		enforce(get(data, PURCHASE_OPTION.key)).oneOf(
			enforce.isNumber().isBetween(0, 2),
			enforce.isNull()
		);
	});

	each(
		[
			HOME_INVOICE_PRICE,
			ORIGINAL_LAND_APPRAISAL,
			ORIGINAL_SALES_PRICE,
			CASH_EQUITY,
			WEB_PRICE,
			RECOVERY_LAND_CREDIT,
			HOME_VALUE,
			LAND_VALUE,
			NADA_BASE,
		],
		(field) => {
			test(field.key, "Must be a positive number", () => {
				enforce(get(data, field.key)).isNumber().gte(0);
			});
		}
	);
});
