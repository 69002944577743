import { Link } from "react-router-dom";
import { Task } from "../Types";
import { useGetTaskTypesQuery } from "../api/endpoints";
import classes from "./SmallCard.module.scss";
import { useGetAllUsersQuery } from "@/features/user/api/endpoints";
import { TaskStatusBadge } from "./TaskStatusBadge";

export const SmallCard = ({ task }: { task: Task }) => {
	const { data: taskTypes } = useGetTaskTypesQuery();
	const { data: users, isLoading: loadingUsers } = useGetAllUsersQuery();

	const {
		createdBy,
		createdDate,
		modifiedBy,
		statusTypeId,
		assignedTo,
		completedBy,
		dueDate,
		id,
		name,
		taskTypeId,
		updatedDate,
	} = task;

	const taskType =
		taskTypes?.find((type) => type.id === taskTypeId)?.name ?? "";

	const findUser = (id: string) => {
		if (loadingUsers) return "loading...";
		const user = users?.find((user) => user.id === id);
		return user ? user?.firstName + " " + user?.lastName : `${id} not found`;
	};

	return (
		<div className={classes.smallCardContainer}>
			<Link to={`/tasks/${id}`}>
				<strong>
					{taskType} - {name}
				</strong>
			</Link>
			<TaskStatusBadge statusTypeId={statusTypeId} />
			<div>
				<p>
					<strong>Created By:</strong>
				</p>
				<p>{findUser(createdBy)}</p>
			</div>
			<div>
				<p>
					<strong>Assigned To:</strong>
				</p>
				<p>{findUser(assignedTo ?? "")}</p>
			</div>
			<div>
				<p>
					<strong>Follow Up Date:</strong>
				</p>
				<p>{new Date(dueDate ?? "").toLocaleDateString()}</p>
			</div>
			<div>
				<p>
					<strong>Created Date:</strong>
				</p>
				<p>{new Date(createdDate).toLocaleDateString()}</p>
			</div>
			{completedBy && (
				<div>
					<p>
						<strong>Completed By:</strong>
					</p>
					<p>{findUser(completedBy)}</p>
				</div>
			)}
			<div>
				<p>
					<strong>Last Modified By:</strong>
				</p>
				<p>{findUser(modifiedBy)}</p>
			</div>
			<div>
				<p>
					<strong>Last Updated:</strong>
				</p>
				<p>{new Date(updatedDate ?? "").toLocaleDateString()}</p>
			</div>
		</div>
	);
};
