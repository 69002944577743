import { DataTableCell, Icon } from "@salesforce/design-system-react";
import { subCategories, useSubcategory } from "./PricingWorksheetForm";
import styles from "../PricingWorksheet.module.scss";
import { WorksheetCellProps } from "../types";
import { RECOVERY } from "../constants";

export const CategoryCell = ({ children, ...props }: WorksheetCellProps) => {
	switch (children) {
		case "Refurbishing":
			return (
				<SubtotalCategoryCell
					title={children}
					{...props}
					className={styles.pointer}
				>
					{children}
				</SubtotalCategoryCell>
			);
		case "Land Improvements":
			return (
				<SubtotalCategoryCell
					title={children}
					{...props}
					className={styles.pointer}
				>
					{children}
				</SubtotalCategoryCell>
			);
		default:
			return (
				<DataTableCell
					{...props}
					{...(children === RECOVERY.label && {
						className: styles.recoveryRow,
					})}
				>
					<p
						style={{
							...(Object.values(subCategories)
								.flat()
								.includes(props.item?.id as string) && { marginLeft: "2rem" }),
						}}
					>
						{children}
					</p>
				</DataTableCell>
			);
	}
};

CategoryCell.displayName = DataTableCell.displayName;

function SubtotalCategoryCell({ children, ...props }: WorksheetCellProps) {
	const { isOpen, toggleOpen } = useSubcategory();

	return (
		<DataTableCell {...props}>
			<div
				style={{ display: "flex" }}
				onClick={() => {
					toggleOpen(props.item?.id as string);
				}}
			>
				<Icon
					name={isOpen(props.item?.id as string) ? "down" : "right"}
					category="utility"
					size="xx-small"
					style={{ marginRight: ".5rem" }}
				/>
				{children}
			</div>
		</DataTableCell>
	);
}

SubtotalCategoryCell.displayName = DataTableCell.displayName;
