import { concatErrorCache, gatewayApi } from "@/ReduxToolkit/GatewayApi";
import { updateUserEndpoint } from "./updateUserEndpoint";
import { deserializeUser } from "./userData";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { filteringCriteria } from "@/features/asset-list/api/endpoints";
import { TaskFilteringCriteria } from "@/features/tasks/Types";

export interface User {
	id: string;
	userTypeId: string;
	firstName: string;
	lastName: string;
	email: string;
	createdDate: string;
	lastModified: string;
	resCode: string;
	applicationData: UserApplicationData[];
}

export type UserApplicationData = {
	id: string;
	applicationName: string;
	data: string;
};

export interface DeserializedUser extends User {
	savedFilters: {
		[key: string]: filteringCriteria[];
	};
	assetListColumns: {
		[key: string]: {
			order: number;
			show: true;
		};
	};
	defaultAssetListView: string;
	roles: string[];
	savedTaskViews: {
		[key: string]: TaskFilteringCriteria[];
	};
	defaultTaskView: string;
}

const userEndpoints = (
	builder: EndpointBuilder<BaseQueryFn, string, string>
) => ({
	getCurrentUser: builder.query<DeserializedUser, void>({
		query: () => {
			return {
				url: `/api/UserInfo/GetCurrentUser`,
				method: "get",
			};
		},
		transformResponse: deserializeUser,
		providesTags: (_result, error, _email) => {
			if (error) {
				return concatErrorCache(["User"], error);
			}
			return ["User"];
		},
	}),
	updateUser: builder.mutation(updateUserEndpoint),
	getAllUsers: builder.query<User[], void | undefined>({
		query: () => {
			return {
				url: "/api/Search/GetAllUsers",
				method: "get",
			};
		},
	}),
	getUser: builder.query<User, string>({
		query: (id) => ({
			url: `/api/UserInfo/${id}`,
			method: "get",
		}),
		providesTags: (_res, _error, id) => [{ type: "User", id }],
	}),
});

const api = gatewayApi.enhanceEndpoints({
	addTagTypes: ["User"],
});

const userApi = api.injectEndpoints({
	endpoints: userEndpoints,
	overrideExisting: true,
});

export const {
	useGetCurrentUserQuery,
	useGetAllUsersQuery,
	useUpdateUserMutation,
	useGetUserQuery,
	endpoints: { getCurrentUser, getAllUsers, updateUser },
} = userApi;
