import { DataTableCell } from "@salesforce/design-system-react";
import { get } from "lodash";
import { useEffect } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { ACCOUNT_BALANCE, NET_SALE } from "../constants";
import { useFormSection, useIsEditable } from "@/Components/Forms";
import { StaticInput } from "./StaticInput";
import { getFieldNameForColumn, getColumn } from "./utils";
import { WorksheetCellProps } from "../types";
import styles from "../PricingWorksheet.module.scss";

export const RecoveryCell = ({ children, ...props }: WorksheetCellProps) => {
	const getFieldName = getFieldNameForColumn(getColumn(children as string));
	const { isStatic } = useFormSection(getColumn(children as string));

	const {
		field: { onChange },
	} = useController({ name: children as string });

	const isEditable = useIsEditable();
	const {
		formState: { isDirty },
	} = useFormContext();

	const [netSale, accountBalance] = useWatch({
		name: [getFieldName(NET_SALE.key), getFieldName(ACCOUNT_BALANCE.key)],
	});

	const initialValue = Number(get(props.data, children as string));
	const calculation =
		accountBalance > 0
			? Number(((Number(netSale) / Number(accountBalance)) * 100).toFixed(2))
			: 0;

	useEffect(() => {
		if (
			isDirty &&
			isEditable &&
			(Number(netSale) || Number(accountBalance)) &&
			calculation !== initialValue
		) {
			onChange(calculation);
		}
	}, [
		accountBalance,
		calculation,
		initialValue,
		isDirty,
		isEditable,
		netSale,
		onChange,
	]);

	return (
		<DataTableCell
			title={`${props.label} ${props.item?.category}`}
			{...props}
			className={styles.recoveryRow}
		>
			<StaticInput
				// isStatic={isStatic}
				containerStyle={{
					paddingRight: isStatic ? ".5rem" : "0rem",
				}}
			>
				{accountBalance > 0 ? calculation : get(props.data, children as string)}
				%
			</StaticInput>
		</DataTableCell>
	);
};

RecoveryCell.displayName = DataTableCell.displayName;
