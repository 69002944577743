import { useSelector } from "react-redux";
import { selectAllPricing } from "./selectors";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import {
	useGetConditionReportQuery,
	useSubmitConditionReportMutation,
} from "./endpoints";
import { sumBy, round } from "lodash";
import {
	DOOR_STRAP,
	FREE_FORM_DOCUMENTATION,
	HITCH_UNDER_UNIT,
	HUD_NUMBER_VERIFIED,
	KEY_LOCATION,
	labelsIndex,
	LOCK_BOX_CODE,
	LOCK_TYPE,
	SERIAL_NUMBER_VERIFIED,
	SKIP_CONDITION_REPORT,
	SKIP_REASON,
} from "../constants/constants";
import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";

export const useConditionReport = () => {
	const { assetId } = useAssetId();
	const {
		data: conditionReport,
		isSuccess,
		...rest
	} = useGetConditionReportQuery(assetId, {
		selectFromResult: ({ data: conditionReport, isSuccess, ...rest }) => {
			const index =
				conditionReport?.length > 0 ? conditionReport.length - 1 : 0;

			const {
				homeId,
				lineItems,
				totalEstimatedRefurbCost,
				locks,
				hasDoorStrap,
				isHitchUnderUnit,
				keyLocation,
				lockBoxCode,
				winterized,
				notes,
				skipReason,
				isFinal,
				isSkipped,
				summary,
			} = conditionReport?.[index] ?? { lineItems: [] };

			const isCompleted = conditionReport?.length > 0;
			return {
				data: {
					homeId,
					lineItems,
					hasDoorStrap,
					isHitchUnderUnit,
					totalEstimatedRefurbCost,
					locks,
					keyLocation,
					lockBoxCode,
					winterized,
					notes,
					skipReason,
					isFinal,
					isSkipped,
					summary,
					isCompleted,
				},
				isSuccess,
				rest,
			};
		},
	});

	const [trigger] = useSubmitConditionReportMutation();

	const pricing = useSelector(selectAllPricing(assetId));

	const update = (formData) => {
		const { overview, bathrooms, bedrooms, miscellaneous, ...data } = formData;
		const groupedData = [
			...(bedrooms ?? []),
			...(bathrooms ?? []),
			...Object.values(data),
		];
		let holder = {};
		groupedData.forEach(function (item) {
			const keys = Object.keys(item);
			keys.forEach((key) => {
				if (holder.hasOwnProperty(key)) {
					if (Number(item[key]) > 0) {
						holder[key] = round(holder[key] + Number(item[key]));
					}
				} else {
					if (Number(item[key]) > 0) {
						holder[key] = round(Number(item[key]));
					}
				}
			});
		});

		const lineItemsFields = Object.keys(holder).map((value) => {
			// value contains the key
			return {
				name: value,
				description: labelsIndex[value],
				quantity: holder[value],
				unitPrice: round(pricing[value]) ?? 0,
			};
		});
		const miscellaneousLineItems = miscellaneous.map((value) => {
			return {
				name: value.label,
				description: value.label,
				quantity: value.quantity,
				unitPrice: round(value.price),
			};
		});
		const lineItems = lineItemsFields.concat(miscellaneousLineItems);
		const finalFormData = {
			homeId: assetId,
			serialNumberVerified: overview[SERIAL_NUMBER_VERIFIED],
			hudNumberVerified: overview[HUD_NUMBER_VERIFIED],
			lineItems: lineItems,
			totalEstimatedRefurbCost: Math.round(
				sumBy(lineItems, (item) => item.quantity * item.unitPrice)
			),
			isHitchUnderUnit: overview[HITCH_UNDER_UNIT] ?? false,
			hasDoorStrap: overview[DOOR_STRAP] ?? false,
			locks: overview[LOCK_TYPE],
			keyLocation: !overview[KEY_LOCATION]
				? "Not Specified"
				: overview[KEY_LOCATION],
			lockBoxCode: !overview[LOCK_BOX_CODE]
				? "Not Specified"
				: overview[LOCK_BOX_CODE],
			notes: overview[FREE_FORM_DOCUMENTATION] ?? "",
			skipReason: overview[SKIP_REASON] ?? 0,
			isFinal: true,
			isSkipped: overview[SKIP_CONDITION_REPORT] ?? false,
			concurrencyToken: "",
		};
		trigger(finalFormData);
	};
	const { bedrooms, bathrooms, ...asset } = useGetAssetQuery(assetId, {
		selectFromResult: ({ data }) => {
			return data ?? {};
		},
	});
	return {
		update,
		conditionReport,
		bedrooms,
		assetId,
		bathrooms,
		isSuccess,
		rest,
		asset,
	};
};
