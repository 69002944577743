import { gatewayApi } from "@/ReduxToolkit";
import { concatErrorCache } from "@/ReduxToolkit/GatewayApi";
import { getFormData } from "@/features/media-manager/api/endpoints/utils";
import { addImagingState, removeImagingState } from "./ImagingSlice";

type ImagingResponse = {
	createdBy: string;
	dateStored: string;
	defaultFileType: string;
	docName: string;
	documentDate: string;
	documentHistory: any[];
	documentId: number;
	documentType: string;
	documentTypeGroup: string;
	keywords: { key: string; value: string }[];
	status: string;
};

const enhancedApi = gatewayApi.enhanceEndpoints({
	addTagTypes: ["Imaging"],
});

const imagingEndpoints = enhancedApi.injectEndpoints({
	endpoints: (builder) => ({
		getImagingByAccountNumber: builder.query<ImagingResponse[], string>({
			query: (accountNumber) =>
				`api/Imaging/documents/account/${accountNumber}`,
			providesTags: (result, error, id) => {
				if (error) return concatErrorCache([{ type: "Imaging", id }], error);
				return [{ type: "Imaging", id }];
			},
			transformResponse: (response: ImagingResponse[]) => {
				return response.sort((a, b) =>
					b.dateStored.localeCompare(a.dateStored)
				);
			},
			onCacheEntryAdded: async (arg, { dispatch, cacheEntryRemoved }) => {
				dispatch(addImagingState(arg));
				cacheEntryRemoved.then(() => {
					dispatch(removeImagingState(arg));
				});
			},
		}),
		uploadImagingDocument: builder.mutation({
			query: (body) => ({
				url: "api/Imaging/documents",
				method: "POST",
				body: getFormData(body),
			}),
			invalidatesTags: (_result, _err, body) => {
				return [{ type: "Imaging", id: body.accountNumber }];
			},
		}),
		uploadTaskDocument: builder.mutation<
			number,
			{ userEmail: string; document: File }
		>({
			query: (body) => ({
				url: "/api/Imaging/documents/taskDocument",
				method: "POST",
				body: getFormData(body),
			}),
		}),
		getDocumentMetadata: builder.query<any, number>({
			query: (documentId) => `api/Imaging/documents/${documentId}`,
		}),
		getDocumentSubtypes: builder.query({
			query: () => "api/LookupData/DocumentSubtypes",
		}),
	}),
});

export const {
	useGetImagingByAccountNumberQuery,
	useUploadImagingDocumentMutation,
	useGetDocumentSubtypesQuery,
	useUploadTaskDocumentMutation,
	useGetDocumentMetadataQuery,
	endpoints: { getImagingByAccountNumber },
} = imagingEndpoints;
