import { Form } from "./ConditionReportForm";
import { ExpandableSection, Button } from "@salesforce/design-system-react";
import classes from "./ControlledForm.module.scss";
import { useConditionReport } from "../../api/useConditionReport";
import { ConditionReportValidation } from "./ConditionReportValidation";
import { ControlledInput } from "./ControlledPrice";
import {
	exteriorConfig,
	generalConfig,
	kitchenConfig,
	overviewConfig,
	livingRoomConfig,
	bathroomConfig,
	bedroomConfig,
	interiorConfig,
} from "../../configs";
import { SubmitButtons } from "./ConditionReportForm";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import styles from "./ControlledPrice.module.scss";
import { OVERVIEW, SKIP_CONDITION_REPORT } from "../../constants/constants";

const Fields = ({ config }) => {
	return (
		<div className={classes.formGrid}>
			{config.map(({ component: Component, props }) => {
				return <Component key={props.name} {...props} />;
			})}
		</div>
	);
};

export const ConditionReportForm = ({ reportType, submit }) => {
	const { bedrooms, bathrooms, update } = useConditionReport();
	const modelDataSubmission = (data) => {
		update(data);
		submit();
	};

	return (
		<>
			<section>
				<Form
					schema={ConditionReportValidation}
					onSubmit={(data) => modelDataSubmission(data)}
				>
					<div style={{ padding: ".5rem" }}>
						<ConditionForm
							reportType={reportType}
							bedrooms={bedrooms}
							bathrooms={bathrooms}
						/>
					</div>
					<div
						style={{
							position: "sticky",
							bottom: "0px",
						}}
					>
						<SubmitButtons cancel={() => submit()} />
					</div>
				</Form>
			</section>
		</>
	);
};

const ConditionForm = ({ reportType, bedrooms, bathrooms }) => {
	const isSkipping = useWatch({ name: `${OVERVIEW}.${SKIP_CONDITION_REPORT}` });
	return (
		<>
			<ExpandableSection title="Overview">
				<Fields config={overviewConfig} />
			</ExpandableSection>
			{!isSkipping && (
				<>
					<ExpandableSection title="General">
						<Fields config={generalConfig} />
					</ExpandableSection>
					{reportType === "condensed" ? (
						<>
							<ExpandableSection title="Interior">
								<Fields config={interiorConfig} />
							</ExpandableSection>
						</>
					) : (
						<>
							<ExpandableSection title="Living Room">
								<Fields config={livingRoomConfig} />
							</ExpandableSection>
							<ExpandableSection title="Kitchen">
								<Fields config={kitchenConfig} />
							</ExpandableSection>
							{bedroomConfig(bedrooms).map((config, index) => {
								const sectionTitle = `Bedroom ${index + 1}`;
								return (
									<ExpandableSection key={sectionTitle} title={sectionTitle}>
										<Fields config={config} />
									</ExpandableSection>
								);
							})}
							{bathroomConfig(bathrooms).map((config, index) => {
								const sectionTitle = `Bathroom ${index + 1}`;
								return (
									<ExpandableSection key={sectionTitle} title={sectionTitle}>
										<Fields config={config} />
									</ExpandableSection>
								);
							})}
						</>
					)}
					<ExpandableSection title="Exterior">
						<Fields config={exteriorConfig} />
					</ExpandableSection>
					<ExpandableSection title="Miscellaneous">
						<MiscellaneousFields />
					</ExpandableSection>
				</>
			)}
		</>
	);
};

const MiscellaneousFields = () => {
	const { control, watch } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		name: "miscellaneous",
		control,
	});
	const watchFieldArray = watch("miscellaneous");

	const controlledFields = fields.map((field, index) => {
		return {
			...field,
			...watchFieldArray[index],
		};
	});
	return (
		<div className={styles.miscContainer}>
			{controlledFields.map((field, index) => {
				return (
					<fieldset
						className={styles.controlledMiscellaneousContainer}
						key={field.id}
						style={{ marginBottom: ".5rem" }}
					>
						<legend>Miscellaneous {index + 1}</legend>
						<div>
							<ControlledInput
								name={`miscellaneous[${index}].label`}
								label="Item Description"
								placeholder="Item Description"
							/>
							<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
								<ControlledInput
									name={`miscellaneous[${index}].quantity`}
									type="number"
									label="Quantity"
								/>
								<ControlledInput
									name={`miscellaneous[${index}].price`}
									type="number"
									label="Unit Price"
								/>
							</div>
						</div>

						<div
							style={{
								display: "grid",
								paddingTop: "20px",
								placeItems: "center",
							}}
						>
							<Button
								assistiveText={{
									icon: `remove special instruction ${index + 1}`,
								}}
								variant="icon"
								iconVariant="bare"
								iconName="delete"
								iconCategory="utility"
								onClick={() => {
									remove(index);
								}}
							/>
						</div>
					</fieldset>
				);
			})}
			<div>
				<Button
					onClick={() => append({ label: "", price: 0, quantity: 0 })}
					variant="link"
				>
					Add Miscellaneous Item
				</Button>
			</div>
		</div>
	);
};
