import { Controller, useForm } from "react-hook-form";
import { inputs, legacyFilters } from "./inputs";
import { useFilterAssetList } from "../../api/useFilterAssetList";
import {
	Button,
	ExpandableSection,
	Input,
} from "@salesforce/design-system-react";
import { useSearchParams } from "react-router-dom";
import { PRICING_WORKSHEET_FEATURE_FLAG } from "@/providers/feature-flags/constants";
import { useFeature } from "@/providers/feature-flags/FeatureProvider";
import { PRICING_WORKSHEET_APPROVAL_STATUS } from "@/features/asset/constants";
import { useIsMobile } from "@/Responsive";
import { useState } from "react";

export const LegacySearchForm = () => {
	const filterNames = Object.keys(legacyFilters);
	const { trigger } = useFilterAssetList();
	const setParams = useSearchParams()[1];
	const pricingWorksheetFeature = useFeature(PRICING_WORKSHEET_FEATURE_FLAG);
	const isMobile = useIsMobile();
	const [isOpen, setIsOpen] = useState(!isMobile);

	const methods = useForm({
		defaultValues: Object.fromEntries(filterNames.map((key) => [key, ""])),
	});

	return (
		<form
			onSubmit={methods.handleSubmit(async (data) => {
				const filters = Object.entries(data)
					.map(([key, value]) =>
						value !== "" ? legacyFilters[key](value) : null
					)
					.filter((x) => x);

				trigger(filters);
			})}
		>
			<ExpandableSection
				title="Filters"
				isOpen={isOpen}
				onToggleOpen={() => setIsOpen(!isOpen)}
			>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: isMobile
							? "1fr 1fr"
							: "1fr 1fr 1fr 1fr 1fr 1fr",
						gap: ".25rem",
					}}
				>
					{inputs
						.filter((input) =>
							input.key !== PRICING_WORKSHEET_APPROVAL_STATUS.key
								? true
								: pricingWorksheetFeature
						)
						.map((input) => (
							<Controller
								key={input.key}
								name={input.key}
								control={methods.control}
								render={(props) => {
									const Component = input?.component;

									return Component ? (
										<Component
											{...props}
											label={input.label}
											{...input?.props}
										/>
									) : (
										<Input
											label={input.label}
											value={props.field.value}
											onChange={props.field.onChange}
										/>
									);
								}}
							/>
						))}
					<div style={{ alignSelf: "flex-end" }}>
						<Button
							onClick={() => {
								methods.reset();
								setParams({});
							}}
						>
							Reset
						</Button>
						<Button type="submit" variant="brand">
							Submit
						</Button>
					</div>
				</div>
			</ExpandableSection>
		</form>
	);
};
