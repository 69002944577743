import { usePatchAssetMutation } from "@/features/asset-details-tab/api/endpoints";
import { Asset } from "@/features/asset/types";
import { useLazyGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, FormsProvider } from "@/Components/Forms";
import { vestResolver } from "@hookform/resolvers/vest";
import { create, test } from "vest";
import {
	DESCRIPTION,
	NEW_LISTING_DETAILS,
} from "@/features/listing/components/constants";
import { useListingConfirmationModal } from "./ListingConfirmationModal";
import { get } from "lodash";
import { webFormValidation } from "./webFormValidation";

type ListingDetails = {
	id?: string;
	marketingInfo: {
		listingDetails: Asset["marketingInfo"]["listingDetails"] | undefined;
	};
};

export const WebForm = ({
	assetId,
	children,
}: {
	assetId: string;
	children: ReactNode;
}) => {
	const selectListingDetails = (asset: Asset | undefined) => {
		return {
			id: asset?.id,
			marketingInfo: {
				listingDetails:
					asset?.marketingInfo?.listingDetails ?? NEW_LISTING_DETAILS,
			},
		};
	};
	const [fetch, { data, isFetching, isLoading, isSuccess, isUninitialized }] =
		useLazyGetAssetQuery({
			selectFromResult: ({ data, ...rest }) => {
				return { data: selectListingDetails(data), ...rest };
			},
		});

	const listingConfirmationModal = useListingConfirmationModal();

	const [confirmedDescription, setConfirmedDescription] =
		useState<boolean>(false);

	const methods = useForm({
		defaultValues: async (): Promise<ListingDetails> => {
			const { data: asset } = await fetch(assetId);
			return selectListingDetails(asset);
		},
		resolver: async (data, context, options) => {
			if (get(getFields(), DESCRIPTION.path) && !confirmedDescription) {
				try {
					await listingConfirmationModal.show({
						description: get(options.fields, DESCRIPTION.path).value,
					});
					setConfirmedDescription(true);
				} catch {
					return vestResolver<ListingDetails, undefined>(
						create(() => {
							test(DESCRIPTION.path, () => {
								return Promise.reject("Description confirmation required");
							});
						})
					)(data, context, options);
				}
			}
			return vestResolver<ListingDetails, undefined>(webFormValidation)(
				data,
				context,
				options
			);
		},
		reValidateMode: "onSubmit",
	});

	function getFields() {
		const fields = methods.formState.dirtyFields;
		return fields;
	}

	const [patch, { isFetching: isPatching }] = usePatchAssetMutation();

	const onSubmit = async (data: ListingDetails): Promise<void> => {
		await patch(data);
	};

	useEffect(() => {
		if (!isUninitialized) {
			setConfirmedDescription(false);
			methods.reset(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching, isLoading, isPatching]);

	return (
		<FormsProvider>
			<Form
				defaultValues={async (): Promise<ListingDetails> => {
					const { data: asset } = await fetch(assetId);
					return selectListingDetails(asset);
				}}
				resolver={async (data, context, options) => {
					if (
						get(context().getFields(), DESCRIPTION.path) &&
						!confirmedDescription
					) {
						try {
							await listingConfirmationModal.show({
								description: get(options.fields, DESCRIPTION.path).value,
							});
							setConfirmedDescription(true);
						} catch {
							return vestResolver<ListingDetails, undefined>(
								create(() => {
									test(DESCRIPTION.path, () => {
										return Promise.reject("Description confirmation required");
									});
								})
							)(data, context, options);
						}
					}
					return vestResolver<ListingDetails, undefined>(webFormValidation)(
						data,
						context,
						options
					);
				}}
				onSubmit={onSubmit}
				isFetching={isFetching}
				isLoading={isLoading}
				isPatching={isPatching}
				formId="webReady"
				data={data}
				context={(methods) => {
					return {
						getFields: () => {
							return methods?.formState?.dirtyFields;
						},
					};
				}}
				reValidateMode="onSubmit"
			>
				<div
					style={{
						position: "relative",
					}}
				>
					<div
						style={{
							backgroundColor: "white",
						}}
					>
						{isSuccess && children}
					</div>
				</div>
			</Form>
		</FormsProvider>
	);
};
