import {
	useGetAllUsersQuery,
	useGetCurrentUserQuery,
} from "@/features/user/api/endpoints";
import {
	useGetEntityTypesQuery,
	useGetTaskStatusTypesQuery,
	useGetTaskTypesQuery,
} from "./endpoints";
import { useTaskTableList } from "./useTaskTableList";
import { useSearchParams } from "react-router-dom";

export const useTasks = () => {
	const {
		data: user,
		isLoading: isLoadingUser,
		isSuccess: isSuccessUser,
	} = useGetCurrentUserQuery();
	const { isLoading: isLoadingStatuses, isSuccess: isSuccessStatuses } =
		useGetTaskStatusTypesQuery();
	const { isLoading: isLoadingUsers, isSuccess: isSuccessUsers } =
		useGetAllUsersQuery();

	const { isLoading: isLoadingTaskTypes, isSuccess: isSuccessTaskTypes } =
		useGetTaskTypesQuery();

	const { isLoading: isLoadingEntityTypes, isSuccess: isSuccessEntityTypes } =
		useGetEntityTypesQuery();
	const listViews = useTaskTableList();

	const [queryParams] = useSearchParams();

	// user?.defaultTaskView should always have a value when the query finishes so "" will never happen
	const selectedList = queryParams.get("view") || user?.defaultTaskView || "";

	const defaultOption = listViews.find((view) => view.value === selectedList);

	return {
		defaultList: defaultOption,
		isLoading:
			isLoadingUser ||
			isLoadingStatuses ||
			isLoadingUsers ||
			isLoadingTaskTypes ||
			isLoadingEntityTypes,
		isSuccess:
			isSuccessUser &&
			isSuccessStatuses &&
			isSuccessUsers &&
			isSuccessTaskTypes &&
			isSuccessEntityTypes,
	};
};
