import { useState } from "react";
import { ConditionReportForm } from "./components/Form";
import { ConditionReportHeader } from "./components/header/ConditionReportHeader";
import { CompletedReport } from "./components/completed-report/CompletedReport";
import { useConditionReport } from "./api/useConditionReport";

export const ConditionReport = () => {
	const { isSuccess } = useConditionReport();
	const [isEditing, setIsEditing] = useState(false);
	const [conditionReportType, setConditionReportType] = useState("");

	const onPromptSelected = (selection) => {
		setConditionReportType(selection);
		setIsEditing(true);
	};
	return (
		<>
			{isSuccess && (
				<>
					<ConditionReportHeader
						isEditing={isEditing}
						reportType={conditionReportType}
						onSelectReportType={(value) => onPromptSelected(value)}
					/>
					{!isEditing && <CompletedReport />}
					{isEditing && (
						<ConditionReportForm
							reportType={conditionReportType}
							submit={() => {
								setIsEditing(false);
								setConditionReportType("");
							}}
						/>
					)}
				</>
			)}
		</>
	);
};
