import { concatErrorCache } from "@/ReduxToolkit/GatewayApi";
import { gatewayApi } from "@/ReduxToolkit/GatewayApi";
import {
	addDocumentationState,
	removeDocumentationState
} from "@/ReduxToolkit/DocumentationSlice";
import { listenerMiddleware } from "@/ReduxToolkit/listenerMiddleware";
import {
	syncDocumentation,
	syncDocumentationDone
} from "@/ReduxToolkit/offlineAssetSlice";
import { offlineDocumentationDB } from "@/storage";
import { Note, NoteReason, NoteSource, NoteSubmission} from "../types";
import { Dispatch } from "redux";

const enhancedApi = gatewayApi.enhanceEndpoints({
	addTagTypes: ['Documentation'],
})

const documentationEndpoints = enhancedApi.injectEndpoints({
	endpoints: (builder) => ({
		getNotes: builder.query<Note[], string>({
			query: (accountId) => {
				return {
					url: `api/Notes/account/${accountId}`,
					method: "get"
				};
			},
			providesTags: (result, error, accountId) => {
				if (error) {
					return concatErrorCache([{ type: "Documentation", accountId }], error);
				}
				return [{ type: "Documentation", accountId }];
			},
			onCacheEntryAdded: async (arg, { dispatch, cacheEntryRemoved }) => {
				dispatch(addDocumentationState(arg));
				cacheEntryRemoved.then(() => {
					dispatch(removeDocumentationState(arg));
				});
			}
		}),
		getVantageNotes: builder.query<Note[], string>({
			query: (homeId) => {
				return {
					url: `api/Notes/vantage/home/${homeId}`,
					method: 'get'
				}
			}
		}),
		getNoteReasons: builder.query<NoteReason[], undefined>({
			query: () => {
				return {
					url: "api/LookupData/NoteReasons",
					method: "GET"
				};
			}
		}),
		getNoteSources: builder.query<NoteSource[], undefined>({
			query: () => {
				return {
					url: "api/LookupData/NoteSources",
					method: "GET"
				};
			}
		}),
	
		submitNote: builder.mutation<string, NoteSubmission>({
			query: (note) => ({
				url: `api/Notes/account/${note.accountId}`,
				method: "POST",
				body: note
			}),
			onQueryStarted: async ({ ...data }, { dispatch, queryFulfilled }) => {
				const patchResult = dispatch(
					documentationEndpoints.util.updateQueryData("getNotes", data.accountId, (draft) => {
						Object.assign(draft, [data, ...draft]);
					})
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
			invalidatesTags: (_res, _, { accountId }) => [{ type: "Documentation", accountId }]
		})
	}),
	overrideExisting: true,
})

export const {
	useGetNotesQuery,
	useGetVantageNotesQuery,
	useGetNoteReasonsQuery,
	useGetNoteSourcesQuery,
	useSubmitNoteMutation,
	endpoints: { getNotes, getVantageNotes, submitNote }
} = documentationEndpoints;

listenerMiddleware.startListening({
	actionCreator: syncDocumentation,
	effect: async (action, api) => {
		api.cancelActiveListeners();

		const documentation = await offlineDocumentationDB.keys();

		const updates = await Promise.all(
			documentation.map(async (id) => {
				const document: any = await offlineDocumentationDB.getItem(id);

				const submission = api.dispatch(
					submitNote.initiate(document)
				);
				
				try {
					const result = await submission.unwrap();

					await offlineDocumentationDB.removeItem(id);

					return { result, unsubscribe: submission.reset};
				} catch (error) {
					return {
						error,
						unsubscribe: submission.reset
					};
				}
			})
		);

		updates.forEach((update) => {
			update.unsubscribe();
		});

		api.dispatch(syncDocumentationDone());
	}
});

export const prefetchDocumentationLookups = (dispatch: Dispatch<any>) => {
	dispatch(
		documentationEndpoints.util.prefetch(`getNoteReasons`, undefined, {
			force: true
		})
	);
	dispatch(
		documentationEndpoints.util.prefetch('getNoteSources', undefined, {
			force: true
		})
	)
}
