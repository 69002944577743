import { Button } from "@salesforce/design-system-react";
import { useCommunity } from "../../api/useCommunities";
import { useCommunitiesModal } from "../Communities";
import { useController, useFormContext } from "react-hook-form";
import { useFormSection } from "@/Components/Form/ControlledForm/FormSection";

import classes from "./CommunityInput.module.scss";
import { CommunityInputConcurrency } from "./CommunityInputConcurrency";
import { CommunityDataCard } from "./CommunityDataCard";
import { useIsOnline } from "@/features/offline-use";

export const CommunitiesInput = ({ ...props }) => {
	const {
		field: { ref, value, ...rest },
	} = useController({ name: props.name });
	const { isStatic, onClickEdit, isReadOnly } = useFormSection();
	const { isConcurrencyError } = useFormContext();
	const communitiesModal = useCommunitiesModal();

	const { data } = useCommunity(value);
	const isOnline = useIsOnline();
	return (
		<div
			style={{
				borderBottom: isStatic ? "1px solid #dddbda" : "unset",
				gridColumn: `span ${props.span ?? 4}`,
			}}
		>
			<div className={classes.inputContainer}>
				<label className="slds-form-element__label" htmlFor={props.name}>
					<div>Community</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "end",
							justifyContent: "end",
							minHeight: "32px",
							color: "black",
						}}
					>
						<div style={{ height: "100%", width: "100%" }}>
							<CommunityDataCard community={data} />
						</div>
						<div className={classes.buttonContainer}>
							{isStatic && isOnline && !isReadOnly && !isConcurrencyError && (
								<Button
									assistiveText={{ icon: "Icon Bare Small" }}
									iconCategory="utility"
									title={`Edit Community`}
									iconName="edit"
									name={`edit Community`}
									iconSize="small"
									iconVariant="bare"
									className={classes.button}
									onClick={() => {
										onClickEdit();
									}}
									variant="icon"
								/>
							)}
							{!isStatic && isOnline && !isReadOnly && !isConcurrencyError && (
								<>
									<Button
										className={classes.buttonSearch}
										assistiveText={{ icon: "search" }}
										title="Search Communities"
										iconCategory="utility"
										iconName="search"
										iconSize="small"
										iconVariant="bare"
										onClick={() => {
											communitiesModal.show({
												submission: (data) => {
													rest.onChange(data.community);
												},
											});
										}}
										variant="icon"
									/>
									<Button
										className={classes.buttonSearch}
										assistiveText={{ icon: "remove" }}
										title="Remove Community"
										iconCategory="utility"
										iconName="delete"
										iconSize="small"
										iconVariant="bare"
										onClick={() => rest.onChange(null)}
										variant="icon"
									/>
								</>
							)}
						</div>
					</div>
				</label>
				<input
					id={props.name}
					{...rest}
					value={value ?? undefined}
					style={{ display: "none" }}
				/>
			</div>
		</div>
	);
};

export const CommunityInput = ({ ...props }) => {
	const { isConcurrencyError, concurrencyDiff } = useFormContext();
	return !isConcurrencyError ? (
		<CommunitiesInput {...props} />
	) : (
		<div className={classes.container}>
			{concurrencyDiff[props.name] &&
			hasConcurrencyError(concurrencyDiff[props.name]) ? (
				<CommunityInputConcurrency
					{...props}
					visualPickerLabel="Select a Community"
					options={concurrencyDiff[props.name]}
				/>
			) : (
				<CommunitiesInput {...props} />
			)}
		</div>
	);
};

function hasConcurrencyError(concurrencyField) {
	return Array.isArray(concurrencyField);
}
