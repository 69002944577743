import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentationFilter, DocumentationState } from "@/features/documentation/types";



const initial: DocumentationState = {}

export const initialState = {
	search: "",
	sourceId: [],
	filters: []
};

export const documentationSlice = createSlice({
	name: "documentation",
	initialState: initial,
	reducers: {
		addDocumentationState: (state, action: PayloadAction<string>) => {
			state[action.payload] = initialState;
			return state;
		},
		removeDocumentationState: (state, action: PayloadAction<string>) => {
			delete state[action.payload];
		},
		setSearch: (state, action: PayloadAction<{id: string, search: string}>) => {
			const { id, search } = action.payload;
			state[id].search = search;
			return state;
		},
		setSource: (state, action: PayloadAction<{id: string, sourceId: number}>) => {
			const { id, sourceId } = action.payload;
			if (state[id].sourceId.includes(sourceId)) {
				state[id].sourceId = state[id].sourceId.filter(
					(item) => item !== sourceId
				);
			} else {
				state[id].sourceId = [...state[id].sourceId, sourceId];
			}
			return state;
		},
		setFilters: (state, action: PayloadAction<{id: string, filters: DocumentationFilter[]}>) => {
			const { id, filters } = action.payload;
			state[id].filters = filters;
			return state;
		},
		removeFilter: (state, action: PayloadAction<{ id: string, filterId: string}>) => {
			const { id, filterId } = action.payload;
			state[id].filters = [
				...state[id].filters.filter((item) => item.id !== filterId)
			];
			return state;
		}
	}
});

export const {
	addDocumentationState,
	removeDocumentationState,
	setSearch,
	setSource,
	setFilters,
	removeFilter
} = documentationSlice.actions;
