import { selectIsOnline } from "@/ReduxToolkit/offlineAssetSlice";
import { Button, Icon } from "@salesforce/design-system-react";
import { forwardRef } from "react";
import { cloneElement } from "react";
import { useSelector } from "react-redux";

const defaultProps = {
	assistiveText: {
		download: "download",
		link: "Preview:",
		loading: "loading",
		moreActions: "more actions",
	},
	crop: "16-by-9",
	href: "#",
	isLoading: false,
	hasNoVisibleTitle: false,
};

export const File = forwardRef((props, ref) => {
	const dragCursor = props.isDragging ? "grabbing" : "grab";

	const containerClassNames = [
		"slds-file",
		"slds-file_card",
		!props.hasNoVisibleTitle && "slds-has-title",
		props.className && props.className,
	]
		.filter((c) => c)
		.join(" ");

	const anchorClassNames = [
		"slds-file__crop",
		props.crop && `slds-file__crop_${props.crop}`,
	]
		.filter((c) => c)
		.join(" ");

	const handleOnClickImage = (event) => {
		if (props.href === "#") {
			event.preventDefault();
		}

		if (props.onClickImage) {
			props.onClickImage(event);
		}
	};

	const assistiveText = {
		...defaultProps.assistiveText,
		...props.assistiveText,
	};

	return (
		<div id={props.id} className={containerClassNames}>
			<figure>
				<a
					href={props.href}
					className={anchorClassNames}
					onClick={handleOnClickImage}
				>
					<FileFigure
						assistiveText={assistiveText}
						labels={{
							title: props.labels.title,
						}}
						isLoading={props.isLoading}
						image={props.image}
						// icon={props.icon}
					/>
				</a>
				{!props.hasNoVisibleTitle ? (
					<figcaption className="slds-file__title slds-file__title_card">
						<div className="slds-media__figure slds-line-height_reset">
							{props.icon
								? cloneElement(props.icon, {
										size: "x-small",
								  })
								: null}
						</div>
						<div
							ref={ref}
							className="slds-media__body"
							{...props.listeners}
							style={{ cursor: props.isDraggable ? dragCursor : "inherit" }}
						>
							<span
								className="slds-file__text slds-truncate"
								title={props.labels.title}
							>
								{props.labels.title}
							</span>
						</div>
					</figcaption>
				) : null}
			</figure>
			{props.externalIcon ? (
				<div className="slds-file__external-icon">
					{cloneElement(props.externalIcon, {
						containerClassName: "slds-file__icon slds-icon_container",
					})}
				</div>
			) : null}
			{props.moreActionsDropdown ? (
				<style>{`
					.dsr-file__more-actions-dropdown  ul.dropdown__list li.slds-dropdown__item > a:before
					{ background: none; }
					.dsr-file__more-actions-dropdown  ul.dropdown__list li.slds-dropdown__item > a:after
					{ background: none; }
					.dsr-file__more-actions > button:first-child
					{ border-radius: 0 0.25rem 0.25rem 0!important;}
			`}</style>
			) : null}
			<FileActions
				assistiveText={assistiveText}
				hasNoVisibleTitle={props.hasNoVisibleTitle}
				onClickDownload={props.onClickDownload}
				moreActionsDropdown={props.moreActionsDropdown}
			/>
		</div>
	);
});

function FileFigure(props) {
	const isOnline = useSelector(selectIsOnline);
	return (
		<>
			<span className="slds-assistive-text">{props.assistiveText.link}</span>
			<img
				alt={props.assistiveText.image || props.labels.title}
				src={props.image}
				{...(!isOnline && {
					crossOrigin: "annonymous",
				})}
			/>
		</>
	);
}
function FileActions(props) {
	const actions = (
		<div className="slds-file__actions-menu">
			<div className="slds-button-group" role="group">
				{typeof props.onClickDownload === "function" ? (
					<Button
						type="button"
						variant="icon"
						iconSize="x-small"
						onClick={props.onClickDownload}
						title="Download"
						className="slds-button_icon-inverse"
					>
						<Icon
							assistiveText={{ label: props.assistiveText.download }}
							category="utility"
							name="download"
							size="xx-small"
							inverse={props.hasNoVisibleTitle}
						/>
					</Button>
				) : null}
				{props.moreActionsDropdown
					? cloneElement(props.moreActionsDropdown, {
							assistiveText: { icon: props.assistiveText.moreActions },
							overlay: false,
							buttonVariant: "icon",
							buttonInverse: props.hasNoVisibleTitle,
							className: "dsr-file__more-actions-dropdown ",
							triggerClassName: "dsr-file__more-actions",
					  })
					: null}
			</div>
		</div>
	);
	if (
		typeof props.onClickDownload === "function" ||
		props.moreActionsDropdown
	) {
		if (!props.hasNoVisibleTitle) {
			return actions;
		}
		return (
			<div className="slds-file__title slds-file__title_scrim">{actions}</div>
		);
	}
	return null;
}
