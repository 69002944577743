import classes from "./CommunityInput.module.scss";

export const CommunityDataCard = ({ community }) => {
	const address = community?.address;

	if (community) {
		return (
			<div className={classes.inputContainer}>
				<p>
					{community.name} #{community.id}
				</p>
				<p>{address.streetAddressLine1}</p>
				<p>
					{address.city}, {address.state}, {address.zip}
				</p>
				<p>
					{community.managerName}{" "}
					<a href={`tel:${community.phone}`}>{community.phone}</a>
				</p>
			</div>
		);
	}
	return <div></div>;
};
