import { removeOfflineConditionReport } from "@/features/offline-use/api/endpoints/syncAssetDetails";
import { concatErrorCache } from "@/ReduxToolkit/GatewayApi";
import { gatewayApi } from "@/ReduxToolkit/GatewayApi";
import { listenerMiddleware } from "@/ReduxToolkit/listenerMiddleware";
import {
	selectIsOnline,
	selectIsSyncing,
	syncConditionReports,
	syncConditionReportsDone,
} from "@/ReduxToolkit/offlineAssetSlice";
import { openToast } from "@/ReduxToolkit/ToastsSlice";
import { offlineConditionReportUploadsDB } from "@/storage";

const conditionReportEndpoints = (builder) => ({
	getConditionReport: builder.query({
		query: (id) => {
			return {
				url: `/api/ConditionReport/GetByHomeId/${id}`,
				method: "get",
			};
		},
		providesTags: (result, error, id) => {
			if (error) {
				return concatErrorCache([{ type: "ConditionReport", id }], error);
			}
			return [{ type: "ConditionReport", id }];
		},
	}),
	submitConditionReport: builder.mutation({
		query: (update) => ({
			url: `api/ConditionReport`,
			method: "POST",
			body: update,
		}),
		onQueryStarted: async ({ ...data }, { dispatch, queryFulfilled }) => {
			const patchResult = dispatch(
				gatewayApi.util.updateQueryData(
					"getConditionReport",
					data.homeId,
					(draft) => {
						Object.assign(draft, [...draft, data]);
					}
				)
			);
			try {
				await queryFulfilled;
			} catch {
				patchResult.undo();
			}
		},
		invalidatesTags: (_res, _, { id }) => [{ type: "ConditionReport", id }],
	}),
});

gatewayApi.injectEndpoints({
	endpoints: conditionReportEndpoints,
	overrideExisting: true,
});

gatewayApi.enhanceEndpoints({
	addTagTypes: ["ConditionReport"],
});

export const {
	useGetConditionReportQuery,
	useSubmitConditionReportMutation,
	endpoints: { getConditionReport, submitConditionReport },
} = gatewayApi;

listenerMiddleware.startListening({
	predicate: submitConditionReport.matchRejected,
	effect: async (action, api) => {
		if (selectIsOnline(api.getState()) && !selectIsSyncing(api.getState())) {
			api.dispatch(
				openToast({
					id: "conditionReport",
					labels: {
						heading: `Error - Condition Report - ${action.payload?.status}`,
						details: `Something went wrong with this request. Please Try again later.`,
					},
					variant: "error",
					timeout: 5000,
				})
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: syncConditionReports,
	effect: async (action, api) => {
		api.cancelActiveListeners();

		const conditionReportIds = await offlineConditionReportUploadsDB.keys();

		const uploads = await Promise.all(
			conditionReportIds.map(async (id) => {
				const report = await offlineConditionReportUploadsDB.getItem(id);

				const submission = api.dispatch(submitConditionReport.initiate(report));

				try {
					await submission.unwrap();
					await api.dispatch(removeOfflineConditionReport.initiate(id));
					return { unsubscribe: submission.unsubscribe };
				} catch (error) {
					return { error, unsubscribe: submission.unsubscribe };
				}
			})
		);

		uploads.forEach((upload) => upload.unsubscribe());

		api.dispatch(syncConditionReportsDone());
	},
});

listenerMiddleware.startListening({
	type: "conditionReportCacheDidUpdate",
	effect: async (action, api) => {
		api.dispatch(
			gatewayApi.util.updateQueryData(
				"getConditionReport",
				action.payload.homeId,
				(draft) => {
					Object.assign(draft, [...draft, ...action.payload.data]);
				}
			)
		);
	},
});
