import { Button, ExpandableSection } from "@salesforce/design-system-react";
import { PricingWorksheetForm } from "./components/PricingWorksheetForm";
import styles from "./PricingWorksheet.module.scss";
import { usePricingWorksheet } from "./usePricingWorksheet";
import { WorksheetTitle } from "./components/WorksheetTitle";
import { PricingInfoForm } from "./components/PricingInfoForm";
import { usePricingWorksheetHistory } from "./hooks/usePricingWorksheetHistory";
import { FormsProvider } from "@/Components/Forms/components/Forms";

export const PricingWorksheet = ({ assetId }: { assetId: string }) => {
	const {
		pricingWorksheets,
		currentWorksheet,
		isSuccess,
		onSubmit,
		isFetching,
		accountNumber,
	} = usePricingWorksheet(assetId);

	const [isOpenHistory, setIsOpenHistory] = usePricingWorksheetHistory();

	return (
		<FormsProvider>
			<div className={styles.formContainer}>
				<div style={{ padding: "0 1rem" }}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							padding: ".25rem",
						}}
					></div>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							paddingLeft: ".25rem",
						}}
					>
						<a
							className="slds-button slds-button_outline-brand"
							href={`https://www.cmhlink.net/vmf/remarketing/fieldmgr/AccountManager.cfm?account=${accountNumber}&section=rw`}
							target="_blank"
							rel="noreferrer noopener"
						>
							Open in LINK
						</a>
						<a
							className="slds-button slds-button_brand"
							href="https://zillow.com"
							target="_blank"
							rel="noreferrer noopener"
						>
							Visit Zillow
						</a>
						<a
							className="slds-button slds-button_brand"
							href="https://realtor.com"
							target="_blank"
							rel="noreferrer noopener"
						>
							Visit Realtor.com
						</a>
						<a
							className="slds-button slds-button_brand"
							href="https://nada.org"
							target="_blank"
							rel="noreferrer noopener"
						>
							Visit NADA
						</a>
					</div>
					<PricingInfoForm homeId={assetId} />
					<ExpandableSection title="Worksheets">
						{isOpenHistory ? (
							<>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "0 1rem ",
										paddingTop: 0,
										marginTop: 0,
									}}
								>
									<Button
										variant="base"
										onClick={setIsOpenHistory}
										label={isOpenHistory ? "Close" : "History"}
										iconName={isOpenHistory ? "close" : "skip_back"}
										iconCategory="utility"
										iconPosition="left"
									/>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: ".5rem",
									}}
								>
									{pricingWorksheets.map((worksheet, index) => {
										return (
											<WorksheetTitle
												data={worksheet}
												homeId={assetId}
												index={index}
												key={`worksheet-${worksheet?.id ?? "new"}`}
											/>
										);
									})}
								</div>
							</>
						) : (
							<>
								{isSuccess && (
									<PricingWorksheetForm
										data={currentWorksheet}
										homeId={assetId}
										onSubmit={onSubmit}
										isFetching={isFetching}
									/>
								)}
							</>
						)}
					</ExpandableSection>
				</div>
			</div>
		</FormsProvider>
	);
};
