import { useTags } from "../../hooks/useTags";
import classes from "../PhotoToolbar/PhotoToolbar.module.scss";
import { Tag } from "./Tag";
import { Dropdown } from "@salesforce/design-system-react";

export const MediaPreviewTags = ({ image }) => {
	const { tags, addTag, removeTag, availableTags } = useTags(image);

	return (
		<div className={classes.tagsContainer}>
			{tags.map((tag) => {
				return <Tag key={tag} value={tag} onRemove={() => removeTag(tag)} />;
			})}
			<>
				<Dropdown
					id="add-tags"
					label={tags.length > 0 ? "" : " Add Tags"}
					iconCategory="utility"
					iconName="add"
					buttonVariant="icon"
					iconSize="medium"
					buttonInverse
					align="left"
					width="xx-small"
					className={classes.tagsDropdown}
					listItemRenderer={(option) => <Tag value={option.label} />}
					options={availableTags.map((tag) => ({ label: tag }))}
					onSelect={(item) => addTag(item.label)}
					triggerClassName={classes.tagsAddButton}
				></Dropdown>
			</>
		</div>
	);
};
