import { useNavigate } from "react-router-dom";
import classes from "./NotificationCard.module.scss";
import { get } from "lodash";
import { ACCOUNT_NUMBER, ADDRESS, CITY, CUSTOMER_NAME, STATE, ZIP } from "../asset/constants";

export const NotificationCard = ({ error, item, Badge }) => {
	const navigate = useNavigate();
	const root = "/account/" + item.account.id + "/asset/" + item.id;
	return (
		<article
			className={classes.errorCard}
			onClick={() => navigate(root + "?tab=details")}
		>
			<div
				style={{
					width: "100%",
					boxShadow: "0 0px 2px 0px rgba(0, 0, 0, 0.103)",
					backgroundColor: "rgb(240, 240, 240)",
					borderTopRightRadius: "5px",
					borderTopLeftRadius: "5px",
					padding: "4px",
				}}
			>
				<Badge />
				<span style={{ paddingLeft: "4px" }}>{error}</span>
			</div>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr",
					padding: ".5rem",
				}}
			>
				<div>
					<div className={classes.account}>
						<a
							href={root + "?tab=details"}
							onClick={(event) => {
								event.preventDefault();
								navigate(root + "?tab=details");
							}}
						>
							{get(item, ACCOUNT_NUMBER.path)}{" "}
							<p className={classes.name}>{get(item, CUSTOMER_NAME.path)}</p>
						</a>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<p>{get(item, ADDRESS.path)}</p>
					<p>
						{get(item, CITY.path)}, {get(item, STATE.path)},{" "}
						{get(item, ZIP.path)}
					</p>
				</div>
			</div>
		</article>
	);
};
