import { Badge } from "@salesforce/design-system-react";
import React from "react";
// Review Status object has keys of review status and values of color for indicators
export const reviewStatusesObject = {
	needsReview: { displayValue: "Needs Review", color: "light" },
	autoRefund: { displayValue: "Auto Refund", color: "inverse" },
	pending: { displayValue: "Pending", color: "warning" },
	completed: { displayValue: "Completed", color: "success" },
};

export const ReviewStatusBadge = ({ reviewStatus, ...props }) => {
	return (
		<Badge
			content={reviewStatusesObject[reviewStatus]?.displayValue || reviewStatus}
			color={reviewStatusesObject[reviewStatus]?.color}
		/>
	);
};
