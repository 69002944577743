import {
	useGetCurrentUserQuery,
	useUpdateUserMutation,
} from "@/features/user/api/endpoints";
import { columns as defaultColumns } from "../components/columns/columns";
import { gatewayApi } from "@/ReduxToolkit";
import { useDispatch } from "react-redux";
import { useMemo, useState } from "react";

export const useColumns = () => {
	const {
		data: columns,
		isSuccess,
		refetch,
	} = useGetCurrentUserQuery(undefined, {
		selectFromResult: ({ data, ...rest }) => {
			const saved = data?.assetListColumns ?? [];

			const columns = Object.entries(defaultColumns)
				.map(([key, value], index) => {
					return [
						key,
						{
							...value,
							...(saved?.length
								? {
										show: saved?.[key]?.show,
										order: saved?.[key]?.order,
								  }
								: {
										show: true,
										order: index,
								  }),
						},
					];
				})
				.sort((a, b) => a.order - b.order);

			return {
				data: Object.fromEntries(columns),
				...rest,
			};
		},
	});

	const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

	const columnState = useMemo(
		() =>
			Object.fromEntries(
				Object.entries(columns).map(([key, { show, order }]) => [
					key,
					{ show, order },
				])
			),
		[columns]
	);

	const [isDirty, setIsDirty] = useState(false);
	const dispatch = useDispatch();

	const patchUser = (update) => {
		dispatch(
			gatewayApi.util.updateQueryData("getCurrentUser", undefined, (user) => {
				const change = { ...user, assetListColumns: update };
				Object.assign(user, change);
			})
		);
	};

	const reset = async () => {
		await refetch();
		setIsDirty(false);
	};

	const hideColumn = async (column) => {
		const update = {
			...columnState,
			[column]: { show: false, order: columns[column].order },
		};
		patchUser(update);
		setIsDirty(true);
	};

	const showColumn = async (column) => {
		const update = {
			...columnState,
			[column]: { show: true, order: columns[column].order },
		};

		patchUser(update);
		setIsDirty(true);
	};

	const save = async () => {
		await updateUser({ assetListColumns: columnState });
		setIsDirty(false);
	};

	return {
		columns,
		hideColumn,
		showColumn,
		isSuccess,
		isUpdating,
		isDirty,
		save,
		reset,
	};
};
