import React from "react";
import { Icon, PageHeader } from "@salesforce/design-system-react";
import { useSales } from "../hooks/useSales";
import { usePayments } from "../hooks/usePayments";
import classes from "./Header.module.scss";
import { PaymentAllocation } from "../types";

const sumPaymentsWithSaleId = (
	saleId: string,
	paymentAllocations: PaymentAllocation[]
) => {
	const amounts = paymentAllocations.map((allocation) => {
		if (allocation.saleId === saleId) {
			return allocation.allocatedAmount;
		}
		return 0;
	});
	const sum = amounts.reduce(
		(accumulator, currentValue) => accumulator + currentValue,
		0
	);
	return sum;
};

export const Header = ({ saleId }: { saleId: string | undefined }) => {
	const { data: salesInfo, isSuccess: isSuccessSales } = useSales(saleId);
	const { data: funds } = usePayments(saleId ?? "");
	const totalPaidOff =
		funds
			?.map((item) =>
				sumPaymentsWithSaleId(saleId ?? "", item.paymentAllocations)
			)
			.reduce((accumulator, currentValue) => accumulator + currentValue, 0) ??
		0;
	const totalSaleAmount = salesInfo?.totalAmount ?? 0;
	const remainingBalance = totalSaleAmount - totalPaidOff;

	const details = [
		{
			label: "Total Sale Amount",
			content: numToDollarFormat(totalSaleAmount),
		},
		{
			label: "Remaining Balance",
			content: numToDollarFormat(remainingBalance),
		},
	];

	return (
		<div className={classes.bookingHeader}>
			<PageHeader
				details={isSuccessSales ? details : null}
				icon={
					<Icon
						assistiveText={{
							label: "Home",
						}}
						category="standard"
						name="contract_line_item"
						title="Home"
					/>
				}
				label="Buyer, Sales, and Source of Funds"
				title="Booking"
				truncate
				variant="record-home"
			/>
		</div>
	);
};

const numToDollarFormat = (num: number) => {
	return num.toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
	});
};
