import { Input } from "./Input";
import { Select } from "./Select";

export const WorksheetInput = (props: any) => {
	switch (props.type) {
		case "enum":
			return <Select {...props} />;
		default:
			return <Input {...props} />;
	}
};
