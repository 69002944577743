import React from "react";
import { useController } from "react-hook-form";
import { VisualPicker, Radio } from "@salesforce/design-system-react";
import classes from "./ConcurrencyRadioPicker.module.scss";
import { useIsMobile } from "@/Responsive";
export const ConcurrencyRadioPicker = ({ name, ...props }) => {
	const {
		field: { ref, ...rest },
	} = useController({
		name: name,
	});
	const isMobile = useIsMobile();
	return (
		<div
			style={{
				padding: "0rem 0rem ",
				gridColumn: `span ${isMobile ? 4 : 8}`,
			}}
		>
			<h1>{props.label}</h1>
			<div className={classes.VisualPickerWrapper}>
				<VisualPicker
					label="Please Select a Note to Keep"
					id={`${name}-visual-picker`}
					className={classes.VisualPicker}
				>
					<Radio
						{...rest}
						onChange={() => rest.onChange(props.options[0].value)}
						className={classes.Radio}
						id={`${name}-option-1`}
						onRenderVisualPicker={() => (
							<span className={classes.Radio}>
								<span className="slds-text-title">
									{props.options[0].label}
								</span>
							</span>
						)}
					/>
					<Radio
						{...rest}
						id={`${name}-option-2`}
						className={classes.Radio}
						onChange={() => rest.onChange(props.options[1].value)}
						onRenderVisualPicker={() => (
							<span className={classes.Radio}>
								<span className="slds-text-title">
									{props.options[1].label}
								</span>
							</span>
						)}
					/>
				</VisualPicker>
			</div>
		</div>
	);
};
