import { useFormSection } from "@/Components/Forms";
import { Button, Textarea } from "@salesforce/design-system-react";
import { useController } from "react-hook-form";
import { useState } from "react";
import { usePricingWorksheet } from "../usePricingWorksheet";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { useIsManager } from "@/providers/roles/AccessHooks";

interface WorksheetCommentProps {
	name: string;
}

export const WorksheetComment = ({ name }: WorksheetCommentProps) => {
	const { isStatic, editField, isFetching } = useFormSection("comment");
	const [isHovered, setIsHovered] = useState(false);
	const { assetId } = useAssetId();
	const { approvalStatus } = usePricingWorksheet(assetId);
	const isManager = useIsManager();

	const isEditable =
		!isFetching &&
		(approvalStatus === "In Progress" ||
			(approvalStatus === "Pending Approval" && isManager));

	const {
		field: { value, ref, ...rest },
	} = useController({ name });

	return (
		<div
			style={{
				display: "flex",
				width: "100%",
				minWidth: "100%",
			}}
			{...(isEditable && {
				onMouseEnter: () => {
					setIsHovered(true);
				},
				onMouseLeave: () => {
					setIsHovered(false);
				},
				onDoubleClick: () => editField(name),
			})}
		>
			<h3
				style={{
					width: "250px",
					padding: ".25rem .5rem",
				}}
			>
				Comments
			</h3>
			{isStatic ? (
				<div
					style={{
						display: "flex",
						width: "100%",
						padding: ".25rem .5rem",
					}}
				>
					<p
						style={{
							width: "100%",
						}}
					>
						{value}
					</p>
					<Button
						variant="icon"
						iconCategory="utility"
						iconName="edit"
						style={{
							marginLeft: ".5rem",
							visibility: isHovered ? "visible" : "hidden",
						}}
						onClick={() => editField(name)}
					></Button>
				</div>
			) : (
				<div
					style={{
						width: "100%",
						padding: ".25rem .5rem",
					}}
				>
					<Textarea textareaRef={ref} {...value} {...rest} />
				</div>
			)}
		</div>
	);
};
