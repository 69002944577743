import { useFormContext } from "react-hook-form";
import { useSubcategory } from "../components/PricingWorksheetForm";
import { getColumn } from "../components/utils";
import {
	BASE_PRICE,
	CREDITS,
	LOT_RENT,
	TRANSPORT_TO_HOME_CENTER,
	DELIVERY,
	REFURBISHING,
	APPLIANCES,
	HVAC,
	MOWING,
	UTILITIES,
	TRASH_OUT,
	DECK,
	F_H_A_PREP,
	GENERAL_REFURB,
	OTHER_REFURBISHING_EXEPENSES,
	LAND_IMPROVEMENTS,
	LAND_PURCHASE_PRICE,
	BUILDING_PERMIT,
	LAND_CLEARING,
	DRIVEWAY,
	GRAVEL,
	FOOTERS,
	FRENCH_DRAIN,
	SEPTIC,
	SEPTIC_PERMIT,
	SEWER_LINE,
	WATER_LINE,
	WELL_PUMP,
	ELECTRIC,
	OTHER_LAND_IMPROVEMENTS,
	OTHER_EXPENSES,
	SURVEY,
	SALE_PRICE,
	CLOSING_COSTS_PERCENT,
	COMMISSION_PERCENT,
	COMMENTS,
} from "../constants";

const fields = [
	BASE_PRICE,
	CREDITS,
	LOT_RENT,
	TRANSPORT_TO_HOME_CENTER,
	DELIVERY,
	REFURBISHING,
	APPLIANCES,
	HVAC,
	MOWING,
	UTILITIES,
	TRASH_OUT,
	DECK,
	F_H_A_PREP,
	GENERAL_REFURB,
	OTHER_REFURBISHING_EXEPENSES,
	LAND_IMPROVEMENTS,
	LAND_PURCHASE_PRICE,
	BUILDING_PERMIT,
	LAND_CLEARING,
	DRIVEWAY,
	GRAVEL,
	FOOTERS,
	FRENCH_DRAIN,
	SEPTIC,
	SEPTIC_PERMIT,
	SEWER_LINE,
	WATER_LINE,
	WELL_PUMP,
	ELECTRIC,
	OTHER_LAND_IMPROVEMENTS,
	OTHER_EXPENSES,
	SURVEY,
	CLOSING_COSTS_PERCENT,
	COMMISSION_PERCENT,
	SALE_PRICE,
	COMMENTS,
];

export const useNextField = ({ name }: { name: string }) => {
	const column = getColumn(name);
	const { setFocus } = useFormContext();
	const { isOpen, toggleOpen } = useSubcategory();

	const columnFields = fields.map((field) => `${column}.${field.key}`);
	const currentFieldIndex = columnFields.findIndex((field) => field === name);
	const nextFieldName = columnFields[currentFieldIndex + 1]?.split(".")?.[1];

	const nextField = async () => {
		if (
			nextFieldName === "refurbishing" ||
			nextFieldName === "landImprovements"
		) {
			if (!isOpen(nextFieldName)) {
				await new Promise<void>((res) => {
					toggleOpen(nextFieldName);
					res();
				});
			}
			setFocus(columnFields[currentFieldIndex + 2], { shouldSelect: true });
		} else if (nextFieldName === COMMENTS.key) {
			setFocus(COMMENTS.key);
		} else {
			setFocus(columnFields[currentFieldIndex + 1], { shouldSelect: true });
		}
	};

	return nextField;
};
