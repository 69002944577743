import React from "react";
import { Textarea } from "@salesforce/design-system-react";
import { useController, useFormContext } from "react-hook-form";
import classes from "./ControlledTextArea.module.scss";
import { StaticTextArea } from "./StaticTextArea";
import { ConcurrencyRadioPicker } from "../ControlledConcurrency/ConcurrencyRadioPicker";
import { removeSpecialCharacters } from "@/Utilities/utils";
import { useFormSection } from "../ControlledForm/FormSection";
export const TextareaInput = ({ ...props }) => {
	const {
		field: { ref, ...rest },
		fieldState: { error },
	} = useController({ name: props.name });

	const { focusField } = useFormContext();
	const { isStatic, onClickEdit, isReadOnly } = useFormSection();

	const value = rest.value || "";

	return isStatic ? (
		<div className={classes.gridItem}>
			<StaticTextArea
				name={props.name}
				key={`field-${props.name}`}
				label={props.label || props.labels.label}
				value={rest.value}
				onClickEdit={() => onClickEdit(props.name)}
				readOnly={props.readOnly || isReadOnly}
				style={{ gridColumn: `span ${props.span ?? 4}` }}
				{...props}
			/>
		</div>
	) : (
		<div className={props.className ?? classes.gridItem}>
			<label
				htmlFor={`textbox-${props.name}`}
				className="slds-form-element__label"
			>
				{props.label}
			</label>
			<Textarea
				id={`textbox-${props.name}`}
				autoFocus={focusField === props.name}
				disabled={props.readOnly}
				name={props.name}
				errorText={error?.message}
				{...rest}
				value={value}
				onChange={(e) => {
					rest.onChange(removeSpecialCharacters(e.target.value));
				}}
			/>
		</div>
	);
};

export const ControlledTextarea = ({ ...props }) => {
	const {
		field: { value },
	} = useController({
		name: props.name,
	});
	const { isConcurrencyError, concurrencyDiff } = useFormContext();

	return !isConcurrencyError ? (
		<TextareaInput {...props} />
	) : (
		<>
			{concurrencyDiff[props.name] &&
			hasConcurrencyError(concurrencyDiff[props.name]) &&
			!isNewOrDeleted(concurrencyDiff[props.name]) ? (
				<ConcurrencyRadioPicker
					{...props}
					options={concurrencyOptions(concurrencyDiff[props.name])}
				/>
			) : (
				<StaticTextArea
					name={props.name}
					key={`field-${props.name}`}
					label={props.label || props.labels.label}
					{...props}
					value={value}
					readOnly={true}
					disabled={true}
					errorText={
						hasConcurrencyError(concurrencyDiff[props.name])
							? "Save to Resolve"
							: null
					}
				/>
			)}
		</>
	);
};

function hasConcurrencyError(concurrencyField) {
	return Array.isArray(concurrencyField);
}

function isNewOrDeleted(concurrencyField) {
	const value =
		concurrencyField?.filter((element) => element !== undefined).length < 2;
	return value;
}

function concurrencyOptions(concurrencyDiff) {
	return concurrencyDiff.map((field) => {
		return {
			label: field ? field.toString() : "-- Leave Blank --",
			value: field,
		};
	});
}
