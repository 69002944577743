import { useContext, useEffect, useMemo } from "react";
import { useFormFocus } from "./useFormFocus";
import { FormSectionContext } from "../providers/FormSectionProvider";
import { FormsContext } from "../components/Forms";

export const useFormSection = (section: string | null = null) => {
	const {
		sections,
		setSections,
		activateSection,
		isEditable,
		isFetching,
		formId,
	} = useContext(FormSectionContext);

	const { setFocusField } = useFormFocus();

	const formsContext = useContext(FormsContext);

	const isOpenForm = formsContext?.openForm === formId;

	useEffect(() => {
		if (section && setSections) {
			setSections((sections) => {
				if (Object.keys(sections).includes(section)) return sections;
				return { ...sections, [section]: false };
			});
		}
	}, [section, setSections]);

	const isActive = useMemo(() => {
		if (section) {
			return sections?.[section];
		} else {
			return Object.values(sections)?.reduce((acc, c) => acc || c, false);
		}
	}, [section, sections]);

	const editField = (field: string) => {
		if (section) {
			activateSection(section);
		}
		if (formsContext) {
			formsContext.setOpenForm(formId);
		}
		setFocusField(field);
	};

	return {
		isStatic: !isActive,
		editField,
		isEditable:
			isEditable &&
			!(
				formsContext !== undefined &&
				formsContext.openForm !== null &&
				!isOpenForm
			),
		isFetching,
	};
};
