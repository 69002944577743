import { Combobox } from "@salesforce/design-system-react";
import { approvedTags } from "../../hooks/useTags";

export function TagsSelect({ multiple, predicate, setPredicate, ...props }) {
	const options = approvedTags.map((tag) => ({
		id: `option-${tag}`,
		label: tag,
		value: tag,
	}));

	const selection = multiple
		? options.filter((option) => predicate?.includes(option.value))
		: options.filter((option) => option.value === predicate);

	return (
		<Combobox
			placeholder={`Select Tag${props.multiple ? "(s)" : ""}`}
			variant="readonly"
			multiple={multiple}
			value={
				selection.length > 0
					? `${selection.length} selected`
					: selection[0]?.value
			}
			selection={selection}
			options={options}
			events={{
				onSelect: (e, { selection: values }) => {
					const value = multiple
						? values.map((selected) => selected?.value)
						: values[0]?.value;
					setPredicate(value);
				},
				onRequestRemoveSelectedOption: (e, { selection }) => {
					setPredicate(selection.map((item) => item.value));
				},
			}}
			deselectOption={multiple ? [] : ""}
			hasDeselect
			menuPosition="relative"
		/>
	);
}
