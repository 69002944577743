import { concatErrorCache } from "@/ReduxToolkit/GatewayApi";

export const getInsuranceQueue = (builder) =>
	builder.query({
		query: () => ({
			url: "api/InsuranceCancellationQueue",
			method: "GET",
		}),
		transformResponse: (response) => {
			return response.map((item) => {
				return {
					...item,
					id: item.id.toString(),
				};
			});
		},
		providesTags: (result, error, id) => {
			if (error) {
				return concatErrorCache(
					[{ type: "InsuranceLoans", id: "LIST" }],
					error
				);
			}
			return [{ type: "InsuranceLoans", id: "LIST" }];
		},
	});

export const requestInsuranceCancellation = (builder) =>
	builder.mutation({
		query: (data) => ({
			url: "api/InsuranceCancellationQueue/requestInsuranceCancellation",
			method: "PUT",
			body: data,
		}),
		invalidatesTags: [{ type: "InsuranceLoans", id: "LIST" }],
	});
