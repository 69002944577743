import classes from "./ConditionReportContent.module.scss";
import { useConditionReport } from "../../api/useConditionReport";
import { LineItem } from "./LineItem";

export const CompletedReport = () => {
	const { conditionReport, isSuccess } = useConditionReport();

	return (
		<>
			{isSuccess && (
				<div className={classes.conditionReportContent}>
					{conditionReport?.lineItems?.map((item, index) => {
						return <LineItem key={index} lineItem={item} />;
					})}
				</div>
			)}
		</>
	);
};
