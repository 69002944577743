import {
	ControlledPriceInput,
	ControlledPriceCheckbox,
	ControlledCheckboxDisable,
} from "../components/Form/ControlledPrice";
import {
	CARPET_ROOM,
	CEILING_FAN,
	DISHWASHER,
	FLOOR_REGISTER,
	INTERIOR_DOOR,
	INTERIOR_TRIM_WORK,
	KILZ_FLOOR_PER_ROOM,
	KITCHEN,
	KITCHEN_CABINET_REPAIR,
	KITCHEN_FAUCET,
	KITCHEN_SINK,
	labelsIndex,
	LIGHT_FIXTURE,
	ONE_INCH_BLINDS,
	OUTLET_LIGHT_REPAIR,
	OVER_STOVE_MICROWAVE,
	PAINT_CEILING,
	PAINT_DOOR,
	PAINT_WALLS,
	RANGE_HOOD,
	REFRIGERATOR,
	REPLACE_FLOOR_DECKING,
	REPLACE_WALL_BOARD,
	SMOKE_ALARM,
	STOVE_RANGE,
	WINDOW,
} from "../constants/constants";

export const kitchenConfig = [
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${KITCHEN}.${DISHWASHER}`,
			costName: DISHWASHER,
			label: labelsIndex[DISHWASHER],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${KITCHEN}.${OVER_STOVE_MICROWAVE}`,
			costName: OVER_STOVE_MICROWAVE,
			label: labelsIndex[OVER_STOVE_MICROWAVE],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${KITCHEN}.${RANGE_HOOD}`,
			costName: RANGE_HOOD,
			label: labelsIndex[RANGE_HOOD],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${KITCHEN}.${REFRIGERATOR}`,
			costName: REFRIGERATOR,
			label: labelsIndex[REFRIGERATOR],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${KITCHEN}.${STOVE_RANGE}`,
			costName: STOVE_RANGE,
			label: labelsIndex[STOVE_RANGE],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${KITCHEN}.${CEILING_FAN}`,
			costName: CEILING_FAN,
			label: labelsIndex[CEILING_FAN],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${KITCHEN}.${INTERIOR_TRIM_WORK}`,
			costName: INTERIOR_TRIM_WORK,
			label: labelsIndex[INTERIOR_TRIM_WORK],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${KITCHEN}.${KITCHEN_FAUCET}`,
			costName: KITCHEN_FAUCET,
			label: labelsIndex[KITCHEN_FAUCET],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${KITCHEN}.${KITCHEN_SINK}`,
			costName: KITCHEN_SINK,
			label: labelsIndex[KITCHEN_SINK],
		},
	},
	{
		component: ControlledCheckboxDisable,
		props: {
			name: `${KITCHEN}.${PAINT_WALLS}`,
			watchField: "general.paintEntireHome",
			costName: PAINT_WALLS,
			label: labelsIndex[PAINT_WALLS],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${KITCHEN}.${PAINT_CEILING}`,
			costName: PAINT_CEILING,
			label: labelsIndex[PAINT_CEILING],
		},
	},
	{
		component: ControlledCheckboxDisable,
		props: {
			name: `${KITCHEN}.${CARPET_ROOM}`,
			watchField: "general.carpetEntireHome",
			costName: CARPET_ROOM,
			label: labelsIndex[CARPET_ROOM],
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${ONE_INCH_BLINDS}`,
			costName: ONE_INCH_BLINDS,
			label: labelsIndex[ONE_INCH_BLINDS],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${FLOOR_REGISTER}`,
			costName: FLOOR_REGISTER,
			label: labelsIndex[FLOOR_REGISTER],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${KILZ_FLOOR_PER_ROOM}`,
			costName: KILZ_FLOOR_PER_ROOM,
			label: labelsIndex[KILZ_FLOOR_PER_ROOM],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${INTERIOR_DOOR}`,
			costName: INTERIOR_DOOR,
			label: labelsIndex[INTERIOR_DOOR],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${KITCHEN_CABINET_REPAIR}`,
			costName: KITCHEN_CABINET_REPAIR,
			label: labelsIndex[KITCHEN_CABINET_REPAIR],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${LIGHT_FIXTURE}`,
			costName: LIGHT_FIXTURE,
			label: labelsIndex[LIGHT_FIXTURE],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${OUTLET_LIGHT_REPAIR}`,
			costName: OUTLET_LIGHT_REPAIR,
			label: labelsIndex[OUTLET_LIGHT_REPAIR],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${PAINT_DOOR}`,
			costName: PAINT_DOOR,
			label: labelsIndex[PAINT_DOOR],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${REPLACE_FLOOR_DECKING}`,
			costName: REPLACE_FLOOR_DECKING,
			label: labelsIndex[REPLACE_FLOOR_DECKING],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${REPLACE_WALL_BOARD}`,
			costName: REPLACE_WALL_BOARD,
			label: labelsIndex[REPLACE_WALL_BOARD],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${SMOKE_ALARM}`,
			costName: SMOKE_ALARM,
			label: labelsIndex[SMOKE_ALARM],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${KITCHEN}.${WINDOW}`,
			costName: WINDOW,
			label: labelsIndex[WINDOW],
			type: "number",
		},
	},
];
