import { ReactNode, createContext, useState } from "react";
import { Task, TaskListPayload } from "../Types";
import { DEFAULT_TASK_QUERY } from "./useTasksQuery";
import { useSearchParams } from "react-router-dom";

interface TaskListContextType {
	query: TaskListPayload;
	setQuery: (query: TaskListPayload) => void;
	selectedTasks: Task[];
	setSelectedTasks: (tasks: Task[]) => void;
	filtersOpen: boolean;
	setFiltersOpen: (open: boolean) => void;
}

interface TaskListProviderProps {
	children: ReactNode;
	defaultQuery?: TaskListPayload;
}

export const TaskListContext = createContext({} as TaskListContextType);

export const TaskListProvider = ({
	children,
	defaultQuery = DEFAULT_TASK_QUERY,
}: TaskListProviderProps) => {
	const [query, setQuery] = useState<TaskListPayload>(defaultQuery);
	const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
	const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

	const [, setSearchParams] = useSearchParams();

	const changeQuery = (newQuery: TaskListPayload) => {
		setQuery(newQuery);
		if (
			newQuery.sortCriteria !== DEFAULT_TASK_QUERY.sortCriteria &&
			newQuery.name
		) {
			setSearchParams({
				sort: newQuery.sortCriteria.propertyPath,
				direction: newQuery.sortCriteria.direction,
				view: newQuery.name,
			});
		}
	};

	return (
		<TaskListContext.Provider
			value={{
				query,
				setQuery: changeQuery,
				selectedTasks,
				setSelectedTasks,
				filtersOpen,
				setFiltersOpen,
			}}
		>
			{children}
		</TaskListContext.Provider>
	);
};
